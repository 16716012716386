import { SectionRole } from "entities/sectionRole";
import { useMemo } from "react";
import {
  useGetSectionRoleQuery,
  useGetSectionRolesQuery,
} from "./sectionRoleEndpoints";

export function useSectionRole(sectionRoleID: number | undefined) {
  const sectionRoleQuery = useGetSectionRoleQuery(sectionRoleID ?? 0, {
    skip: (sectionRoleID ?? 0) <= 0,
  });
  const sectionRoleEntity = sectionRoleQuery.data;

  return useMemo(() => {
    const ret: typeof sectionRoleQuery & {
      sectionRole?: SectionRole | undefined;
    } = {
      ...sectionRoleQuery,
    };
    if (sectionRoleEntity) ret.sectionRole = new SectionRole(sectionRoleEntity);
    return ret;
  }, [sectionRoleEntity]);
}

export function useSectionRoles(opt?: { skip: boolean }) {
  const sectionRoleQuery = useGetSectionRolesQuery(null, opt);
  const sectionRoleEntitiesMap = sectionRoleQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof sectionRoleQuery & {
      sectionRoles?: SectionRole[];
      sectionRolesMap?: { [id: number]: SectionRole };
    } = {
      ...sectionRoleQuery,
      sectionRoles: [],
      sectionRolesMap: {},
    };
    if (sectionRoleEntitiesMap) {
      const sectionRoles = [];
      const sectionRolesMap = {};

      for (const key in sectionRoleEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(sectionRoleEntitiesMap, key)) {
          const c = sectionRoleEntitiesMap[key];
          const sectionRole = new SectionRole(c);
          sectionRoles.push(sectionRole);
          sectionRolesMap[sectionRole.id] = sectionRole;
        }
      }
      sectionRoles.sort((a, b) => a.position - b.position);
      ret.sectionRoles = sectionRoles;
      ret.sectionRolesMap = sectionRolesMap;
    }

    return ret;
  }, [sectionRoleEntitiesMap]);
}
