import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Project_Entity } from "entities/project";
import { Tag_Entity } from "entities/tag";
import { rhapsodyApi } from "../api/rhapsodyApi";
import { Autopilot_Entity } from "entities/autopilot";
import { Email_Entity } from "entities/email";
import { PdProject_Entity } from "entities/pdProject";

const projectsAdapter = createEntityAdapter<Project_Entity>();
const emailsAdapter = createEntityAdapter<Email_Entity>();
const emailInitialState = emailsAdapter.getInitialState();
const initialState = projectsAdapter.getInitialState();

export const projectEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProject: build.query<Project_Entity, number>({
      query: (id) => `projects/${id}`,
      providesTags: (result, error, id) => [{ type: "projects", id }],
    }),
    duplicateProject: build.query<Project_Entity, number>({
      query: (id) => `projects/${id}/duplicate`,
      providesTags: (result, error, id) => [{ type: "projects", id }],
    }),
    getProjects: build.query<
      EntityState<Project_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `projects`,
          params: args,
        };
      },
      transformResponse: (responseData: Project_Entity[]) => {
        return projectsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["projects"],
    }),
    getProjectEmails: build.query<
      EntityState<Email_Entity>,
      { projectID: number; filters: Record<string, unknown> | void }
    >({
      query: (body) => {
        return {
          url: `projects/${body.projectID}/emails`,
        };
      },
      transformResponse: (responseData: Email_Entity[]) => {
        return emailsAdapter.setAll(emailInitialState, responseData);
      },
      providesTags: ["emails"],
    }),
    getProjectAutopilot: build.query<Autopilot_Entity, number>({
      query: (projectID) => `projects/${projectID}/autopilot`,
      providesTags: (result, error, projectID) => [
        { type: "autopilot", id: projectID },
      ],
    }),
    updateProjectAutopilot: build.mutation<
      void,
      { projectID: number; body: Partial<Autopilot_Entity> }
    >({
      query: ({ projectID, body }) => ({
        method: "PUT",
        body: body,
        url: `projects/${projectID}/autopilot`,
      }),
      invalidatesTags: ["projects"],
    }),

    getAllProjects: build.query<
      EntityState<Project_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `projects`,
          params: args,
        };
      },
      transformResponse: (responseData: Project_Entity[]) => {
        return projectsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["projects"],
    }),
    getActiveProjects: build.query<EntityState<Project_Entity>, any | void>({
      query: (args) => {
        let filters: any = [
          {
            name: "archived",
            comparison: "eq",
            value: false,
          },
        ];
        if (args?.filters) {
          filters = [...filters, ...args.filters];
        }
        return {
          url: `projects`,
          params: {
            filters: JSON.stringify(filters),
          },
        };
      },
      transformResponse: (responseData: Project_Entity[]) => {
        return projectsAdapter.setAll(initialState, responseData);
      },
      providesTags: [{ type: "projects", id: "active" }],
    }),
    getArchivedProjects: build.query<EntityState<Project_Entity>, any | void>({
      query: (args) => {
        let filters: any = [
          {
            name: "archived",
            comparison: "eq",
            value: true,
          },
        ];
        if (args?.filters) {
          filters = [...filters, ...args.filters];
        }
        return {
          url: `projects`,
          params: {
            filters: JSON.stringify(filters),
          },
        };
      },
      transformResponse: (responseData: Project_Entity[]) => {
        return projectsAdapter.setAll(initialState, responseData);
      },
      providesTags: [{ type: "projects", id: "archived" }],
    }),
    getCustomerProjects: build.query<EntityState<Project_Entity>, number>({
      query: (customerID) => `customers/${customerID}/projects`,
      transformResponse: (responseData: Project_Entity[]) => {
        return projectsAdapter.setAll(initialState, responseData);
      },
      providesTags: (r, e, customerID) => [
        { type: "projects", id: `customer_${customerID}` },
      ],
    }),
    getPayrollProjects: build.query<EntityState<Project_Entity>, number>({
      query: (payrollID) => `payrolls/${payrollID}/projects`,
      transformResponse: (responseData: Project_Entity[]) => {
        return projectsAdapter.setAll(initialState, responseData);
      },
      providesTags: (r, e, payrollID) => [
        { type: "projects", id: `payroll_${payrollID}` },
      ],
    }),
    getMusicianProjects: build.query<EntityState<Project_Entity>, number>({
      query: (musicianID) => `musicians/${musicianID}/projects`,
      transformResponse: (responseData: Project_Entity[]) => {
        return projectsAdapter.setAll(initialState, responseData);
      },
      providesTags: (r, e, musicianID) => [
        { type: "projects", id: `musician_${musicianID}` },
      ],
    }),
    getVenueProjects: build.query<EntityState<Project_Entity>, number>({
      query: (venueID) => `studios/${venueID}/projects`,
      transformResponse: (responseData: Project_Entity[]) => {
        return projectsAdapter.setAll(initialState, responseData);
      },
      providesTags: (r, e, venueID) => [
        { type: "projects", id: `venue_${venueID}` },
      ],
    }),
    getTagProjects: build.query<EntityState<Project_Entity>, number>({
      query: (tagID) => `tags/${tagID}`,
      transformResponse: (responseData: Tag_Entity) => {
        return projectsAdapter.setAll(
          initialState,
          responseData.projects?.filter((p) => !p.archived) ?? []
        );
      },
      providesTags: (r, e, tagID) => [{ type: "projects", id: `tag_${tagID}` }],
    }),
    createProject: build.mutation<Project_Entity, Partial<Project_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `projects`,
      }),
      invalidatesTags: ["projects"],
    }),
    updateProject: build.mutation<
      void,
      { id: number; body: Partial<Project_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projects/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "projects", id },
        "projects",
      ],
    }),
    paydaySync: build.mutation<
      void,
      { id: number; body: Partial<PdProject_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projects/${args.id}/paydaySync`,
      }),
      invalidatesTags: [],
    }),
    projectAutofill: build.mutation<
      EntityState<Project_Entity>,
      {
        id: number;
        body: {
          projectID: number;
          optsMusician?: boolean;
          optsRole?: boolean;
          optsInstruments?: boolean;
          optsMemo?: boolean;
          preview?: boolean;
          chairIDs?: number[];
        };
      }
    >({
      query: (args) => {
        return {
          method: "PUT",
          body: args.body,
          url: `projects/${args.id}/autofill`,
        };
      },
    }),
    deleteProject: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `projects/${id}`,
      }),
      invalidatesTags: ["projects"],
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useLazyDuplicateProjectQuery,
  useGetProjectsQuery,
  useGetProjectAutopilotQuery,
  useUpdateProjectAutopilotMutation,
  useGetProjectQuery,
  useProjectAutofillMutation,
  useGetActiveProjectsQuery,
  useGetArchivedProjectsQuery,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetTagProjectsQuery,
  useLazyGetTagProjectsQuery,
  useUpdateProjectMutation,
  useGetCustomerProjectsQuery,
  useGetPayrollProjectsQuery,
  useGetVenueProjectsQuery,
  useGetMusicianProjectsQuery,
  useGetProjectEmailsQuery,
  usePaydaySyncMutation,
} = projectEndpoints;

export default projectEndpoints;
