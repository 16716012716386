import {
  alpha,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
// import PropTypes from 'prop-types';
// material-ui
import { Email } from "@mui/icons-material";
import { getCurrentStageName, mapToArray } from "helpers";
// styles
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Sheet,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Email as EmailEntity } from "entities/email";
import { Inspector_Entity } from "entities/inspector";
import { Interactor } from "entities/interactor";
import { Stage, Stage_Entity } from "entities/stage";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import EmailsDataGrid from "features/dataGrids/EmailsDataGrid";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import * as moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  mercuryIDForHistorySelector,
  missionControlModeSelector,
  setFormOpen,
  setSelectedEmailID,
} from "reducers/rhapsody";
import {
  layoutDebugSelector,
  layoutUtilsSelector,
} from "reducers/v2/missionControl";
import { rhapsodyApi } from "redux/api";
import {
  useUpdateAssignmentMercuryMutation,
  useUpdateAssignmentMutation,
  useUpdateMissionMercuryMutation,
} from "redux/assignment/assignmentEndpoints";
import {
  useCreateInspectorMutation,
  useDeleteInspectorMutation,
} from "redux/inspector/inspectorEndpoints";
import { useMercuryJob } from "redux/mercuryJob/mercuryJobHooks";
import { useMusician } from "redux/musician/musicianHooks";
import { useCurrentUser } from "redux/user/userHook";
import { meSelector } from "selectors/me";
import { useLazyGetEmailsQuery } from "redux/rhapsodyEmails/emailEndpoints";
import { seasonUtilsSelector } from "reducers/v2/seasonControl";

/**
 *
 * @returns {ReactElement} ChairMercuryHistory page
 */
export function MercuryHistory() {
  const open = useSelector(formOpenSelector("mercuryHistory"));
  const mercuryID = useSelector(mercuryIDForHistorySelector);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const readOnly = useReadOnly();
  const me = useSelector(meSelector);
  const { mercuryJob } = useMercuryJob(mercuryID);
  const utils = useSelector(layoutUtilsSelector);
  const seasonUtils = useSelector(seasonUtilsSelector);
  const missionControlMode = useSelector(missionControlModeSelector);
  const _musician = utils?.musiciansMap[mercuryJob?.refID];
  const [addEvent, setAddEvent] = useState(false);
  const [event, setEvent] = useState("");
  const { musician: _m } = useMusician(mercuryJob?.refID, {
    skip: _musician !== undefined,
  });
  const [createInspector] = useCreateInspectorMutation();

  const musician = _musician ?? _m;
  const [updateAssignment] = useUpdateAssignmentMutation();
  const assignments = mapToArray(utils?.assignmentsMap);
  const assignment = assignments?.find((a) => a.musicianID === musician?.id);

  const mission = seasonUtils?.missions?.find(
    (a) => a.musicianID === musician?.id
  );

  useEffect(() => {
    setValue(assignment?.memo ?? "");
  }, [assignment]);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "mercuryHistory" }));
  };

  if (!mercuryJob) return <div></div>;
  if (!musician) return <div></div>;

  const saveEvent = () => {
    createInspector({
      createdBy: `${me.firstName} ${me.lastName}`,
      jobStageID: mercuryJob.currentStage.relationID,
      message: event,
      icon: "fa-solid fa-notes",
      kind: "event",
      title: "Event",
    }).then(() => dispatch(rhapsodyApi.util.invalidateTags(["mercuryJobs"])));
    setEvent("");
    setAddEvent(false);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <MusicianAvatar musician={musician} size={48} noBadge />
          <Box sx={{ flex: 1 }}>
            <Typography level={"h6"}>{musician?.fullName()}</Typography>
            {musician.email ? (
              <Typography
                startDecorator={<i className="fa-solid fa-envelope"></i>}
                level={"body2"}
                onClick={() => {
                  window.open(`mailTo:${musician?.email}`, "_blank");
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: alpha("#9e9e9e", 0.2),
                  },
                  "&:active": {
                    background: alpha("#9e9e9e", 0.3),
                  },
                }}
              >
                {musician.email}
              </Typography>
            ) : (
              []
            )}
            {musician.phone ? (
              <Typography
                onClick={() => {
                  window.open(`tel:${musician?.phone}`, "_blank");
                }}
                startDecorator={<i className="fa-solid fa-phone"></i>}
                level={"body2"}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: alpha("#9e9e9e", 0.2),
                  },
                  "&:active": {
                    background: alpha("#9e9e9e", 0.3),
                  },
                }}
              >
                {musician.phone}
              </Typography>
            ) : (
              []
            )}
          </Box>
        </Box>
        {assignment ? (
          <Textarea
            readOnly={missionControlMode !== "edit"}
            onBlur={() =>
              updateAssignment({
                id: assignment.id,
                body: { memo: value },
              })
            }
            sx={{
              fontSize: 11,
              fontWeight: 400,
              p: 0.5,
              mt: 1,
              flex: 1,
              background: "#F2F2F2",
            }}
            maxRows={4}
            minRows={2}
            endDecorator={
              <Box
                sx={{
                  pl: 0.5,
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography level="body4">Assignment Memo</Typography>
                {missionControlMode === "edit" ? (
                  <i
                    onClick={() => {
                      updateAssignment({
                        id: assignment.id,
                        body: { memo: "" },
                      });
                      setValue("");
                    }}
                    style={{ cursor: "pointer" }}
                    className="fa-solid fa-trash"
                  ></i>
                ) : (
                  []
                )}
              </Box>
            }
            disabled={readOnly}
            placeholder={`Any info to add for this assignment?`}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            size="md"
            variant="outlined"
          />
        ) : (
          []
        )}
        <Divider sx={{ mt: 1 }}>
          <Typography level="body2">Events History</Typography>
        </Divider>
        <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
          {addEvent ? (
            <Textarea
              variant="outlined"
              autoFocus
              startDecorator={"New Note"}
              size="sm"
              value={event}
              onChange={(e) => setEvent(e.target.value)}
              sx={{ flex: 1, background: "rgba(255,235,59,0.1)" }}
              maxRows={5}
              // placeholder=""
              endDecorator={
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "end",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Typography level="body4">
                    Not visible by musicians
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Button color="neutral" onClick={() => setAddEvent(false)}>
                    Cancel
                  </Button>
                  <Button onClick={saveEvent}>Save</Button>
                </Box>
              }
            />
          ) : (
            <Chip
              onClick={() => {
                setAddEvent(true);
              }}
              endDecorator={<i className="fa-solid fa-notes"></i>}
              color="neutral"
              variant="outlined"
              size="sm"
            >
              + Add Note
            </Chip>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: 400,
            display: "flex",
            gap: 1,
            flexDirection: "column-reverse",
          }}
        >
          <>
            {!mercuryJob?.stages ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Alert color="neutral">Nothing has happened yet!</Alert>
              </Box>
            ) : (
              <>
                {mercuryJob?.stages?.map((s) => (
                  <MercuryJobStage
                    key={s.id}
                    stage={s}
                    assignmentID={assignment?.id}
                    missionID={mission?.id}
                    current={s.id === mercuryJob?.currentStage?.id}
                    validInteractors={mercuryJob?.validInteractors?.filter(
                      (i) => i.stageID === s.id
                    )}
                  />
                ))}
              </>
            )}
          </>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Button color="neutral" variant="soft" onClick={onClose}>
          Release
        </Button> */}
        <Box sx={{ flexGrow: 1 }} />
        <Button color="neutral" variant="soft" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function MercuryJobStage({
  stage,
  current,
  validInteractors,
  assignmentID,
  missionID,
}: {
  stage: Stage;
  current: boolean;
  validInteractors: Interactor[];
  assignmentID?: number;
  missionID?: number;
}) {
  const debug = useSelector(layoutDebugSelector);
  const inspectors = {
    pre: [],
    post: [],
  };
  const readOnly = useReadOnly();
  const [loading, setLoading] = useState(false);
  const askQuestion = useAskQuestion();
  const [deleteInspector] = useDeleteInspectorMutation();
  const [updateAssignmentMercuryJob] = useUpdateAssignmentMercuryMutation();
  const [updateMissionMercuryJob] = useUpdateMissionMercuryMutation();
  const [getEmails] = useLazyGetEmailsQuery();
  const dispatch = useDispatch();

  for (const key in stage.inspectors) {
    if (Object.hasOwnProperty.call(stage.inspectors, key)) {
      const inspector = stage.inspectors[key];
      if (moment(inspector.createdAt).isAfter(moment(stage.createdAt))) {
        inspectors.post.push(inspector);
      }
      if (moment(inspector.createdAt).isBefore(moment(stage.createdAt))) {
        inspectors.pre.push(inspector);
      }

      if (moment(inspector.createdAt).isSame(moment(stage.createdAt))) {
        inspectors.post.push(inspector);
      }
    }
  }

  inspectors.pre = inspectors.pre.sort((a, b) => b.id - a.id);
  inspectors.post = inspectors.post.sort((a, b) => a.id - b.id);

  function getInspectors(pre: boolean) {
    const data = pre ? inspectors.pre : inspectors.post;
    return data?.reverse().map((i, index) => {
      let previousI;
      if (Number(index) > 0 && Number(index) <= inspectors.post.length) {
        previousI = inspectors.post[Number(index) - 1];
      }
      return (
        <Box key={i.id} sx={{ display: "flex", gap: 0.5 }}>
          <Inspector
            pre
            meta={
              !previousI ||
              moment(previousI.createdAt).format("YYYY/MM/DD") !==
                moment(i.createdAt).format("YYYY/MM/DD")
            }
            stage={stage}
            current={current}
            i={i}
          />
          {debug ? (
            <IconButton
              onClick={() => {
                deleteInspector(i.id).then(() =>
                  dispatch(rhapsodyApi.util.invalidateTags(["mercuryJobs"]))
                );
              }}
              variant="plain"
              size="sm"
              sx={{ fontSize: 11 }}
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          ) : (
            []
          )}
        </Box>
      );
    });
  }

  const handleAdminAnswer = async (interactor: Interactor) => {
    const call = missionID
      ? updateMissionMercuryJob
      : updateAssignmentMercuryJob;
    if (!readOnly) {
      askQuestion("Are you sure?", ["Cancel", "Yes"], {
        subtitle: (
          <Typography>You are about to answer for the musician.</Typography>
        ),
      }).then((i) => {
        if (i == 1) {
          setLoading(true);
          call({
            id: missionID ?? assignmentID,
            body: { mercuryInteractorID: interactor.id },
          }).then(() => {
            setTimeout(() => {
              setLoading(false);
              dispatch(rhapsodyApi.util.invalidateTags(["mercuryJobs"]));
            }, 1000);
          });
        }
      });
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      {getInspectors(false)}
      <Paper
        variant="outlined"
        sx={{
          border: `solid 1px ${stage.color}`,
          background: current && stage.color,
          color: current && "white",
          p: 1,
          borderRadius: "4px",
        }}
      >
        <Box sx={{ display: "flex", gap: 0.5, flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <img
                src={stage.icon}
                alt={`${stage.name}-icon`}
                style={{
                  height: 20,
                  filter: current && "brightness(0) invert(1)",
                }}
              />
              <Typography
                level="body2"
                style={{ lineHeight: 0.8, color: "inherit" }}
              >
                <b>
                  {getCurrentStageName(stage)}
                  {stage.acknowledged ? "(Acknowleged)" : ""}
                </b>
              </Typography>
            </Box>
            <Tooltip arrow variant="outlined" size="sm" title="Show Email">
              <IconButton
                size="sm"
                variant="plain"
                sx={{ color: "white" }}
                onClick={() => {
                  getEmails({
                    filters: JSON.stringify([
                      {
                        name: "refID",
                        value: stage.relationID,
                        comparison: "eq",
                      },
                    ]),
                  })
                    .unwrap()
                    .then((e) => {
                      if (e.ids.length) {
                        if (e.ids.length === 1) {
                          dispatch(setSelectedEmailID(e.entities[e.ids[0]].id));
                        } else {
                          const rows = e.ids.reduce<EmailEntity[]>((a, v) => {
                            a.push(new EmailEntity(e.entities[v]));
                            return a;
                          }, []);
                          askQuestion("Multiple emails found", ["Cancel"], {
                            subtitle: (
                              <Box sx={{ width: "100vw" }}>
                                <EmailsDataGrid
                                  hideFooter
                                  hideHeader
                                  autoHeight
                                  value={rows}
                                />
                              </Box>
                            ),
                          });
                        }
                      }
                    });
                }}
              >
                <Email
                  style={{
                    color: "black",
                    fontSize: "inherit",
                    filter: current && "brightness(0) invert(1)",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          {(assignmentID || missionID) &&
          validInteractors?.length &&
          !stage?.acknowledged ? (
            <Paper
              sx={{
                p: 0.5,
                display: "flex",
                gap: 0.5,
                flexDirection: "column",
                position: "relative",
              }}
              variant="outlined"
            >
              <Divider sx={{ display: "flex", gap: 1 }}>
                <Typography
                  level="body3"
                  sx={{ display: "flex", gap: 1, alignItems: "center" }}
                >
                  <i className="fa-regular fa-arrow-down"></i>Admin Answer
                  <i className="fa-regular fa-arrow-down"></i>
                </Typography>
              </Divider>
              <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
                {validInteractors
                  ?.filter((i) => i.kind === "button")
                  ?.map((i) => (
                    <Box
                      key={i.id}
                      sx={{
                        cursor: !readOnly && "pointer",
                        opacity: readOnly ? 0.3 : 1,
                      }}
                      onClick={() => handleAdminAnswer(i)}
                    >
                      <Chip
                        disabled={readOnly}
                        sx={{ background: i.color }}
                        size="sm"
                      >
                        {i.name}
                      </Chip>
                    </Box>
                  ))}
              </Box>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    backdropFilter: "blur(10px)",
                    position: "absolute",
                    top: 0,
                  }}
                >
                  <CircularProgress size="sm" />
                </Box>
              ) : (
                []
              )}
            </Paper>
          ) : (
            []
          )}
        </Box>
      </Paper>
      <Meta
        date={stage.createdAt}
        author={stage.createdBy}
        align="right"
      ></Meta>
      {getInspectors(true)}
    </Box>
  );
}

function Inspector({
  i,
  stage,
  current,
  post,
  pre,
  meta,
}: {
  i: Inspector_Entity;
  stage: Stage_Entity;
  current?: boolean;
  post?: boolean;
  pre?: boolean;
  meta?: boolean;
}) {
  const [deleteInspector] = useDeleteInspectorMutation();
  const dispatch = useDispatch();

  switch (i.kind) {
    case "checkbox":
      return (
        <div
          style={{
            flex: 1,
            textAlign: i.stageTriggered ? "right" : "left",
          }}
        >
          <Tooltip
            size="sm"
            variant="outlined"
            arrow
            title={`${moment(i.createdAt).format("lll")} by ${i.createdBy}`}
          >
            <Chip
              size="sm"
              startDecorator={
                <i
                  className={`${i.icon} p-right` || "fad fa-comment p-right"}
                ></i>
              }
              variant="soft"
              color="neutral"
              endDecorator={<i className="fas fa-check-square p-left"></i>}
            >
              {i.title}
            </Chip>
          </Tooltip>
        </div>
      );
    case "signature":
      return (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Tooltip
            size="sm"
            variant="outlined"
            arrow
            title={`${moment(i.createdAt).format("lll")} by ${i.createdBy}`}
          >
            <Sheet
              variant="soft"
              color="neutral"
              style={{ borderRadius: 8, padding: 6 }}
            >
              <Typography level="body2">{i.title}</Typography>
              <img src={i.message} alt="signature" style={{ width: "100%" }} />
            </Sheet>
          </Tooltip>
        </div>
      );
    case "file":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              textAlign: "left",
            }}
          >
            <Tooltip
              size="sm"
              variant="outlined"
              arrow
              title={`${moment(i.createdAt).format("lll")} by ${i.createdBy}`}
            >
              <Button
                size="sm"
                startDecorator={i.icon ? <i className={i.icon}></i> : undefined}
                color="neutral"
                variant="soft"
                onClick={() => window.open(i.message)}
              >
                {i.title}
              </Button>
            </Tooltip>
          </div>
        </div>
      );
    case "event":
      return (
        <Tooltip
          size="sm"
          variant="outlined"
          arrow
          title={`${moment(i.createdAt).format("lll")} by ${i.createdBy}`}
        >
          <Sheet
            sx={{
              background: "rgba(255,235,59,0.1)",
              p: 1,
              flex: 1,
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <i className="fa-solid fa-notes"></i>
            <Box sx={{ flexGrow: 1 }}>
              <Typography level="body2">
                <b>{i.message}</b>
              </Typography>
            </Box>
            <IconButton
              onClick={() =>
                deleteInspector(i.id).then(() =>
                  dispatch(rhapsodyApi.util.invalidateTags(["mercuryJobs"]))
                )
              }
              color="neutral"
              size="sm"
              variant="plain"
            >
              <i className="fa-solid fa-trash"></i>
            </IconButton>
          </Sheet>
        </Tooltip>
      );
    default:
      return (
        <div
          style={{
            flex: 1,
            justifyContent: i.stageTriggered ? "start" : "end",
            display: "flex",
            padding: 2,
          }}
        >
          <Tooltip
            size="sm"
            variant="outlined"
            arrow
            title={`${moment(i.createdAt).format("lll")} by ${i.createdBy}`}
          >
            <Sheet
              // size="sm"
              variant="soft"
              color="neutral"
              sx={{ borderRadius: 4, width: "auto" }}
            >
              <Typography
                level="body3"
                sx={{ alignItems: "start", color: "black" }}
                startDecorator={
                  i.icon ? (
                    <i className={i.icon} style={{ padding: 4 }}></i>
                  ) : undefined
                }
              >
                {i.message}
              </Typography>
            </Sheet>
          </Tooltip>
        </div>
      );
  }
}

function Meta({ date, author, align }) {
  return (
    <Tooltip
      size="sm"
      variant="outlined"
      arrow
      title={moment(date).format("lll")}
    >
      <div
        style={{
          textAlign: align || "right",
        }}
      >
        <Typography level="body3">
          {author}, {moment(date).fromNow()}
        </Typography>
      </div>
    </Tooltip>
  );
}
