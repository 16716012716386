import { HProject_Entity } from "entities/hProject";
import { rhapsodyApi } from "../api/rhapsodyApi";

export const projectMercuryEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectMercury: build.query<HProject_Entity, number>({
      query: (id) => `projects/${id}/mercury`,
      providesTags: (result, error, id) => [{ type: "projectMercury", id }],
    }),
    getSeasonUpcomingStages: build.query<
      HProject_Entity,
      { id: number; body?: { musicianIDs?: number[]; nudge?: boolean } }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `tags/${args.id}/mercury/project`,
      }),
      providesTags: (result, error, body) => [
        "seasonMercury",
        { type: "seasonMercury", id: body.id },
      ],
    }),
    getUpcomingStages: build.query<
      HProject_Entity,
      { id: number; body?: { musicianIDs?: number[]; nudge?: boolean } }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `projects/${args.id}/mercury/project`,
      }),
      providesTags: (result, error, body) => [
        "projectMercury",
        { type: "projectMercury", id: body.id },
      ],
    }),
  }),
});

export const {
  useGetProjectMercuryQuery,
  useGetUpcomingStagesQuery,
  useGetSeasonUpcomingStagesQuery,
  useLazyGetSeasonUpcomingStagesQuery,
  useLazyGetUpcomingStagesQuery,
} = projectMercuryEndpoints;

export default projectMercuryEndpoints;
