// import PropTypes from 'prop-types';

// styles
import { Button, Typography } from "@mui/joy";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useNavigate } from "react-router-dom";
import { useUpdatePieceMutation } from "redux/piece/pieceEndpoints";
import { usePiece } from "redux/piece/pieceHooks";

export default function PieceDangerZone({ pieceID }) {
  const { piece } = usePiece(pieceID);
  const [updatePiece] = useUpdatePieceMutation();

  const askQuestion = useAskQuestion();

  const navigate = useNavigate();

  if (!piece) return <Skeleton />;
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Button
        color="danger"
        onClick={async () => {
          askQuestion(
            "You're about to delete this Piece",
            ["Cancel", "Delete"],
            {
              subtitle: (
                <Typography>
                  You won't be able to recover anything from this piece.
                </Typography>
              ),
            }
          ).then((t) => {
            if (t) {
              updatePiece({
                id: piece.id,
                body: {
                  deleted: true,
                },
              });
              navigate(RouterConfig.pieces);
            }
          });
        }}
      >
        Delete permanently
      </Button>
      <Typography level="body2">
        You won't be able to recover anything from this piece.
        <br />
        Existing Projects and Work Sessions won't be affected.
      </Typography>
    </Box>
  );
}
