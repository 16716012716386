/**
 * FileImport_Entity type guard.
 *
 * @param {any} fileImportJson FileImport object from API
 * @returns {boolean} Return true if type is FileImport_Entity
 */
function validator(
  fileImportJson: unknown
): fileImportJson is FileImport_Entity {
  if (typeof fileImportJson === "object" && fileImportJson != null) {
    FileImport_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(fileImportJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class FileImport_Entity {
  static requiredFields = ["id"];

  constructor(fileImportJson: unknown) {
    if (validator(fileImportJson)) {
      this.id = fileImportJson.id;
      this.file = fileImportJson.file;
      this.category = fileImportJson.category;
      this.notes = fileImportJson.notes;
      this.status = fileImportJson.status;
      this.createdAt = fileImportJson.createdAt;
      this.updatedAt = fileImportJson.updatedAt;
    } else
      throw new Error(
        `Failed to create new instance of ${FileImport_Entity.name}`
      );
  }

  id: number;
  file: string;
  category: string;
  notes: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}
