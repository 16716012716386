import { Box, Divider, Sheet, Tooltip, Typography } from "@mui/joy";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { bookletHTML, projectNoteHTML } from "booklet/helpers";
import { Venue_Entity } from "entities/venue";
import { mapToArray } from "helpers";
import { useSelector } from "react-redux";
import {
  layoutInternalSelector,
  layoutUtilsSelector,
} from "reducers/v2/missionControl";
import { useBooklets } from "redux/booklet/bookletHooks";

/**
 *
 * @returns {ReactElement} ProjectBooklets page
 */
export function ProjectBooklets({
  onAdd,
}: {
  onAdd: (c: string, dynamic?: boolean) => void;
}) {
  const utils = useSelector(layoutUtilsSelector);
  const internal = useSelector(layoutInternalSelector);
  const { booklets } = useBooklets();
  const musicians = mapToArray(utils.musiciansMap);
  const pieces = mapToArray(utils.piecesMap);
  const musiciansWithBooklets = musicians.filter((m) =>
    booklets.find((b) => b.musicianID === m.id)
  );
  const venues = utils.workSessions.reduce<Partial<Venue_Entity>[]>((a, v) => {
    if (!a.find((e) => e.id === v.venueID))
      a.push({ name: v.studioName, id: v.venueID });
    return a;
  }, []);

  const venuesWithBooklets = venues.filter((m) =>
    booklets.find((b) => b.venueID === m.id)
  );

  const piecesWithBooklets = pieces.filter((m) =>
    booklets.find((b) => b.pieceID === m.id)
  );

  const snippets = booklets.filter((b) => b.snippet);

  return (
    <Sheet
      variant="plain"
      sx={{
        borderRadius: 8,
        flexGrow: 1,
        height: 0,
        width: "100%",
        maxHeight: "auto",
        overflow: "scroll",
      }}
    >
      <SimpleTreeView>
        {internal ? (
          <TreeItem
            itemId={`project`}
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b>Project</b>
                </Typography>
              </Box>
            }
          >
            <BookletItem
              onClick={(dynamic) =>
                onAdd(bookletHTML.project.info({ utils, dynamic }))
              }
              content={bookletHTML.project.info({ utils })}
              name={"Info"}
              itemId={`info_1`}
            />
            <BookletItem
              onClick={(dynamic) =>
                onAdd(
                  bookletHTML.project.chairCount({ internal, utils, dynamic })
                )
              }
              content={bookletHTML.project.chairCount({ internal, utils })}
              name={"Chair Count"}
              itemId={`count`}
            />
            <BookletItem
              onClick={(dynamic) =>
                onAdd(
                  bookletHTML.project.schedule({ internal, utils, dynamic })
                )
              }
              content={bookletHTML.project.schedule({ internal, utils })}
              name={"Schedule"}
              itemId={`schedule_1`}
            />
            <BookletItem
              onClick={(dynamic) =>
                onAdd(bookletHTML.project.program({ utils, dynamic }))
              }
              content={bookletHTML.project.program({ utils })}
              name={"Program"}
              itemId={`program_1`}
            />
            <BookletItem
              onClick={(dynamic) =>
                onAdd(bookletHTML.project.links({ utils, dynamic }))
              }
              content={bookletHTML.project.links({ utils })}
              name={"Links"}
              itemId={`links_1`}
            />
            <BookletItem
              onClick={(dynamic) =>
                onAdd(bookletHTML.project.contacts({ utils, dynamic }))
              }
              content={bookletHTML.project.contacts({ utils })}
              name={"Contacts"}
              itemId={`contacts_1`}
            />
            <BookletItem
              onClick={(dynamic) =>
                onAdd(bookletHTML.project.liveRosters({ utils, dynamic }))
              }
              content={bookletHTML.project.liveRosters({ utils })}
              name={"Live Rosters"}
              itemId={`liveRosters_1`}
            />
            {utils.project.dressCode ? (
              <BookletItem
                onClick={(dynamic) =>
                  onAdd(bookletHTML.project.dressCode({ utils, dynamic }))
                }
                content={utils.project.dressCode}
                name={"Dress Code"}
                itemId={`dresscode`}
              />
            ) : (
              []
            )}
          </TreeItem>
        ) : (
          []
        )}
        {utils.projectNotes?.length ? (
          <TreeItem
            itemId={`projectNotes`}
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b>Project Notes</b>
                </Typography>
              </Box>
            }
          >
            {utils.projectNotes.map((pn) => {
              return (
                <BookletItem
                  key={pn.id}
                  onClick={(dynamic) =>
                    onAdd(projectNoteHTML({ utils, dynamic, id: pn.id }))
                  }
                  content={pn.value}
                  name={pn.name}
                  itemId={`projectNote_${pn.id}`}
                />
              );
            })}
          </TreeItem>
        ) : (
          []
        )}
        {internal ? (
          <TreeItem
            itemId={`internal`}
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b>Rosters</b>
                </Typography>
              </Box>
            }
          >
            <BookletItem
              onClick={(dynamic) =>
                onAdd(
                  bookletHTML.project.roster({
                    internal,
                    utils,
                    dynamic,
                    id: 1,
                  })
                )
              }
              content={bookletHTML.project.roster({
                internal,
                utils,
                id: 1,
              })}
              name={"Roster"}
              itemId={`roster_1`}
            />
            <BookletItem
              onClick={(dynamic) =>
                onAdd(
                  bookletHTML.project.roster({
                    internal,
                    utils,
                    dynamic,
                    id: 2,
                  })
                )
              }
              content={bookletHTML.project.roster({
                internal,
                utils,
                id: 2,
              })}
              name={"Roster 2 columns"}
              itemId={`roster_2`}
            />
            <BookletItem
              onClick={(dynamic) =>
                onAdd(
                  bookletHTML.project.roster({
                    internal,
                    utils,
                    dynamic,
                    id: 3,
                  })
                )
              }
              content={bookletHTML.project.roster({
                internal,
                utils,
                id: 3,
              })}
              name={"Roster 3 columns"}
              itemId={`roster_3`}
            />
          </TreeItem>
        ) : (
          []
        )}
        <Divider>Booklets</Divider>
        {piecesWithBooklets?.length ? (
          <TreeItem itemId="pieces" label={<b>Pieces</b>}>
            {piecesWithBooklets.map((m) => {
              const pieceBooklets = booklets.filter((b) => b.pieceID === m.id);

              return (
                <TreeItem
                  key={m.id}
                  itemId={`piece_${m.id}`}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography>{m.name}</Typography>
                    </Box>
                  }
                >
                  {pieceBooklets.map((mb) => (
                    <BookletItem
                      key={mb.id}
                      onClick={(dynamic) =>
                        onAdd(dynamic ? mb.dynamic() : mb.content)
                      }
                      content={mb.content}
                      name={mb.name}
                      itemId={`bpiece_${mb.id}`}
                    />
                  ))}
                </TreeItem>
              );
            })}
          </TreeItem>
        ) : (
          []
        )}
        {venuesWithBooklets?.length ? (
          <TreeItem itemId="venues" label={<b>Venues</b>}>
            {venuesWithBooklets.map((m) => {
              const venueBooklets = booklets.filter((b) => b.venueID === m.id);

              return (
                <TreeItem
                  key={m.id}
                  itemId={`venue_${m.id}`}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography>{m.name}</Typography>
                    </Box>
                  }
                >
                  {venueBooklets.map((mb) => (
                    <BookletItem
                      key={mb.id}
                      onClick={(dynamic) =>
                        onAdd(dynamic ? mb.dynamic() : mb.content)
                      }
                      content={mb.content}
                      name={mb.name}
                      itemId={`bvenue_${mb.id}`}
                    />
                  ))}
                </TreeItem>
              );
            })}
          </TreeItem>
        ) : (
          []
        )}
        {musiciansWithBooklets?.length ? (
          <TreeItem itemId="musicians" label={<b>Musicians</b>}>
            {musiciansWithBooklets.map((m) => {
              const musicianBooklets = booklets.filter(
                (b) => b.musicianID === m.id
              );

              return (
                <TreeItem
                  key={m.id}
                  itemId={`musician_${m.id}`}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography>{m.fullName()}</Typography>
                    </Box>
                  }
                >
                  {musicianBooklets.map((mb) => (
                    <BookletItem
                      key={mb.id}
                      onClick={(dynamic) =>
                        onAdd(dynamic ? mb.dynamic() : mb.content)
                      }
                      content={mb.content}
                      name={mb.name}
                      itemId={`bmusician_${mb.id}`}
                    />
                  ))}
                </TreeItem>
              );
            })}
          </TreeItem>
        ) : (
          []
        )}
        {snippets.length > 0 ? (
          <TreeItem
            itemId={`general`}
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b>General</b>
                </Typography>
              </Box>
            }
          >
            {snippets.map((mb) => (
              <BookletItem
                key={mb.id}
                onClick={(dynamic) =>
                  onAdd(dynamic ? mb.dynamic() : mb.content)
                }
                content={mb.content}
                name={mb.name}
                itemId={`snippet_${mb.id}`}
              />
            ))}
          </TreeItem>
        ) : (
          []
        )}
      </SimpleTreeView>
    </Sheet>
  );
}

function BookletItem({
  content,
  name,
  onClick,
  itemId,
}: {
  content: string;
  name: string;
  onClick: (dynamic: boolean) => void;
  itemId: string;
}) {
  return (
    <TreeItem
      itemId={itemId}
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            sx={{
              maxWidth: 600,
              maxHeight: 800,
              overflow: "scroll",
            }}
            variant="outlined"
            arrow
            title={
              <div
                style={{ marginTop: 4 }}
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            }
          >
            <Typography sx={{ flexShrink: 0 }} variant="outlined" level="body3">
              <i className="fa-solid fa-eye"></i>
            </Typography>
          </Tooltip>
          <Typography level="body2" sx={{ flexGrow: 1 }}>
            {name}
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Tooltip
              arrow
              size="sm"
              enterDelay={1000}
              enterNextDelay={1000}
              title="Add dynamic block"
            >
              <Typography
                variant="outlined"
                level="body2"
                onClick={() => onClick(true)}
                sx={{
                  flexShrink: 0,
                  "&:hover": {
                    background: "#e3f2fd",
                  },
                  "&:active": {
                    background: "#bbdefb",
                  },
                }}
              >
                <i className="fa-solid fa-bolt"></i>
              </Typography>
            </Tooltip>
            <Tooltip
              arrow
              size="sm"
              enterDelay={1000}
              enterNextDelay={1000}
              title="Copy content (editable)"
            >
              <Typography
                variant="outlined"
                level="body2"
                onClick={() => onClick(false)}
                sx={{
                  flexShrink: 0,
                  "&:hover": {
                    background: "#e3f2fd",
                  },
                  "&:active": {
                    background: "#bbdefb",
                  },
                }}
              >
                <i className="fa-solid fa-plus"></i>
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      }
    />
  );
}
