import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { FileImport_Entity } from "entities/fileImport";
import { rhapsodyApi } from "redux/api";

const fileImportsAdapter = createEntityAdapter<FileImport_Entity>();
const initialState = fileImportsAdapter.getInitialState();

export const fileImportEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getFileImports: build.query<EntityState<FileImport_Entity>, void>({
      query: () => `fileImports`,
      transformResponse: (responseData: FileImport_Entity[]) => {
        return fileImportsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["fileImports"],
    }),
    getFileImport: build.query<FileImport_Entity, number>({
      query: (id) => `fileImports/${id}`,
      providesTags: (result, error, id) => [{ type: "fileImports", id }],
    }),
    createFileImport: build.mutation<
      FileImport_Entity,
      Partial<FileImport_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `fileImports`,
      }),
      invalidatesTags: ["fileImports"],
    }),
    updateFileImport: build.mutation<
      void,
      { id: number; body: Partial<FileImport_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `fileImports/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "fileImports", id },
        "fileImports",
      ],
    }),
    deleteFileImport: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `fileImports/${id}`,
      }),
      invalidatesTags: ["fileImports"],
    }),
  }),
});

export const {
  useGetFileImportQuery,
  useGetFileImportsQuery,
  useCreateFileImportMutation,
  useDeleteFileImportMutation,
  useUpdateFileImportMutation,
} = fileImportEndpoints;

export default fileImportEndpoints;
