import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { Venue_Entity } from "entities/venue";
import { rhapsodyApi } from "../api/rhapsodyApi";

const venuesAdapter = createEntityAdapter<Venue_Entity>();
const initialState = venuesAdapter.getInitialState();

export const venueEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getVenues: build.query<EntityState<Venue_Entity>, void>({
      query: () => `studios`,
      transformResponse: (responseData: Venue_Entity[]) => {
        return venuesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["venues"],
    }),
    getVenue: build.query<Venue_Entity, number>({
      query: (id) => `studios/${id}`,
      providesTags: (result, error, id) => [{ type: "venues", id }],
    }),
    createVenue: build.mutation<Venue_Entity, Partial<Venue_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `studios`,
      }),
      invalidatesTags: ["venues"],
    }),
    createVenue2: build.mutation<Venue_Entity, Partial<Venue_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `studios`,
      }),
      invalidatesTags: [],
    }),
    updateVenue: build.mutation<
      void,
      { id: number; body: Partial<Venue_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `studios/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "venues", id },
        "venues",
      ],
    }),

    updateVenue2: build.mutation<
      void,
      { id: number; body: Partial<Venue_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `studios/${args.id}`,
      }),
      invalidatesTags: [],
    }),
    deleteVenue: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `studios/${id}`,
      }),
      invalidatesTags: ["venues"],
    }),
  }),
});

export const {
  useUpdateVenue2Mutation,
  useGetVenueQuery,
  useGetVenuesQuery,
  useCreateVenueMutation,
  useCreateVenue2Mutation,
  useDeleteVenueMutation,
  useUpdateVenueMutation,
} = venueEndpoints;

export default venueEndpoints;

export const selectVenuesResult = venueEndpoints.endpoints.getVenues.select();

const selectVenuesData = createSelector(
  selectVenuesResult,
  (venuesResult) => venuesResult.data
);

const store = configureStore({
  reducer: {
    [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllVenues, selectById: selectVenueById } =
  venuesAdapter.getSelectors<RootState>(
    (state) => selectVenuesData(state) ?? initialState
  );
