import {
  Button,
  Checkbox,
  Chip,
  IconButton,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { DialogContent, DialogTitle, Grid, alpha } from "@mui/material";
import { Box } from "@mui/system";
import { useTour } from "@reactour/tour";
import DialogClose from "atoms/DialogClose/DialogClose";
import { InternalSection } from "entities/internal/InternalSection";
import { PieceInfo } from "features/ProjectPieces/PieceInfo";
import { CHAIR_WIDTH, Position } from "features/chair/v2/position";
import { editModeTour } from "features/tour/tour";
import { WorkSessionInfo } from "features/workSessions/workSessionInfo";
import { AddChairs } from "hooks/Instrumentation/v2";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import hotkeys from "hotkeys-js";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  missionControlModeSelector,
  missionControlViewModeSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import {
  layoutCollapseMapSelector,
  layoutDebugSelector,
  layoutInternalSelector,
  layoutSetCollapseMap,
  layoutSetMoveToSectionID,
  layoutUnselectAll,
  layoutUtilsSelector,
  positionsSelectedSelector,
  restoreSelection,
  saveSelection,
  selectPositions,
  selectionSelector,
  setInstrumentationProjectPieceIDs,
  setInstrumentationWorkSessionIDs,
  setReorderInternalSection,
  unselectPositions,
} from "reducers/v2/missionControl";
import { useChairs } from "redux/rhapsodyChair/rhapsodyChairHooks";
import GroupActions from "./GroupActions/v2";
import GroupNotify from "./GroupNotify/GroupNotify";
import { GroupSelection } from "./GroupSelection/v2";
import { Gravity } from "./gravity";
import { LayoutProps2 } from "./types";
import { useSoloWorkSession } from "features/projects/ProjectMissionControl/utils";

/**
 * This is a simple wrapper that ensures families and sections are fetched from the API before rendering the LayoutComponent
 */
export function LayoutGroup(props: LayoutProps2) {
  const internal = props.internal;
  const utils = props.utils;
  const selection = props.selection;
  const { selectedProjectPieces, selectedWorkSessions } =
    useSelector(selectionSelector);
  const missionControlViewMode = useSelector(missionControlViewModeSelector);
  const ref = useRef<HTMLElement>();

  const missionControlMode = useSelector(missionControlModeSelector);

  const getContent = () => {
    if (missionControlViewMode === "grouped") {
      return (
        <Box
          sx={{
            overflow: "auto",
            flex: 1,
          }}
        >
          <Layout {...props} />
        </Box>
      );
    }

    if (missionControlViewMode === "workSessions") {
      return (
        <Box sx={{ overflow: "auto", display: "flex", gap: 1 }}>
          {selectedWorkSessions.map((w) => (
            <Box
              sx={{
                width: CHAIR_WIDTH + 25,
                flexShrink: 0,
              }}
              key={w.id}
            >
              <Layout
                {...props}
                vertical
                workSessionID={w.id}
                internal={internal.iforWorkSession(w.id, utils, selection)}
                title={<WorkSessionInfo workSessionID={w.id} />}
              />
            </Box>
          ))}
        </Box>
      );
    }

    if (missionControlViewMode === "pieces") {
      return (
        <Box sx={{ overflow: "auto", display: "flex", gap: 1 }}>
          {selectedProjectPieces.map((p) => (
            <Box
              sx={{
                width: CHAIR_WIDTH + 25,
                flexShrink: 0,
              }}
              key={p.id}
            >
              <Layout
                key={p.id}
                {...props}
                vertical
                projectPieceID={p.id}
                internal={internal.iforProjectPiece(p.id, utils, selection)}
                title={<PieceInfo projectPieceID={p.id} />}
              />
            </Box>
          ))}
        </Box>
      );
    }
  };

  return (
    <>
      <Box
        ref={ref}
        id="selecto-container"
        sx={{
          cursor: missionControlMode === "view" ? "default" : "crosshair",
          display: "flex",
          gap: 1,
          flex: 1,
          overflowY: "auto",
          minWidth: "auto",
          width: 0,
          backgroundImage:
            missionControlMode !== "view"
              ? "radial-gradient(rgba(155,155,155, 0.3) 1px, transparent 0)"
              : undefined,
          backgroundSize: "15px 15px",
        }}
      >
        {getContent()}
        <GroupSelection reference={ref} />
      </Box>
      {missionControlMode === "edit" ? <GroupActions /> : []}
      {missionControlMode === "invite" ? <GroupNotify /> : []}
    </>
  );
}

/**
 *
 * @returns {ReactElement} Layout page
 */
export function Layout({
  internal,
  utils,
  vertical,
  title,
  workSessionID,
  projectPieceID,
  selection,
}: LayoutProps2) {
  const {
    sectionsMap,
    familiesMap,
    piecesMap,
    projectPiecesMap,
    workSessionsMap,
    project,
  } = utils;
  const { selectedProjectPieceIDs, selectedWorkSessionIDs } =
    useSelector(selectionSelector);
  const layout = useSelector(layoutInternalSelector);
  const readOnly = useReadOnly();
  const missionControlViewMode = useSelector(missionControlViewModeSelector);
  const debug = useSelector(layoutDebugSelector);
  const [open, setOpen] = useState(false);
  const collapseMap = useSelector(layoutCollapseMapSelector);
  const missionControlMode = useSelector(missionControlModeSelector);
  const soloWorkSession = useSoloWorkSession();
  const missionControlModeTint = useMissionControlModeTint();
  const dispatch = useDispatch();
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  let addChairDisabled = true;
  for (const k in selectedProjectPieceIDs) {
    if (selectedProjectPieceIDs.hasOwnProperty(k)) {
      if (selectedProjectPieceIDs[k]) addChairDisabled = false;
    }
  }
  const selectedWorkSessionIDsArray = [];
  for (const key in selectedWorkSessionIDs) {
    if (Object.hasOwnProperty.call(selectedWorkSessionIDs, key)) {
      if (selectedWorkSessionIDs[key])
        selectedWorkSessionIDsArray.push(parseInt(key));
    }
  }

  const selectedProjectPieceIDsArray = [];
  for (const key in selectedProjectPieceIDs) {
    if (Object.hasOwnProperty.call(selectedProjectPieceIDs, key)) {
      if (selectedProjectPieceIDs[key])
        selectedProjectPieceIDsArray.push(parseInt(key));
    }
  }
  const projectPiece = projectPiecesMap[projectPieceID];
  const piece = piecesMap[projectPiece?.pieceID];
  const workSession = workSessionsMap[workSessionID];

  hotkeys("esc", function (event) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault();
    dispatch(layoutSetMoveToSectionID(null));
    dispatch(layoutUnselectAll());
  });

  const [observer, setObserver] = useState<{
    mode: "edit" | "invite";
    callback: () => void;
  } | null>();

  let className = "";
  if (missionControlMode === "edit") className = "missionControlEdit";
  if (missionControlMode === "invite") className = "missionControlInvite";

  useEffect(() => {
    if (missionControlMode === "edit" && observer?.mode === "edit") {
      observer.callback();
      setObserver(null);
    }
    if (missionControlMode === "invite" && observer?.mode === "invite") {
      observer.callback();
      setObserver(null);
    }
  }, [missionControlMode]);

  let tooltipLabel = addChairDisabled
    ? "Available in Edit mode only"
    : `Add Chairs ${piece ? `for ${piece.name}` : ""}`;

  if (workSessionID) {
    tooltipLabel = addChairDisabled
      ? "Available in Edit mode only"
      : `Add Chairs ${workSession ? `for ${workSession.title}` : ""}`;
  }

  if (!internal) return <div />;

  const configureInstrumentation = () => {
    if (projectPieceID) {
      dispatch(
        setInstrumentationProjectPieceIDs(
          projectPieceID ? [projectPieceID] : null
        )
      );
      dispatch(setInstrumentationWorkSessionIDs(selectedWorkSessionIDsArray));
    } else if (workSessionID) {
      dispatch(
        setInstrumentationWorkSessionIDs(workSessionID ? [workSessionID] : null)
      );
      dispatch(setInstrumentationProjectPieceIDs(selectedProjectPieceIDsArray));
    } else {
      dispatch(setInstrumentationWorkSessionIDs(selectedWorkSessionIDsArray));
      dispatch(setInstrumentationProjectPieceIDs(selectedProjectPieceIDsArray));
    }
  };

  const reorder = (_internalSection: InternalSection) => {
    dispatch(setReorderInternalSection(_internalSection));
    dispatch(layoutUnselectAll());
    dispatch(selectPositions(_internalSection.enabledPositionIDs));
    dispatch(
      setFormOpen({
        isOpen: true,
        formID: "reorderInSection",
      })
    );
  };

  return (
    <Box
      id="background"
      className={className}
      sx={{
        flexGrow: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        userSelect: "none",
        // overflow: "auto",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          p: 0.5,
          top: 0,
          zIndex: 999,
          backdropFilter: "blur(10px)",
          background: "#ffffffAA",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              textOverflow: "ellipsis",
              flex: 1,
              width:
                missionControlViewMode !== "grouped"
                  ? "100%"
                  : CHAIR_WIDTH + 20,
            }}
          >
            {missionControlMode !== "view" ? (
              <Box sx={{ flexShrink: 0 }}>
                <SelectoCheckbox2
                  size="lg"
                  positionIDs={internal.enabledPositionIDs}
                />
              </Box>
            ) : undefined}
            <Typography
              level={vertical ? "body1" : "h6"}
              noWrap
              sx={{ flexShrink: 1 }}
            >
              {title ?? "Full Ensemble"}
            </Typography>
            <MetricBox
              type="full"
              size="md"
              workSessionID={workSessionID}
              projectPieceID={projectPieceID}
            />
            <Box sx={{ flexGrow: 1 }} />
            {utils.chairs.length > 0 || project?.template ? (
              <Tooltip variant="outlined" arrow size="sm" title={tooltipLabel}>
                <Chip
                  variant="outlined"
                  disabled={readOnly}
                  color="neutral"
                  size={missionControlViewMode !== "grouped" ? "sm" : "md"}
                  onClick={() => {
                    const callback = () => {
                      setIsOpen(false);
                      dispatch(
                        setFormOpen({ isOpen: true, formID: "instrumentation" })
                      );
                      configureInstrumentation();
                    };
                    if (missionControlMode === "edit") {
                      if (!addChairDisabled) return callback();
                    } else {
                      setSteps([editModeTour]);
                      setCurrentStep(0);
                      setIsOpen(true);
                      setObserver({ mode: "edit", callback });
                    }
                  }}
                >
                  <i className="fa-kit fa-solid-chair-circle-plus"></i>
                  {missionControlViewMode === "grouped" ? "+ Chairs" : ""}
                </Chip>
              </Tooltip>
            ) : (
              []
            )}
          </Box>
        </Box>
      </Box>
      {debug && <Selecto />}
      {internal.chairIDs.length === 0 ? (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: "30%",
          }}
        >
          <Typography level="h6">
            <i className="fa-solid fa-chair"></i>
          </Typography>
          <Typography level="body2">
            <b>No Chairs</b>
          </Typography>
          {missionControlViewMode === "grouped" && !project?.template ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                startDecorator={
                  !layout.defaultPieceOnly ? (
                    <i className="fa-solid fa-plus"></i>
                  ) : (
                    <i className="fa-solid fa-circle-play"></i>
                  )
                }
                onClick={() => {
                  if (!layout.defaultPieceOnly) {
                    dispatch(
                      setFormOpen({ isOpen: true, formID: "instrumentation" })
                    );
                  } else {
                    setOpen(true);
                  }
                }}
              >
                {!layout.defaultPieceOnly ? "Add Chairs" : "Get Started"}
              </Button>
              <DialogClose open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Tell us more about your Project:</DialogTitle>
                <DialogContent>
                  <Box sx={{ width: 500 }}>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Button
                        sx={{
                          flex: 1,
                          height: 200,
                          flexDirection: "column",
                        }}
                        variant="outlined"
                        color="neutral"
                        onClick={() => {
                          setOpen(false);
                          dispatch(
                            setFormOpen({
                              isOpen: true,
                              formID: "instrumentation",
                            })
                          );
                        }}
                      >
                        <Typography level="h2" sx={{ pb: 1 }}>
                          <i className="fa-light fa-rectangle"></i>
                        </Typography>
                        <Typography level="h6">Single Seating</Typography>

                        <Typography level="body2">
                          My project only needs one seating arrangement.
                        </Typography>
                      </Button>
                      <Button
                        sx={{
                          flex: 1,
                          height: 200,
                          flexDirection: "column",
                        }}
                        variant="outlined"
                        color="neutral"
                        onClick={() => {
                          setOpen(false);
                          dispatch(
                            setFormOpen({
                              isOpen: true,
                              formID: "projectPiece2",
                            })
                          );
                        }}
                      >
                        <Typography level="h2" sx={{ pb: 1 }}>
                          <i className="fa-light fa-rectangle-history"></i>
                        </Typography>
                        <Typography level="h6">Multiple Pieces</Typography>
                        <Typography level="body2">
                          My project needs multiple seating arrangements.
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </DialogContent>
              </DialogClose>
            </Box>
          ) : (
            []
          )}
        </Box>
      ) : (
        []
      )}
      {internal.families?.map((f) => (
        <Sheet
          sx={{
            p: 1,
            borderRadius: "8px",
            background: alpha(missionControlModeTint, 0.05),
            backdropFilter: "blur(2px)",
          }}
          variant={"outlined"}
          key={f.familyID}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              level={vertical ? "body1" : "h6"}
              sx={{ fontWeight: 600 }}
              startDecorator={
                missionControlMode !== "view" ? (
                  <SelectoCheckbox2 positionIDs={f.enabledPositionIDs} />
                ) : undefined
              }
              endDecorator={
                <MetricBox
                  type="family"
                  size="md"
                  id={f.familyID}
                  workSessionID={workSessionID}
                  projectPieceID={projectPieceID}
                />
              }
            >
              {familiesMap[f.familyID]?.name}
            </Typography>
            <IconButton
              size="sm"
              color="neutral"
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                dispatch(
                  layoutSetCollapseMap({
                    ...collapseMap,
                    [f.familyID]: !(collapseMap[f.familyID] ?? true),
                  })
                );
              }}
            >
              <i
                className={
                  collapseMap[f.familyID] ?? true
                    ? "fa-solid fa-chevron-up"
                    : "fa-solid fa-chevron-down"
                }
              ></i>
            </IconButton>
          </Box>
          {debug ? (
            <DebugChairIDs
              chairIDs={f.chairIDs}
              visibleChairIDs={f.visibleChairIDs}
            />
          ) : (
            []
          )}
          {collapseMap[f.familyID] ?? true ? (
            <Grid
              container
              alignItems="stretch"
              sx={{
                minHeight: 50,
              }}
            >
              {f.sections?.map((s) => (
                <Grid
                  item
                  xs={vertical ? 12 : undefined}
                  key={s.sectionID}
                  sx={{
                    transition: "min-height .3s",
                    ml: missionControlViewMode === "grouped" ? 1 : 0,
                  }}
                >
                  <Box className={`section${s.sectionID}`}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        level="body2"
                        startDecorator={
                          missionControlMode !== "view" ? (
                            <SelectoCheckbox2
                              size="sm"
                              positionIDs={s.enabledPositionIDs}
                            />
                          ) : undefined
                        }
                        endDecorator={
                          <MetricBox
                            type="section"
                            size="sm"
                            id={s.sectionID}
                            workSessionID={workSessionID}
                            projectPieceID={projectPieceID}
                          />
                        }
                      >
                        {sectionsMap[s.sectionID]?.name}
                      </Typography>
                      <Box sx={{ display: "flex", height: 32 }}>
                        {missionControlMode === "edit" ? (
                          <Gravity
                            internalSection={s}
                            utils={utils}
                            selection={selection}
                          />
                        ) : (
                          []
                        )}
                        <Tooltip
                          enterDelay={1000}
                          enterNextDelay={1000}
                          variant="outlined"
                          arrow
                          size="sm"
                          title="Reorder Musicians"
                        >
                          <IconButton
                            onClick={() => {
                              if (missionControlViewMode === "workSessions") {
                                dispatch(saveSelection());
                                soloWorkSession(s.forWorkSessionID);
                              }
                              reorder(s);
                            }}
                            size="sm"
                            color="neutral"
                            variant="plain"
                          >
                            <i className="fa-solid fa-shuffle"></i>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                    {debug ? (
                      <DebugChairIDs
                        chairIDs={s.chairIDs}
                        visibleChairIDs={s.visibleChairIDs}
                      />
                    ) : (
                      []
                    )}
                    <Box
                      sx={{
                        pt: 1,
                        background: "rgba(0,255,255,255, 0.1)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          flexDirection: "column",
                        }}
                      >
                        {/* <AnimatedList animation="grow"> */}
                        {s.positions.map((p) => {
                          return (
                            <Box key={p.order}>
                              <Position
                                internal={internal}
                                utils={utils}
                                internalPosition={p}
                                workSessionID={workSessionID}
                                projectPieceID={projectPieceID}
                              />
                            </Box>
                          );
                        })}
                        {/* </AnimatedList> */}
                      </Box>
                      {missionControlMode === "edit" ? (
                        <AddChairs
                          forWorkSessionID={internal.forWorkSessionID}
                          forProjectPieceID={internal.forProjectPieceID}
                          sectionID={s.sectionID}
                        ></AddChairs>
                      ) : (
                        <Box sx={{ height: 32 }} />
                      )}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            []
          )}
        </Sheet>
      ))}
      <Box sx={{ minHeight: 120, width: "100%" }}></Box>
    </Box>
  );
}

export function SelectoCheckbox2({
  positionIDs,
  size,
  cssClassHandler = false,
}: {
  positionIDs: string[];
  size?: "sm" | "md" | "lg";
  cssClassHandler?: boolean;
}) {
  const missionControlColor = useMissionControlColor();
  const selecto = useSelector(positionsSelectedSelector);
  const [selected, setSelected] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();
  const { checked, intermediate } = selecto.checkboxStatus(positionIDs);

  useEffect(() => {
    if (selected !== checked && cssClassHandler) {
      setSelected(checked);
      const element: any = ref?.current;
      const chairContainer =
        element?.parentElement?.parentElement?.parentElement;

      if (chairContainer) {
        if (checked) {
          chairContainer.classList.add("selecto2Selected");
        } else {
          chairContainer.classList.remove("selecto2Selected");
        }
      }
    }
  }, [checked, cssClassHandler, selected]);

  return (
    <Checkbox
      id="selecto-checkbox"
      ref={ref}
      onChange={(e) => {
        e.stopPropagation();
        e.preventDefault();
        const value = e.target.checked;
        if (!value && checked) {
          return dispatch(unselectPositions(positionIDs));
        }
        dispatch(selectPositions(positionIDs));
      }}
      size={size}
      variant={intermediate && !checked ? "soft" : undefined}
      checkedIcon={
        !checked && intermediate ? (
          <i className="fa-solid fa-minus"></i>
        ) : undefined
      }
      color={missionControlColor}
      checked={checked || intermediate}
    />
  );
}

export function DebugChairIDs({
  chairIDs,
  visibleChairIDs,
}: {
  chairIDs: number[];
  visibleChairIDs: number[];
}) {
  const projectID = useSelector(selectedProjectIDSelector);
  const { chairsMap } = useChairs(projectID);

  return (
    <Grid container sx={{ display: "flex", gap: 0.5 }}>
      {chairIDs.map((c) => (
        <Grid key={c} item>
          <Tooltip title={chairsMap[c]?.workSessionID}>
            <Typography
              level="body5"
              sx={{
                color: chairsMap[c]?.workSessionID ? "red" : "black",
                opacity: visibleChairIDs.indexOf(c) >= 0 ? 1 : 0.3,
              }}
            >
              {c}
            </Typography>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
}

export function useMissionControlModeTint() {
  const missionControlMode = useSelector(missionControlModeSelector);
  let background = "";
  switch (missionControlMode) {
    case "view":
      background = "#616161";
      break;
    case "edit":
      background = "#616161";
      break;
    case "invite":
      background = "#616161";
      break;
    case "follow":
      background = "#616161";
      break;
    default:
      break;
  }

  return background;
}

export function useMissionControlColor() {
  const missionControlMode = useSelector(missionControlModeSelector);
  let background: "neutral" | "warning" | "primary" = "neutral";
  switch (missionControlMode) {
    case "view":
      background = "primary";
      break;
    case "edit":
      background = "primary";
      break;
    case "invite":
      background = "primary";
      break;
    case "follow":
      background = "primary";
      break;
    default:
      break;
  }

  return background;
}

function Selecto() {
  const { visibleChairIDs, chairIDs } = useSelector(positionsSelectedSelector);
  return (
    <DebugChairIDs chairIDs={chairIDs} visibleChairIDs={visibleChairIDs} />
  );
}

function MetricBox({
  size = "md",
  id,
  type,
  projectPieceID,
  workSessionID,
}: {
  size: "md" | "sm";
  id?: number;
  type: "family" | "section" | "full";
  projectPieceID?: number;
  workSessionID?: number;
}) {
  const missionControlModeTint = useMissionControlModeTint();
  const internal = useSelector(layoutInternalSelector);
  const utils = useSelector(layoutUtilsSelector);

  let value = 0;
  let max = 0;

  if (!internal) return <Box />;
  switch (type) {
    case "family":
      value = internal.getFamily(id)?.enabledPositionCount;
      if (projectPieceID)
        value = internal.positions.filter(
          (p) =>
            p.familyID === id && p.visibilityForProjectPieces[projectPieceID]
        ).length;

      if (workSessionID)
        value = internal.positions.filter(
          (p) => p.familyID === id && p.visibilityForWorkSessions[workSessionID]
        ).length;

      max = internal.getFamily(id)?.positionCount;
      break;
    case "section":
      const familyID = utils.sectionsMap[id]?.familyID;
      max = internal.getSection(id, familyID).positionCount;
      value = internal.getSection(id, familyID).enabledPositionCount;

      if (projectPieceID)
        value = internal.positions.filter(
          (p) =>
            p.sectionID === id && p.visibilityForProjectPieces[projectPieceID]
        ).length;

      if (workSessionID)
        value = internal.positions.filter(
          (p) =>
            p.sectionID === id && p.visibilityForWorkSessions[workSessionID]
        ).length;

      break;
    case "full":
      max = internal.positionCount;
      value = internal.enabledPositionCount;
      if (projectPieceID)
        value = internal.positions.filter(
          (p) => p.visibilityForProjectPieces[projectPieceID]
        ).length;

      if (workSessionID)
        value = internal.positions.filter(
          (p) => p.visibilityForWorkSessions[workSessionID]
        ).length;

      break;
    default:
      break;
  }

  return (
    <Sheet
      variant="soft"
      sx={{
        borderRadius: size === "md" ? "8px" : "6px",
        pl: 0.5,
        pr: 0.5,
        height: size === "md" ? 20 : 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fontWeight: 600,
        fontSize: size === "md" ? 12 : 10,
        color: "white",
        opacity: 0.3,
        background: missionControlModeTint,
      }}
    >
      {value === max ? max : `${value}/${max}`}
    </Sheet>
  );
}
