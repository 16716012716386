import { Button } from "@mui/joy";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import DialogClose from "atoms/DialogClose/DialogClose";
import { getMusicianCount } from "helpers";
import { useState } from "react";
import { useMusician } from "redux/musician/musicianHooks";
import { useProjectSections } from "redux/projectSection/projectSectionHooks";

export default function WhosPlaying({
  projectID,
  onSubmit,
  whosPlayingMeta,
  defaultOpen,
}) {
  const [open, setOpen] = useState(defaultOpen ?? false);
  const { projectSections } = useProjectSections(projectID);
  // const musicians = useSelector(musiciansMapSelector);

  const handleCheck = (id) => (e) => {
    const checked = e.target.checked;
    let meta = {};
    try {
      meta = JSON.parse(whosPlayingMeta);
      if (meta === "{}") meta = {};
    } catch (error) {}
    meta[id] = checked;
    var projectMusicians = [];
    if (id[0] === "f") {
      projectMusicians = getProjectMusiciansForFamily(parseInt(id.slice(2)));
    }
    if (id[0] === "s") {
      projectMusicians = getProjectMusiciansForSection(parseInt(id.slice(2)));
    }

    for (const key in projectMusicians) {
      if (Object.hasOwnProperty.call(projectMusicians, key)) {
        const projectMusician = projectMusicians[key];
        meta[`m-${projectMusician.id}`] = checked;
      }
    }

    for (const j in Object.keys(meta)) {
      if (Object.hasOwnProperty.call(Object.keys(meta), j)) {
        const key = Object.keys(meta)[j];
        if (!meta[key]) delete meta[key];
      }
    }

    onSubmit(meta);
  };

  const selectAll = () => {
    let projectMusicians = getProjectMusicians();
    let meta = {};
    for (const key in projectMusicians) {
      if (Object.hasOwnProperty.call(projectMusicians, key)) {
        const projectMusician = projectMusicians[key];
        meta[`m-${projectMusician.id}`] = true;
      }
    }

    onSubmit(meta);
  };

  const getProjectMusicians = () => {
    let projectMusicians = [];
    for (const key in projectSections) {
      if (Object.hasOwnProperty.call(projectSections, key)) {
        const projectSection = projectSections[key];
        projectMusicians = [
          ...projectMusicians,
          ...getProjectMusiciansForSection(projectSection.id),
        ];
      }
    }

    return projectMusicians;
  };

  const getProjectSectionsForFamily = (familyID) => {
    return projectSections?.filter((s) => s.familyID === familyID);
  };

  const getProjectMusiciansForSection = (sectionID) => {
    return (
      projectSections
        .find((s) => s.id === sectionID)
        ?.projectMusicians?.filter((m) => m.prime) ?? []
    );
  };

  const getProjectMusiciansForFamily = (familyID) => {
    const sections = getProjectSectionsForFamily(familyID);
    let projectMusicians = [];

    for (const key in sections) {
      if (Object.hasOwnProperty.call(sections, key)) {
        const section = sections[key];
        projectMusicians = [
          ...projectMusicians,
          ...getProjectMusiciansForSection(section.id),
        ];
      }
    }

    return projectMusicians;
  };

  const familyIsChecked = (f) => {
    let meta = {};
    const projectMusicians = getProjectMusiciansForFamily(f.id);
    try {
      meta = JSON.parse(whosPlayingMeta) || {};
    } catch (error) {}

    for (const key in projectMusicians) {
      if (Object.hasOwnProperty.call(projectMusicians, key)) {
        const projectMusician = projectMusicians[key];
        if (!meta[`m-${projectMusician.id}`]) return false;
      }
    }

    return true;
  };

  const sectionIsChecked = (s) => {
    let meta = {};
    const projectMusicians = getProjectMusiciansForSection(s.id);
    try {
      meta = JSON.parse(whosPlayingMeta) || {};
    } catch (error) {}

    for (const key in projectMusicians) {
      if (Object.hasOwnProperty.call(projectMusicians, key)) {
        const projectMusician = projectMusicians[key];
        if (!meta[`m-${projectMusician.id}`]) return false;
      }
    }

    return true;
  };

  const projectMusicianIsChecked = (pm) => {
    let meta = {};
    try {
      meta = JSON.parse(whosPlayingMeta) || {};
    } catch (error) {}
    return meta[`m-${pm.id}`] ?? false;
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  };

  const getCount = () => {
    let meta = {};
    try {
      meta = JSON.parse(whosPlayingMeta) || {};
    } catch (error) {}
    return Object.keys(meta).length;
  };
  if (!projectID) return <Typography>Select a project first</Typography>;

  const getFamilies = () => {
    const families = [];
    for (const key in projectSections) {
      if (Object.hasOwnProperty.call(projectSections, key)) {
        const projectSection = projectSections[key];
        if (!families.find((p) => p.name === projectSection.familyName)) {
          families.push({
            name: projectSection.familyName,
            id: projectSection.familyID,
          });
        }
      }
    }
    return families;
  };

  const getSection = (familyName) => {
    return projectSections?.filter((s) => s.familyName === familyName);
  };

  const count = getCount();
  const projectMusicians = getProjectMusicians();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      {projectSections.length ? (
        <Box
          onClick={handleClickOpen}
          color={
            getMusicianCount(whosPlayingMeta) ? "textPrimary" : "textSecondary"
          }
        >
          <Button
            size="sm"
            startDecorator={
              count === 0 ? <i className="fa-solid fa-plus"></i> : []
            }
            variant={"plain"}
            color={"neutral"}
          >
            {count ? (
              <>
                {projectMusicians.length === count ? (
                  <Box sx={{ lineHeight: 1 }}>
                    Full instrumentation
                    <br />
                    <span style={{ fontSize: 10 }}>
                      {count} musician{count > 1 ? "s" : ""}
                    </span>
                  </Box>
                ) : (
                  <Box sx={{ lineHeight: 1 }}>
                    {getMusicianCount(whosPlayingMeta)} musicians
                  </Box>
                )}
                <i
                  className="fas fa-arrow-circle-right p-left"
                  style={{ fontSize: 12 }}
                ></i>
              </>
            ) : (
              <>Select Instrumentation</>
            )}
          </Button>
        </Box>
      ) : (
        []
      )}
      <DialogClose
        fullWidth={true}
        maxWidth={"xl"}
        onClose={() => setOpen(false)}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Select who's playing in this session?
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              onClick={() => onSubmit("{}")}
              variant="soft"
              color="neutral"
            >
              Unselect all
            </Button>
            <Button
              onClick={() => {
                selectAll();
              }}
              variant="soft"
              color="neutral"
            >
              Select all
            </Button>
          </Box>
          {getFamilies().map((f) => {
            const familyChecked = familyIsChecked(f);
            return (
              <Box key={f.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheck(`f-${f.id}`)}
                      size=""
                      sx={{ padding: 0.5 }}
                      checked={familyChecked}
                    />
                  }
                  label={
                    <Typography color="primary" variant="h6">
                      {f.name}
                    </Typography>
                  }
                />
                <Grid container>
                  {getSection(f.name).map((s) => {
                    const sectionChecked = sectionIsChecked(s);
                    return (
                      <Grid
                        key={s.id}
                        sx={{
                          width: 300,
                          pb: 4,
                          borderLeft: `solid 1px rgba(155,155,155,0.3)`,
                        }}
                        item
                      >
                        <Box sx={{ pl: 2 }}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size=""
                                  checked={sectionChecked}
                                  onChange={handleCheck(`s-${s.id}`)}
                                  sx={{ padding: 0.5 }}
                                />
                              }
                              label={
                                <Typography
                                  variant="subtitle1"
                                  color="primary"
                                  sx={{ fontWeight: 600 }}
                                >
                                  {s.sectionName}
                                </Typography>
                              }
                            />
                          </Box>
                          {s.projectMusicians
                            .filter((m) => m.prime)
                            .map((pm) => {
                              const projectMusicianChecked =
                                projectMusicianIsChecked(pm);
                              return (
                                <Musician
                                  key={pm.id}
                                  musicianID={pm.musicianID}
                                  checked={projectMusicianChecked}
                                  onCheck={handleCheck(`m-${pm.id}`)}
                                />
                              );
                            })}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            variant="soft"
            color="neutral"
            onClick={(e) => {
              handleClose(e);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              handleClose(e);
            }}
            autoFocus
          >
            Select
          </Button>
        </DialogActions>
      </DialogClose>
    </Box>
  );
}

function Musician({ musicianID, onCheck, checked }) {
  const { musician } = useMusician(musicianID);
  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            size=""
            sx={{ padding: 0.5 }}
            onChange={onCheck}
            checked={checked}
          />
        }
        label={
          musician ? (
            musician.nickName || `${musician?.firstName} ${musician?.lastName}`
          ) : (
            <Typography sx={{ color: "text.secondary" }}>
              <i>Empty Position</i>
            </Typography>
          )
        }
      />
    </Box>
  );
}
