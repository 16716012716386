import { Button, Chip, ChipDelete, Input, Typography } from "@mui/joy";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Upload from "atoms/FileUpload";
import { FileImport_Entity } from "entities/fileImport";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedImportIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import {
  useCreateFileImportMutation,
  useDeleteFileImportMutation,
  useUpdateFileImportMutation,
} from "redux/fileImport/fileImportEndpoints";
import { useFileImport } from "redux/fileImport/fileImportHooks";

/**
 *
 * @returns {ReactElement} FormFileImport page
 */
export function FormFileImport() {
  const fileImportID = useSelector(selectedImportIDSelector);
  const { fileImport } = useFileImport(fileImportID);
  const open = useSelector(formOpenSelector("fileImport"));
  const [_fileImport, setFileImport] = useState<Partial<FileImport_Entity>>({});
  const [file, setFile] = useState<File>();
  const [createFileImport] = useCreateFileImportMutation();
  const [updateFileImport] = useUpdateFileImportMutation();
  const [deleteFileImport] = useDeleteFileImportMutation();

  const askQuestion = useAskQuestion();

  const dispatch = useDispatch();

  useEffect(() => {
    if (fileImport?.id) {
      setFileImport(fileImport);
    }
  }, [fileImport]);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "fileImport" }));
  };

  const save = () => {
    if (fileImportID && _fileImport) {
      updateFileImport({ id: fileImportID, body: _fileImport });
    } else if (_fileImport) {
      createFileImport(_fileImport);
    }
    onClose();
  };

  const askDelete = () => {
    askQuestion("Are you sure?", ["Cancel", "Yes"], {
      subtitle: (
        <Typography>
          You are about to delete a Project Piece. Chairs linked to this Project
          Piece will be deleted as well. .
        </Typography>
      ),
    }).then((i) => {
      if (i == 1) confirmDelete();
    });
  };

  const confirmDelete = async () => {
    if (fileImportID) deleteFileImport(fileImportID);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography level="h5">Upload</Typography>
        <Typography level="body2">
          Your upload is encrypted and securely protected.
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: 1, minWidth: 400 }}
      >
        {_fileImport?.file ?? file ? (
          <Chip
            startDecorator={<i className="fa-solid fa-file"></i>}
            color="neutral"
            endDecorator={
              <ChipDelete
                onDelete={() => setFileImport((i) => ({ ...i, file: "" }))}
              />
            }
          >
            {file?.name ?? _fileImport.file}
          </Chip>
        ) : (
          <Upload
            onUpload={(url, file) => {
              setFile(file);

              setFileImport((i) => ({ ...i, file: url }));
            }}
          />
        )}
        <Input
          placeholder="What is it?"
          value={_fileImport?.notes ?? ""}
          onChange={(e) =>
            setFileImport((f) => ({ ...f, notes: e.target.value }))
          }
        />
      </DialogContent>
      <DialogActions>
        {fileImportID ? (
          <Button variant="soft" onClick={askDelete} color="danger">
            Delete
          </Button>
        ) : (
          []
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={onClose} color="neutral" variant="soft">
          Cancel
        </Button>
        <Button onClick={save}>{fileImportID ? "Save" : "Submit"}</Button>
      </DialogActions>
    </Dialog>
  );
}
