import { PdGroup_Entity } from "entities/pdGroup";
import { PdItem_Entity } from "entities/pdItem";

/**
 * PdProject_Entity type guard.
 *
 * @param {any} pdProjectJson PdProject object from API
 * @returns {boolean} Return true if type is PdProject_Entity
 */

export default class PdProject_Entity {
  constructor(projectJson: PdProject_Entity) {
    try {
      this.id = projectJson.id;
      this.organizationID = projectJson.organizationID;
      this.parentID = projectJson.parentID;
      this.childID = projectJson.childID;
      this.name = projectJson.name;
      this.description = projectJson.description;
      this.revision = projectJson.revision;
      this.footnote = projectJson.footnote;
      this.currency = projectJson.currency;
      this.doubling = projectJson.doubling;
      this.splitCosts = projectJson.splitCosts;
      this.pension = projectJson.pension;
      this.welfare = projectJson.welfare;
      this.total = projectJson.total;
      this.groups = projectJson.groups;
      this.items = projectJson.items;
      this.createdAt = projectJson.createdAt;
      this.updatedAt = projectJson.updatedAt;
    } catch (e) {
      console.log(`Failed to create new instance of ${PdProject_Entity.name}`);
    }
  }

  id: number;
  organizationID: number;
  parentID: number;
  childID: number;
  name: string;
  description: string;
  revision: string;
  footnote: string;
  currency: string;
  doubling: string;
  splitCosts: boolean;
  pension: number;
  welfare: number;
  total: number;
  groups: Array<PdGroup_Entity>;
  items: Array<PdItem_Entity>;
  createdAt: string;
  updatedAt: string;
}
