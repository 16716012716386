import { KeyboardArrowRight } from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Box } from "@mui/material";
import { DataGridPro, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import Id from "atoms/Id/Id";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedPieceID } from "reducers/rhapsody";
import { usePieces } from "redux/piece/pieceHooks";
import { useTagProjects } from "redux/project/projectHooks";
import { useProjectPiecesFilter } from "redux/projectPiece/projectPieceHooks";

export default function PiecesDataGrid({
  tagID,
  autoheight = true,
}: {
  tagID?: number;
  autoheight?: boolean;
}) {
  const { pieces, isLoading } = usePieces(undefined, { skip: tagID > 0 });
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const { tagProjects } = useTagProjects(tagID);
  const projectIDs = tagProjects?.reduce((a, v) => {
    if (!a.includes(v.id)) a.push(v.id);
    return a;
  }, []);
  const { projectPieces } = useProjectPiecesFilter(
    {
      filters: JSON.stringify([
        {
          name: "project_pieces.projectID",
          value: projectIDs,
          comparison: "in",
        },
      ]),
    },
    { skip: !tagID || projectIDs?.length === 0 }
  );

  const pieceIDs =
    projectPieces?.reduce((a, v) => {
      if (!a.includes(v.pieceID)) a.push(v.pieceID);
      return a;
    }, []) ?? [];

  const { pieces: tagPieces } = usePieces(
    {
      filters: JSON.stringify([
        {
          name: "pieces.id",
          value: pieceIDs,
          comparison: "in",
        },
      ]),
    },
    { skip: !tagID || pieceIDs?.length === 0 }
  );

  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      width: 75,
      renderCell: (p) => (
        <Id startDecorator={<i className="fa-solid fa-feather"></i>}>
          {p.row.id}
        </Id>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      flex: 1,
    },
    {
      field: "composer",
      headerName: "Composer",
      type: "string",
      flex: 1,
    },
    {
      field: "arranger",
      headerName: "Arranger",
      type: "string",
      flex: 1,
    },
    {
      field: "edition",
      headerName: "Edition",
      type: "string",
      flex: 1,
    },
    {
      field: "notes",
      headerName: "Notes",
      type: "string",
      flex: 1,
      renderCell: (p) => (
        <Sheet sx={{ background: "rgba(255,235,59,0.1)", p: p.value && 1 }}>
          <Typography level="body3">{p.value}</Typography>
        </Sheet>
      ),
    },
  ];
  if (tagID) {
    columns.push({
      field: "projectID",
      headerName: "Played in",
      type: "string",
      flex: 1,
      renderCell: (p) => {
        const pieceID = p.row.id;
        const projects = tagProjects.filter((p) => {
          const _projectPieces = projectPieces.filter(
            (pp) => pp.pieceID === pieceID
          );
          return _projectPieces.find((pp) => pp.projectID === p.id);
        });
        return (
          <Tooltip
            variant="outlined"
            arrow
            title={
              <List>
                {projects.map((e) => (
                  <ListItemButton
                    onClick={() => {
                      navigate(
                        `${RouterConfig.projects}/${e.id}/mission-control`
                      );
                    }}
                    key={e.id}
                  >
                    <ListItemContent>
                      <Typography
                        startDecorator={
                          <Id
                            startDecorator={
                              <i className="fa-solid fa-folder-open"></i>
                            }
                          >
                            {e.id}
                          </Id>
                        }
                      >
                        {e.name}
                      </Typography>
                    </ListItemContent>
                    <KeyboardArrowRight />
                  </ListItemButton>
                ))}
              </List>
            }
          >
            <Typography
              color="primary"
              endDecorator={<i className="fa-regular fa-circle-info"></i>}
            >
              {projects.length} Project{projects.length > 1 ? "s" : ""}
            </Typography>
          </Tooltip>
        );
      },
    });
  }

  let rows = pieces;
  if (tagID) rows = tagPieces;

  return (
    <DataGridPro
      autoHeight={autoheight}
      density="compact"
      pagination
      pageSize={25}
      onRowClick={(p) => {
        dispatch(setSelectedPieceID(p.row.id));
      }}
      loading={isLoading}
      rows={rows ?? []}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: Toolbar,
      }}
    />
  );
}

function Toolbar() {
  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        gap: 2,
        alignItems: "center",
      }}
    >
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}
