import { Box, Button, List, ListItemButton, Tooltip } from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import { REACT_APP_API } from "config";
import { PdLabor } from "entities/pdLabor";
import { AppConfig } from "environement";
import { currencyFormatter } from "helpers";
import { useState } from "react";
import { useSelector } from "react-redux";
import { layoutUtilsSelector } from "reducers/v2/missionControl";

export type BformID =
  | "b3"
  | "b4"
  | "b5"
  | "b6"
  | "b7"
  | "b8"
  | "b9"
  | "b10"
  | "b11"
  | "b12";

export type BBody = {
  formID: BformID;
  content: string[][];
};

/**
 *
 * @returns {ReactElement} ProjectContinuationForm page
 */
export function ProjectContinuationForm({ labors }: { labors: PdLabor[] }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { chairsMap, musiciansMap, project, instrumentsMap } =
    useSelector(layoutUtilsSelector);
  const forms: BformID[] = [
    "b3",
    "b4",
    "b5",
    "b6",
    "b7",
    "b8",
    "b9",
    "b10",
    "b11",
    "b12",
  ];

  const getRow = (labor: PdLabor, formID: BformID) => {
    const apiEndpoint = AppConfig.api.endpoint;
    let endpoint = "live";
    if (apiEndpoint.includes("alpha")) endpoint = "alpha";
    if (apiEndpoint.includes("demo")) endpoint = "demo";

    const chair = chairsMap[labor.parentID];
    const musician = musiciansMap[chair?.musicianID];
    const instrumentIDs = JSON.parse(
      chair.instrumentIDs ? chair.instrumentIDs : "[]"
    ) as number[];
    const instrumentNames = instrumentIDs.reduce((a, v) => {
      a.push(instrumentsMap[v].name);
      return a;
    }, []);

    const wage = currencyFormatter.format(labor.total / 1000);
    const cartage = currencyFormatter.format(labor.cartage / 1000);
    const wageAndCartage = `${wage}\r\n${cartage}`;

    const musicianAndInstruments = musician
      ? `${musician.lastName} ${
          musician.firstName
        } ${musician.initials()}\r\n(${instrumentNames.join(", ")})`
      : "";

    const pension = currencyFormatter.format(labor.pension / 1000);
    const welfare = currencyFormatter.format(labor.welfare / 1000);

    const ssn = endpoint === "live" ? musician?.ssn ?? "" : "";

    switch (formID) {
      case "b3":
        return [
          musician?.localNumber ?? "",
          musicianAndInstruments,
          ssn,
          "",
          "",
          "",
          "",
          wageAndCartage,
          pension,
          welfare,
        ];
      case "b4":
        return [
          musician?.localNumber ?? "",
          "",
          musicianAndInstruments,
          musician?.formattedAddress() ?? "",
          ssn,
          "",
          "",
          wage,
          cartage,
          pension,
          welfare,
        ];
      case "b5":
        return [
          musician?.localNumber ?? "",
          "",
          musicianAndInstruments,
          musician?.formattedAddress() ?? "",
          ssn,
          "",
          "",
          wage,
          cartage,
          pension,
          welfare,
        ];
      case "b6":
        return [
          musician?.localNumber ?? "",
          "",
          musicianAndInstruments,
          ssn,
          "",
          "",
          "",
          "",
          wage,
          cartage,
          pension,
          welfare,
        ];
      case "b7":
        return [
          musician?.localNumber ?? "",
          "",
          musicianAndInstruments,
          musician?.formattedAddress() ?? "",
          ssn,
          "",
          "",
          wage,
          cartage,
          pension,
          welfare,
        ];
      case "b8":
        return [
          musician?.localNumber ?? "",
          "",
          musicianAndInstruments,
          musician?.formattedAddress() ?? "",
          ssn,
          "",
          wage,
          cartage,
          pension,
          welfare,
        ];
      case "b9":
        return [
          musician?.localNumber ?? "",
          "",
          musicianAndInstruments,
          musician?.formattedAddress() ?? "",
          ssn,
          "",
          "",
          wage,
          cartage,
          pension,
          welfare,
        ];
      case "b10":
        return [
          musician?.localNumber ?? "",
          musicianAndInstruments,
          musician?.formattedAddress() ?? "",
          ssn,
          "",
          wage,
          cartage,
          pension,
          welfare,
        ];
      case "b11":
        return [
          musician?.localNumber ?? "",
          musicianAndInstruments,
          musician?.formattedAddress() ?? "",
          ssn,
          "",
          wage,
          pension,
          welfare,
        ];
      case "b12":
        return [
          musician?.localNumber ?? "",
          musicianAndInstruments,
          musician?.formattedAddress() ?? "",
          ssn,
          wage,
          pension,
        ];
      default:
        return [];
    }
  };

  const bformValues = async (id: BformID) => {
    setLoading(true);
    const body: BBody = {
      formID: id,
      content: [],
    };

    labors.forEach((l) => {
      body.content.push(getRow(l, id));
    });

    const apiEndpoint = AppConfig.api.endpoint;
    let endpoint = "live";
    if (apiEndpoint.includes("alpha")) endpoint = "alpha";
    if (apiEndpoint.includes("demo")) endpoint = "demo";

    fetch(
      // `http://localhost:3000`,
      `https://bform.${endpoint}.rhapsody.la`,
      {
        body: JSON.stringify(body),
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
      }
    )
      .then((response) => response.blob())
      .then((d) => {
        const url = URL.createObjectURL(d);
        const a = document.createElement("a");
        a.download = `${project?.name}_${id}`;
        a.href = url;
        console.log(`${project?.name}_${id}`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  return (
    <Tooltip
      arrow
      open={open}
      color="neutral"
      variant="outlined"
      title={
        <ClickAwayListener
          onClickAway={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(false);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 80,
            }}
          >
            <List sx={{ overflowY: "auto" }} size="sm">
              {forms.map((e) => {
                return (
                  <ListItemButton
                    sx={{ textAlign: "center", justifyContent: "center" }}
                    onClick={() => {
                      bformValues(e);
                      setOpen(false);
                    }}
                    key={e}
                  >
                    {e.toUpperCase()}
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
        </ClickAwayListener>
      }
    >
      <Button
        loading={loading}
        onClick={(e) => setOpen(true)}
        size="sm"
        variant="outlined"
      >
        Export Continuation Form
      </Button>
    </Tooltip>
  );
}
