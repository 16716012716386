/*global google*/
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Option,
  Select,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { NerdFileUpload } from "@nerdjs/nerd-ui";
import { Dictionary } from "@reduxjs/toolkit";
import { Venue } from "entities/venue";
import { Worksheet } from "exceljs";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { createWorkbook, Indexes } from "features/exporter/utils";
import fileToArrayBuffer from "file-to-array-buffer";
import { heightWithToolbar } from "global";
import { parsePlace, Place } from "hooks/googlePlaceSuggestions/maps";
import { isValidNumber, parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedVenueID } from "reducers/rhapsody";
import { rhapsodyApi } from "redux/api";
import {
  useCreateVenue2Mutation,
  useUpdateVenue2Mutation,
} from "redux/venue/venueEndpoints";
import { useVenues } from "redux/venue/venueHooks";

/**
 *
 * @returns {ReactElement} Importer page
 */
export function VenueImporter() {
  const [excelFile, setExcelFile] = useState<File>();
  const [worksheet, setWorksheet] = useState<Worksheet>();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [mapping, setMapping] = useState<Dictionary<string>>({});
  const [createVenue] = useCreateVenue2Mutation();
  const [importedVenueNames, setImportedVenueNames] = useState<string[]>([]);
  const [updateVenue] = useUpdateVenue2Mutation();
  const [errorVenueEmails, setErrorVenueNames] = useState<string[]>([]);
  const [importing, setImporting] = useState(0);
  const [updating, setUpdating] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const { venues } = useVenues();
  const [loading, setLoading] = useState<string>("");
  const dispatch = useDispatch();
  const askQuestion = useAskQuestion();
  const [outOfSync, setOutOfSync] = useState<
    { venue: Venue; issues: string[]; row: any }[]
  >([]);
  const apiRef = useGridApiRef();

  const reversedMapping = reverseDict(mapping);

  const venuesByName = useMemo(() => {
    return venues.reduce<Dictionary<Venue>>((a, v) => {
      a[v.name?.trim().toLowerCase()] = v;
      return a;
    }, {});
  }, [venues]);

  useEffect(() => {
    setMapping(
      localStorage.getItem("importerVenueMapping")
        ? JSON.parse(localStorage.getItem("importerVenueMapping"))
        : {}
    );

    setRows(
      localStorage.getItem("importerVenueRows")
        ? JSON.parse(localStorage.getItem("importerVenueRows"))
        : []
    );
  }, []);

  useEffect(() => {
    if (mapping) {
      localStorage.setItem("importerVenueMapping", JSON.stringify(mapping));
    }
  }, [mapping]);

  useEffect(() => {
    if (rows) {
      localStorage.setItem("importerVenueRows", JSON.stringify(rows));
    }
  }, [rows]);

  useEffect(() => {
    if (excelFile) process();
  }, [excelFile]);

  useEffect(() => {
    if (worksheet) {
      buildDataGrid();
    }
  }, [outOfSync]);

  useEffect(() => {
    if (worksheet) {
      genOutOfSync();
      buildDataGrid();
    }
  }, [worksheet, mapping, venues, loading, rows]);

  const venueFields: any[] = [
    {
      field: "email",
      required: false,
      noteType: false,
      name: "Email",
      testValue: (e: string) => e,
      format: (e: string) => e,
    },
    {
      field: "name",
      required: true,
      noteType: false,
      name: "Name",
      testValue: (e: string) => e,
      format: (e: string) =>
        e
          ?.trim()
          .toLowerCase()
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" "),
    },
    {
      field: "contact",
      required: false,
      noteType: false,
      name: "Contact",
      testValue: (e: string) => e,
      format: (e: string) => e,
    },
    {
      field: "phone",
      required: false,
      noteType: false,
      name: "Phone",
      testValue: (e: string) => {
        return isValidNumber(`${e}`.replace(/\D/g, ""), "US");
      },
      format: (e: string) => {
        try {
          if (isValidNumber(`${e}`.replace(/\D/g, ""), "US")) {
            const phoneNumber = parsePhoneNumber(`${e}`, "US");
            return phoneNumber.format("E.164");
          }
        } catch (error) {}
        return "";
      },
    },
    {
      field: "address",
      required: false,
      noteType: false,
      name: "Address",
      testValue: (e: string) => e,
      format: (e: string) => e?.trim(),
    },
  ];

  const process = async () => {
    const workbook = createWorkbook();
    const arrayBuffer = await fileToArrayBuffer(excelFile);
    await workbook.xlsx.load(arrayBuffer);
    const firstWorkSheet = workbook.worksheets[0];
    setWorksheet(firstWorkSheet);
  };

  const format = (field: string) => {
    const venueField = venueFields.find((e) => e.field === mapping[field]);
    const _rows = [...rows];
    _rows.forEach((r) => (r[field] = venueField.format(r[field])));
    setRows(_rows);
  };

  const buildDataGrid = async () => {
    const _columns: GridColDef[] = [];
    const _rows = [];
    const indexes = new Indexes(worksheet);

    let processingColumnsFinished = false;
    while (!processingColumnsFinished) {
      const headerValue = await indexes.get();
      if (headerValue) {
        _columns.push({
          field: `${headerValue}`,
          width: 200,
          editable: true,
          renderHeader: (e) => (
            <Box
              sx={{ display: "flex", flex: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {mapping[e.field] ? (
                <Tooltip
                  title="Format"
                  variant="outlined"
                  size="sm"
                  arrow
                  enterDelay={1000}
                  enterNextDelay={1000}
                >
                  <IconButton
                    size="sm"
                    variant="plain"
                    color="neutral"
                    onClick={() => {
                      format(e.field);
                    }}
                  >
                    <i className="fa-solid fa-wand-magic-sparkles"></i>
                  </IconButton>
                </Tooltip>
              ) : undefined}
              <Select
                size="sm"
                placeholder={e.field}
                variant={"soft"}
                color={mapping[e.field] ? "success" : "neutral"}
                sx={{ flexGrow: 1 }}
                value={mapping[e.field] ? mapping[e.field] : null}
                onChange={(a, v) => {
                  if (v) setMapping((m) => ({ ...m, [e.field]: v }));
                }}
              >
                {venueFields
                  .filter((f) => !reversedMapping[f.field])
                  .map((f) => (
                    <Option
                      key={f.field}
                      value={f.field}
                      color={f.noteType ? "info" : undefined}
                      variant={f.noteType ? "soft" : undefined}
                    >
                      {f.noteType ? (
                        <Typography
                          level="body2"
                          endDecorator={
                            <Typography level="body4">Custom Field</Typography>
                          }
                        >
                          {f.name}
                        </Typography>
                      ) : (
                        <Typography
                          level="body2"
                          endDecorator={
                            f.required ? (
                              <Typography color="warning" level="body4">
                                Required
                              </Typography>
                            ) : undefined
                          }
                        >
                          {f.name}
                        </Typography>
                      )}
                    </Option>
                  ))}
              </Select>
            </Box>
          ),
        });
      } else {
        processingColumnsFinished = true;
      }
      indexes.nextColumn();
    }

    indexes.goToColumn(0);
    indexes.goToRow(2);

    let processingRowFinished = false;
    let count = 1;

    if (!rows || rows.length === 0) {
      while (!processingRowFinished) {
        const item = { id: count };
        if (!(await indexes.get())) {
          processingRowFinished = true;
          continue;
        }
        for (const c in _columns) {
          if (Object.prototype.hasOwnProperty.call(_columns, c)) {
            const _column = _columns[c];
            const cell = await indexes.get();
            let text = cell?.toString();
            if (cell && typeof cell === "object") {
              const _cell = cell as any;
              if (typeof _cell.text === "object") {
                text = _cell.text.richText[0].text;
              } else {
                text = _cell?.text;
              }
            }
            item[_column.field] = text;
            indexes.nextColumn();
          }
        }
        _rows.push(item);
        count++;
        indexes.goToColumn(0);
        indexes.nextRow();
      }
    }

    _columns.push({
      field: "action",
      width: 60,
      headerName: "",
      valueGetter: (e) => {
        const name = e.row[reversedMapping["name"]];
        if (!name) return 4;
        if (outOfSync.find((e) => e.venue.name === name)) return 2;
        if (errorVenueEmails.includes(name)) return 3;
        return 1;
      },
      renderCell: (e) => {
        const name = e.row[reversedMapping["name"]];
        if (errorVenueEmails.includes(name))
          return (
            <IconButton size="sm" variant="plain" color="danger">
              <i className="fa-solid fa-triangle-exclamation"></i>
            </IconButton>
          );
        if (loading && name === loading) return <CircularProgress size="sm" />;
        if (venueFields[name]) {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flex: 1,
                position: "relative",
              }}
            >
              {outOfSync.find((e) => e.venue.name === name) ? (
                <i
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    zIndex: 99,
                    background: "#2196f3",
                    color: "white",
                    borderRadius: 20,
                    fontSize: 8,
                    padding: "2px",
                  }}
                  className="fa-solid fa-right-left-large"
                ></i>
              ) : (
                <i
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    zIndex: 99,
                    color: "#2A5C2C",
                    background: "white",
                    borderRadius: 20,
                    fontSize: 11,
                  }}
                  className="fa-solid fa-circle-check"
                ></i>
              )}
            </Box>
          );
        }
        if (importedVenueNames.includes(name)) {
          return (
            <Tooltip
              title="Venue Imported"
              variant="outlined"
              size="sm"
              arrow
              enterDelay={1000}
              enterNextDelay={1000}
            >
              <IconButton
                onClick={() =>
                  dispatch(setSelectedVenueID(venuesByName[name].id))
                }
                size="sm"
                variant="plain"
                color="success"
              >
                <i className="fa-solid fa-circle-check"></i>
              </IconButton>
            </Tooltip>
          );
        }
        if (!name) return <Box />;
        return (
          <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
            <Tooltip
              title="Import Now"
              variant="outlined"
              size="sm"
              arrow
              enterDelay={1000}
              enterNextDelay={1000}
              onClick={async () => {
                await importVenue(e.row);
                dispatch(rhapsodyApi.util.invalidateTags(["venues"]));
              }}
            >
              <IconButton size="sm" variant="plain">
                <i className="fa-solid fa-arrow-down-to-line"></i>
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    });
    setColumns(_columns);
    if (!rows || rows.length === 0) setRows(_rows);
  };

  const prepareVenueForImport = (r) => {
    const body: any = {};
    Object.keys(mapping).forEach((m) => {
      if (!mapping[m]) return;
      if (r[m] && /^-?\d+$/.test(mapping[m])) {
      } else {
        body[mapping[m]] = r[m];
      }
    });

    return { body };
  };

  const patchVenues = async () => {
    let count = 0;
    setUpdating(0);
    for (const c in outOfSync) {
      if (Object.prototype.hasOwnProperty.call(outOfSync, c)) {
        const venueID = await patchVenue(
          outOfSync[c].row,
          outOfSync[c].venue,
          outOfSync[c].issues
        );
        if (venueID) {
          count++;
          setUpdating(count);
        }
        // }
      }
    }
    dispatch(rhapsodyApi.util.invalidateTags(["venues"]));
    setUpdating(0);
  };

  const patchVenue = async (r: any, venue: Venue, issue: string[]) => {
    const { body } = prepareVenueForImport(r);
    setLoading(body.name);
    const _body = JSON.parse(JSON.stringify(body));
    delete _body.instruments;

    try {
      const index = rows?.findIndex(
        (r) => r[reversedMapping["name"]] === body.name
      );
      apiRef.current.scrollToIndexes({ rowIndex: index });
      await updateVenue({
        id: venue.id,
        body: _body,
      }).unwrap();

      setLoading(undefined);
      return venue.id;
    } catch (error) {
      console.log(error);
      setErrorVenueNames((i) => [...i, body.name]);
      setErrors((i) => [
        ...i,
        `${body.name}: [${error.data.status}] ${error.data.description}`,
      ]);
    }
  };

  const importVenues = async () => {
    let count = 0;
    setImporting(0);
    for (const c in rows) {
      if (Object.prototype.hasOwnProperty.call(rows, c)) {
        const venueID = await importVenue(rows[c]);
        if (venueID) {
          count++;
          setImporting(count);
        }
        // }
      }
    }
    dispatch(rhapsodyApi.util.invalidateTags(["venues"]));
    setImporting(0);
  };

  const geocode = (address: string): Promise<Place> => {
    return new Promise((resolve, reject) => {
      if (address) {
        const googleMapsGeocoder = new google.maps.Geocoder();
        googleMapsGeocoder.geocode({ address }, async (results) => {
          if (results?.length) {
            const place = parsePlace(results[0]);
            const query = `https://maps.googleapis.com/maps/api/timezone/json?location=${place.latitude},${place.longitude}&timestamp=1458000000&key=AIzaSyDaY4YObbcCFiWtGQYU3UbLdiEszNv40uQ`;
            const r = await fetch(query, {
              method: "GET",
            });

            const j = await r.json();
            resolve({ ...place, timezone: j.timeZoneId });
          } else {
            reject("Venue Geocode: No Result");
          }
        });
      } else {
        reject("Venue Geocode: No Address");
      }
    });
  };

  const importVenue = async (r) => {
    const { body } = prepareVenueForImport(r);

    if (venuesByName[body.name] || importedVenueNames.includes(body.name))
      return;

    setLoading(body.name);
    const _body = JSON.parse(JSON.stringify(body));
    delete _body.instruments;

    try {
      const index = rows?.findIndex(
        (r) => r[reversedMapping["name"]] === body.name
      );
      apiRef.current.scrollToIndexes({ rowIndex: index });
      const g = await geocode(_body.address);

      const venue = await createVenue({
        ..._body,
        address: g.line1,
        city: g.city,
        state: g.state,
        zipcode: g.zip,
        latitude: `${g.latitude}`,
        longitude: `${g.longitude}`,
        placeID: g.placeId,
        tzName: g.timezone,
      }).unwrap();
      if (!venue) return;
      setImportedVenueNames((e) => [...e, venue.name]);
      setLoading(undefined);
      return venue.id;
    } catch (error) {
      setErrorVenueNames((i) => [...i, body.name]);
      setErrors((i) => [
        ...i,
        `${body.firstName} ${body.lastName}: [${error.data.status}] ${error.data.description}`,
      ]);
    }
  };

  const onRowUpdate = async (newRow: any) => {
    return new Promise<any>((resolve) => {
      const index = rows?.findIndex((r) => r.id === newRow.id);
      const _rows = [...rows];
      _rows[index] = newRow;
      setRows(_rows);
      resolve(newRow);
    });
  };

  const genOutOfSync = () => {
    const ret: { venue: Venue; issues: string[]; row: any }[] = [];
    rows?.forEach((r) => {
      const name = r[reversedMapping["name"]];
      if (!name) return;
      const venue = venuesByName[name];
      if (!venue) return;
      const issues: string[] = [];
      for (const rowKey in mapping) {
        if (mapping.hasOwnProperty(rowKey)) {
          const venueKey = mapping[rowKey];
          const venueField = venueFields.find((m) => m.field === venueKey);

          const venueValue = venue[venueKey];
          const rowValue = r[rowKey];

          if (
            venueField &&
            rowValue &&
            !venueField.noteType &&
            venueField.format(rowValue) !== venueValue
          ) {
            issues.push(venueKey);
          }
        }
      }
      if (issues.length) ret.push({ venue, issues, row: r });
    });
    setOutOfSync(ret);
  };

  const getCellClassName = (c: GridCellParams) => {
    const field = mapping[c.field];
    const venueField = venueFields.find((m) => m.field === field);
    const name = c.row[reversedMapping["name"]];
    const oos = outOfSync.find((e) => e.venue.name === name);

    if (oos && oos.issues.includes(mapping[c.field])) {
      return "cellInfo";
    }
    if (venueField && venueField.required && !venueField?.testValue(c.value)) {
      return "cellDanger";
    }

    if (venueField && !venueField.required && !venueField?.testValue(c.value)) {
      return "cellWarning";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        p: 1,
        background: "#eceff1",
        height: heightWithToolbar,
        width: "100vw",
        position: "relative",
      }}
    >
      <Box sx={{ flex: 1, display: "flex" }}>
        {!worksheet ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                level="h6"
                startDecorator={<i className="fa-solid fa-file-excel"></i>}
              >
                Upload Excel
              </Typography>
              <Typography level="body4">
                Drag and drop or click the button bellow
              </Typography>
              <Box sx={{ width: 150, mt: 2 }}>
                <NerdFileUpload
                  uploadFile={(e: File) => {
                    setExcelFile(e);
                  }}
                  hideFilesList
                  dense
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 1,
              flex: 1,
              minWidth: "auto",
              width: 0,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Button
                  size="sm"
                  color="neutral"
                  variant="outlined"
                  sx={{ background: "white" }}
                  onClick={() => {
                    setMapping({});
                  }}
                  startDecorator={<i className="fa-solid fa-table"></i>}
                >
                  Clear Mapping
                </Button>
                <Button
                  size="sm"
                  color="neutral"
                  variant="outlined"
                  sx={{ background: "white" }}
                  onClick={() => {
                    askQuestion("Are you sure?", ["Cancel", "Yes"], {
                      subtitle: (
                        <Typography>
                          All the data will be revert back to what is in the
                          initial Excel documents. Edits you have made will be
                          lost.
                        </Typography>
                      ),
                    }).then((e) => {
                      if (e) setRows(undefined);
                    });
                  }}
                  startDecorator={<i className="fa-solid fa-rotate-left"></i>}
                >
                  Reset Rows
                </Button>
                <Typography level="body2">
                  Venues ({venues.length} rows)
                </Typography>
                {errors.length ? (
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() =>
                      askQuestion("The following errors occured", ["OK"], {
                        subtitle: (
                          <Typography>
                            <ul>
                              {errors.map((e) => (
                                <li key={e}>{e}</li>
                              ))}
                            </ul>
                          </Typography>
                        ),
                      })
                    }
                  >
                    See Errors ({errors.length})
                  </Button>
                ) : (
                  []
                )}
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                {outOfSync?.length ? (
                  <Button
                    size="sm"
                    color="primary"
                    variant="soft"
                    endDecorator={
                      <i className="fa-solid fa-right-left-large"></i>
                    }
                    startDecorator={updating ? <CircularProgress /> : undefined}
                    onClick={patchVenues}
                  >
                    Update {outOfSync.length}
                  </Button>
                ) : (
                  []
                )}
                <Button
                  size="sm"
                  color="neutral"
                  variant="outlined"
                  sx={{ background: "white" }}
                  endDecorator={<i className="fa-solid fa-file-import"></i>}
                  startDecorator={importing ? <CircularProgress /> : undefined}
                  onClick={importVenues}
                >
                  Import {importing ? `(${importing} imported)` : "All"}
                </Button>
              </Box>
            </Box>
            {columns ? (
              <DataGridPremium
                sx={{ background: "white", width: "calc(100vw - 16px)" }}
                slots={{ toolbar: QuickSearchToolbar, footer: GridFooter }}
                apiRef={apiRef}
                density="compact"
                processRowUpdate={onRowUpdate}
                getCellClassName={getCellClassName}
                columns={columns}
                rows={rows ?? []}
                initialState={{ pinnedColumns: { left: ["action"] } }}
              />
            ) : (
              []
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

function reverseDict(obj: Dictionary<string>) {
  const reversed: Dictionary<string> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      reversed[obj[key]] = key;
    }
  }
  return reversed;
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        fullWidth
        variant="outlined"
        size="small"
        sx={{ flex: 1 }}
      />
    </Box>
  );
}

function GridFooter() {
  const api = useGridApiContext();
  return (
    <Box
      sx={{
        p: 1,
        borderTop: "solid 1px #E0E0E0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography level="body3">Legend:</Typography>
        <Tooltip
          variant="outlined"
          size="sm"
          arrow
          title={
            <span>
              Field is required in order to import the row.
              <br />
              Rows with red cells won't be imported.
            </span>
          }
        >
          <Chip sx={{ background: "#f4433628", color: "#f44336" }} size="sm">
            Required
          </Chip>
        </Tooltip>
        <Tooltip
          variant="outlined"
          size="sm"
          arrow
          title={
            <span>
              Cell needs attention.
              <br />
              Not critical but the info could be misformed.
            </span>
          }
        >
          <Chip sx={{ background: "#ff980028", color: "#ff9800" }} size="sm">
            Needs Attention
          </Chip>
        </Tooltip>
        <Tooltip
          variant="outlined"
          size="sm"
          arrow
          title={
            <span>
              The info in the Excel differs with what's in the Address Book.
              <br />
              An update will be applied to Venues with blue cells.
            </span>
          }
        >
          <Chip sx={{ background: "#2196f328", color: "#2196f3" }} size="sm">
            Needs Update
          </Chip>
        </Tooltip>
      </Box>
      <Typography level="body2">
        Total Rows: {api?.current.getRowsCount()}
      </Typography>
    </Box>
  );
}
