/*global google*/
import { Instrument } from "entities/instrument";
import { Stage_Entity } from "entities/stage";
import { readyStage } from "entities/stage/helper";
import Musician_Entity from "./musician";
import { MusicianHoliday } from "entities/musicianHoliday";
import moment from "moment";
import { WorkSession } from "entities/workSession";
import { extendMoment } from "moment-range";
import { parsePlace } from "hooks/googlePlaceSuggestions/maps";
import { Venue } from "entities/venue";

const momentRange = extendMoment(moment);
export default class Musician extends Musician_Entity {
  instruments: Instrument[];

  constructor(musiciansJSON: Musician_Entity) {
    super(musiciansJSON);
    if (musiciansJSON.instruments)
      this.instruments = Instrument.fromList(musiciansJSON.instruments);
  }

  static fromList(musiciansJSON: unknown): Array<Musician> {
    const musicians: Musician[] = [];
    if (musiciansJSON)
      Array.isArray(musiciansJSON) &&
        musiciansJSON.forEach((musicianJSON) => {
          musicians.push(new Musician(musicianJSON));
        });
    return musicians;
  }

  isOff(musicianHolidays: MusicianHoliday[]) {
    const now = moment();

    for (const key in musicianHolidays) {
      if (Object.prototype.hasOwnProperty.call(musicianHolidays, key)) {
        const h = musicianHolidays[key];
        if (h.musicianID === this.id) {
          const start = h.from();
          const end = h.to();

          if (now.isSameOrAfter(start, "day") && now.isSameOrBefore(end, "day"))
            return true;
        }
      }
    }
    return false;
  }

  offUntil(musicianHolidays: MusicianHoliday[]) {
    const now = moment();

    for (const key in musicianHolidays) {
      if (Object.prototype.hasOwnProperty.call(musicianHolidays, key)) {
        const h = musicianHolidays[key];
        if (h.musicianID === this.id) {
          const start = h.from();
          const end = h.to();

          if (now.isSameOrAfter(start, "day") && now.isSameOrBefore(end, "day"))
            return end.format("ll");
        }
      }
    }
    return null;
  }

  isOffFor(musicianHolidays: MusicianHoliday[], workSessions: WorkSession[]) {
    let holiday: MusicianHoliday;
    workSessions?.forEach((workSession) => {
      musicianHolidays.forEach((h) => {
        if (h.musicianID === this.id) {
          const wStart = workSession.from();
          const wEnd = workSession.to();
          const wRange = momentRange.range(wStart, wEnd);

          const hStart = h.from();
          const hEnd = h.to();
          const hRange = momentRange.range(hStart, hEnd);

          holiday;

          if (wRange.overlaps(hRange)) holiday = h;
        }
      });
    });

    return holiday;
  }

  fullName(withNickname = true) {
    if (withNickname && this.nickName) return this.nickName;
    return `${this.firstName ?? ""} ${this.lastName ?? ""}`;
  }

  condensedName() {
    if (this.nickName) return this.nickName;
    return `${this.firstName ? this.firstName[0] : ""}. ${this.lastName ?? ""}`;
  }

  formattedAddress() {
    return `${this.address ?? ""}\n ${this.city ?? ""} ${this.state ?? ""} ${
      this.zipcode ?? ""
    }`;
  }

  initials() {
    let ret = "";
    if (this.firstName) ret += this.firstName[0];
    if (this.lastName) ret += this.lastName[0];
    return ret;
  }

  getStage(stagesMap): Stage_Entity {
    if (this?.mercuryStage?.id) {
      return stagesMap[this?.mercuryStage?.id ?? 0];
    }

    return readyStage;
  }

  toJson() {
    return JSON.stringify(this);
  }

  validAddress() {
    const hasAddress = this.address?.length > 0;
    const hasZipcode = this.zipcode?.length > 0;
    const hasCity = this.city?.length > 0;
    const hasState = this.state?.length > 0;

    return hasAddress && hasZipcode && hasCity && hasState;
  }

  async geocode(): Promise<Geocode> {
    return new Promise((resolve, reject) => {
      if (this.address) {
        const googleMapsGeocoder = new google.maps.Geocoder();
        googleMapsGeocoder.geocode(
          { address: this.formattedAddress() },
          async (results) => {
            if (results?.length) {
              const place = parsePlace(results[0]);
              resolve({
                placeID: place.placeId,
                latitude: `${place.latitude}`,
                longitude: `${place.longitude}`,
              });
            } else {
              reject("No Result");
            }
          }
        );
      } else {
        reject("Geocode: No Address");
      }
    });
  }

  async distanceToVenue(venue: Venue): Promise<Distance> {
    return new Promise((resolve, reject) => {
      console.log(this, venue);
      if (this.placeID && venue.placeID) {
        const directionsService = new google.maps.DirectionsService();
        directionsService.route(
          {
            origin: {
              lat: parseFloat(this.latitude),
              lng: parseFloat(this.longitude),
            },
            destination: {
              lat: parseFloat(venue.latitude),
              lng: parseFloat(venue.longitude),
            },
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status === "OK") {
              resolve({
                mileage: Math.floor(
                  (response.routes[0].legs[0].distance.value / 1609.34) * 1000
                ),
                polyline: response.routes[0].overview_polyline,
              });
            } else {
              reject("Directions request failed due to " + status);
            }
          }
        );
      } else {
        console.log("distanceToVenue: No Address");
      }
    });
  }
}

export type Geocode = {
  placeID?: string;
  latitude?: string;
  longitude?: string;
};

export type Distance = {
  mileage?: number;
  polyline?: string;
};
