import { Badge, Chip, Switch, Typography, useTheme } from "@mui/joy";
import { Hidden, Tooltip, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import { NerdPaginationFooter } from "@nerdjs/nerd-ui";
import { useLocalStorage } from "@uidotdev/usehooks";
import Id from "atoms/Id/Id";
import { Email } from "entities/email";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setSelectedEmailID } from "reducers/rhapsody";
import { useCurrentCompany } from "redux/company/companyHooks";
import { useMusicians } from "redux/musician/musicianHooks";
import { useProjectEmails } from "redux/project/projectHooks";
import { meSelector } from "selectors/me";
import { EmailsSearch } from "./emailSearch";
import { useEmails } from "redux/rhapsodyEmails/emailHooks";

const EMAILS_PER_PAGE = 50;

export default function EmailsDataGrid({
  filter = false,
  email,
  projectID,
  autoHeight,
  value,
  hideHeader,
  hideFooter,
}: {
  filter?: boolean;
  email?: string;
  projectID?: number;
  autoHeight?: boolean;
  value?: Email[];
  hideHeader?: boolean;
  hideFooter?: boolean;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filters = searchParams.get("filters");
  const limit = searchParams.get("limit");
  const offset = searchParams.get("offset");
  const { musicians } = useMusicians();
  const [orgEmailsOnly] = useLocalStorage("orgEmailsOnly", false);
  const { company } = useCurrentCompany();

  const emailFilter =
    email &&
    JSON.stringify([{ name: "sentToEmail", comparison: "eq", value: email }]);

  let _filters = email ? emailFilter : filters;

  if (orgEmailsOnly && company) {
    const _filtersObj = JSON.parse(_filters ?? "[]");
    _filtersObj.push({
      name: "sentFromName",
      comparison: "eq",
      value: company.contact,
    });
    _filters = JSON.stringify(_filtersObj);
  }
  const params = {
    filters: _filters,
    offset: offset !== null ? offset : "0",
    limit: limit !== null ? limit : `${EMAILS_PER_PAGE}`,
  };

  const { emails, isLoading: l1 } = useEmails(params, {
    skip: projectID !== undefined || value,
  });
  const { emails: projectEmails, isLoading: l2 } = useProjectEmails(projectID);

  let rows = value ?? emails;
  if (projectID) rows = projectEmails;

  const dispatch = useDispatch();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isEmailsPage = pathnames.indexOf("emails") >= 0;

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      width: 75,
      renderCell: (p) => (
        <Id startDecorator={<i className="fa-solid fa-envelope"></i>}>
          {p.row.id}
        </Id>
      ),
    },
    {
      field: "recipients",
      headerName: "To",
      type: "string",
      width: 250,
      valueGetter: (p) => p?.value[0]?.emailAddress,
      renderCell: (p) => {
        const musician = musicians?.find((m) => m.email === p.value);

        return (
          <Typography
            level="body2"
            startDecorator={
              musician ? (
                <MusicianAvatar size={22} musician={musician} />
              ) : undefined
            }
          >
            {musician ? musician.fullName() : p.value}
          </Typography>
        );
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      type: "string",
      flex: 1,
    },
    {
      field: "sentFromEmail",
      headerName: "Sender",
      type: "string",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 175,
      type: "date",
      valueFormatter: (p) => {
        return moment(p.value).fromNow();
      },
      renderCell: (p) => {
        return (
          <Tooltip title={moment(p.value).format("lll")}>
            <Box sx={{ lineHeight: 1.2, fontSize: 12 }}>
              {moment(p.value).format("lll")}
              <br />
              <span style={{ fontSize: 11 }}>{moment(p.value).fromNow()}</span>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "sent",
      headerName: "Status",
      type: "boolean",
      renderCell: (p) => {
        const sent = p.value;
        return (
          <Tooltip
            title={
              !sent
                ? `${p.row.failCount} attempt${p.row.failCount > 1 ? "s" : ""}`
                : "Email Successfully Sent!"
            }
          >
            <Badge badgeContent={p.row.failCount} color="danger" size="sm">
              <Chip
                size="sm"
                color={sent ? "success" : "warning"}
                variant="soft"
                startDecorator={
                  sent ? (
                    <i className="fa-solid fa-check"></i>
                  ) : (
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                  )
                }
              >
                {sent ? "Sent" : "Pending"}
              </Chip>
            </Badge>
          </Tooltip>
        );
      },
    },
  ];

  const rowsLoading = l1;

  let toolbar = QuickSearchToolbar;
  if (!filter && !email && !projectID) toolbar = CustomToolbar;

  console.log(rows);

  return (
    <DataGridPro
      hideFooter={hideFooter}
      autoHeight={autoHeight}
      loading={rowsLoading}
      density="compact"
      onRowClick={(p) => {
        if (isEmailsPage) {
          navigate(`/emails/${p.row.id}${location.search ?? ""}`);
        }
        dispatch(setSelectedEmailID(p.row.id));
      }}
      rows={rows ?? []}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: !hideHeader ? toolbar : undefined,
        Footer: !hideFooter ? CustomFooter : undefined,
      }}
      columnVisibilityModel={{
        id: !sm,
        sentFromEmail: !sm,
      }}
      componentsProps={{
        footer: { isFetching: rowsLoading, count: emails.length },
      }}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "id",
              sort: "desc",
            },
          ],
        },
      }}
    />
  );
}

function CustomToolbar() {
  const me = useSelector(meSelector);
  const [orgEmailsOnly, setOrgEmailsOnly] = useLocalStorage(
    "orgEmailsOnly",
    false
  );
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;

  return (
    <Hidden smDown>
      <Box
        className="header"
        sx={{
          display: "flex",
          p: 1,
          alignItems: "start",
          gap: 1,
          position: "relative",
          justifyContent: "space-between",
        }}
      >
        <EmailsSearch />
        {superadmin ? (
          <Typography
            level="body2"
            component="label"
            sx={{ cursor: "pointer" }}
            endDecorator={
              <Switch
                onChange={(e) => setOrgEmailsOnly(e.target.checked)}
                checked={orgEmailsOnly}
                size="sm"
                sx={{ ml: 1 }}
              />
            }
          >
            Organization Emails Only
          </Typography>
        ) : (
          []
        )}
      </Box>
    </Hidden>
  );
}

function CustomFooter(props: { isFetching: boolean; count: number }) {
  const { isFetching, count } = props;
  return (
    <NerdPaginationFooter
      count={count}
      loading={isFetching}
      defaultItemsPerPage={EMAILS_PER_PAGE}
    />
  );
}

function QuickSearchToolbar() {
  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        alignItems: "center",
      }}
    >
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}
