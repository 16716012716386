import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/joy";
import { PdProject_Entity } from "entities/pdProject";
import { useExporter } from "features/exporter/exporter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  pdModeSelector,
  selectedPdProjectIDSelector,
  selectedProjectIDSelector,
  setFormOpen,
  setPdMode,
} from "reducers/rhapsody";
import { paydayApi } from "redux/api/paydayApi";
import {
  useDeletePdProjectMutation,
  useUpdatePdProjectMutation,
} from "redux/pdProject/pdProjectEndpoints";
import { usePdProject } from "redux/pdProject/pdProjectHooks";
import { usePaydaySyncMutation } from "redux/project/projectEndpoints";

/**
 *
 * @returns {ReactElement} PdHeader page
 */
export function PdHeader({ onClose }: { onClose: () => void }) {
  const pdProjectID = useSelector(selectedPdProjectIDSelector);
  const rhapsodyProjectID = useSelector(selectedProjectIDSelector);
  const { pdProject } = usePdProject(pdProjectID);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>();
  const [syncPdProject] = usePaydaySyncMutation();
  const [deletePdProject] = useDeletePdProjectMutation();
  const [updatePdProject] = useUpdatePdProjectMutation();
  const pdMode = useSelector(pdModeSelector);
  const exporter = useExporter();

  const [_pdProject, setPdProject] = useState<PdProject_Entity>(pdProject);

  useEffect(() => {
    setPdProject(pdProject);
  }, [pdProject]);

  const reSync = () => {
    syncPdProject({
      id: rhapsodyProjectID,
      body: { id: pdProject?.id, childID: pdProject?.childID },
    })
      .unwrap()
      .then(() => {
        dispatch(paydayApi.util.invalidateTags(["pdProjects"]));
      });
  };

  return (
    <Box
      sx={{
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        p: 1,
        borderBottom: "solid 1px #D1D8DB",
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography level="h6" sx={{ fontFamily: "Tornac" }}>
          Budget & Payroll
        </Typography>
        <ToggleButtonGroup
          variant="outlined"
          sx={{ background: "white" }}
          onChange={(e, v) => {
            if (v) dispatch(setPdMode(v));
          }}
          value={pdMode}
          size="sm"
        >
          <Button value="budget">Budget</Button>
          <Button value="payroll">Payroll</Button>
        </ToggleButtonGroup>
      </Box>

      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        {pdMode === "budget" ? (
          <Button
            onClick={() => {
              setAnchorEl(null);
              dispatch(
                setFormOpen({ isOpen: true, formID: "pdReorderGroups" })
              );
            }}
            startDecorator={<i className="fa-solid fa-shuffle"></i>}
            color="neutral"
            sx={{ background: "white" }}
            variant="outlined"
            size="sm"
          >
            Reorder Groups
          </Button>
        ) : (
          []
        )}
        <Button
          onClick={() => {
            dispatch(setFormOpen({ isOpen: true, formID: "scales" }));
          }}
          startDecorator={<i className="fa-solid fa-sack-dollar"></i>}
          color="neutral"
          sx={{ background: "white" }}
          variant="outlined"
          size="sm"
        >
          View Scales
        </Button>
        <Button
          onClick={() => {
            reSync();
          }}
          color="neutral"
          sx={{ background: "white" }}
          variant="outlined"
          size="sm"
          startDecorator={<i className="fa-solid fa-arrows-rotate"></i>}
        >
          Re-Sync
        </Button>
        <Tooltip
          title="Export in Excel"
          size="sm"
          variant="outlined"
          enterDelay={1000}
          enterNextDelay={1000}
          arrow
        >
          <IconButton
            onClick={(e) => exporter.payday()}
            variant="outlined"
            color="neutral"
            sx={{ background: "white" }}
            size="sm"
          >
            <i className="fa-solid fa-arrow-down-to-line"></i>
          </IconButton>
        </Tooltip>
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          variant="outlined"
          color="neutral"
          sx={{ background: "white" }}
          size="sm"
        >
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </IconButton>
        <Menu
          disablePortal
          size="sm"
          sx={{ fontSize: 14, fontWeight: 500 }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            color="danger"
            onClick={() => {
              setAnchorEl(null);
              deletePdProject(pdProjectID)
                .unwrap()
                .then(() => {
                  dispatch(paydayApi.util.invalidateTags(["pdProjects"]));
                });
              onClose();
            }}
          >
            <i
              className="fa-sharp fa-solid fa-trash"
              style={{ paddingRight: 8 }}
            ></i>
            Delete Document
          </MenuItem>
        </Menu>
        <Tooltip
          title="Close Document"
          size="sm"
          variant="outlined"
          enterDelay={1000}
          enterNextDelay={1000}
          arrow
        >
          <IconButton
            variant="outlined"
            color="neutral"
            sx={{ background: "white" }}
            size="sm"
            onClick={onClose}
          >
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
