import {
  Button,
  Chip,
  ChipDelete,
  CircularProgress,
  Input,
  Sheet,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { NerdFileUpload } from "@nerdjs/nerd-ui";
import { Dictionary } from "@reduxjs/toolkit";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Assignment_Entity } from "entities/assignment";
import { Link_Entity } from "entities/link";
import { Musician, Musician_Entity } from "entities/musician";
import { Piece_Entity } from "entities/piece";
import { Project_Entity } from "entities/project";
import { ProjectContact_Entity } from "entities/projectContact";
import { ProjectNote_Entity } from "entities/projectNote";
import { ProjectPiece_Entity } from "entities/projectPiece";
import { ProjectRoster_Entity } from "entities/projectRoster";
import { RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { SubRule_Entity } from "entities/subRule";
import { Venue_Entity } from "entities/venue";
import { WorkSession_Entity } from "entities/workSession";
import { WorkSessionProjectPiece_Entity } from "entities/workSessionProjectPiece";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import {
  useLazyGetAssignmentsQuery,
  useUpdateAssignmentMutation,
} from "redux/assignment/assignmentEndpoints";
import { useCreateLinkMutation } from "redux/link/linkEndpoints";
import {
  useCreateMusician2Mutation,
  useUpdateMusicianInstruments2Mutation,
} from "redux/musician/musicianEndpoints";
import { useMusicians } from "redux/musician/musicianHooks";
import { useCreatePieceMutation } from "redux/piece/pieceEndpoints";
import { usePieces } from "redux/piece/pieceHooks";
import { useCreateProjectMutation } from "redux/project/projectEndpoints";
import { useCreateProjectContactMutation } from "redux/projectContact/projectContactEndpoints";
import { useCreateProjectNoteMutation } from "redux/projectNote/projectNoteEndpoints";
import {
  useCreateProjectPieceMutation,
  useDeleteProjectPieceMutation,
  useLazyGetProjectPiecesQuery,
} from "redux/projectPiece/projectPieceEndpoints";
import { useCreateProjectRosterMutation } from "redux/projectRoster/projectRosterEndpoints";
import { useCreateChairs2Mutation } from "redux/rhapsodyChair/rhapsodyChairEndpoints";
import { useCreateSubRuleMutation } from "redux/subRule/subRuleEndpoints";
import { useCreateVenueMutation } from "redux/venue/venueEndpoints";
import { useVenues } from "redux/venue/venueHooks";
import { useCreateWorkSession2Mutation } from "redux/workSession/workSessionEndpoints";
import { useCreateWorkSessionProjectPiece2Mutation } from "redux/workSessionProjectPiece/workSessionProjectPieceEndpoints";

export function useImportProject() {
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState<Dictionary<boolean>>({});
  const { musicians } = useMusicians();
  const { pieces } = usePieces();
  const { venues } = useVenues();

  const musiciansByEmail = musicians.reduce<Dictionary<Musician>>((a, v) => {
    a[v.email] = v;
    return a;
  }, {});

  const [createVenue] = useCreateVenueMutation();
  const [createProject] = useCreateProjectMutation();
  const [createSubRule] = useCreateSubRuleMutation();
  const [createMusician] = useCreateMusician2Mutation();
  const [updateMusicianInstrument] = useUpdateMusicianInstruments2Mutation();
  const [createWorkSession] = useCreateWorkSession2Mutation();
  const [createPiece] = useCreatePieceMutation();
  const [createProjectPiece] = useCreateProjectPieceMutation();
  const [createChairs] = useCreateChairs2Mutation();
  const [createLink] = useCreateLinkMutation();
  const [createProjectNote] = useCreateProjectNoteMutation();
  const [createProjectContact] = useCreateProjectContactMutation();
  const [deleteProjectPiece] = useDeleteProjectPieceMutation();
  const [getLazyProjectPieces] = useLazyGetProjectPiecesQuery();
  const [getAssignments] = useLazyGetAssignmentsQuery();
  const [createProjectRoster] = useCreateProjectRosterMutation();
  const [updateAssignment] = useUpdateAssignmentMutation();
  const [createWorkSessionProjectPiece] =
    useCreateWorkSessionProjectPiece2Mutation();

  const importNow = async (content: Content) => {
    setLoading(true);
    setLoadingState({});

    const _project = await importProject(content);

    const _musicians = await importMusicians(content);

    const _venues = await importVenues(content);
    const { _workSessions, _workSessionsRosetta } = await importWorkSessions(
      _project,
      [...venues, ..._venues],
      content
    );

    const _projectContacts = await importProjectContacts(_project, content);

    const _projectLinks = await importLinks(_project, content);

    const _subRules = await importSubRules(_project, content);

    const _importProjectRosters = await importProjectRosters(_project, content);

    const _projectNotes = await importProjectNotes(_project, content);

    const _pieces = await importPieces(content);

    const { _projectPieces, _projectPiecesRosetta } = await importProjectPieces(
      _project,
      [...pieces, ..._pieces],
      content
    );

    const _workSessionProjectPieces = await importWorkSessionProjectPieces(
      _project,
      _workSessionsRosetta,
      _projectPiecesRosetta,
      content
    );
    const _chairs = await importChairs(
      _project,
      _workSessionsRosetta,
      _projectPiecesRosetta,
      [...musicians, ..._musicians],
      content
    );

    await importAssignments(_project, [...musicians, ..._musicians], content);

    await deleteDefaultProjectPiece(_project);

    setLoading(false);
    return _project;
  };

  const deleteDefaultProjectPiece = async (_project: Project_Entity) => {
    const { data } = await getLazyProjectPieces({
      filters: JSON.stringify([
        {
          name: "project_pieces.projectID",
          comparison: "eq",
          value: _project?.id,
        },
      ]),
    });

    if (data.ids.length > 1) {
      data.ids.forEach((projectPieceID) => {
        if (!data.entities[projectPieceID].pieceID) {
          deleteProjectPiece(projectPieceID as number);
        }
      });
    }
  };

  const importMusicians = async (content: Content) => {
    console.log("importMusicians");
    setLoadingState((p) => ({ ...p, ["musicians"]: true }));
    const _musicians: Musician_Entity[] = [];
    for (const key in content.musicians) {
      if (Object.hasOwnProperty.call(content.musicians, key)) {
        const m = content.musicians[key];
        if (!musiciansByEmail[m.email]) {
          const _musician = await createMusician({
            ...m,
            sub: false,
            latitude: "",
            longitude: "",
            placeID: "",
            tzName: "",
          }).unwrap();
          m.instruments.forEach((i) => {
            updateMusicianInstrument({
              id: _musician.id,
              body: { instrumentID: i.id },
            });
          });
          _musicians.push(_musician);
        }
      }
    }
    setLoadingState((p) => ({ ...p, ["musicians"]: false }));
    return _musicians;
  };

  const importVenues = async (content: Content) => {
    console.log("importVenues");
    setLoadingState((p) => ({ ...p, ["venues"]: true }));
    const _venues: Venue_Entity[] = [];
    for (const key in content.venues) {
      if (Object.hasOwnProperty.call(content.venues, key)) {
        const v = content.venues[key];
        if (venues.findIndex((e) => e.name === v.name) === -1) {
          const _venue = await createVenue(v).unwrap();
          _venues.push(_venue);
        }
      }
    }
    setLoadingState((p) => ({ ...p, ["venues"]: false }));
    return _venues;
  };

  const importProject = async (content: Content) => {
    console.log("importProject");
    setLoadingState((p) => ({ ...p, ["project"]: true }));
    const r = await createProject({
      ...content.project,
      name: content.projectName,
    }).unwrap();
    setLoadingState((p) => ({ ...p, ["project"]: false }));
    return r;
  };

  const importProjectContacts = async (
    _project: Project_Entity,
    content: Content
  ) => {
    console.log("importProjectContacts");
    setLoadingState((p) => ({ ...p, ["projectContacts"]: true }));
    const _projectContacts: ProjectContact_Entity[] = [];
    for (const key in content.projectContacts) {
      if (Object.hasOwnProperty.call(content.projectContacts, key)) {
        const p = content.projectContacts[key];
        const _projectContact = await createProjectContact({
          ...p,
          projectID: _project.id,
        }).unwrap();

        _projectContacts.push(_projectContact);
      }
    }
    setLoadingState((p) => ({ ...p, ["projectContacts"]: false }));
    return _projectContacts;
  };

  const importLinks = async (_project: Project_Entity, content: Content) => {
    console.log("importLinks");
    setLoadingState((p) => ({ ...p, ["links"]: true }));
    const _links: Link_Entity[] = [];
    for (const key in content.links) {
      if (Object.hasOwnProperty.call(content.links, key)) {
        const p = content.links[key];
        const _link = await createLink({
          ...p,
          projectID: _project.id,
          tagID: 0,
        }).unwrap();

        _links.push(_link);
      }
    }
    setLoadingState((p) => ({ ...p, ["links"]: false }));
    return _links;
  };

  const importSubRules = async (_project: Project_Entity, content: Content) => {
    console.log("importSubRules");
    setLoadingState((p) => ({ ...p, ["subRules"]: true }));
    const _subRules: SubRule_Entity[] = [];
    for (const key in content.subRules) {
      if (Object.hasOwnProperty.call(content.subRules, key)) {
        const p = content.subRules[key];
        const _subRule = await createSubRule({
          ...p,
          projectID: _project.id,
        }).unwrap();

        _subRules.push(_subRule);
      }
    }
    setLoadingState((p) => ({ ...p, ["subRules"]: false }));
    return _subRules;
  };

  const importProjectRosters = async (
    _project: Project_Entity,
    content: Content
  ) => {
    console.log("importProjectRosters");
    setLoadingState((p) => ({ ...p, ["projectRosters"]: true }));
    const _projectRosters: ProjectRoster_Entity[] = [];
    for (const key in content.projectRosters) {
      if (Object.hasOwnProperty.call(content.projectRosters, key)) {
        const p = content.projectRosters[key];
        const _projectRoster = await createProjectRoster({
          ...p,
          projectID: _project.id,
        }).unwrap();

        _projectRosters.push(_projectRoster);
      }
    }
    setLoadingState((p) => ({ ...p, ["projectRosters"]: false }));
    return _projectRosters;
  };

  const importProjectNotes = async (
    _project: Project_Entity,
    content: Content
  ) => {
    console.log("importProjectNotes");
    setLoadingState((p) => ({ ...p, ["projectNotes"]: true }));
    const _projectNotes: ProjectNote_Entity[] = [];
    for (const key in content.projectNotes) {
      if (Object.hasOwnProperty.call(content.projectNotes, key)) {
        const p = content.projectNotes[key];
        const _projectNote = await createProjectNote({
          ...p,
          projectID: _project.id,
        }).unwrap();

        _projectNotes.push(_projectNote);
      }
    }
    setLoadingState((p) => ({ ...p, ["projectNotes"]: false }));
    return _projectNotes;
  };

  const importWorkSessions = async (
    project: Project_Entity,
    venues: Venue_Entity[],
    content: Content
  ) => {
    setLoadingState((p) => ({ ...p, ["workSessions"]: true }));
    const _workSessions: WorkSession_Entity[] = [];
    const _workSessionsRosetta: Dictionary<number> = {};
    for (const key in content.workSessions) {
      if (Object.hasOwnProperty.call(content.workSessions, key)) {
        const w = content.workSessions[key];
        const initialVenue = content.venues.find((v) => v.id === w.venueID);
        const venue = venues.find((v) => v.name === initialVenue.name);
        const newWorkSession = await createWorkSession({
          ...w,
          projectID: project.id,
          venueID: venue.id,
          mainEngagement: {
            tzName: w.tzName,
            venueID: venue.id,
            dateFromUTC: w.dateFromUTC,
            dateToUTC: w.dateToUTC,
            noTimeRange: w.noTimeRange,
          },
        }).unwrap();
        _workSessions.push(newWorkSession);
        _workSessionsRosetta[w.id] = newWorkSession.id;
      }
    }
    setLoadingState((p) => ({ ...p, ["workSessions"]: false }));
    return { _workSessions, _workSessionsRosetta };
  };

  const importPieces = async (content: Content) => {
    console.log("importPieces");
    setLoadingState((p) => ({ ...p, ["pieces"]: true }));
    const _newPieces: Piece_Entity[] = [];
    for (const key in content.pieces) {
      if (Object.hasOwnProperty.call(content.pieces, key)) {
        const p = content.pieces[key];
        if (
          pieces.findIndex(
            (e) => e.name === p.name && e.composer === p.composer
          ) === -1
        ) {
          const newPiece = await createPiece(p).unwrap();
          _newPieces.push(newPiece);
        }
      }
    }
    setLoadingState((p) => ({ ...p, ["pieces"]: false }));
    return _newPieces;
  };

  const importProjectPieces = async (
    project: Project_Entity,
    _pieces: Piece_Entity[],
    content: Content
  ) => {
    console.log("importPieces");
    setLoadingState((p) => ({
      ...p,
      ["projectPieces"]: true,
    }));
    const _projectPieces: ProjectPiece_Entity[] = [];
    const _projectPiecesRosetta: Dictionary<number> = {};

    for (const key in content.projectPieces) {
      if (Object.hasOwnProperty.call(content.projectPieces, key)) {
        const projectPiece = content.projectPieces[key];
        const initialPiece =
          content.pieces.find((p) => p.id === projectPiece.pieceID) ??
          _pieces[0];

        const piece: Piece_Entity = _pieces.find(
          (e) =>
            e.name === initialPiece.name && e.composer === initialPiece.composer
        );

        const _projectPiece = await createProjectPiece({
          ...projectPiece,
          projectID: project.id,
          pieceID: piece?.id ?? _pieces[0]?.id,
        }).unwrap();

        _projectPieces.push(_projectPiece);
        _projectPiecesRosetta[projectPiece.id] = _projectPiece.id;
      }
    }

    setLoadingState((p) => ({
      ...p,
      ["projectPieces"]: false,
    }));

    return { _projectPieces, _projectPiecesRosetta };
  };

  const importWorkSessionProjectPieces = async (
    _project: Project_Entity,
    _workSessionsRosetta: Dictionary<number>,
    _projectPiecesRosetta: Dictionary<number>,
    content: Content
  ) => {
    setLoadingState((p) => ({
      ...p,
      ["workSessionProjectPieces"]: true,
    }));
    console.log("importWorkSessionProjectPieces");
    const _workSessionProjectPieces: WorkSessionProjectPiece_Entity[] = [];

    for (const key in content.workSessionProjectPieces) {
      if (Object.hasOwnProperty.call(content.workSessionProjectPieces, key)) {
        const workSessionProjectPiece = content.workSessionProjectPieces[key];

        const _workSessionProjectPiece = await createWorkSessionProjectPiece({
          ...workSessionProjectPiece,
          projectID: _project.id,
          projectPieceID:
            _projectPiecesRosetta[workSessionProjectPiece.projectPieceID],
          workSessionID:
            _workSessionsRosetta[workSessionProjectPiece.workSessionID],
        }).unwrap();

        _workSessionProjectPieces.push(_workSessionProjectPiece);
      }
    }

    setLoadingState((p) => ({
      ...p,
      ["workSessionProjectPieces"]: false,
    }));

    return _workSessionProjectPieces;
  };

  const importChairs = async (
    _project: Project_Entity,
    _workSessionsRosetta: Dictionary<number>,
    _projectPiecesRosetta: Dictionary<number>,
    _musicians: Musician_Entity[],
    content: Content
  ) => {
    console.log("importChairs");
    setLoadingState((p) => ({
      ...p,
      ["chairs"]: true,
    }));
    const body: RhapsodyChair_Entity[] = [];
    for (const key in content.chairs) {
      if (Object.hasOwnProperty.call(content.chairs, key)) {
        const _chair = { ...content.chairs[key] };
        const initialMusician = content.musicians.find(
          (m) => m.id === _chair?.musicianID
        );
        const musician = _musicians.find(
          (m) => m.email === initialMusician?.email
        );

        _chair.projectID = _project.id;
        _chair.projectPieceID = _projectPiecesRosetta[_chair?.projectPieceID];
        _chair.workSessionID = _workSessionsRosetta[_chair?.workSessionID];
        _chair.musicianID = musician?.id;
        delete _chair?.id;
        delete _chair?.assignmentID;
        delete _chair?.workSessionIDs;
        _chair.chairCount = 1;

        body.push(_chair);
      }
    }
    const r = await createChairs(body).unwrap();
    setLoadingState((p) => ({
      ...p,
      ["chairs"]: false,
    }));
    return r;
  };

  const importAssignments = async (
    _project: Project_Entity,
    musicians: Musician_Entity[],
    content: Content
  ) => {
    console.log("importAssignments");
    const _assignments = await getAssignments({
      filters: JSON.stringify([
        {
          name: "assignments.projectID",
          comparison: "eq",
          value: _project.id,
        },
      ]),
    }).unwrap();

    const _assignmentsArray = _assignments.ids.reduce((a, v) => {
      a.push(_assignments.entities[v]);
      return a;
    }, []);

    for (const key in content.assignments) {
      if (Object.hasOwnProperty.call(content.assignments, key)) {
        const _assignment = { ...content.assignments[key] };
        const initialMusician = content.musicians.find(
          (e) => e.id === _assignment.musicianID
        );
        const musician = musicians.find(
          (e) => e.email === initialMusician?.email
        );

        if (_assignment.memo && musician) {
          const a = _assignmentsArray.find((e) => e.musicianID === musician.id);
          if (a)
            await updateAssignment({
              id: a.id,
              body: { memo: _assignment.memo },
            });
        }
      }
    }
  };

  return { importNow, loading, loadingState };
}

export type Content = {
  projectName?: string;
  assignments?: Assignment_Entity[];
  chairs?: RhapsodyChair_Entity[];
  projectRosters?: ProjectRoster_Entity[];
  links?: Link_Entity[];
  musicians?: Musician_Entity[];
  pieces?: Piece_Entity[];
  project?: Project_Entity;
  projectContacts?: ProjectContact_Entity[];
  workSessionProjectPieces?: WorkSessionProjectPiece_Entity[];
  projectNotes?: ProjectNote_Entity[];
  projectPieces?: ProjectPiece_Entity[];
  workSessions?: WorkSession_Entity[];
  subRules?: SubRule_Entity[];
  venues?: Venue_Entity[];
};

/**
 *
 * @returns {ReactElement} ProjectImport page
 */
export function ProjectImport() {
  const open = useSelector(formOpenSelector("projectImport"));
  const [file, setFile] = useState<File | null>();
  const [content, setContent] = useState<Content | null>();
  const [projectName, setProjectName] = useState("");
  const { importNow, loading, loadingState } = useImportProject();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "projectImport" }));
  };

  useEffect(() => {
    if (file) process();
  }, [file]);

  useEffect(() => {
    if (content) setProjectName(content?.project?.name ?? "");
  }, [content]);

  const process = async () => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = `${e.target.result}`;
      const json = JSON.parse(text);
      setContent(json);
    };

    reader.readAsText(file);
  };

  return (
    <DialogClose fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Import</DialogTitle>
      <DialogContent sx={{ flexDirection: "column", gap: 1, display: "flex" }}>
        <NerdFileUpload
          uploadFile={(e: File) => {
            console.log(e);
            setFile(e);
          }}
          hideFilesList
          dense
        />
        {file ? (
          <Chip
            variant="soft"
            endDecorator={
              <ChipDelete
                onDelete={() => {
                  setFile(null);
                  setContent(null);
                }}
              />
            }
          >
            {file.name}
          </Chip>
        ) : (
          <Typography level="body2">Upload the JSON file</Typography>
        )}
        {content ? (
          <Sheet sx={{ p: 1, borderRadius: 8 }} variant="soft" color="neutral">
            <Typography level="body1" sx={{ fontWeight: 800 }}>
              Summary:
            </Typography>
            {Object.keys(content).map((k) => {
              const inProgess = loadingState[k] === true;
              const done = loadingState[k] === false;

              let color: "success" | "neutral" | "primary" = "neutral";
              if (inProgess) color = "primary";
              if (done) color = "success";

              return (
                <Typography
                  startDecorator={
                    inProgess ? (
                      <CircularProgress color={color} size="sm" />
                    ) : undefined
                  }
                  color={color}
                  key={k}
                  level="body2"
                >
                  {k}: {content[k]?.length ?? 1}
                </Typography>
              );
            })}
          </Sheet>
        ) : (
          []
        )}
        {content ? (
          <Input
            startDecorator={"New Project name:"}
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button
          startDecorator={loading ? <CircularProgress size="sm" /> : undefined}
          disabled={!content || loading}
          onClick={async () => {
            const _project = await importNow(content);
            onClose();
            navigate(
              `${
                _project.template
                  ? RouterConfig.templates
                  : RouterConfig.projects
              }/${_project.id}/mission-control`
            );
          }}
        >
          Import
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
