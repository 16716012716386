import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Email_Entity } from "entities/email";
import { rhapsodyApi } from "redux/api";
import { postalApi } from "redux/api/postalApi";

const emailsAdapter = createEntityAdapter<Email_Entity>();
const initialState = emailsAdapter.getInitialState();

export const emailEndpoints = rhapsodyApi.injectEndpoints({
  // export const emailEndpoints = postalApi.injectEndpoints({
  endpoints: (build) => ({
    getEmails: build.query<
      EntityState<Email_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `companies/current/emails`,
          // url: `emails`,
          params: args,
        };
      },
      transformResponse: (responseData: Email_Entity[]) => {
        return emailsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["emails"],
    }),
    getEmail: build.query<Email_Entity, number>({
      query: (id) => `companies/current/emails/${id}`,
      providesTags: (result, error, id) => [{ type: "emails", id }],
    }),
  }),
});

export const { useGetEmailQuery, useLazyGetEmailsQuery, useGetEmailsQuery } =
  emailEndpoints;

export default emailEndpoints;
