/*global google*/
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Musician } from "entities/musician";
import { useExporter } from "features/exporter/exporter";
import { InstrumentsTooltip } from "features/musicians/InstrumentsPopover/InstrumentsTooltip";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { heightWithToolbar } from "global";
import { parsePlace } from "hooks/googlePlaceSuggestions/maps";
import { SearchTooltip } from "hooks/searchTooltip/searchTooltip";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { setSelectedMusicianID } from "reducers/rhapsody";
import { layoutDebugSelector } from "reducers/v2/missionControl";
import { useGroups } from "redux/group/groupHooks";
import { useUpdateMusicianMutation } from "redux/musician/musicianEndpoints";
import { useMusicians } from "redux/musician/musicianHooks";
import { usePieces } from "redux/piece/pieceHooks";
import { useProjects } from "redux/project/projectHooks";
import { useTags } from "redux/tag/tagHooks";
import { useVenues } from "redux/venue/venueHooks";

/**
 *
 * @returns {ReactElement} Explore page
 */
export function Explore() {
  const ref = useRef<HTMLDivElement>(null);
  const center = { lat: 33.98344574576471, lng: -118.2108967598036 };
  const { musicians: _musicians, musiciansMap } = useMusicians();
  const { venues } = useVenues();
  const [map, setMap] = useState<google.maps.Map>();
  const zoom = 9.174641843438149;
  const [musicianMarkers, setMusicianMarkers] = useState<google.maps.Marker[]>(
    []
  );
  const { projects } = useProjects();
  const { pieces } = usePieces();
  const { groups } = useGroups();
  const { tags } = useTags();
  const [venueMarkers, setVenueMarkers] = useState<google.maps.Marker[]>([]);
  const dispatch = useDispatch();
  const [instrumentIDs, setInstrumentIDs] = useState<number[]>([]);
  const [venueIDs, setVenueIDs] = useState<number[]>([]);
  const [projectIDs, setProjectIDs] = useState<number[]>([]);
  const [piecesIDs, setPiecesIDs] = useState<number[]>([]);
  const [groupIDs, setGroupIDs] = useState<number[]>([]);
  const [tagIDs, setTagIDs] = useState<number[]>([]);
  const musicianListRef = useRef<HTMLDivElement>();
  const [musicians, setMusicians] = useState<Musician[]>([]);
  const exporter = useExporter(musicians);
  const [placeMusician, setPlaceMusicians] = useState(false);
  const [updateMusician] = useUpdateMusicianMutation();
  const musiciansNotLocated = _musicians.filter((m) => !m.latitude);
  const debugMode = useSelector(layoutDebugSelector);

  const handleMapClick = useCallback(
    (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          const parse = parsePlace(results[0]);
          const musicianID = musiciansNotLocated[0]?.id;
          updateMusician({
            id: musicianID,
            body: {
              ...musiciansMap[musicianID],
              placeID: parse.placeId,
              latitude: `${lat}`,
              longitude: `${lng}`,
              address: parse.line1,
              city: parse.city,
              state: parse.state,
              zipcode: parse.zip,
            },
          });
        }
      });
    },
    [_musicians] // Ensure the latest state is used
  );

  useEffect(() => {
    if (map && placeMusician) {
      map.addListener("click", handleMapClick);
      return () => google.maps.event.clearListeners(map, "click"); // Cleanup on unmount
    }
  }, [handleMapClick, map, placeMusician, _musicians]);

  useEffect(() => {
    if (instrumentIDs.length > 0) {
      setMusicians(
        _musicians.reduce<Musician[]>((a, v) => {
          v.instruments?.forEach((i) => {
            if (instrumentIDs.includes(i.id) && !a.includes(v)) {
              a.push(v);
            }
          });
          return a;
        }, [])
      );
    } else {
      setMusicians(_musicians);
    }
  }, [instrumentIDs, _musicians]);

  useEffect(() => {
    musicianMarkers.forEach((m) => {
      const markerMusician = m.get("musician");
      if (musicians.find((m) => m.id === markerMusician.id)) {
        m.setVisible(true);
      } else {
        m.setVisible(false);
      }
    });
  }, [musicians]);

  useEffect(() => {
    venueMarkers.forEach((v) => {
      const venueMarker = v.get("venue");
      if (venueIDs.length === 0 || venueIDs.find((m) => m === venueMarker.id)) {
        v.setVisible(true);
      } else {
        v.setVisible(false);
      }
    });
  }, [venueIDs]);

  useEffect(() => {
    if (venues && map && venueMarkers.length === 0) {
      const bounds = new google.maps.LatLngBounds();
      const _markers = [];

      venues.forEach((v) => {
        if (v.latitude && v.longitude) {
          const position = {
            lat: parseFloat(v.latitude),
            lng: parseFloat(v.longitude),
          };
          const m = new google.maps.Marker({
            position,
            map: map,
            title: `${v.name}`,
            icon: {
              url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png", // Google Maps' built-in blue dot icon
              scaledSize: new google.maps.Size(30, 30), // Adjust the size if needed
            },
            zIndex: 2,
          });
          m.set("venue", v);
          const infoWindow = new google.maps.InfoWindow({
            headerDisabled: true,
            content: `<div style='padding:5px;'>${v.name}</div>`,
          });
          m.addListener("mouseover", () => {
            infoWindow.open(map, m);
          });
          m.addListener("mouseout", () => {
            infoWindow.close();
          });
          _markers.push(m);
          bounds.extend(position);
        }
      });
      map.fitBounds(bounds);
      console.log(`setVenueMarkers: ${_markers?.length}`);
      setVenueMarkers(_markers);
    }

    if (musicians && map && musicianMarkers.length === 0) {
      const _markers = [];
      musicians.forEach((musician) => {
        if (musician.latitude && musician.longitude) {
          const position = {
            lat: parseFloat(musician.latitude),
            lng: parseFloat(musician.longitude),
          };
          const m = new google.maps.Marker({
            position,
            map: map,
            icon: {
              url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Google Maps' built-in blue dot icon
              scaledSize: new google.maps.Size(15, 15), // Adjust the size if needed
            },
            title: `${musician.fullName()}`,
            zIndex: 1,
          });
          m.set("musician", musician);
          const infoWindow = new google.maps.InfoWindow({
            headerDisabled: true,
            content: `<div style='padding:5px;'>${musician.fullName()}<br />${
              musician.instrumentNames
            }</div>`,
          });
          m.addListener("click", () => {
            dispatch(setSelectedMusicianID(musician.id));
          });
          m.addListener("mouseover", () => {
            infoWindow.open(map, m);
          });
          m.addListener("mouseout", () => {
            infoWindow.close();
          });
          _markers.push(m);
        }
      });
      console.log(`setMusicianMarkers: ${_markers?.length}`);
      setMusicianMarkers(_markers);
    }
  }, [musicians, map]);

  useEffect(() => {
    if (ref.current) {
      initMap();
    }
  }, [ref]);

  const initMap = () => {
    if (ref.current && !map) {
      const m = new window.google.maps.Map(ref.current, {
        mapId: "147c16fccc039a21",
        center,
        zoom,
        disableDefaultUI: true,
      });
      setMap(m);
    }
  };

  const mapElement = useMemo(() => {
    return <Box ref={ref} sx={{ flexGrow: 1 }} />;
  }, []);

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const m = musicians[index];

    return (
      <ListItemButton
        onClick={() => {
          const marker = musicianMarkers?.find(
            (k) => k.get("musician")?.id === m.id
          );
          if (marker) {
            map.setZoom(16);
            map.setCenter(marker?.getPosition());
          }
        }}
        sx={{ background: !m.placeID ? "#ff980022" : undefined }}
        style={style}
        key={m.id}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            p: 1,
            width: 234,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          <MusicianAvatar musician={m} size={32} />
          <Box sx={{ flexGrow: 1 }}>
            <Typography level="body2">{m.fullName()}</Typography>
            <Typography noWrap overflow="hidden" level="body3">
              {m.instrumentNames}
            </Typography>
          </Box>
          {!m.placeID ? (
            <Tooltip
              arrow
              size="sm"
              color="neutral"
              variant="outlined"
              title="No Address"
            >
              <i
                style={{ color: "#ff9800" }}
                className="fa-solid fa-location-pin-slash"
              ></i>
            </Tooltip>
          ) : (
            []
          )}
        </Box>
      </ListItemButton>
    );
  }

  console.log("Render");

  return (
    <Box
      sx={{
        flex: 1,
        height: heightWithToolbar,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{ height: 48, display: "flex", gap: 1, alignItems: "center", p: 1 }}
      >
        <Typography level="h6">Filters:</Typography>
        <InstrumentsTooltip
          instrumentIDs={instrumentIDs}
          hideTitle
          maxHeight={600}
          onSelect={(i) => setInstrumentIDs(i)}
        >
          <Button
            variant="solid"
            color={instrumentIDs?.length ? "primary" : "neutral"}
            size="sm"
            startDecorator={
              instrumentIDs?.length ? (
                <Typography
                  variant="outlined"
                  level="body3"
                  sx={{
                    borderColor: "white",
                    color: "white",
                    background: "#ffffff22",
                    mr: 0.5,
                  }}
                >
                  {instrumentIDs.length}
                </Typography>
              ) : (
                []
              )
            }
            endDecorator={
              instrumentIDs?.length ? (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setInstrumentIDs([]);
                  }}
                  className="fa-solid fa-xmark"
                ></i>
              ) : (
                <i className="fa-solid fa-chevron-down"></i>
              )
            }
          >
            Instruments
          </Button>
        </InstrumentsTooltip>
        <SearchTooltip
          getLabel={(e) => e.name}
          items={venues}
          selectedIDs={venueIDs}
          onSelect={(i) => {
            console.log("onSelect");
            setVenueIDs(i);
          }}
        >
          <Button
            variant="solid"
            color={venueIDs?.length ? "primary" : "neutral"}
            size="sm"
            startDecorator={
              venueIDs?.length ? (
                <Typography
                  variant="outlined"
                  level="body3"
                  sx={{
                    borderColor: "white",
                    color: "white",
                    background: "#ffffff22",
                    mr: 0.5,
                  }}
                >
                  {venueIDs.length}
                </Typography>
              ) : (
                []
              )
            }
            endDecorator={
              venueIDs?.length ? (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setVenueIDs([]);
                  }}
                  className="fa-solid fa-xmark"
                ></i>
              ) : (
                <i className="fa-solid fa-chevron-down"></i>
              )
            }
          >
            Venues
          </Button>
        </SearchTooltip>
        <SearchTooltip
          getLabel={(e) => e.name}
          items={groups}
          selectedIDs={groupIDs}
          onSelect={(i) => {
            setGroupIDs(i);
          }}
        >
          <Button
            variant="solid"
            color={groupIDs?.length ? "primary" : "neutral"}
            size="sm"
            startDecorator={
              groupIDs?.length ? (
                <Typography
                  variant="outlined"
                  level="body3"
                  sx={{
                    borderColor: "white",
                    color: "white",
                    background: "#ffffff22",
                    mr: 0.5,
                  }}
                >
                  {groupIDs.length}
                </Typography>
              ) : (
                []
              )
            }
            endDecorator={
              groupIDs?.length ? (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setGroupIDs([]);
                  }}
                  className="fa-solid fa-xmark"
                ></i>
              ) : (
                <i className="fa-solid fa-chevron-down"></i>
              )
            }
          >
            Groups
          </Button>
        </SearchTooltip>
        {/* <SearchTooltip
          getLabel={(e) => e.name}
          items={tags}
          selectedIDs={tagIDs}
          onSelect={(i) => {
            setTagIDs(i);
          }}
        >
          <Button
            variant="solid"
            color={tagIDs?.length ? "primary" : "neutral"}
            size="sm"
            startDecorator={
              tagIDs?.length ? (
                <Typography
                  variant="outlined"
                  level="body3"
                  sx={{
                    borderColor: "white",
                    color: "white",
                    background: "#ffffff22",
                    mr: 0.5,
                  }}
                >
                  {tagIDs.length}
                </Typography>
              ) : (
                []
              )
            }
            endDecorator={
              tagIDs?.length ? (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setTagIDs([]);
                  }}
                  className="fa-solid fa-xmark"
                ></i>
              ) : (
                <i className="fa-solid fa-chevron-down"></i>
              )
            }
          >
            Seasons
          </Button>
        </SearchTooltip>
        <SearchTooltip
          getLabel={(e) => e.name}
          items={projects}
          selectedIDs={projectIDs}
          onSelect={(i) => {
            setProjectIDs(i);
          }}
        >
          <Button
            variant="solid"
            color={projectIDs?.length ? "primary" : "neutral"}
            size="sm"
            startDecorator={
              projectIDs?.length ? (
                <Typography
                  variant="outlined"
                  level="body3"
                  sx={{
                    borderColor: "white",
                    color: "white",
                    background: "#ffffff22",
                    mr: 0.5,
                  }}
                >
                  {projectIDs.length}
                </Typography>
              ) : (
                []
              )
            }
            endDecorator={
              projectIDs?.length ? (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setProjectIDs([]);
                  }}
                  className="fa-solid fa-xmark"
                ></i>
              ) : (
                <i className="fa-solid fa-chevron-down"></i>
              )
            }
          >
            Projects
          </Button>
        </SearchTooltip>
        <SearchTooltip
          getLabel={(e) => `${e.name}, ${e.composer}`}
          items={pieces}
          selectedIDs={piecesIDs}
          onSelect={(i) => {
            setProjectIDs(i);
          }}
        >
          <Button
            variant="solid"
            color={piecesIDs?.length ? "primary" : "neutral"}
            size="sm"
            startDecorator={
              piecesIDs?.length ? (
                <Typography
                  variant="outlined"
                  level="body3"
                  sx={{
                    borderColor: "white",
                    color: "white",
                    background: "#ffffff22",
                    mr: 0.5,
                  }}
                >
                  {piecesIDs.length}
                </Typography>
              ) : (
                []
              )
            }
            endDecorator={
              piecesIDs?.length ? (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setPiecesIDs([]);
                  }}
                  className="fa-solid fa-xmark"
                ></i>
              ) : (
                <i className="fa-solid fa-chevron-down"></i>
              )
            }
          >
            Pieces
          </Button>
        </SearchTooltip> */}
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => {
            setGroupIDs([]);
            setInstrumentIDs([]);
            setPiecesIDs([]);
            setTagIDs([]);
            setVenueIDs([]);
            setProjectIDs([]);
          }}
        >
          Clear Filters
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {debugMode ? (
          <Button
            endDecorator={<i className="fa-solid fa-arrow-down-to-line"></i>}
            variant={placeMusician ? "soft" : "solid"}
            color={placeMusician ? "primary" : "neutral"}
            disabled={musiciansNotLocated?.length === 0}
            size="sm"
            onClick={() => {
              setPlaceMusicians(true);
            }}
          >
            Place Musicians{" "}
            {musiciansNotLocated?.length ? musiciansNotLocated[0].id : ""}
          </Button>
        ) : (
          []
        )}
        <Button
          endDecorator={<i className="fa-solid fa-arrow-down-to-line"></i>}
          variant="solid"
          size="sm"
          onClick={() => exporter.musicianSelection()}
        >
          Export
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          flex: 1,
          display: "flex",
        }}
      >
        <Sheet
          sx={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            height: "calc(calc(var(--vh, 1vh) * 100) - 134px)",
            overflow: "scroll",
          }}
          ref={musicianListRef}
        >
          {musicianListRef?.current ? (
            <FixedSizeList
              height={musicianListRef?.current.clientHeight}
              width={250}
              itemSize={50}
              itemCount={musicians.length}
              overscanCount={5}
            >
              {renderRow}
            </FixedSizeList>
          ) : (
            []
          )}
        </Sheet>
        <Divider orientation="vertical" />
        {mapElement}
      </Box>
    </Box>
  );
}
