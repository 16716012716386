import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Sheet,
  Typography,
} from "@mui/joy";
import { Grid } from "@mui/material";
import { AxisConfig, BarChart, BarSeriesType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { Stage } from "entities/stage";
import { WorkSession } from "entities/workSession";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { Tardy } from "hooks/AttendanceAssignments/AttendanceAssignments";
import { iconForPlan } from "hooks/subscription/constants";
import { finale, useMySubscription } from "hooks/subscription/restrictionHooks";
import { Duration } from "luxon";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFormOpen, setSelectedMusicianID } from "reducers/rhapsody";
import { useAssignmentsFilter } from "redux/assignment/assignmentHooks";
import { useCurrentCompany } from "redux/company/companyHooks";
import { useMusicianMercuryJobs } from "redux/mercuryJob/mercuryJobHooks";
import { useProjectsFilter } from "redux/project/projectHooks";
import { useProjectTags } from "redux/projectTag/projectTagHooks";
import { useChairsFilter } from "redux/rhapsodyChair/rhapsodyChairHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import { useStages } from "redux/stage/stageHooks";
import { useTags } from "redux/tag/tagHooks";
import { useWorkSessionsFilter } from "redux/workSession/workSessionHooks";

/**
 *
 * @returns {ReactElement} MusicianReport page
 */
export function MusicianReport({ musicianID }: { musicianID: number }) {
  const {
    projects,
    workSessions,
    positionChart,
    roleChart,
    acceptanceChart,
    hoursWorked,
    nudgeCount,
    rows,
    columns,
    seasonCount,
    responseTime,
    tardies,
  } = useMusicianReport(musicianID);

  const subscription = useMySubscription();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const disableSx =
    subscription !== finale
      ? {
          filter: "blur(10px)",
          userSelect: "none",
          pointerEvent: "none",
        }
      : {};

  const { company } = useCurrentCompany();

  const notEnoughData = (
    <Sheet
      sx={{
        height: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
      }}
      variant="soft"
    >
      <Typography
        startDecorator={<i className="fa-duotone fa-chart-simple"></i>}
        level="body2"
      >
        Not enough data
      </Typography>
    </Sheet>
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        height: "calc(100vh - 203px)",
        overflow: "scroll",
        position: "relative",
      }}
    >
      <Typography>
        This feature is currently in Beta and only supports Maestro Projects.
      </Typography>
      {subscription !== finale ? (
        <Alert
          color="warning"
          startDecorator={iconForPlan[finale]}
          endDecorator={
            <Button
              onClick={() =>
                dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }))
              }
            >
              Subscribe
            </Button>
          }
        >
          The Finale plan is required to access Musician's Insights.
        </Alert>
      ) : (
        []
      )}
      <Grid container spacing={2}>
        <Grid item sx={{ flex: 1 }}>
          <Card variant="solid" color="primary" invertedColors>
            <CardContent orientation="horizontal">
              <Typography level="h3">
                <i className="fa-solid fa-folder-open"></i>
              </Typography>
              <CardContent>
                <Typography level="body1">Projects</Typography>
                <Typography
                  level="h5"
                  sx={{ alignItems: "baseline", ...disableSx }}
                  endDecorator={
                    seasonCount ? (
                      <Typography level="body3">
                        ({seasonCount} Season{seasonCount !== 1 ? "s" : ""})
                      </Typography>
                    ) : (
                      []
                    )
                  }
                >
                  {projects?.length}
                </Typography>
              </CardContent>
            </CardContent>
            {/* <CardActions>
              <Button variant="soft" size="sm">
                See breakdown
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <Card variant="solid" color="primary" invertedColors>
            <CardContent orientation="horizontal">
              <Typography level="h3">
                <i className="fa-solid fa-calendar-day"></i>
              </Typography>
              <CardContent>
                <Typography level="body1">Work Sessions</Typography>
                <Typography level="h5" sx={disableSx}>
                  {workSessions?.length}
                </Typography>
              </CardContent>
            </CardContent>
            {/* <CardActions>
              <Button variant="soft" size="sm">
                See breakdown
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <Card variant="solid" color="primary" invertedColors>
            <CardContent orientation="horizontal">
              <Typography level="h3">
                <i className="fa-solid fa-clock"></i>
              </Typography>
              <CardContent>
                <Typography level="body1">Hours Worked</Typography>
                <Typography level="h5" sx={disableSx}>
                  {secondsToHumanReadable(hoursWorked)}
                </Typography>
              </CardContent>
            </CardContent>
            {/* <CardActions>
              <Button variant="soft" size="sm">
                See breakdown
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <Card variant="solid" color="primary" invertedColors>
            <CardContent orientation="horizontal">
              <Typography level="h3">
                <i className="fa-solid fa-hourglass-end"></i>
              </Typography>
              <CardContent>
                <Typography level="body1">Avg Response Time</Typography>
                <Typography
                  level="h5"
                  sx={{ ...disableSx, alignItems: "center" }}
                >
                  {secondsToHumanReadable(responseTime, true)}
                </Typography>
              </CardContent>
            </CardContent>
            {/* <CardActions>
              <Button variant="soft" size="sm">
                See breakdown
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <Card variant="solid" color="primary" invertedColors>
            <CardContent orientation="horizontal">
              <Typography level="h3">
                <i className="fa-solid fa-bullhorn"></i>
              </Typography>
              <CardContent>
                <Typography level="body1">Nudge Count</Typography>
                <Typography level="h5" sx={disableSx}>
                  {nudgeCount} time{nudgeCount !== 1 ? "s" : ""}
                </Typography>
              </CardContent>
            </CardContent>
            {/* <CardActions>
              <Button variant="soft" size="sm">
                See breakdown
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card>
            <Box>
              <Typography level="h6">Section Orders</Typography>
              <Typography level="body2">
                How many times the musician plays for a given order.
              </Typography>
            </Box>
            {positionChart.series.length &&
            positionChart.series[0].data.length ? (
              <BarChart
                sx={disableSx}
                series={positionChart.series}
                xAxis={positionChart.xAxis}
                height={200}
                leftAxis={null}
                axisHighlight={
                  subscription !== finale ? { x: "none", y: "none" } : undefined
                }
              />
            ) : (
              notEnoughData
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <Box>
              <Typography level="h6">Section Roles</Typography>
              <Typography level="body2">
                How many times the musician plays for a given role.
              </Typography>
            </Box>
            {roleChart.series.length && roleChart.series[0].data.length ? (
              <BarChart
                sx={disableSx}
                series={roleChart.series}
                xAxis={roleChart.xAxis}
                height={200}
                leftAxis={null}
                axisHighlight={
                  subscription !== finale ? { x: "none", y: "none" } : undefined
                }
              />
            ) : (
              notEnoughData
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <Box>
              <Typography level="h6">Statuses</Typography>
              <Typography level="body2">
                Musician's statuses for their Assignments.
              </Typography>
            </Box>
            {acceptanceChart.series.length &&
            acceptanceChart.series[0].data.length ? (
              <BarChart
                sx={disableSx}
                series={acceptanceChart.series}
                xAxis={acceptanceChart.xAxis}
                height={200}
                leftAxis={null}
                axisHighlight={
                  subscription !== finale ? { x: "none", y: "none" } : undefined
                }
              />
            ) : (
              notEnoughData
            )}
          </Card>
        </Grid>
      </Grid>
      <Card>
        <Typography level="h6">Season's Tracker</Typography>
        <DataGridPremium
          sx={disableSx}
          autoHeight
          rows={rows}
          columns={columns}
        />
      </Card>
      <Card>
        <Typography level="h6">Attendance</Typography>
        <DataGridPremium
          hideFooter
          autoHeight
          onRowClick={(e) => {
            dispatch(setSelectedMusicianID());
            navigate(
              `${RouterConfig.projects}/${e.row.projectID}/mission-control`
            );
          }}
          rows={tardies ?? []}
          columns={[
            {
              field: "projectID",
              headerName: "Work Session",
              width: 300,
              renderCell: (r) => {
                const project = projects?.find((w) => w.id == r.value);
                return (
                  <Box>
                    <Typography>{project?.name}</Typography>
                  </Box>
                );
              },
            },
            {
              field: "workSessionID",
              headerName: "Work Session",
              width: 300,
              renderCell: (r) => {
                const workSession = workSessions?.find((w) => w.id == r.value);
                return (
                  <Box>
                    <Typography>{workSession?.title}</Typography>
                    <Typography level="body2">
                      {workSession?.formatDateRange()}
                    </Typography>
                  </Box>
                );
              },
            },
            {
              field: "type",
              headerName: "Type",
              renderCell: (c) => {
                return (
                  <Typography
                    startDecorator={
                      c.row.type === "Late" ? (
                        <i className="fa-regular fa-alarm-clock"></i>
                      ) : (
                        <i className="fa-regular fa-user-xmark"></i>
                      )
                    }
                    sx={{ textTransform: "capitalize" }}
                    level="body2"
                  >
                    {`${c.value}`.replace("-", " ")}
                  </Typography>
                );
              },
            },
            { field: "comments", flex: 1, headerName: "Comments" },
          ]}
        />
      </Card>
      <Box
        sx={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {company?.defaultProjectVersion === 1 ? (
          <Typography
            level="body2"
            startDecorator={<i className="fa-solid fa-chart-mixed"></i>}
          >
            Data are based on Maestro Projects only.
          </Typography>
        ) : (
          []
        )}
      </Box>
    </Box>
  );
}

function useMusicianReport(musicianID: number) {
  const { mercuryJobs } = useMusicianMercuryJobs(musicianID);
  const { sectionRolesMap } = useSectionRoles();
  const { tags, tagsMap } = useTags();
  const { projectTags } = useProjectTags();
  const { stagesMap } = useStages();
  const { assignments } = useAssignmentsFilter({
    filters: JSON.stringify([
      {
        name: "assignments.musicianID",
        comparison: "eq",
        value: musicianID,
      },
    ]),
  });
  const subscription = useMySubscription();

  const assignmentsIDs =
    assignments?.reduce((a, v) => {
      if (!a.includes(v.id)) a.push(v.id);
      return a;
    }, []) ?? [];

  const { chairs } = useChairsFilter(
    {
      filters: JSON.stringify([
        {
          name: "chairs.assignmentID",
          comparison: "in",
          value: assignmentsIDs,
        },
      ]),
    },
    { skip: assignmentsIDs.length === 0 }
  );

  const projectIDs =
    assignments?.reduce((a, v) => {
      if (!a.includes(v.id)) a.push(v.projectID);
      return a;
    }, []) ?? [];

  const { projects } = useProjectsFilter(
    {
      filters: JSON.stringify([
        {
          name: "id",
          comparison: "in",
          value: projectIDs,
        },
        {
          name: "version",
          comparison: "eq",
          value: 2,
        },
      ]),
    },
    { skip: projectIDs.length === 0 }
  );

  const workSessionsIDs =
    chairs?.reduce((a, v) => {
      v.workSessionIDs?.forEach((w) => {
        if (!a.includes(w)) a.push(w);
      });
      return a;
    }, []) ?? [];

  const { workSessions } = useWorkSessionsFilter(
    {
      filters: JSON.stringify([
        {
          name: "studio_sessions.id",
          comparison: "in",
          value: workSessionsIDs,
        },
      ]),
    },
    { skip: workSessionsIDs.length === 0 }
  );

  // Roles
  const getRole = () => {
    const roleData = chairs?.reduce((a, v) => {
      const role = sectionRolesMap[v.sectionRoleID];
      if (role) {
        if (a[role.name]) {
          a[role.name]++;
        } else {
          a[role.name] = 1;
        }
      }
      return a;
    }, {});

    const roleXAxisData = Object.keys(roleData);
    const roleChart: {
      xAxis: MakeOptional<AxisConfig, "id">[];
      series: MakeOptional<BarSeriesType, "type">[];
    } = {
      series: [
        {
          data: Object.values(roleData),
          color: "#448aff",
        },
      ],
      xAxis: [
        {
          scaleType: "band",
          data: roleXAxisData,
          hideTooltip: subscription !== finale,
        },
      ],
    };
    return roleChart;
  };

  // Position
  const getPosition = () => {
    const maxOrder = chairs?.reduce((a, v) => {
      const order = v.sectionOrder;
      if (order > a) a = order;
      return a;
    }, 0);

    const positionData = chairs?.reduce((a, v) => {
      const order = v.sectionOrder;
      a[order - 1]++;
      return a;
    }, new Array(maxOrder).fill(0));

    const positionXAxisData: number[] = [];
    positionData.forEach((d, i) => {
      positionXAxisData.push(i + 1);
    });

    const positionChart: {
      xAxis: MakeOptional<AxisConfig, "id">[];
      series: MakeOptional<BarSeriesType, "type">[];
    } = {
      series: [
        {
          data: positionData,
          color: "#448aff",
        },
      ],
      xAxis: [
        {
          scaleType: "band",
          data: positionXAxisData,
          hideTooltip: subscription !== finale,
        },
      ],
    };

    return positionChart;
  };

  // Acceptance
  const getAcceptance = () => {
    const acceptanceData = assignments?.reduce((a, v) => {
      const stage = stagesMap[v.mercuryStageID];
      if (stage) {
        if (a[stage.wording()]) {
          a[stage.wording()]++;
        } else {
          a[stage.wording()] = 1;
        }
      }
      return a;
    }, {});

    const acceptanceXAxisData = Object.keys(acceptanceData);
    const acceptanceChart: {
      xAxis: MakeOptional<AxisConfig, "id">[];
      series: MakeOptional<BarSeriesType, "type">[];
    } = {
      series: [
        {
          data: Object.values(acceptanceData),
          color: "#448aff",
        },
      ],
      xAxis: [
        {
          scaleType: "band",
          data: acceptanceXAxisData,
          hideTooltip: subscription !== finale,
        },
      ],
    };

    return acceptanceChart;
  };

  const playedWorkSessions = workSessions.reduce<WorkSession[]>((a, v) => {
    const _chairs = chairs.filter((e) => e.workSessionIDs.includes(v.id));
    console.log(_chairs);
    const _assignments = assignments.filter((b) =>
      _chairs.find((z) => z.assignmentID === b.id)
    );

    _assignments.forEach((b) => {
      if (b.active) {
        a.push(v);
      }
    });
    return a;
  }, []);

  const getHoursWorked = () => {
    const hoursWorked = playedWorkSessions.reduce((a, v) => {
      const duration = moment(v.dateToUTC).diff(v.dateFromUTC, "second");
      a += duration;
      return a;
    }, 0);

    return hoursWorked;
  };

  const getNudgeCount = () => {
    return mercuryJobs.reduce((a, v) => {
      a += v.currentStage?.nudgeCount;
      return a;
    }, 0);
  };

  const columns: GridColDef<any>[] = [
    {
      field: "seasonName",
      headerName: "Season",
      width: 250,
      renderCell: (p) => (
        <Typography
          level="body2"
          startDecorator={
            tagsMap[p.row.id].image ? (
              <img
                style={{ borderRadius: 4 }}
                src={tagsMap[p.row.id].image}
                height={25}
              />
            ) : undefined
          }
        >
          {p.value}
        </Typography>
      ),
    },
    {
      field: "projectCount",
      headerName: "Projects",
      flex: 1,
      renderCell: (p) => (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            width: "100%",
            gap: 1,
            alignItems: "center",
          }}
        >
          <LinearProgress
            thickness={15}
            sx={{ maxWidth: 150 }}
            value={
              p.row.projectCount
                ? (p.row.relatedAssignments.length / p.row.projectCount) * 100
                : 0
            }
            determinate
          />
          <Box>
            <Typography color="primary" level="body2" sx={{ fontWeight: 600 }}>
              {p.row.relatedAssignments.length}/{p.row.projectCount} Active
            </Typography>
            {p.row.projectCount ? (
              <Typography level="body4">
                {Math.round(
                  (p.row.relatedAssignments.length / p.row.projectCount) * 100
                )}
                % Active
              </Typography>
            ) : (
              []
            )}
          </Box>
        </Box>
      ),
    },
    {
      field: "workSessionCount",
      headerName: "Work Sessions",
      flex: 1,
      renderCell: (p) => (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            width: "100%",
            gap: 1,
            alignItems: "center",
          }}
        >
          <LinearProgress
            thickness={15}
            sx={{ maxWidth: 150 }}
            value={
              p.row.workSessionCount
                ? (p.row.playedWorkSessions.length / p.row.workSessionCount) *
                  100
                : 0
            }
            determinate
          />
          <Box>
            <Typography color="primary" level="body2" sx={{ fontWeight: 600 }}>
              {p.row.playedWorkSessions.length}/{p.row.workSessionCount} Active
            </Typography>
            {p.row.workSessionCount ? (
              <Typography level="body4">
                {Math.round(
                  (p.row.playedWorkSessions.length / p.row.workSessionCount) *
                    100
                )}
                % Active
              </Typography>
            ) : (
              []
            )}
          </Box>
        </Box>
      ),
    },
  ];

  const rows = tags.reduce((a, v) => {
    const relatedProjects = projects.filter((p) =>
      projectTags.find((pt) => pt.projectID === p.id && pt.tagID === v.id)
    );
    const releatedWorkSessions = workSessions.filter((w) =>
      relatedProjects.find((p) => p.id === w.projectID)
    );
    const relatedAssignments = assignments.filter((a) =>
      relatedProjects.find((p) => p.id === a.projectID && a.active)
    );

    a.push({
      id: v.id,
      seasonName: v.name,
      projectCount: relatedProjects?.length,
      projects: relatedProjects?.length,
      relatedAssignments,
      playedWorkSessions: playedWorkSessions.filter((w) =>
        relatedProjects.find((p) => p.id === w.projectID)
      ),
      workSessionCount: releatedWorkSessions?.length,
      workSessions: releatedWorkSessions,
    });
    return a;
  }, []);

  const getSeasonCount = () => {
    return tags.reduce((a, v) => {
      const relatedProjects = projects.filter((p) =>
        projectTags.find((pt) => pt.projectID === p.id && pt.tagID === v.id)
      );
      if (relatedProjects.length) a++;
      return a;
    }, 0);
  };
  const getResponseTime = () => {
    let count = 0;
    const totalDuration = mercuryJobs.reduce((a, v) => {
      let invite: Stage;
      v?.stages?.forEach((s) => {
        if (s.id === 1) {
          invite = s;
        } else if (invite) {
          const duration = moment(s.createdAt).diff(moment(invite.createdAt));
          // if (duration <= 4320000 * 2)
          // we ignore answers after 5 days
          a += duration;
          count++;
        }
      });
      return a;
    }, 0);

    if (count) return totalDuration / count;
    return 0;
  };

  const tardies = assignments.reduce<Tardy[]>((a, v) => {
    const assignmentTardies: Tardy[] = JSON.parse(
      v.tardiness ? v.tardiness : "[]"
    );
    assignmentTardies.forEach((e) => a.push({ ...e, projectID: v.projectID }));
    return a;
  }, []);

  return {
    tardies,
    chairs,
    assignments,
    projects,
    workSessions,
    positionChart: getPosition(),
    roleChart: getRole(),
    acceptanceChart: getAcceptance(),
    hoursWorked: getHoursWorked(),
    nudgeCount: getNudgeCount(),
    seasonCount: getSeasonCount(),
    responseTime: getResponseTime(),
    mercuryJobs,
    rows,
    columns,
  };
}

export function secondsToHumanReadable(seconds: number, showDays?: boolean) {
  // Convert the seconds into a Luxon Duration
  const duration = Duration.fromObject({ seconds });
  const hours = Math.floor(duration.as("hour"));
  const minutes = Math.floor(duration.as("minutes") % 60);
  const days = Math.floor(duration.as("day") % 24);

  if (days && showDays) return `${days}d`;

  if (!hours && !minutes) return "0h";
  return `${hours ? `${hours}h` : ""} ${minutes ? `${minutes}m` : ""}`;
}
