import MusicianGroup_Entity from "./musicianGroup";
export default class MusicianGroup extends MusicianGroup_Entity {
  static fromList(musicianGroupsJSON: unknown): Array<MusicianGroup> {
    const musicianGroups: MusicianGroup[] = [];
    if (musicianGroupsJSON)
      Array.isArray(musicianGroupsJSON) &&
        musicianGroupsJSON.forEach((musicianGroupJSON) => {
          musicianGroups.push(new MusicianGroup(musicianGroupJSON));
        });
    return musicianGroups;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
