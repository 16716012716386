import { Divider, Tooltip } from "@mui/joy";
import { Box, ClickAwayListener } from "@mui/material";
import { ReactElement, useState } from "react";
import { InstrumentPicker } from "./InstrumentPicker";

/**
 *
 * @returns {ReactElement} InstrumentsPopover page
 */
export function InstrumentsTooltip({
  onSelect,
  children,
  sectionID,
  familyID,
  hideTitle,
  height,
  hideToolbar,
  instrumentIDs,
  maxHeight,
  search,
  clearInstruments = false,
}: {
  onSelect: (i: number[] | null) => void;
  instrumentIDs?: number[];
  children: ReactElement;
  sectionID?: number;
  familyID?: number;
  hideTitle?: boolean;
  hideToolbar?: boolean;
  height?: number;
  search?: boolean;
  maxHeight?: number;
  clearInstruments?: boolean;
}) {
  const [open, setOpen] = useState(false);

  const handleOnSelect = (i) => {
    onSelect(i);
    setOpen(false);
  };

  return (
    <Tooltip
      arrow
      open={open}
      color="neutral"
      variant="outlined"
      title={
        <ClickAwayListener
          onClickAway={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(false);
          }}
        >
          <Box
            sx={{
              width: 400,
              maxHeight: maxHeight ?? 400,
              overflowY: "auto",
              height,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InstrumentPicker
              instrumentIDs={instrumentIDs}
              hideToolbar={hideToolbar}
              hideTitle={hideTitle}
              sectionID={sectionID}
              familyID={familyID}
              onSelect={handleOnSelect}
              clearInstruments={clearInstruments}
            />
          </Box>
        </ClickAwayListener>
      }
    >
      <Box onClick={() => setOpen(true)}>{children}</Box>
    </Tooltip>
  );
}
