import { Box, Button } from "@mui/joy";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { setSelectedCalendarDay } from "actions";
import moment, { utc } from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedMusicianIDSelector,
  setFormOpen,
  setSelectedCustomerID,
  setSelectedMusicianHolidayID,
} from "reducers/rhapsody";
import { useMusicianHolidays } from "redux/musicianHoliday/musicianHolidayHooks";

/**
 *
 * @returns {ReactElement} MusicianTimeOff page
 */
export function MusicianTimeOff() {
  const dispatch = useDispatch();
  const musicianID = useSelector(selectedMusicianIDSelector);
  const { musicianHolidays } = useMusicianHolidays(
    {
      filters: JSON.stringify([
        {
          name: "musician_holidays.musicianID",
          value: musicianID,
          comparison: "eq",
        },
      ]),
    },
    { skip: !musicianID }
  );
  return (
    <Box sx={{ display: "flex", gap: 1, flex: 1, flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          disabled={!musicianID}
          size="sm"
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "musicianHoliday" }))
          }
        >
          + New Time Off
        </Button>
      </Box>
      <DataGridPremium
        hideFooter
        onCellClick={(r) => {
          dispatch(setSelectedMusicianHolidayID(r.row.id));
          dispatch(setFormOpen({ isOpen: true, formID: "musicianHoliday" }));
        }}
        columns={[
          {
            field: "dateFromUTC",
            headerName: "From",
            width: 150,
            valueFormatter: (r) => moment(r.value).utc().format("ll"),
          },
          {
            field: "dateToUTC",
            headerName: "To",
            width: 150,
            valueFormatter: (r) => moment(r.value).utc().format("ll"),
          },
          { field: "kind", headerName: "Type", width: 150 },
          { field: "comments", headerName: "Comments", flex: 1 },
        ]}
        rows={musicianHolidays ?? []}
      />
    </Box>
  );
}
