import JoyTypography from "@mui/joy/Typography";
import { Box, darken } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogClose from "atoms/DialogClose/DialogClose";
// material-ui
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  formOpenSelector,
  setMissionControlMode,
  setMissionControlViewMode,
} from "reducers/rhapsody";

// styles
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Input,
  Option,
  Select,
  Sheet,
  Tooltip,
} from "@mui/joy";
import { ConfettiCenterContext } from "features/context/ConfettiCenter/ConfettiCenter";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { setFormOpen } from "reducers/rhapsody";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";
import { useCreateProjectMutation } from "redux/project/projectEndpoints";
import { useCreateProjectTagMutation } from "redux/projectTag/projectTagEndpoints";
import { useTags } from "redux/tag/tagHooks";
import { useLazyGetSeasonUpcomingStagesQuery } from "redux/projectMercury/projectMercuryEndpoints";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import Upload from "atoms/FileUpload";
import { Project } from "entities/project";
import { useCreateProjectRosterMutation } from "redux/projectRoster/projectRosterEndpoints";

export default function FormProject({ template = false }) {
  const { tags } = useTags();
  const formID = template ? "template" : "project";
  const formOpen = useSelector(formOpenSelector(formID));
  const params = useParams();
  const [createProject] = useCreateProjectMutation();
  const [getSeasonMercury] = useLazyGetSeasonUpcomingStagesQuery();
  const { company } = useCurrentCompany();
  const settings = useCompanySettings();
  const askQuestion = useAskQuestion();
  const [project, setProject] = useState<Partial<Project>>({
    name: "",
    customerID: 0,
    composer: "",
    agreement: "",
    contractor: "",
    template,
    tagID: params?.tagID ? parseInt(params.tagID) : 0,
    version: 1,
    hideWhenNotPlaying: settings?.hideWhenNotPlaying,
  });
  const [createProjectTag] = useCreateProjectTagMutation();
  const dispatch = useDispatch();
  const ConfettiCenter = useContext(ConfettiCenterContext);
  const [createProjectRoster] = useCreateProjectRosterMutation();
  const navigate = useNavigate();

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    setProject((s) => ({ ...s, [name]: value }));
  };

  useEffect(() => {
    if (company) {
      setProject((p) => ({
        ...p,
        version: company.defaultProjectVersion ?? 1,
      }));
    }
  }, [company]);

  const save = async () => {
    createProject(project)
      .unwrap()
      .then((p) => {
        ConfettiCenter.show();
        if (project.tagID) {
          createProjectTag({
            projectID: p?.id,
            tagID: project.tagID,
          });
        }
        dispatch(setFormOpen({ isOpen: false, formID: formID }));
        if (settings?.rosterPresets?.length > 0 && !project.template)
          createProjectRosters(p.id);
        let to = "seating";
        if (project.version === 2) to = "mission-control";
        dispatch(setMissionControlMode("edit"));
        dispatch(setMissionControlViewMode("grouped"));
        navigate(
          `${template ? RouterConfig.templates : RouterConfig.projects}/${
            p?.id
          }/${to}`
        );
      });
  };

  const createProjectRosters = (projectID: number) => {
    settings?.rosterPresets.forEach((e) => {
      if (e.autoCreate) {
        createProjectRoster({
          projectID,
          options: JSON.stringify({ ...e.options, active: true }),
          name: e.name,
        });
      }
    });
  };

  const disabled = project.name.length === 0;
  return (
    <>
      <DialogClose
        open={formOpen}
        scroll="paper"
        onClose={() =>
          dispatch(
            setFormOpen({
              isOpen: false,
              formID: template ? "template" : "project",
            })
          )
        }
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <div
            style={{
              background: "#e3f2fd",
              padding: 16,
              borderRadius: "16px",
              textAlign: "center",
            }}
          >
            <i
              style={{ fontSize: 80, color: "#001E3C" }}
              className={
                template ? "fa-duotone fa-square-dashed" : "fad fa-folder-open"
              }
            ></i>
            <Typography variant="h6" style={{ color: "#001E3C" }}>
              {template ? "New Template" : "New Project"}
            </Typography>
            <Typography
              style={{ color: "#001E3C", opacity: 0.8 }}
              variant="body2"
            >
              {template
                ? "A template is a call order you can use to autofill new projects"
                : "Create a new project to start the contracting process."}
            </Typography>
          </div>
          <Grid container spacing={2} style={{ marginTop: 8, maxWidth: 500 }}>
            <Grid item>
              <Box sx={{ display: "flex", gap: 1 }}>
                {project.logoURL ? (
                  <img
                    src={project.logoURL}
                    style={{ width: 95, height: 95, borderRadius: "8px" }}
                  />
                ) : (
                  <Upload
                    onUpload={(f) => setProject((t) => ({ ...t, logoURL: f }))}
                    cropDimension={{ width: 250, height: 250 }}
                  >
                    <Box
                      sx={{
                        width: 95,
                        height: 95,
                        flexShrink: 0,
                        border: "dashed 1.5px #9e9e9e",
                        borderRadius: "8px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <JoyTypography level="h4" sx={{ color: "#9e9e9e" }}>
                        <i className="fa-duotone fa-image"></i>
                      </JoyTypography>
                    </Box>
                  </Upload>
                )}
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <JoyTypography
                    level="body2"
                    sx={{ color: "black", fontWeight: 600 }}
                  >
                    Project Cover
                  </JoyTypography>
                  <JoyTypography level="body3">
                    An artwork respresenting your Project. It must be at least
                    250px by 250px with a max size of 512KB. The supported MIME
                    types are JPG, PNG, and GIf.
                  </JoyTypography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Upload
                      cropDimension={{ width: 250, height: 250 }}
                      onUpload={(f) =>
                        setProject((t) => ({ ...t, logoURL: f }))
                      }
                    />
                    {project.logoURL ? (
                      <Chip
                        onClick={() =>
                          setProject((t) => ({ ...t, logoURL: "" }))
                        }
                        size="sm"
                        variant="outlined"
                        color="danger"
                      >
                        Remove Cover
                      </Chip>
                    ) : (
                      []
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Input
                id="name"
                type="text"
                autoFocus
                endDecorator="Required"
                autoComplete="off"
                startDecorator={template ? "Template Name:" : "Project Name:"}
                value={project.name}
                onChange={handleChange("name")}
              />
            </Grid>
            {tags?.length && !template ? (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", gap: 1, alignItems: "center" }}
              >
                <Select
                  sx={{ flexGrow: 1 }}
                  slotProps={{
                    listbox: { sx: { zIndex: 9999 } },
                  }}
                  endDecorator="Optional"
                  startDecorator="Season:"
                  value={project.tagID ?? null}
                  onChange={(e, v) => {
                    if (v && v != project.tagID) {
                      getSeasonMercury({ id: v, body: {} })
                        .unwrap()
                        .then((r) => {
                          const callSent = r.jobs.reduce((a, v) => {
                            if (v.currentStage && !v.currentStage?.terminus)
                              a = true;
                            return a;
                          }, false);
                          if (callSent) {
                            askQuestion("Error", ["OK"], {
                              subtitle: (
                                <Typography>
                                  You already have sent season invites. The
                                  project can't be added to the season at this
                                  point. Contact support for more help.
                                </Typography>
                              ),
                            });
                          } else {
                            setProject((s) => ({
                              ...s,
                              tagID: v as number,
                            }));
                          }
                        });
                    }
                  }}
                >
                  {tags.map((t) => (
                    <Option key={t.id} value={t.id}>
                      <JoyTypography
                        startDecorator={
                          <img
                            src={t.image}
                            style={{
                              height: 40,
                              marginRight: 8,
                            }}
                          />
                        }
                      >
                        {t.name}
                      </JoyTypography>
                    </Option>
                  ))}
                </Select>
                {project.tagID ? (
                  <IconButton
                    color="neutral"
                    variant="plain"
                    onClick={() => {
                      setProject((s) => ({
                        ...s,
                        tagID: undefined,
                      }));
                    }}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </IconButton>
                ) : (
                  []
                )}
              </Grid>
            ) : (
              []
            )}

            {company?.defaultProjectVersion === 1 ? (
              <Grid item xs={12}>
                <Alert
                  onClick={() => {
                    setProject((s) => ({
                      ...s,
                      version: project.version === 2 ? 1 : 2,
                    }));
                  }}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      background: darken("#F2EAFD", 0.02),
                    },
                  }}
                  color="info"
                  endDecorator={
                    <Checkbox
                      checked={project.version === 2}
                      size="lg"
                      color="info"
                    />
                  }
                >
                  <Box>
                    <JoyTypography sx={{ fontWeight: 600 }}>
                      Try <JoyTypography>Maestro</JoyTypography>
                    </JoyTypography>
                    <JoyTypography
                      endDecorator={
                        <JoyTypography
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            window.open(
                              "https://helpdesk.rhapsody.la/whats-new/",
                              "_blank"
                            );
                          }}
                          sx={{
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          level="body3"
                        >
                          Learn more
                        </JoyTypography>
                      }
                      level="body2"
                      sx={{ color: "#1F1E21" }}
                    >
                      Next generation of {template ? "Template" : "Projects"}{" "}
                      with 50+ new features.
                    </JoyTypography>
                  </Box>
                </Alert>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="soft"
            color="neutral"
            onClick={() => dispatch(setFormOpen({ isOpen: false, formID }))}
          >
            Cancel
          </Button>
          <Button
            style={{
              background: "#2196f3",
              color: "white",
              opacity: disabled ? 0.5 : 1,
            }}
            onClick={save}
            disabled={disabled}
            endDecorator={<i className="far fa-chevron-right p-left"></i>}
          >
            Next
          </Button>
        </DialogActions>
      </DialogClose>
    </>
  );
}
