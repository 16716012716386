import { useMemo } from "react";
import {
  useGetFileImportQuery,
  useGetFileImportsQuery,
} from "./fileImportEndpoints";
import { FileImport } from "entities/fileImport";

export function useFileImport(fileImportID: number | undefined) {
  const fileImportQuery = useGetFileImportQuery(fileImportID ?? 0, {
    skip: (fileImportID ?? 0) <= 0,
  });
  const fileImportEntity = fileImportQuery.data;

  return useMemo(() => {
    const ret: typeof fileImportQuery & {
      fileImport?: FileImport | undefined;
    } = {
      ...fileImportQuery,
    };
    if (fileImportEntity) ret.fileImport = new FileImport(fileImportEntity);
    return ret;
  }, [fileImportEntity]);
}

export function useFileImports() {
  const fileImportQuery = useGetFileImportsQuery();
  const fileImportEntitiesMap = fileImportQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof fileImportQuery & {
      fileImports?: FileImport[];
      fileImportsMap?: { [id: number]: FileImport };
    } = {
      ...fileImportQuery,
      fileImports: [],
      fileImportsMap: {},
    };
    if (fileImportEntitiesMap) {
      const fileImports: FileImport[] = [];
      const fileImportsMap: { [id: number]: FileImport } = {};

      for (const key in fileImportEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(fileImportEntitiesMap, key)) {
          const c = fileImportEntitiesMap[key];
          const fileImport = new FileImport(c);
          fileImports.push(fileImport);
          fileImportsMap[fileImport.id] = fileImport;
        }
      }
      ret.fileImports = fileImports;
      ret.fileImportsMap = fileImportsMap;
    }

    return ret;
  }, [fileImportEntitiesMap]);
}
