import { useState } from "react";
// import PropTypes from 'prop-types';

// styles
import { Button, Sheet, Typography } from "@mui/joy";
import { Hidden, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import ProjectsDataGrid from "features/dataGrids/ProjectsDataGrid";
import { updateURLParameter } from "helpers";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import TagCard from "hooks/TagCard";
import { Helmet } from "react-helmet";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { setFormOpen, setTabIndex, tabIndexSelector } from "reducers/rhapsody";
import { useTags } from "redux/tag/tagHooks";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { meSelector } from "selectors/me";
import { heightWithToolbar } from "global";

export default function ProjectsPage({ template }) {
  const param = useParams();
  const tabIndex = useSelector(tabIndexSelector("projects"));
  const readOnly = useReadOnly();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const me = useSelector(meSelector);
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;

  const [tagFocused, setTagFocused] = useState({ id: -1 });

  const { tags } = useTags();

  if (param.projectID) return <Outlet />;

  const getSeasonCarousel = () => (
    <Sheet
      variant="soft"
      color="neutral"
      sx={{ display: "flex", height: 180, borderRadius: "8px", mt: 2 }}
    >
      <ScrollContainer
        style={{
          flex: 1,
          width: 0,
          padding: 16,
          height: 180,
          scrollBehavior: "smooth",
        }}
        hideScrollbars={false}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: 148,
          }}
        >
          <div
            onClick={() => {
              setTagFocused({ id: -1 });
            }}
          >
            <TagCard
              tagID={tagFocused?.id}
              selected={tagFocused?.id === -1}
              name={"Active"}
              icon={"fa-duotone fa-folder-open"}
            />
          </div>
          {tags
            ?.sort((a, b) => b.id - a.id)
            .map((t) => (
              <div
                key={t.id}
                onClick={() => {
                  setTagFocused(t);
                }}
                onDoubleClick={() => {
                  navigate(`${RouterConfig.seasons}/${t.id}/projects`);
                }}
              >
                <TagCard
                  tagID={t?.id}
                  name={`${t.name}`}
                  selected={t.id === tagFocused?.id}
                  editable
                  image={t.image}
                />
              </div>
            ))}
          <div
            onClick={() => {
              setTagFocused({ id: -2 });
            }}
          >
            <TagCard
              tagID={tagFocused?.id}
              icon="fa-duotone fa-box-archive"
              selected={tagFocused?.id === -2}
              name={"Archived"}
            />
          </div>
        </div>
      </ScrollContainer>
    </Sheet>
  );
  return (
    <>
      <Helmet>
        <title>Rhapsody | {template ? "Templates" : "Projects"}</title>
      </Helmet>
      <Box
        sx={{
          p: 2,
          display: "flex",
          gap: 2,
          flexDirection: "column",
          justifyContent: "stretch",
          margin: "auto",
          maxWidth: 1600,
          width: "100%",
          position: "relative",
          flex: 1,
          height: heightWithToolbar,
        }}
      >
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography level="h5">
              {template ? "Template" : "Projects"}
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              {!template ? (
                <Button
                  onClick={() =>
                    dispatch(setFormOpen({ isOpen: true, formID: "tag" }))
                  }
                  disabled={readOnly}
                  size="sm"
                  variant="soft"
                  color="neutral"
                  startDecorator={<i className="fa-solid fa-plus"></i>}
                >
                  <Hidden smDown>Add</Hidden> Season
                </Button>
              ) : (
                []
              )}
              <Button
                disabled={readOnly}
                color="neutral"
                variant="soft"
                onClick={() =>
                  dispatch(
                    setFormOpen({
                      isOpen: true,
                      formID: "projectImport",
                    })
                  )
                }
                size="sm"
                startDecorator={<i className="fa-solid fa-cloud-arrow-up"></i>}
              >
                <Hidden smDown>Import</Hidden>{" "}
                {template ? "Template" : "Project"}
              </Button>
              <Button
                disabled={readOnly}
                onClick={() =>
                  dispatch(
                    setFormOpen({
                      isOpen: true,
                      formID: template ? "template" : "project",
                    })
                  )
                }
                size="sm"
                startDecorator={<i className="fa-solid fa-plus"></i>}
              >
                <Hidden smDown>Add</Hidden> {template ? "Template" : "Project"}
              </Button>
            </Box>
          </Box>
          <Hidden smDown>{!template ? getSeasonCarousel() : []}</Hidden>
          {template ? (
            <Hidden smDown>
              <Tabs
                value={tabIndex}
                onChange={(e, v) => {
                  dispatch(setTabIndex({ tabID: "projects", value: v }));
                  navigate({
                    search: updateURLParameter("projects", v),
                  });
                }}
              >
                <Tab label="Active" />
                <Tab label="Archived" />
              </Tabs>
            </Hidden>
          ) : (
            []
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            height: 0,
            minHeight: "auto",
          }}
        >
          <ProjectsDataGrid
            autoheight={false}
            tagID={tagFocused?.id > 0 ? tagFocused?.id : undefined}
            template={template && tabIndex === 0}
            archivedTemplate={template && tabIndex === 1}
            archived={tagFocused?.id === -2}
            bulkActions
          />
        </Box>
      </Box>
    </>
  );
}
