import { Box, Divider, Grid, Link, Sheet, Tooltip, Typography } from "@mui/joy";
import { SparkLineChart } from "@mui/x-charts";
import { useLocalStorage } from "@uidotdev/usehooks";
import { WorkSession } from "entities/workSession";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import moment from "moment";
import ReactVisibilitySensor from "react-visibility-sensor";
import { useWeeklyDigest } from "redux/digest/digestHooks";
import { useMusicians } from "redux/musician/musicianHooks";
import { useProjects } from "redux/project/projectHooks";
import { useStages } from "redux/stage/stageHooks";
import { useActiveWorkSessions } from "redux/workSession/workSessionHooks";
import { JournalWidget } from "./journalWidget";
import { JournalWorkSession } from "./journalWorkSession";
import { Message } from "./message";
import { ProjectHiring } from "./projectHiring";
// import PropTypes from 'prop-types';

// styles
import { Button, List, ListItemButton, ListItemDecorator } from "@mui/joy";
import { Hidden, ListItemSecondaryAction, Paper } from "@mui/material";

import { setFormOpen } from "reducers/rhapsody";
import { HelpCard } from "hooks/helpCard/helpCard";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Digest } from "entities/digest";
import { useEmails } from "redux/rhapsodyEmails/emailHooks";

/**
 *
 * @returns {ReactElement} Journal page
 */
export function JournalPage() {
  return (
    <Box
      sx={{
        maxWidth: 1920,
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        gap: 3,
        flexDirection: "column",
        position: "relative",
        p: 2,
      }}
    >
      <Widgets />
      <WelcomeHelpCard />
      {/* <Messages /> */}
      <HappeningNow />
      <Hiring />
    </Box>
  );
}

function Widgets() {
  const { activeWorkSessions, isLoading: workSessionsLoading } =
    useActiveWorkSessions();
  const { projects, isLoading: projectsLoading } = useProjects();

  const { emails, isLoading: emailsLoading } = useEmails({
    filters: JSON.stringify([
      {
        name: "createdAt",
        comparison: "gte",
        value: moment().add(-30, "day").format("YYYY-MM-DD"),
      },
    ]),
  });

  const workSessions = activeWorkSessions.filter((e) =>
    moment(e.dateToUTC).isAfter(moment())
  );

  const todayEmails = emails.filter((e) =>
    moment(e.createdAt).isSame(moment(), "day")
  );

  const data = emails.reduce((a, v) => {
    const index = moment().diff(moment(v.createdAt), "d");
    a[index]++;
    return a;
  }, new Array(31).fill(1));

  const workSessionsThisWeek = activeWorkSessions.filter((w) => {
    const from = moment(w.dateFromUTC);
    const to = moment(w.dateToUTC);
    const beginOfWeek = moment().startOf("week");
    const endOfWeek = moment().endOf("week");

    return (
      from.isSameOrAfter(beginOfWeek, "day") &&
      to.isSameOrBefore(endOfWeek, "day")
    );
  });

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <JournalWidget
        title="Active Project"
        favicon="fa-solid fa-folder-open"
        value={`${projects?.length}`}
        description={`${
          workSessionsThisWeek.reduce((a, v) => {
            if (!a.includes(v.projectID)) a.push(v.projectID);
            return a;
          }, []).length
        } happening this week`}
        loading={projectsLoading}
        href={`${RouterConfig.projects}`}
      />
      <JournalWidget
        title="Work Sessions"
        favicon="fa-solid fa-music"
        value={`${workSessions?.length}`}
        description={`${workSessionsThisWeek.length} happening this week`}
        loading={workSessionsLoading}
        href={`${RouterConfig.workSessions}`}
      />
      {/* <JournalWidget
        title="Musician Messages"
        favicon="fa-sharp fa-solid fa-comment"
        value={`${workSessions?.length}`}
        description={`${workSessionsThisWeek.length} happening this week`}
        loading={workSessionsLoading}
        href={`${RouterConfig.workSessions}`}
      /> */}
      <JournalWidget
        title="Emails sent today"
        favicon="fa-solid fa-envelope"
        value={`${todayEmails.length}`}
        description={`${emails.length} sent in the last 30 days`}
        loading={emailsLoading}
        href={`${RouterConfig.emails}`}
      >
        <SparkLineChart
          plotType="bar"
          data={data.reverse()}
          height={40}
          colors={["#2196f3"]}
        />
      </JournalWidget>
    </Box>
  );
}

function Messages() {
  const [dismissed, setDismissed] = useLocalStorage("digestDismissed", {});
  const { digests, isLoading: digestLoading } = useWeeklyDigest();
  const { projectsMap, isLoading: projectsLoading } = useProjects();
  const { stagesMap, isLoading: stagesLoading } = useStages();

  const musicianIDs = digests.reduce((a, v) => {
    v.musicians.forEach((m) => {
      if (!a.includes(m.musicianID)) a.push(m.musicianID);
    });
    return a;
  }, []);

  const { musiciansMap, isLoading: musiciansLoading } = useMusicians(
    {
      filters: JSON.stringify([
        {
          name: "musicians.id",
          comparison: "in",
          value: musicianIDs,
        },
      ]),
    },
    { skip: musicianIDs?.length === 0 }
  );

  const loading =
    digestLoading || projectsLoading || stagesLoading || musiciansLoading;

  const items = Digest.fromList(
    digests.reduce((a, v) => {
      v.musicians.forEach((m) => {
        const e = { ...v };
        e.musicians = [m];
        a.push(e);
      });
      return a;
    }, [])
  );

  const visibleDigests = items
    .filter(
      (e) =>
        !dismissed[`${e.musicians[0].date}-${e.musicians[0].musicianID}`] &&
        e.musicians[0].musicianID &&
        e.musicians[0].message
    )
    .sort(
      (a, b) =>
        moment(b.musicians[0].date).valueOf() -
        moment(a.musicians[0].date).valueOf()
    );

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography level="h5">Messages</Typography>
          {Object.keys(dismissed).length ? (
            <Tooltip
              variant="outlined"
              size="sm"
              enterDelay={1000}
              enterNextDelay={1000}
              arrow
              title="Restore dismissed messages"
            >
              <Link
                onClick={() => setDismissed({})}
                color="neutral"
                level="body2"
                startDecorator={<i className="fa-solid fa-rotate-left"></i>}
              >
                Restore
              </Link>
            </Tooltip>
          ) : (
            []
          )}
        </Box>
        <Divider />
      </Box>
      <Box
        sx={{
          width: "calc(100% + 32px)",
          marginLeft: "-16px",
          overflow: "auto",
          pl: 2,
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: "100%",
            // overflow: "auto",
            minWidth: "auto",
            width: 0,
          }}
        >
          {loading ? (
            <>
              <Message loading />
              <Message loading />
              <Message loading />
            </>
          ) : (
            <>
              {visibleDigests.map((e) => {
                const item = e.musicians[0];
                if (item.musicianID && item.message) {
                  return (
                    <Message
                      key={e.toJson()}
                      musician={musiciansMap[e.musicians[0].musicianID]}
                      project={projectsMap[e.projectID]}
                      stage={stagesMap[e.stageID]}
                      message={item.message}
                      date={item.date}
                    />
                  );
                }
              })}
            </>
          )}
          {!loading && visibleDigests.length === 0 ? (
            <Box sx={{ flexShrink: 0 }}>
              <Typography>👍 All caught up!</Typography>
              <Typography level="body2">
                You've read all your messages.
              </Typography>
            </Box>
          ) : (
            []
          )}
        </Box>
      </Box>
    </>
  );
}

function Hiring() {
  const { projects, isLoading: projectsLoading } = useProjects();
  const { activeWorkSessions } = useActiveWorkSessions();
  const upcomingEvents = getUpcomingEvents(activeWorkSessions);

  const activeProjectID = upcomingEvents.reduce((a, i) => {
    if (!a.includes(i.projectID)) a.push(i.projectID);
    return a;
  }, []);

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography level="h5">Hiring in progress</Typography>
        </Box>
        <Divider />
      </Box>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {projects
          ?.filter((p) => activeProjectID.indexOf(p.id) >= 0)
          .map((p) => {
            return (
              <Grid key={p.id} sm={12} md={6} lg={4}>
                <ReactVisibilitySensor partialVisibility={true}>
                  {({ isVisible }) => {
                    return (
                      <Sheet
                        sx={{ height: 300, borderRadius: "8px" }}
                        color="neutral"
                        variant="soft"
                      >
                        {isVisible ? <ProjectHiring p={p} /> : "false"}
                      </Sheet>
                    );
                  }}
                </ReactVisibilitySensor>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

function HappeningNow() {
  const { activeWorkSessions } = useActiveWorkSessions();
  const todayEvents = getTodayEvents(activeWorkSessions);

  if (todayEvents?.length === 0) return <Box />;

  return (
    <>
      <Box>
        <Typography level="h5">Happening Today</Typography>
        <Divider />
      </Box>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {todayEvents.map((e) => (
          <Grid key={e.id} sm={12} md={6}>
            <JournalWorkSession workSession={e} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function getUpcomingEvents(events: WorkSession[]) {
  const now = moment();

  return events.filter((event) => {
    const eventStartDate = moment(event.dateFromUTC);
    return eventStartDate.isSameOrAfter(now, "day");
  });
}

function getTodayEvents(events: WorkSession[]) {
  const now = moment();

  return events
    .filter((event) => {
      const eventStartDate = moment(event.dateFromUTC);
      return eventStartDate.isSame(now, "day");
    })
    .sort(
      (a, b) =>
        moment(b.dateFromUTC).valueOf() - moment(a.dateFromUTC).valueOf()
    );
}

function WelcomeHelpCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <HelpCard title="Get Started with Rhapsody">
      <Grid container>
        <Hidden smDown>
          <Grid xs={12} md={4}>
            <Typography level="body2" sx={{ fontWeight: 600, color: "black" }}>
              Add your musicians
            </Typography>
            <Typography level="body3">
              Add your talents to your personnal address book.
            </Typography>
            <Link
              underline="none"
              endDecorator={<i className="fa-regular fa-arrow-right"></i>}
              level="body3"
              onClick={() => {
                navigate(RouterConfig.addressBook);
                dispatch(setFormOpen({ isOpen: true, formID: "musician" }));
              }}
            >
              Start
            </Link>
            <br />
            <img
              style={{
                width: 250,
                marginTop: 8,
                boxShadow: "0px 4px 8px rgba(155,155,155,0.4)",
              }}
              src={
                "https://storage.googleapis.com/rhapsody/create-musicians.png"
              }
            />
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid xs={12} md={4}>
            <Typography level="body2" sx={{ fontWeight: 600, color: "black" }}>
              Create your first project
            </Typography>
            <Typography level="body3">
              Project is where your start your contracting
            </Typography>
            <Link
              underline="none"
              endDecorator={<i className="fa-regular fa-arrow-right"></i>}
              level="body3"
              onClick={() => {
                navigate(RouterConfig.projects);
                dispatch(setFormOpen({ isOpen: true, formID: "project" }));
              }}
            >
              Start
            </Link>
            <br />
            <img
              style={{
                width: 250,
                marginTop: 8,
                boxShadow: "0px 4px 8px rgba(155,155,155,0.4)",
              }}
              src={"https://storage.googleapis.com/rhapsody/create-project.png"}
            />
          </Grid>
        </Hidden>
        <Grid
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography level="body2" sx={{ fontWeight: 600, color: "black" }}>
            Helpdesk
          </Typography>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "start",
            }}
          >
            <Typography
              level="body2"
              sx={{
                fontWeight: 600,
                color: "black",
              }}
            >
              Not sure where to start?
            </Typography>
            <Typography level="body3">
              Take a look to our Helpdesk section.
              <br />
              Learn how to get started with tutorials & videos.
            </Typography>
            <Button
              endDecorator={<i className="fa-regular fa-arrow-right"></i>}
              variant="soft"
              onClick={() =>
                window.open("https://helpdesk.rhapsody.la/", "_blank")
              }
              size="sm"
              color="info"
            >
              Go to Helpdesk
            </Button>
          </Paper>
          <List size="sm">
            <ListItemButton
              onClick={() =>
                window.open(
                  "https://helpdesk.rhapsody.la/rhapsody-helpdesk/getting-started/creating-a-project",
                  "_blank"
                )
              }
            >
              <ListItemDecorator>
                <i className="fa-duotone fa-circle-video"></i>
              </ListItemDecorator>
              Creating a Project
              <ListItemSecondaryAction>
                <i className="fa-regular fa-arrow-right"></i>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Divider />
            <ListItemButton
              onClick={() =>
                window.open(
                  "https://helpdesk.rhapsody.la/rhapsody-helpdesk/getting-started/adding-work-sessions",
                  "_blank"
                )
              }
            >
              <ListItemDecorator>
                <i className="fa-duotone fa-circle-video"></i>
              </ListItemDecorator>
              Adding Work Sessions
              <ListItemSecondaryAction>
                <i className="fa-regular fa-arrow-right"></i>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Divider />
            <ListItemButton
              onClick={() =>
                window.open(
                  "https://helpdesk.rhapsody.la/rhapsody-helpdesk/getting-started/sending-the-call",
                  "_blank"
                )
              }
            >
              <ListItemDecorator>
                <i className="fa-duotone fa-circle-video"></i>
              </ListItemDecorator>
              Sending The Call
              <ListItemSecondaryAction>
                <i className="fa-regular fa-arrow-right"></i>
              </ListItemSecondaryAction>
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </HelpCard>
  );
}
