import { renderToString } from "react-dom/server";
import Booklet_Entity from "./booklet";
import { AppConfig } from "environement";
export default class Booklet extends Booklet_Entity {
  static fromList(bookletsJSON: unknown): Array<Booklet> {
    const booklets: Booklet[] = [];
    if (bookletsJSON)
      Array.isArray(bookletsJSON) &&
        bookletsJSON.forEach((bookletJSON) => {
          booklets.push(new Booklet(bookletJSON));
        });
    return booklets;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  publicUrl(): string {
    const apiEndpoint = AppConfig.api.endpoint;
    let endpoint = "live";
    if (apiEndpoint.includes("alpha")) endpoint = "alpha";
    if (apiEndpoint.includes("demo")) endpoint = "demo";

    return `https://booklet.${endpoint}.rhapsody.la?token=${this.publicToken}`;
  }

  dynamic(): string {
    return renderToString(
      <>
        <div
          className={`readonly-block booklet-${this.id}`}
          dangerouslySetInnerHTML={{
            __html: this.content,
          }}
        />
        <br />
      </>
    );
  }
}
