import { useTheme } from "@mui/joy";
import { Grow, useMediaQuery, Zoom } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function SlidingDialog({ children, onClose, open }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <DialogClose
      open={open}
      closeColor={"neutral"}
      TransitionComponent={Transition}
      fullScreen
      sx={{ margin: "12px" }}
      slotProps={{
        root: {
          style: { borderRadius: "8px", overflow: "hidden" },
        },
      }}
      onClose={() => (onClose ? onClose() : navigate(-1))}
    >
      {children}
    </DialogClose>
  );
}

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});
