import { Internal } from "entities/internal";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { ReactElement } from "react";
import { renderToString } from "react-dom/server";

type BookletHTMLProps = {
  internal?: Internal;
  utils?: LayoutUtils;
  dynamic?: boolean;
  id?: number;
};

export const bookletHTML = {
  project: {
    program: projectProgramHTML,
    chairCount: projectChairCountHTML,
    info: projectInfoHTML,
    schedule: projectScheduleHTML,
    roster: projectRosterHTML,
    dressCode: projectDressCodeHTML,
    note: projectNoteHTML,
    links: projectLinksHTML,
    contacts: projectContactsHTML,
    liveRosters: projectLiveRostersHTML,
  },
};

function wrap(id: string, content: ReactElement) {
  return (
    <>
      <div className={`readonly-block ${id}`}>{content}</div>
      <br />
    </>
  );
}

export function projectProgramHTML({ dynamic, utils }: BookletHTMLProps) {
  let content = (
    <>
      <h2>Program</h2>
      <table>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th>Program</th>
            <th>Instrumentation</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {utils.projectPieces.map((p) => {
            const piece = utils.piecesMap[p.pieceID];
            return (
              <tr key={p.id}>
                <td>
                  {piece
                    ? `${piece?.name}, ${piece.composer}`
                    : "Seating Arrangement"}
                </td>
                <td>{p.description}</td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
  if (dynamic) content = wrap("project-program", content);
  return renderToString(content);
}

export function projectChairCountHTML({
  internal,
  dynamic,
  utils,
}: BookletHTMLProps) {
  const violinsCount = internal.positions.filter(
    (p) => p.sectionID === 80
  )?.length;
  const violinICount = internal.positions.filter(
    (p) => p.sectionID === 1
  )?.length;
  const violinIICount = internal.positions.filter(
    (p) => p.sectionID === 2
  )?.length;
  const viola = internal.positions.filter((p) => p.sectionID === 3)?.length;
  const celloCount = internal.positions.filter(
    (p) => p.sectionID === 4
  )?.length;
  const bassCount = internal.positions.filter((p) => p.sectionID === 5)?.length;

  const count = [];
  if (violinsCount)
    count.push(`${violinsCount} ${utils.sectionsMap[80].shorthand}`);
  if (violinICount)
    count.push(`${violinICount} ${utils.sectionsMap[1].shorthand}`);
  if (violinIICount)
    count.push(`${violinIICount} ${utils.sectionsMap[2].shorthand}`);
  if (viola) count.push(`${viola} ${utils.sectionsMap[3].shorthand}`);
  if (celloCount) count.push(`${celloCount} ${utils.sectionsMap[4].shorthand}`);
  if (bassCount) count.push(`${bassCount} ${utils.sectionsMap[5].shorthand}`);

  let content = (
    <>
      <b>Max Count:</b> {internal.positionCount} Musician
      {internal.positionCount > 1 ? "s" : ""}
      <br />
      <b>String Count:</b> {count.join(", ")}
    </>
  );
  if (dynamic) content = wrap("project-chairCount", content);
  return renderToString(content);
}

export function projectInfoHTML({ dynamic, utils }: BookletHTMLProps) {
  const conductorChairs = utils.chairs.filter((c) => c.sectionRoleID === 17);
  const conductors = [];
  conductorChairs.forEach((c) => {
    const conductor = utils.musiciansMap[c.musicianID];
    if (conductor && !conductors.includes(conductor.fullName()))
      conductors.push(conductor.fullName());
  });
  const venues = [];
  utils.workSessions.forEach((w) => {
    if (!venues.includes(w.studioName)) {
      venues.push(w.studioName);
    }
  });
  let content = (
    <>
      {utils.tag ? (
        <div>
          <b>Season:</b> {utils.tag?.name}
        </div>
      ) : (
        []
      )}
      <div>
        <b>Project Name:</b> {utils.project?.name}
      </div>
      {conductors.length ? (
        <div>
          <b>Conductor{conductors.length > 1 ? "s" : ""}:</b>{" "}
          {conductors.join(", ")}
        </div>
      ) : (
        []
      )}
      {venues.length ? (
        <div>
          <b>Location{venues.length > 1 ? "s" : ""}:</b> {venues.join(", ")}
        </div>
      ) : (
        []
      )}
    </>
  );
  if (dynamic) content = wrap("project-info", content);
  return renderToString(content);
}

export function projectScheduleHTML({
  internal,
  dynamic,
  utils,
}: BookletHTMLProps) {
  let content = (
    <>
      <h2>Schedule</h2>
      <table>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th>Date</th>
            <th>Start</th>
            <th>End</th>
            <th>Place</th>
            <th>Rehearsal Order</th>
          </tr>
        </thead>
        <tbody>
          {utils.workSessions.map((w) => {
            return (
              <tr key={w.id}>
                <td>{w.formatDate()}</td>
                <td>{w.formatFrom()}</td>
                <td>{w.formatTo()}</td>
                <td>{w.studioName}</td>
                <td>{w.schedule(utils, internal)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
  if (dynamic) content = wrap("project-schedule", content);
  return renderToString(content);
}

export function projectRosterHTML({
  internal,
  dynamic,
  utils,
  id,
}: BookletHTMLProps) {
  let content = (
    <div style={{ columnCount: id, columnGap: 16 }}>
      {internal.sections.map((s) => {
        const musicianNames = [];
        s.positions.forEach((p) =>
          p.visibleMusicianIDs.forEach((m) => {
            musicianNames.push(utils.musiciansMap[m]?.fullName());
          })
        );
        return (
          <>
            <span key={s.sectionID}>
              <b>{utils.sectionsMap[s.sectionID].name}: </b>
              {musicianNames.join(", ")}
            </span>
            <br />
          </>
        );
      })}
    </div>
  );

  if (dynamic) content = wrap(`project-roster-${id}`, content);
  return renderToString(content);
}

export function projectDressCodeHTML({ dynamic, utils }: BookletHTMLProps) {
  let content = (
    <div
      dangerouslySetInnerHTML={{
        __html: utils.project.dressCode,
      }}
    ></div>
  );
  if (dynamic) content = wrap("project-dressCode", content);
  return renderToString(content);
}

export function projectNoteHTML({ dynamic, utils, id }: BookletHTMLProps) {
  const projectNote = utils.projectNotes?.find((e) => e.id === id);
  let content = (
    <div
      dangerouslySetInnerHTML={{
        __html: projectNote?.value ?? "Project Note not found",
      }}
    ></div>
  );
  if (dynamic) content = wrap(`project-note-${id}`, content);
  return renderToString(content);
}

export function projectLinksHTML({ dynamic, utils }: BookletHTMLProps) {
  let content = (
    <>
      <h2>Links</h2>
      <table>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          {utils.links.map((l) => {
            return (
              <tr key={l.id}>
                <td>{l.name}</td>
                <td>{l.description}</td>
                <td>
                  <a href={l.url}>{l.url}</a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
  if (dynamic) content = wrap("project-links", content);
  return renderToString(content);
}

export function projectContactsHTML({ dynamic, utils }: BookletHTMLProps) {
  let content = (
    <>
      <h2>Contacts</h2>
      <table>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Contacts</th>
          </tr>
        </thead>
        <tbody>
          {utils.projectContacts.map((l) => {
            const musician = utils.musiciansMap[l.musicianID];
            return (
              <tr key={l.id}>
                <td>{musician?.fullName()}</td>
                <td>{l.role}</td>
                <td>
                  {musician?.email}, {musician?.phone}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
  if (dynamic) content = wrap("project-contacts", content);
  return renderToString(content);
}

export function projectLiveRostersHTML({ dynamic, utils }: BookletHTMLProps) {
  let content = (
    <>
      <h2>Live Rosters</h2>
      <table>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th>Name</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          {utils.projectRosters.map((l) => {
            return (
              <tr key={l.id}>
                <td>{l.name}</td>
                <td>
                  <a href={l.publicUrl()}>{l.publicUrl()}</a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
  if (dynamic) content = wrap("project-liveRosters", content);
  return renderToString(content);
}
