import {
  Chip,
  CssVarsProvider,
  Divider,
  ListItemDecorator,
  Menu,
  MenuItem,
  Sheet,
  Typography,
} from "@mui/joy";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { initNetworkAction } from "@nerdjs/nerd-network";
import { NerdFileUploadWrapper } from "@nerdjs/nerd-ui";
import { TourProvider } from "@reactour/tour";
import { getCurrentUser, getUserApps } from "actions";
import { AppConfig } from "environement";
import AskQuestion from "features/context/AskQuestion";
import Centrifugo from "features/context/Centrifugo";
import ConfettiCenter from "features/context/ConfettiCenter";
import { ErrorMonitor } from "features/errorMonitor/errorMonitor";
import { tourConfig } from "features/tour/tour";
import { useRouteMatch } from "helpers";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { Authentication } from "hooks/Authentication/Authentication";
import Openner from "hooks/Openner";
import { Shortcut } from "hooks/Shortcut/shortcut";
import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { apiEndpointSelector, setFormOpen } from "reducers/rhapsody";
import { JournalPage } from "routes/journal/v2/journal";
import { RhapsodyAppBar } from "./appbar";
import { appTheme } from "./themeConfig";
import { useSelector } from "react-redux";
import { show } from "@intercom/messenger-js-sdk";
import { useCompanySettings } from "redux/company/companyHooks";

export default function Root() {
  const dispatch = useDispatch();
  const apiEndpoint = useSelector(apiEndpointSelector);
  React.useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
  }, []);

  if (apiEndpoint) return <App />;

  return <Box />;
}

export function App() {
  const routeMatch = useRouteMatch([`/`]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState();
  const [helpEl, setHelpEl] = React.useState();
  const location = useLocation();
  const navigate = useNavigate();
  const settings = useCompanySettings();

  React.useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
    init();
  }, []);

  const init = () => {
    dispatch(getCurrentUser());
    dispatch(getUserApps());
  };

  const pathnames = location.pathname.split("/").filter((x) => x);
  const onboarding = pathnames.indexOf("onboarding") >= 0;

  const firstDay = window.localStorage.getItem("calendarFirstDay")
    ? parseInt(window.localStorage.getItem("calendarFirstDay"))
    : 0;

  moment.updateLocale("en", {
    week: {
      dow: firstDay,
    },
  });

  const menu = [
    { label: "Home", to: RouterConfig.root, icon: "fa-regular fa-house" },
    {
      label: "Calendar",
      to: RouterConfig.calendar,
      icon: "fa-regular fa-calendar",
    },
    {
      label: "Projects",
      to: RouterConfig.projects,
      icon: "fa-regular fa-folder-open",
    },
    {
      label: "Work Sessions",
      to: RouterConfig.workSessions,
      icon: "fa-regular fa-music",
    },
    {
      label: "Customers",
      to: RouterConfig.customers,
      more: true,
      icon: "fa-regular fa-building",
    },
    {
      label: "Venues",
      to: RouterConfig.venues,
      more: true,
      icon: "fa-regular fa-location-dot",
    },
    {
      label: "Payrolls",
      to: RouterConfig.payrolls,
      more: true,
      icon: "fa-regular fa-university",
    },
    {
      label: "Settings",
      to: `${RouterConfig.myAccount}/credentials`,
      icon: "fa-regular fa-gear",
      more: true,
    },
    {
      label: "Pieces",
      to: RouterConfig.pieces,
      icon: "fa-regular fa-feather",
    },
    {
      label: "Templates",
      to: RouterConfig.templates,
      more: false,
      icon: "fa-regular fa-square-dashed",
    },
    {
      label: "Address Book",
      to: RouterConfig.addressBook,
      icon: "fa-regular fa-user-music",
    },
    {
      label: "Emails",
      to: RouterConfig.emails,
      icon: "fa-regular fa-envelope",
    },
  ];

  if (settings.explore) {
    menu.splice(menu.length - 1, 0, {
      label: "Explore",
      to: RouterConfig.explore,
      icon: "fa-solid fa-magnifying-glass",
      endDecorator: (
        <Typography
          variant="outlined"
          sx={{ color: "inherit", ml: "2px" }}
          level="body5"
        >
          NEW
        </Typography>
      ),
    });
  }

  return (
    <CssVarsProvider theme={appTheme}>
      <TourProvider {...tourConfig}>
        <NerdFileUploadWrapper>
          <ConfettiCenter>
            <AskQuestion>
              <Centrifugo>
                <ErrorMonitor />
                <Openner />
                <Shortcut />
                <Box>
                  <CssBaseline />
                  {!onboarding ? (
                    <Box
                      sx={{
                        position: "sticky",
                        width: "100%",
                        top: 0,
                        zIndex: 999,
                      }}
                    >
                      <RhapsodyAppBar />
                      <Sheet
                        sx={{
                          display: "flex",
                          gap: 1,
                          width: "100vw",
                        }}
                        variant="outlined"
                      >
                        <Box
                          sx={{
                            maxWidth: 1600,
                            margin: "auto",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 1,
                            flex: 1,
                          }}
                        >
                          <Box
                            sx={{
                              overflowX: "auto",
                              minWidth: "auto",
                              width: 0,
                              display: "flex",
                              flexGrow: 1,
                              p: 1,
                              gap: 0.5,
                            }}
                          >
                            {menu
                              .filter((m) => !m.more)
                              .map((m) => {
                                const selected =
                                  pathnames[0] === m.to.slice(1) ||
                                  (pathnames[0] === undefined &&
                                    m.to === RouterConfig.root);
                                return (
                                  <Chip
                                    key={m.to}
                                    size="sm"
                                    startDecorator={
                                      m.icon ? <i className={m.icon} /> : []
                                    }
                                    endDecorator={m.endDecorator}
                                    component={Link}
                                    onClick={() => console.log("Click")}
                                    to={m.to}
                                    variant={selected ? "solid" : "plain"}
                                    color={selected ? "primary" : "neutral"}
                                  >
                                    {m.label}
                                  </Chip>
                                );
                              })}
                            <Divider orientation="vertical" />
                            <Chip
                              size="sm"
                              onClick={(e) => setAnchorEl(e.currentTarget)}
                              variant="plain"
                              color="neutral"
                              endDecorator={
                                <i className="fa-solid fa-chevron-down"></i>
                              }
                            >
                              More
                            </Chip>
                            <Menu
                              size="sm"
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => setAnchorEl(null)}
                            >
                              {menu
                                .filter((m) => m.more)
                                .map((m) => {
                                  const selected =
                                    pathnames[0] === m.to.slice(1) ||
                                    (pathnames[0] === undefined &&
                                      m.to === RouterConfig.root);
                                  return (
                                    <MenuItem
                                      key={m.to}
                                      size="sm"
                                      component={Link}
                                      onClick={() => {
                                        navigate(m.to);
                                        setAnchorEl(null);
                                      }}
                                      to={m.to}
                                      variant={selected ? "solid" : "plain"}
                                      color={selected ? "primary" : "neutral"}
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        alignItems: "center",
                                      }}
                                    >
                                      {m.icon ? <i className={m.icon} /> : []}
                                      {m.label}
                                    </MenuItem>
                                  );
                                })}
                            </Menu>
                          </Box>
                          <Chip
                            size="sm"
                            onClick={(e) => setHelpEl(e.currentTarget)}
                            variant="plain"
                            color="neutral"
                            startDecorator={
                              <i className="fa-solid fa-circle-question"></i>
                            }
                          >
                            Help
                          </Chip>
                          <Menu
                            anchorEl={helpEl}
                            open={Boolean(helpEl)}
                            onClose={() => setHelpEl(null)}
                            size="sm"
                            sx={{ maxWidth: 250 }}
                          >
                            <MenuItem
                              onClick={() => {
                                window.open(
                                  "https://helpdesk.rhapsody.la",
                                  "_blank"
                                );
                                setHelpEl(null);
                              }}
                            >
                              <ListItemDecorator>
                                <i className="fa-solid fa-book"></i>
                              </ListItemDecorator>
                              Helpdesk
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setHelpEl(null);
                                show();
                              }}
                            >
                              <ListItemDecorator>
                                <i className="fa-solid fa-comment"></i>
                              </ListItemDecorator>
                              Chat with us
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setHelpEl(null);
                                dispatch(
                                  setFormOpen({
                                    formID: "shortcut",
                                    isOpen: true,
                                  })
                                );
                              }}
                            >
                              <ListItemDecorator>
                                <i className="fa-solid fa-keyboard"></i>
                              </ListItemDecorator>
                              Keyboard Shortcuts
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Sheet>
                    </Box>
                  ) : (
                    []
                  )}
                  <Box>
                    <Outlet />
                    {routeMatch?.pattern?.path === "/" ? <JournalPage /> : []}
                  </Box>
                </Box>
              </Centrifugo>
            </AskQuestion>
          </ConfettiCenter>
        </NerdFileUploadWrapper>
      </TourProvider>
      <Authentication />
    </CssVarsProvider>
  );
}
