import { Box } from "@mui/material";

import {
  Button,
  Divider,
  Option,
  Select,
  Sheet,
  Switch,
  Typography,
} from "@mui/joy";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { useDispatch, useSelector } from "react-redux";
import { setFormOpen } from "reducers/rhapsody";
import {
  useUpdateCompanyMutation,
  useUpdateCurrentCompanyMutation,
} from "redux/company/companyEndpoints";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";
import { meSelector } from "selectors/me";
import { SearchTooltip } from "hooks/searchTooltip/searchTooltip";

export default function AppSettings() {
  // const [ state, setState ] = React.useState(initialValue);
  const { company } = useCurrentCompany();
  const settings = useCompanySettings();
  const [updateCompany] = useUpdateCompanyMutation();
  const [updateCurrentCompany] = useUpdateCurrentCompanyMutation();
  const askQuestion = useAskQuestion();

  const me = useSelector(meSelector);
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;
  const dispatch = useDispatch();

  console.log(settings);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          width: 600,
          borderRadius: "8px",
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography startDecorator={<i className="fa-solid fa-violin"></i>}>
            Default Strings
          </Typography>
          <Typography level="body2">
            How many musicians for your string positions?
          </Typography>
        </Box>
        <Button
          size="sm"
          color="neutral"
          variant="outlined"
          sx={{ background: "white" }}
          onClick={() =>
            dispatch(setFormOpen({ formID: "defaultStrings", isOpen: true }))
          }
        >
          {settings.defaultStrings
            ? settings.defaultStrings.join("-")
            : "Not set"}
        </Button>
      </Sheet>
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          width: 600,
          borderRadius: "8px",
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            startDecorator={<i className="fa-solid fa-clothes-hanger"></i>}
          >
            Dress Code Snippets
          </Typography>
          <Typography level="body2">
            Create dress code snippets ready to use on your Projects
          </Typography>
        </Box>
        <Button
          size="sm"
          color="neutral"
          variant="outlined"
          sx={{ background: "white" }}
          onClick={() =>
            dispatch(setFormOpen({ formID: "defaultDressCodes", isOpen: true }))
          }
        >
          {settings.defaultDressCodes?.length > 0
            ? `${settings.defaultDressCodes?.length} Snippet${
                settings.defaultDressCodes?.length !== 1 ? "s" : ""
              }`
            : "Not set"}
        </Button>
      </Sheet>
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          width: 600,
          borderRadius: "8px",
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography startDecorator={<i className="fa-solid fa-notes"></i>}>
            Project Note Snippets
          </Typography>
          <Typography level="body2">
            Create note snippets ready to use on your Projects
          </Typography>
        </Box>
        <Button
          size="sm"
          color="neutral"
          variant="outlined"
          sx={{ background: "white" }}
          onClick={() =>
            dispatch(
              setFormOpen({ formID: "defaultProjectNotes", isOpen: true })
            )
          }
        >
          {settings.defaultProjectNotes?.length > 0
            ? `${settings.defaultProjectNotes?.length} Snippet${
                settings.defaultProjectNotes?.length !== 1 ? "s" : ""
              }`
            : "Not set"}
        </Button>
      </Sheet>
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          width: 600,
          borderRadius: "8px",
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography startDecorator={<i className="fa-solid fa-users"></i>}>
            Roster Presets
          </Typography>
          <Typography level="body2">
            Create rosters configuration ready to use.
          </Typography>
        </Box>
        <Button
          size="sm"
          color="neutral"
          variant="outlined"
          sx={{ background: "white" }}
          onClick={() =>
            dispatch(setFormOpen({ formID: "defaultRosters", isOpen: true }))
          }
        >
          {settings.rosterPresets?.length > 0
            ? `${settings.rosterPresets?.length} Preset${
                settings.rosterPresets?.length !== 1 ? "s" : ""
              }`
            : "Not set"}
        </Button>
      </Sheet>
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          width: 600,
          borderRadius: "8px",
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          startDecorator={<i className="fa-solid fa-calendar-days"></i>}
        >
          Week starts on:
        </Typography>
        <Select
          size="sm"
          sx={{ width: 150 }}
          value={settings?.calendarFirstDay ?? "0"}
          onChange={(e, v) => {
            updateCurrentCompany({
              ...company,
              settings: JSON.stringify({
                ...settings,
                calendarFirstDay: v,
              }),
            });
          }}
        >
          <Option value={"0"}>Sunday</Option>
          <Option value={"1"}>Monday</Option>
        </Select>
      </Sheet>
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          borderRadius: "8px",
          width: 600,
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          startDecorator={<i className="fa-solid fa-arrow-down-short-wide"></i>}
        >
          Work Sessions are sorted by date:
        </Typography>
        <Select
          size="sm"
          sx={{ width: 150 }}
          value={settings?.workSessionSort ?? "asc"}
          onChange={(e, v) => {
            updateCurrentCompany({
              ...company,
              settings: JSON.stringify({
                ...settings,
                workSessionSort: v,
              }),
            });
          }}
        >
          <Option value={"asc"}>Ascending</Option>
          <Option value={"desc"}>Descending</Option>
        </Select>
      </Sheet>
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          borderRadius: "8px",
          width: 600,
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            startDecorator={<i className="fa-solid fa-box-archive"></i>}
          >
            Calls for archived musicians
          </Typography>
          <Typography level="body2">
            Archived Musician can receive calls
          </Typography>
        </Box>
        <Switch
          checked={settings?.canContactArchivedMusicians ?? false}
          onChange={(e) => {
            updateCurrentCompany({
              ...company,
              settings: JSON.stringify({
                ...settings,
                canContactArchivedMusicians: e.target.checked,
              }),
            });
          }}
        />
      </Sheet>
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          borderRadius: "8px",
          width: 600,
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography startDecorator={<i className="fa-solid fa-chair"></i>}>
            Assignment for subs
          </Typography>
          <Typography level="body2">Subs can receive Assignments</Typography>
        </Box>
        <Switch
          checked={settings?.assignmentsForSubs ?? true}
          onChange={(e) => {
            updateCurrentCompany({
              ...company,
              settings: JSON.stringify({
                ...settings,
                assignmentsForSubs: e.target.checked,
              }),
            });
          }}
        />
      </Sheet>
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          borderRadius: "8px",
          width: 600,
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography startDecorator={<i className="fa-solid fa-eye"></i>}>
            Show full schedule in calls (Default value)
          </Typography>
          <Typography level="body2">
            If enabled, musicians will see all the Work Sessions the Project
            contains (even the ones they are not in).
          </Typography>
        </Box>
        <Switch
          checked={!settings?.hideWhenNotPlaying}
          onChange={(e) => {
            updateCurrentCompany({
              ...company,
              settings: JSON.stringify({
                ...settings,
                hideWhenNotPlaying: !e.target.checked,
              }),
            });
          }}
        />
      </Sheet>
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          borderRadius: "8px",
          width: 600,
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            endDecorator={
              <Typography
                level="body3"
                sx={{ background: "white", fontWeight: 600, borderRadius: 8 }}
                variant="outlined"
              >
                BETA
              </Typography>
            }
            startDecorator={<i className="fa-solid fa-person-from-portal"></i>}
          >
            Release Requests:
          </Typography>
          <Typography level="body2">
            Musicians can asked to be released for Work Sessions
          </Typography>
        </Box>

        <Switch
          onChange={(e) => {
            updateCurrentCompany({
              ...company,
              enableRelease: e.target.checked,
            });
          }}
          checked={company?.enableRelease ?? false}
        />
      </Sheet>
      <Sheet
        variant="soft"
        sx={{
          display: "flex",
          gap: 1,
          borderRadius: "8px",
          width: 600,
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          startDecorator={<i className="fa-solid fa-square-question"></i>}
        >
          Restore Helpdesk cards:
        </Typography>
        <Button
          size="sm"
          color="neutral"
          variant="outlined"
          sx={{ background: "white" }}
          onClick={() => {
            window.localStorage.setItem("helpCardDismiss", "{}");
            askQuestion("Done!", ["OK"], {
              subtitle: (
                <Typography>
                  You will see helpdesk cards again to guide you understanding
                  how to use Rhapsody.
                </Typography>
              ),
            });
          }}
        >
          Restore
        </Button>
      </Sheet>
      {superadmin ? <Divider>Power-Ups</Divider> : []}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              endDecorator={
                <Typography
                  level="body3"
                  sx={{ background: "white", fontWeight: 600, borderRadius: 8 }}
                  variant="outlined"
                >
                  BETA
                </Typography>
              }
              startDecorator={<i className="fa-solid fa-ticket"></i>}
            >
              Season Invites
            </Typography>
            <Typography level="body2">
              Enable Missions & Season invites
            </Typography>
          </Box>
          <Switch
            checked={settings.seasonInvite ?? false}
            onChange={(e) => {
              updateCurrentCompany({
                ...company,
                settings: JSON.stringify({
                  ...settings,
                  seasonInvite: e.target.checked,
                }),
              });
            }}
          />
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={<i className="fa-solid fa-book"></i>}
              endDecorator={
                <Typography
                  level="body3"
                  sx={{ background: "white", fontWeight: 600, borderRadius: 8 }}
                  variant="outlined"
                >
                  BETA
                </Typography>
              }
            >
              Booklet
            </Typography>
            <Typography level="body2">
              Write rich text for Projects, Seasons, Musicians, Venues, and
              Pieces.
            </Typography>
          </Box>
          <Switch
            checked={settings.booklet ?? false}
            onChange={(e) => {
              updateCompany({
                id: company.id,
                body: {
                  ...company,
                  settings: JSON.stringify({
                    ...settings,
                    booklet: e.target.checked,
                  }),
                },
              });
            }}
          />
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
              endDecorator={
                <Typography
                  level="body3"
                  sx={{ background: "white", fontWeight: 600, borderRadius: 8 }}
                  variant="outlined"
                >
                  BETA
                </Typography>
              }
            >
              Explore
            </Typography>
            <Typography level="body2">
              Enable Explore view where musicians are displayed on a map with
              the venues.
            </Typography>
          </Box>
          <Switch
            checked={settings.explore ?? false}
            onChange={(e) => {
              updateCompany({
                id: company.id,
                body: {
                  ...company,
                  settings: JSON.stringify({
                    ...settings,
                    explore: e.target.checked,
                  }),
                },
              });
            }}
          />
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={<i className="fa-solid fa-file-lines"></i>}
            >
              AFM Contination forms
            </Typography>
            <Typography level="body2">
              Enable AFM BForm exports on the Budget and Payroll
            </Typography>
          </Box>
          <Switch
            checked={settings.bform ?? false}
            onChange={(e) => {
              updateCompany({
                id: company.id,
                body: {
                  ...company,
                  settings: JSON.stringify({
                    ...settings,
                    bform: e.target.checked,
                  }),
                },
              });
            }}
          />
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={
                <i className="fa-solid fa-diamond-turn-right"></i>
              }
            >
              Mileage Calculation
            </Typography>
            <Typography level="body2">
              Using the Google Direction API to calculate mileage on the budget
              & payroll
            </Typography>
          </Box>
          <Switch
            checked={settings.mileage ?? false}
            onChange={(e) => {
              updateCompany({
                id: company.id,
                body: {
                  ...company,
                  settings: JSON.stringify({
                    ...settings,
                    mileage: e.target.checked,
                  }),
                },
              });
            }}
          />
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? <Divider>Admin Settings</Divider> : []}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={<i className="fa-brands fa-intercom"></i>}
            >
              Hide Intercom
            </Typography>
            <Typography level="body2">
              Disable Intercom. The floating button will disappear.
            </Typography>
          </Box>
          <Switch
            checked={localStorage.getItem("hideIntercom") === "true"}
            onChange={(e) => {
              localStorage.setItem(
                "hideIntercom",
                e.currentTarget.checked ? "true" : "false"
              );
              window.location.reload();
            }}
          />
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={<i className="fa-solid fa-hourglass-end"></i>}
            >
              Trial Expired Message
            </Typography>
            <Typography level="body2">
              Show a message inviting the user to pay the onboarding fee
            </Typography>
          </Box>
          <Switch
            checked={settings.trialExpired ?? false}
            onChange={(e) => {
              updateCompany({
                id: company.id,
                body: {
                  ...company,
                  settings: JSON.stringify({
                    ...settings,
                    trialExpired: e.target.checked,
                  }),
                },
              });
            }}
          />
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={<i className="fa-solid fa-folder-open"></i>}
            >
              Default Project Version
            </Typography>
            <Typography level="body2">
              Only Super Admin can see this. If set to Maestro, user won't be
              able to create legacy Projects.
            </Typography>
          </Box>
          <Select
            sx={{ width: 150 }}
            size="sm"
            onChange={(e, v) => {
              updateCompany({
                id: company.id,
                body: { ...company, defaultProjectVersion: v },
              });
            }}
            value={company?.defaultProjectVersion ?? 1}
          >
            <Option value={1}>Legacy</Option>
            <Option value={2}>Maestro</Option>
          </Select>
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={<i className="fa-solid fa-diagram-sankey"></i>}
            >
              Custom Mercury Pipeline
            </Typography>
            <Typography level="body2">
              Only Super Admin can see this.
            </Typography>
          </Box>
          <Select
            sx={{ width: 150 }}
            size="sm"
            onChange={(e, v) => {
              updateCompany({
                id: company.id,
                body: { ...company, pipelineID: v },
              });
            }}
            value={company?.pipelineID ?? 1}
          >
            {company?.pipelines?.map((p) => {
              return (
                <Option key={p.id} value={p.id}>
                  {p.name}
                </Option>
              );
            })}
          </Select>
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={<i className="fa-solid fa-dollar-sign"></i>}
            >
              Restricted (Payment fault)
            </Typography>
            <Typography level="body2">
              Account not in good standing. Freeze account.
            </Typography>
          </Box>
          <Switch
            checked={settings?.restricted ?? false}
            onChange={(e) => {
              updateCompany({
                id: company.id,
                body: {
                  ...company,
                  settings: JSON.stringify({
                    ...settings,
                    restricted: e.target.checked,
                  }),
                },
              });
            }}
          />
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={<i className="fa-brands fa-cc-stripe"></i>}
            >
              Stripe Unpaid Invoices
            </Typography>
            <Typography level="body2">
              Shows the list of the unpaid balance at launch
            </Typography>
          </Box>
          <Button
            size="sm"
            color="info"
            variant="outlined"
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "stripeBalance" }))
            }
          >
            Configure
          </Button>
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography startDecorator={<i className="fa-solid fa-eye"></i>}>
              API Preview only
            </Typography>
            <Typography level="body2">
              Only preview changes without doing the action.
            </Typography>
          </Box>
          <Switch
            checked={settings?.apiPreview ?? false}
            onChange={(e) => {
              updateCompany({
                id: company.id,
                body: {
                  ...company,
                  settings: JSON.stringify({
                    ...settings,
                    apiPreview: e.target.checked,
                  }),
                },
              });
            }}
          />
        </Sheet>
      ) : (
        []
      )}
      {superadmin ? (
        <Sheet
          variant="soft"
          color="info"
          sx={{
            display: "flex",
            gap: 1,
            borderRadius: "8px",
            width: 600,
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              startDecorator={
                <i className="fa-solid fa-square-exclamation"></i>
              }
            >
              Hide error messages
            </Typography>
            <Typography level="body2">
              For demo purposes only. API errors won't be displayed.
            </Typography>
          </Box>
          <Switch
            checked={settings?.hideApiErrors ?? false}
            onChange={(e) => {
              updateCompany({
                id: company?.id,
                body: {
                  ...company,
                  settings: JSON.stringify({
                    ...settings,
                    hideApiErrors: e.target.checked,
                  }),
                },
              });
            }}
          />
        </Sheet>
      ) : (
        []
      )}
    </Box>
  );
}
