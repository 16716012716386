import { Close } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/joy";
import { Dialog, useMediaQuery } from "@mui/material";

export default function DialogClose(props) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Dialog
      {...props}
      open={props.open ?? false}
      onClose={props.onClose}
      fullScreen={sm || props.fullScreen}
    >
      {props.onClose ? (
        <div
          style={{
            position: "fixed",
            top: 4,
            right: 4,
            zIndex: 999,
          }}
        >
          <IconButton onClick={props.onClose} size="sm" color="neutral">
            <Close sx={{ fontSize: 28 }} />
          </IconButton>
        </div>
      ) : (
        []
      )}
      {props.children}
    </Dialog>
  );
}
