/**
 * MusicianGroup_Entity type guard.
 *
 * @param {any} musicianGroupJson MusicianGroup object from API
 * @returns {boolean} Return true if type is MusicianGroup_Entity
 */

export default class MusicianGroup_Entity {
  constructor(musicianGroupJson: MusicianGroup_Entity) {
    try {
      this.id = musicianGroupJson.id;
      this.musicianID = musicianGroupJson.musicianID;
      this.groupID = musicianGroupJson.groupID;
      this.createdAt = musicianGroupJson.createdAt;
      this.updatedAt = musicianGroupJson.updatedAt;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  musicianID: number;
  groupID: number;
  createdAt: string;
  updatedAt: string;
}
