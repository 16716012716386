import moment from "moment";
import MusicianHoliday_Entity from "./musicianHoliday";
export default class MusicianHoliday extends MusicianHoliday_Entity {
  static fromList(musicianHolidaysJSON: unknown): Array<MusicianHoliday> {
    const musicianHolidays: MusicianHoliday[] = [];
    if (musicianHolidaysJSON)
      Array.isArray(musicianHolidaysJSON) &&
        musicianHolidaysJSON.forEach((musicianHolidayJSON) => {
          musicianHolidays.push(new MusicianHoliday(musicianHolidayJSON));
        });
    return musicianHolidays;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  from() {
    return moment(this.dateFromUTC.split("T")[0]).startOf("day");
  }

  to() {
    return moment(this.dateToUTC.split("T")[0]).endOf("day");
  }

  formattedDateRange() {
    const from = this.from();
    const to = this.to();

    if (from.isSame(to, "day")) return from.format("MMM Do YYYY");

    return `from ${from.format("MMM Do")} to ${to.format("MMM Do YYYY")}${
      this.kind ? ` (${this.kind})` : ""
    }`;
  }
}
