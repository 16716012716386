import { Badge, Chip, CircularProgress, Typography } from "@mui/joy";
import { Alert, Box, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import DialogClose from "atoms/DialogClose/DialogClose";
// material-ui
// custom
import { REACT_APP_API, REACT_APP_API_PREFIX, REACT_APP_ID } from "config";
// routes
// hooks
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
// styles
import { Sheet } from "@mui/joy";
import Id from "atoms/Id/Id";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectedEmailIDSelector,
  setFormOpen,
  setSelectedEmailID,
} from "reducers/rhapsody";
import { formOpenSelector } from "reducers/rhapsody";
import { useForwardEmailMutation } from "redux/email/emailEndpoints";
import { useTrails } from "redux/trail/trailHooks";
import { useMusician, useMusicians } from "redux/musician/musicianHooks";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useEmail } from "redux/rhapsodyEmails/emailHooks";

function Forward({ id }) {
  const { email } = useEmail(id);
  const open = useSelector(formOpenSelector("forward"));
  const [to, setTo] = React.useState("");
  const [cc, setCc] = React.useState("");
  const [bcc, setBcc] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [forwardEmail, { isSuccess }] = useForwardEmailMutation();
  const dispatch = useDispatch();
  const askQuestion = useAskQuestion();

  useEffect(() => {
    return () => {
      dispatch(setSelectedEmailID());
    };
  }, []);

  useEffect(() => {
    if (isSuccess)
      askQuestion("Success", ["OK"], {
        subtitle: <Typography>Email has been forwarded.</Typography>,
      });
  }, [isSuccess]);

  const onClose = () =>
    dispatch(setFormOpen({ isOpen: false, formID: "forward" }));

  const send = async () => {
    onClose();

    const recipients = [];
    let _tmp = to.split(",");
    _tmp.forEach((e) =>
      recipients.push({
        emailAddress: e.trim(),
      })
    );

    _tmp = cc.split(",");
    if (_tmp && _tmp[0] !== "") {
      _tmp.forEach((e) =>
        recipients.push({
          emailAddress: e.trim(),
          cc: true,
        })
      );
    }

    _tmp = bcc.split(",");
    if (_tmp && _tmp[0] !== "") {
      _tmp.forEach((e) =>
        recipients.push({
          emailAddress: e.trim(),
          bcc: true,
        })
      );
    }

    forwardEmail({
      id: email.id,
      body: {
        recipients,
        subject,
      },
    });
  };

  return (
    <DialogClose
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogContent>
        <Typography level="h6">Send Email</Typography>
        <Typography level="caption" color="textSecondary">
          Multiple Recipients: Use the comma separator between email addresses.
        </Typography>
        <Grid container spacing={1} style={{ marginTop: 8 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="standard-basic"
              label="To:"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Cc:"
              value={cc}
              onChange={(e) => setCc(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Bcc:"
              value={bcc}
              onChange={(e) => setBcc(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={send} autoFocus variant="contained">
          Send
        </Button>
      </DialogActions>
    </DialogClose>
  );
}

export default function EmailPage({ id }) {
  const [anchorEl, setAnchorEl] = useState();
  const emailID = id || useSelector(selectedEmailIDSelector);
  const { email } = useEmail(emailID);
  const { trails } = useTrails(emailID);
  const dispatch = useDispatch();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const navigate = useNavigate();
  const isEmailsPage = pathnames.indexOf("emails") >= 0;
  const { musicians } = useMusicians();
  const readOnly = useReadOnly();
  const [key, setKey] = useState(1);
  const iframeRef = useRef();

  const sent = email?.sent;

  useEffect(() => {
    return () => {
      if (isEmailsPage)
        navigate(`${RouterConfig.emails}${location.search ?? ""}`);
    };
  }, []);

  if (!email)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  const getHeader = () => (
    <Sheet
      variant="soft"
      sx={{
        padding: 1,
        background: "white",
        display: "flex",
        gap: 1,
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Id startDecorator={<i className="fa-solid fa-envelope"></i>}>
            {email.id}
          </Id>
          <Typography level="body2">
            Created on {moment(email.createdAt).format("lll")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Tooltip
            title={
              !sent
                ? `${email.failCount} attempt${email.failCount > 1 ? "s" : ""}`
                : "Email Successfully Sent!"
            }
          >
            <Badge badgeContent={email.failCount} color="danger" size="sm">
              <Chip
                size="sm"
                color={sent ? "success" : "warning"}
                variant="soft"
                startDecorator={
                  sent ? (
                    <i className="fa-solid fa-check"></i>
                  ) : (
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                  )
                }
              >
                {sent ? "Sent" : "Pending"}
              </Chip>
            </Badge>
          </Tooltip>
          <Badge size="sm" color="danger" badgeContent={trails?.length ?? 0}>
            <div onClick={(e) => setAnchorEl(e.currentTarget)}>
              <Chip
                size="sm"
                startDecorator={<i className="fa-solid fa-eye"></i>}
                variant="solid"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                Views
              </Chip>
            </div>
          </Badge>
          <Chip
            size="sm"
            endDecorator={<i className="fa-solid fa-share"></i>}
            variant="solid"
            onClick={(e) =>
              dispatch(setFormOpen({ isOpen: true, formID: "forward" }))
            }
          >
            Forward
          </Chip>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item style={{ width: 92 }}>
          <Typography level="body2" color="textSecondary">
            From:
          </Typography>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Typography level="body2">
            {email.sentFromName} ({email.sentFromEmail})
          </Typography>
        </Grid>
      </Grid>
      {to.length ? (
        <Grid container spacing={2}>
          <Grid item style={{ width: 92 }}>
            <Typography level="body2" color="textSecondary">
              To:
            </Typography>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Typography level="body2">
              {to.map((t, i) => {
                const musician = musicians.find((m) => m.email === t);
                return (
                  <Typography
                    startDecorator={
                      musician ? (
                        <MusicianAvatar musician={musician} size={24} />
                      ) : undefined
                    }
                    key={t}
                    level="body2"
                    color="textSecondary"
                  >
                    {musician ? `${musician.fullName()} (${t})` : `${t}`}
                  </Typography>
                );
              })}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        []
      )}
      {cc.length ? (
        <Grid container spacing={2}>
          <Grid item style={{ width: 92 }}>
            <Typography level="body2" color="textSecondary">
              Cc:
            </Typography>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Typography level="body2">
              {cc.map((t, i) => (
                <Typography key={t} level="">
                  {t}
                </Typography>
              ))}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        []
      )}
      {bcc.length ? (
        <Grid container spacing={2}>
          <Grid item style={{ width: 92 }}>
            <Typography level="body2" color="textSecondary">
              Bcc:
            </Typography>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Typography level="body2">
              {bcc.map((t, i) => (
                <Chip key={t} size="sm" variant="outlined">
                  {t}
                </Chip>
              ))}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        []
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item style={{ width: 92 }}>
          <Typography level="body2" color="textSecondary">
            Subject:
          </Typography>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Typography level="body2">
            <b>{email.subject}</b>
          </Typography>
        </Grid>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Chip
          sx={{ color: "#fb8c00" }}
          size="sm"
          color="warning"
          variant="plain"
          onClick={() => {
            fetch(
              `${REACT_APP_API}${REACT_APP_API_PREFIX}/rhapsody/repairs/email/${email.id}`,
              {
                credentials: "include",
                headers: {
                  "content-type": "application/json",
                  "accept-encoding": "gzip, deflate",
                  "app-id": REACT_APP_ID,
                },
                method: "GET",
              }
            ).then(() => setKey((i) => i + 1));
          }}
        >
          Can’t see the email?
        </Chip>
      </Grid>
      {email.attachments && email.attachments.length ? (
        <Grid container spacing={1} style={{ marginTop: 16 }}>
          {email.attachments.map((f, i) => (
            <div onClick={() => window.open(f.url, "_blank")} key={f.id}>
              <Chip
                onClick={() => window.open(f.url, "_blank")}
                key={f.id}
                startDecorator={<i className="fa-solid fa-paperclip"></i>}
                size="sm"
                variant="solid"
              >
                {f.name}
              </Chip>
            </div>
          ))}
        </Grid>
      ) : (
        []
      )}
      <Popover
        id={Boolean(anchorEl) ? "simple-popper" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setAnchorEl()}
      >
        <Paper
          style={{
            padding: 20,
            maxHeight: 600,
            overflow: "auto",
            width: 360,
          }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <Alert severity="info">
                The following is not a proof of delivery. It shows the list
                devices that opened the email. It could be the final user or an
                anti-spam or other proxy engines before the delivery.
              </Alert>
            </Grid>
            {trails &&
              trails.map((t, i) => {
                return (
                  <Grid item xs={12} key={t.id}>
                    <Chip size="sm" color="neutral">
                      {t.remoteIP}
                    </Chip>
                    <Typography display="block" variant="body2">
                      {moment(t.createdAt).format("lll")}
                    </Typography>
                    <Typography display="block" variant="body2">
                      {t.userAgent}
                    </Typography>
                    {Number(i) < trails.length - 1 ? <Divider /> : []}
                  </Grid>
                );
              })}
          </Grid>
        </Paper>
      </Popover>
    </Sheet>
  );

  const to = [];
  const cc = [];
  const bcc = [];

  email &&
    email.recipients &&
    email.recipients.forEach((e) => {
      if (e.cc) {
        cc.push(e?.emailAddress);
      } else if (e.bcc) {
        bcc.push(e?.emailAddress);
      } else {
        to.push(e?.emailAddress);
      }
    });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "stretch",
        flex: 1,
      }}
    >
      <Box>{getHeader()}</Box>
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          display: "flex",
          position: "relative",
        }}
      >
        {email ? (
          <iframe
            key={key}
            title="Email Preview"
            ref={iframeRef}
            src={`${REACT_APP_API}${REACT_APP_API_PREFIX}/rhapsody/companies/current/emails/${email.id}/HTML`}
            style={{ flex: 1, border: "none" }}
          ></iframe>
        ) : (
          []
        )}
        {readOnly ? (
          <Box
            sx={{
              // background: "rgba(0,0,0,05)",
              position: "absolute",
              top: 0,
              height: "100%",
              width: "100%",
            }}
          />
        ) : (
          []
        )}
      </Box>
      <Forward id={emailID} />
    </Box>
  );
}
