import {
  Box,
  Checkbox,
  Link,
  List,
  ListItemButton,
  Sheet,
  Switch,
  Tooltip,
  Typography,
} from "@mui/joy";
import { alpha } from "@mui/material";
import { useSelector } from "react-redux";
import {
  chairIDsHoveredSelector,
  layoutDebugSelector,
  layoutUtilsSelector,
  selectionSelector,
  setSelectedWorkSessionIDs,
} from "reducers/v2/missionControl";
import { EditButton, SoloButton, oneTrue, useSoloWorkSession } from "./utils";
import { WorkSession } from "entities/workSession";
import { useMissionControlModeTint } from "hooks/Layout/v2";
import {
  missionControlModeSelector,
  missionControlViewModeSelector,
  setActionInitiator,
  setFormOpen,
  setSelectedVenueID,
  setSelectedWorkSessionID,
} from "reducers/rhapsody";
import { useDispatch } from "react-redux";

/**
 *
 * @returns {ReactElement} WorkSessionsSelect page
 */
export function WorkSessionsSelect({ hideEdit }: { hideEdit?: boolean }) {
  const utils = useSelector(layoutUtilsSelector);
  const handleSoloWorkSession = useSoloWorkSession();
  const dispatch = useDispatch();
  const { selectedWorkSessionIDs } = useSelector(selectionSelector);
  const { workSessions } = utils;
  const missionControlViewMode = useSelector(missionControlViewModeSelector);
  const hasOneWorkSessionSelected = oneTrue(selectedWorkSessionIDs);

  const handleSelectWorkSession = (id: number) => {
    const currentValue = selectedWorkSessionIDs[id] ?? false;
    const nextValue = !currentValue;
    dispatch(setActionInitiator("workSession"));

    if (!(hasOneWorkSessionSelected && currentValue)) {
      // dispatch(layoutUnselectAll());
      dispatch(
        setSelectedWorkSessionIDs({
          ...selectedWorkSessionIDs,
          [id]: nextValue,
        })
      );
    }
  };

  return (
    <List size="sm" sx={{ p: 0 }}>
      {workSessions.map((w) => (
        <WorkSessionListItemButton
          toggle
          onSolo={() => handleSoloWorkSession(w.id)}
          onClick={() => handleSelectWorkSession(w.id)}
          onEdit={
            !hideEdit
              ? () => {
                  dispatch(setSelectedWorkSessionID(w.id));
                  dispatch(
                    setFormOpen({ formID: "workSessionEdit", isOpen: true })
                  );
                }
              : undefined
          }
          disableCheck={
            hasOneWorkSessionSelected && selectedWorkSessionIDs[w.id]
          }
          disabled={missionControlViewMode === "workSessions"}
          key={w.id}
          selected={selectedWorkSessionIDs[w.id]}
          workSession={w}
        />
      ))}
    </List>
  );
}

function WorkSessionListItemButton({
  selected,
  workSession,
  disableCheck,
  onClick,
  onEdit,
  onSolo,
  disabled,
  toggle,
}: {
  selected: boolean;
  workSession: WorkSession;
  disableCheck?: boolean;
  onClick: () => void;
  onEdit?: () => void;
  onSolo: () => void;
  disabled?: boolean;
  toggle?: boolean;
}) {
  const debug = useSelector(layoutDebugSelector);
  const chairIDsHovered = useSelector(chairIDsHoveredSelector);
  const missionControlModeTint = useMissionControlModeTint();
  const missionControlNode = useSelector(missionControlModeSelector);
  const utils = useSelector(layoutUtilsSelector);
  const dispatch = useDispatch();
  let CheckComponent = Checkbox;
  if (toggle) CheckComponent = Switch;

  let variant: "soft" | undefined;
  chairIDsHovered?.forEach((c) => {
    const _chair = utils.chairsMap[c];
    if (_chair?.workSessionIDs.includes(workSession.id)) {
      variant = "soft";
      return;
    }
  });

  return (
    <ListItemButton
      sx={{
        gap: 1,
        justifyContent: "space-between",
        cursor: disableCheck || disabled ? "not-allowed" : undefined,
        transition: "background .2s",
      }}
      selected={selected}
      onClick={() => {
        if (!disableCheck && !disabled) {
          onClick();
        }
      }}
      variant={variant}
      onDoubleClick={onEdit}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <CheckComponent
          disabled={disabled}
          variant={disableCheck ? "soft" : undefined}
          color={toggle ? undefined : "primary"}
          size="sm"
          sx={{ opacity: disabled ? 0.4 : 1 }}
          checked={selected ?? false}
        />
        <Box>
          <Typography
            endDecorator={
              debug ? (
                <Typography level="body5">{workSession.id}</Typography>
              ) : undefined
            }
            sx={{
              fontWeight: "normal",
              color: selected ? missionControlModeTint : undefined,
            }}
            level="body2"
          >
            {workSession.title}
          </Typography>
          <Typography
            sx={{
              fontWeight: "normal",
              color: selected ? missionControlModeTint : undefined,
            }}
            level="body3"
          >
            {workSession.formatDateRange()}
            <Link
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                dispatch(setSelectedVenueID(workSession.venueID));
              }}
              sx={{ pl: 0.5 }}
              level="body3"
              color="neutral"
            >
              @{workSession.studioName}
            </Link>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 0.5 }}>
        {missionControlNode === "edit" && onEdit ? (
          <EditButton onClick={onEdit} />
        ) : (
          []
        )}

        {onSolo ? (
          <Box
            sx={{
              opacity: disabled ? 0.4 : 1,
              cursor: disableCheck || disabled ? "not-allowed" : undefined,
              pointerEvents: disableCheck || disabled ? "none" : undefined,
            }}
          >
            <SoloButton onClick={!disabled && onSolo} />
          </Box>
        ) : (
          []
        )}
      </Box>
    </ListItemButton>
  );
}
