import { Button, Input, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { mapToArray } from "helpers";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useImportProject } from "hooks/ProjectImport/ProjectImport";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useProjectContacts } from "redux/projectContact/projectContactHooks";
import { useProjectRosters } from "redux/projectRoster/projectRosterHooks";
import { useSubRules } from "redux/subRule/subRuleHooks";
import { useVenues } from "redux/venue/venueHooks";

/**
 *
 * @returns {ReactElement} useDuplicateProject page
 */
export function DuplicateProjectForm() {
  const open = useSelector(formOpenSelector("duplicateProject"));
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "duplicateProject" }));
  };
  const utils = useSelector(layoutUtilsSelector);
  const {
    project,
    links,
    projectNotes,
    chairs,
    workSessions,
    musiciansMap,
    projectPieces,
    workSessionProjectPieces,
    piecesMap,
    assignmentsMap,
  } = utils;
  const [projectName, setProjectName] = useState(`COPY - ${project?.name}`);
  const projectID = useSelector(selectedProjectIDSelector);
  const navigate = useNavigate();
  const { projectRosters } = useProjectRosters(projectID);
  const { projectContacts } = useProjectContacts(projectID);
  const { subRules } = useSubRules({
    filters: JSON.stringify([
      {
        name: "sub_rules.projectID",
        comparison: "eq",
        value: projectID,
      },
    ]),
  });

  const venueIDs = utils.workSessions.reduce((a, v) => {
    if (!a.includes(v.venueID)) a.push(v.venueID);
    return a;
  }, []);

  const { venues } = useVenues();
  const { importNow, loading, loadingState } = useImportProject();

  const content = {
    projectName,
    project,
    workSessions: workSessions,
    chairs: chairs,
    musicians: mapToArray(musiciansMap),
    projectRosters,
    projectPieces: projectPieces,
    workSessionProjectPieces: workSessionProjectPieces,
    pieces: mapToArray(piecesMap),
    assignments: mapToArray(assignmentsMap),
    projectContacts,
    links,
    projectNotes,
    subRules,
    venues: venues.filter((v) => venueIDs.includes(v.id)),
  };

  return (
    <DialogClose onClose={onClose} open={open}>
      <DialogTitle>
        Duplicate Project
        <Typography level="body3">
          This action will create an exact copy of this Project including its
          Pieces, Work Sessions, Links, Notes, Musicians, Live Rosters, and the
          Who's next rules. Musicians will be on the "Ready" stage.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontWeight: 600 }}>
          How would you like to name the duplicated Project?
        </Typography>
        <Input
          autoFocus
          onChange={(e) => setProjectName(e.target.value)}
          value={projectName}
          startDecorator="Name:"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={loading}
          onClick={async () => {
            const _project = await importNow(content);
            onClose();
            navigate(
              `${
                _project.template
                  ? RouterConfig.templates
                  : RouterConfig.projects
              }/${_project.id}/mission-control`
            );
          }}
        >
          Duplicate
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
