/*global google*/

import { Autocomplete, AutocompleteProps, Box, ListItemButton } from "@mui/joy";
import { Typography } from "@mui/material";
import {
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { parsePlace } from "./maps";

type PlaceResult = {
  address?: string;
  zipcode?: string;
  city?: string;
  state?: string;
  formattedAddress?: string;
  placeID?: string;
  latitude?: number;
  longitude?: number;
  tzName?: string;
};

/**
 *
 * @returns {ReactElement} googlePlaceSuggestions page
 */
export function GooglePlaceSuggestions({
  onSelect,
  value,
}: {
  onSelect: (e: PlaceResult) => void;
  value?: string;
  autoCompleteProps?: Omit<
    AutocompleteProps<
      google.maps.places.AutocompletePrediction,
      false,
      false,
      true
    >,
    "options"
  >;
}) {
  const [loadingMapsLocations, setLoadingMapsLocations] = useState(true);

  const googleMapsGeocoder = new google.maps.Geocoder();

  const [googleMapsPredictions, setGoogleMapsPredictions] = useState<
    google.maps.places.AutocompletePrediction[] | null
  >(null);
  const [locationInputValue, setLocationInputValue] = useState(value ?? "");
  const googleMapsAutocompleteService =
    new google.maps.places.AutocompleteService();

  const [location, setLocation] =
    useState<google.maps.places.AutocompletePrediction>();

  const getGoogleAddresses = (searchText: string) => {
    googleMapsAutocompleteService.getPlacePredictions(
      {
        input: searchText,
        componentRestrictions: { country: "us" },
      },
      (googleSuggestions) => setGoogleMapsPredictions(googleSuggestions)
    );
  };

  const handleValueChange = (
    _: SyntheticEvent<Element, Event>,
    newValue: string | google.maps.places.AutocompletePrediction | null
  ) => {
    if (typeof newValue === "string") return;
    if (newValue) {
      googleMapsGeocoder.geocode(
        { address: newValue?.description },
        async (results) => {
          if (results?.length) {
            const place = parsePlace(results[0]);

            const query = `https://maps.googleapis.com/maps/api/timezone/json?location=${place.latitude},${place.longitude}&timestamp=1458000000&key=AIzaSyDaY4YObbcCFiWtGQYU3UbLdiEszNv40uQ`;
            const r = await fetch(query, {
              method: "GET",
            });

            const j = await r.json();

            onSelect({
              address: place.line1,
              zipcode: place.zip,
              state: place.state,
              city: place.city,
              formattedAddress: place.formattedAddress,
              placeID: place.placeId,
              latitude: place.latitude,
              longitude: place.longitude,
              tzName: j.timeZoneId,
            });
          }
        }
      );
      setLocation(newValue);
    }
  };

  useEffect(() => {
    setLocationInputValue(value ?? "");
  }, [value]);

  return (
    <Autocomplete
      placeholder="Autofill address with Google Maps"
      color="neutral"
      slotProps={{
        input: {
          style: { textAlign: "center" },
        },
        root: {
          sx: {
            boxShadow: "none",
          },
        },
        listbox: {
          sx: {
            zIndex: (theme) => theme.zIndex.modal,
          },
        },
      }}
      variant="outlined"
      options={googleMapsPredictions ?? []}
      value={location as any}
      onChange={(e, v) => {
        handleValueChange(
          e,
          v as any as google.maps.places.AutocompletePrediction
        );
        setLocationInputValue("");
      }}
      inputValue={locationInputValue}
      freeSolo
      onInputChange={(_e, v) => {
        setLocationInputValue(v);
        getGoogleAddresses(v);
      }}
      getOptionLabel={(option) => {
        if (typeof option != "string") {
          return option.structured_formatting.main_text;
        } else return option;
      }}
      renderOption={(props, c) => {
        const _c = c as google.maps.places.AutocompletePrediction;
        return (
          <LocationOption
            props={props}
            c={_c}
            key={_c.place_id}
            cellInput={false}
          />
        );
      }}
    />
  );
}

/**
 *
 * @param {LocationOptionProps} params Component params
 * @returns {ReactElement} LocationOption
 */
function LocationOption(params: LocationOptionProps): ReactElement {
  const { c, cellInput, props } = params;

  /**
   *
   * @param {PropsWithChildren} _props props
   * @returns {ReactElement} Wrapper
   */
  function Wrapper(_props: PropsWithChildren): ReactElement {
    return (
      <Box component="li" {...props}>
        <ListItemButton>{_props.children}</ListItemButton>
      </Box>
    );
  }

  return (
    <Wrapper>
      <Box>
        <Typography variant="body2">
          {c.structured_formatting.main_text}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography variant="caption">
            {c.structured_formatting.secondary_text}
          </Typography>
        </Box>
      </Box>
    </Wrapper>
  );
}

type LocationOptionProps =
  | {
      props: HTMLAttributes<HTMLLIElement>;
      c: google.maps.places.AutocompletePrediction;
      cellInput: true;
    }
  | {
      props: Omit<HTMLAttributes<HTMLLIElement>, "color">;
      c: google.maps.places.AutocompletePrediction;
      cellInput: false;
    };
