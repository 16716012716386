import { Box, Button, IconButton, Sheet, Typography } from "@mui/joy";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useDispatch } from "react-redux";
import { setFormOpen, setSelectedImportID } from "reducers/rhapsody";
import { useFileImports } from "redux/fileImport/fileImportHooks";

/**
 *
 * @returns {ReactElement} Imports page
 */
export function Imports() {
  const dispatch = useDispatch();
  const { fileImports } = useFileImports();

  return (
    <Sheet
      variant="soft"
      sx={{
        p: 2,
        maxWidth: 600,
        margin: "auto",
        display: "flex",
        gap: 2,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography level="h6">Imports</Typography>
          <Typography level="body2">
            Securely upload sensitive import documents to the Rhapsody team.
          </Typography>
        </Box>
        <Button
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "fileImport" }))
          }
          size="sm"
        >
          Upload New
        </Button>
      </Box>
      <DataGridPremium
        sx={{ background: "white" }}
        autoHeight
        onRowClick={(p) => {
          dispatch(setSelectedImportID(p.row.id));
          dispatch(setFormOpen({ isOpen: true, formID: "fileImport" }));
        }}
        hideFooter
        density="compact"
        initialState={{
          pinnedColumns: { right: ["actions"] },
        }}
        columns={[
          {
            field: "notes",
            headerName: "Name",
            flex: 1,
          },
          {
            field: "file",
            headerName: "Download",
            align: "right",
            renderCell: (r) => (
              <IconButton
                size="sm"
                color="neutral"
                onClick={() => window.open(r.row.file)}
              >
                <i className="fa-solid fa-file-arrow-down"></i>
              </IconButton>
            ),
          },
        ]}
        rows={fileImports}
      />
    </Sheet>
  );
}
