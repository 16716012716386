import FileImport_Entity from "./fileImport";
export default class FileImport extends FileImport_Entity {
  static fromList(fileImportsJSON: unknown): Array<FileImport> {
    const fileImports: FileImport[] = [];
    if (fileImportsJSON)
      Array.isArray(fileImportsJSON) &&
        fileImportsJSON.forEach((fileImportJSON) => {
          fileImports.push(new FileImport(fileImportJSON));
        });
    return fileImports;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
