import {
  Box,
  Button,
  IconButton,
  Input,
  List,
  ListItemButton,
  Tooltip,
} from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";

/**
 *
 * @returns {ReactElement} SearchTooltip page
 */
export function SearchTooltip<T extends { id?: number }>({
  items,
  onSelect,
  selectedIDs = [],
  children,
  height,
  maxHeight,
  autoOk,
  getLabel,
}: {
  items: T[];
  onSelect: (i: number[] | null) => void;
  selectedIDs?: number[];
  children: ReactElement;
  autoOk?: boolean;
  height?: number;
  maxHeight?: number;
  getLabel: (a: T) => string;
}) {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [index, setIndex] = useState(0);
  const [ids, setIds] = useState<number[]>([]);

  const handleOnSelect = () => {
    onSelect(ids);
    setOpen(false);
  };

  useEffect(() => {
    if (selectedIDs) setIds(selectedIDs);
  }, []);

  useEffect(() => {
    if (ids && autoOk) handleOnSelect();
  }, [ids]);

  useEffect(() => {
    if (selectedIDs?.length && open) {
      setIndex(-1);
    } else {
      setIndex(0);
    }
  }, [open]);

  let _items = [...items];

  if (searchText)
    _items = _items.filter((i) =>
      getLabel(i).toLowerCase().includes(searchText.toLowerCase())
    );

  if (index === -1) _items = _items.filter((i) => ids.includes(i.id));

  return (
    <Tooltip
      arrow
      open={open}
      color="neutral"
      variant="outlined"
      title={
        <ClickAwayListener
          onClickAway={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(false);
          }}
        >
          <Box
            sx={{
              width: 400,
              height,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
              variant="soft"
              size="sm"
              autoFocus
              placeholder="Search"
              endDecorator={
                <IconButton
                  tabIndex={-1}
                  onClick={() => setSearchText("")}
                  variant="plain"
                  color="neutral"
                >
                  <i className="fa-solid fa-xmark"></i>
                </IconButton>
              }
            />
            {selectedIDs?.length ? (
              <Box sx={{ pt: 1, pb: 1, display: "flex", gap: 1 }}>
                <Button
                  tabIndex={-1}
                  size="sm"
                  onClick={() => setIndex(-1)}
                  sx={{ flexShrink: 0 }}
                  color={index === -1 ? "primary" : "neutral"}
                  variant={index === -1 ? "solid" : "soft"}
                  key={-1}
                >
                  Selected
                </Button>
                <Button
                  tabIndex={-1}
                  size="sm"
                  onClick={() => setIndex(0)}
                  sx={{ flexShrink: 0 }}
                  color={index === 0 ? "primary" : "neutral"}
                  variant={index === 0 ? "solid" : "soft"}
                  key={-1}
                >
                  All
                </Button>
              </Box>
            ) : (
              []
            )}
            <List
              sx={{ maxHeight: maxHeight ?? 400, overflowY: "auto" }}
              size="sm"
            >
              {_items.map((e) => {
                const selected = ids.includes(e.id);
                return (
                  <ListItemButton
                    selected={selected}
                    variant={selected ? "soft" : undefined}
                    onClick={() => {
                      if (selected) {
                        setIds((s) => s.filter((r) => r !== e.id));
                      } else {
                        setIds((s) => [...s, e.id]);
                      }
                    }}
                    key={e.id}
                  >
                    {getLabel(e)}
                  </ListItemButton>
                );
              })}
            </List>
            {index === -1 ? (
              <Button
                color="neutral"
                onClick={() => {
                  setIds([]);
                  onSelect([]);
                  setOpen(false);
                  setIndex(0);
                }}
              >
                Clear All
              </Button>
            ) : (
              <Button onClick={handleOnSelect}>Add</Button>
            )}
          </Box>
        </ClickAwayListener>
      }
    >
      <Box onClick={() => setOpen(true)}>{children}</Box>
    </Tooltip>
  );
}
