import { CircularProgress, Input, Sheet, Textarea, Typography } from "@mui/joy";
import { Grid } from "@mui/material";
import { Piece_Entity } from "entities/piece";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectedPieceIDSelector } from "reducers/rhapsody";
import { useUpdatePieceMutation } from "redux/piece/pieceEndpoints";
import { usePiece } from "redux/piece/pieceHooks";

export default function PieceGeneral({ id }) {
  const params = useParams();
  const pieceID =
    id || parseInt(params.pieceID) || useSelector(selectedPieceIDSelector);
  const { piece, isLoading } = usePiece(pieceID);
  const [updatePiece, { isLoading: isUpdating }] = useUpdatePieceMutation();

  const [_piece, _setPiece] = useState<Piece_Entity | undefined>();

  useEffect(() => {
    _setPiece(new Piece_Entity(piece));
  }, [piece]);

  const save = async (name: string, numeric?: boolean) => {
    if (piece[name] === _piece[name]) return;
    updatePiece({
      id: piece.id,
      body: {
        [name]: numeric ? Number(_piece[name]) : _piece[name],
      },
    });
  };

  if (!_piece) return <CircularProgress />;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", gap: 1, flexDirection: "column" }}
      >
        <Sheet
          variant="soft"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            p: 2,
            borderRadius: "8px",
          }}
        >
          <Input
            startDecorator="Name:"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("name");
            }}
            fullWidth
            onBlur={() => save("name")}
            value={_piece.name || ""}
            onChange={(e) =>
              _setPiece((p) => {
                p.name = e.target.value;
                return { ...p };
              })
            }
          />
          <Input
            startDecorator="Composer:"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("composer");
            }}
            fullWidth
            onBlur={() => save("composer")}
            value={_piece.composer || ""}
            onChange={(e) =>
              _setPiece((p) => {
                p.composer = e.target.value;
                return { ...p };
              })
            }
          />
          <Input
            startDecorator="Arranger:"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("arranger");
            }}
            fullWidth
            onBlur={() => save("arranger")}
            value={_piece.arranger || ""}
            onChange={(e) =>
              _setPiece((p) => {
                p.arranger = e.target.value;
                return { ...p };
              })
            }
          />
          <Input
            startDecorator="Edition:"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("edition");
            }}
            fullWidth
            onBlur={() => save("edition")}
            value={_piece.edition || ""}
            onChange={(e) =>
              _setPiece((p) => {
                p.edition = e.target.value;
                return { ...p };
              })
            }
          />
        </Sheet>
      </Grid>
      <Grid item xs={12} md={6}>
        <Textarea
          placeholder="Anything to write down about this Piece?"
          sx={{ width: "100%", background: "rgba(255,235,59,0.1)" }}
          onKeyPress={(e) => {
            if (e.key === "Enter") save("notes");
          }}
          onBlur={() => save("notes")}
          value={_piece?.notes ?? ""}
          onChange={(e) =>
            _setPiece((p) => {
              p.notes = e.target.value;
              return { ...p };
            })
          }
          size="sm"
          endDecorator={
            <Typography level="body4">Not visible by musicians</Typography>
          }
        />
      </Grid>
    </Grid>
  );
}
