import { onUnreadCountChange, show } from "@intercom/messenger-js-sdk";
import { Badge, Sheet, Tooltip } from "@mui/joy";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import { useEffect, useState } from "react";

/**
 *
 * @returns {ReactElement} IntercomButton page
 */
export function IntercomButton() {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [messageCount, setMessageCount] = useState(0);

  useEffect(() => {
    onUnreadCountChange((unreadCount: number) => {
      setMessageCount(unreadCount);
    });
  }, []);

  if (!currentUser) return null;

  return (
    <Tooltip
      title="Need help?"
      enterDelay={0.2}
      size="sm"
      arrow
      variant="outlined"
      placement="left"
    >
      <Sheet
        color="primary"
        variant="solid"
        onClick={show}
        sx={{
          position: "fixed",
          cursor: "pointer",
          bottom: "0px",
          right: "0px",
          width: 34,
          height: 34,

          borderTopLeftRadius: "12px",
          display: "flex",
          background: "#ffc107",
          alignItems: "center",
          justifyContent: "center",
          transformOrigin: "right bottom",
          transition: `transform 167ms cubic-bezier(0.33, 0, 0, 1)`,
          "&:hover": {
            transform: "scale(1.4)",
          },
        }}
      >
        <Badge
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          color="danger"
          size="sm"
          badgeInset={-6}
          badgeContent={messageCount}
        >
          <i
            style={{ fontSize: 21, color: "#4E2E00" }}
            className="fa-brands fa-intercom"
          />
        </Badge>
      </Sheet>
    </Tooltip>
  );
}
