import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Input,
  List,
  ListItemButton,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Box, ClickAwayListener, Paper, Snackbar } from "@mui/material";
import { Section } from "entities/section";
import { actionIcons, actionLabel } from "features/chair/useChairActions";
import { CHAIR_WIDTH } from "features/chair/v2/position";
import { useUpdater } from "features/chair/v2/useUpdater";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { InstrumentsTooltip } from "features/musicians/InstrumentsPopover/InstrumentsTooltip";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormID,
  formOpenMapSelector,
  missionControlViewModeSelector,
  setChairAccessorial,
  setFormOpen,
} from "reducers/rhapsody";
import {
  layoutDebugSelector,
  layoutInternalSelector,
  layoutUnselectAll,
  layoutUtilsSelector,
  positionsSelectedSelector,
  selectionSelector,
  setAttendanceAssignmentIDs,
  setAttendanceWorkSessionID,
} from "reducers/v2/missionControl";
import { useUpdateCurrentCompanyMutation } from "redux/company/companyEndpoints";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";

export default function GroupActions() {
  const selecto = useSelector(positionsSelectedSelector);
  const selection = useSelector(selectionSelector);
  const { sectionRoles } = useSectionRoles();
  const layoutUtils = useSelector(layoutUtilsSelector);
  const [chairMemo, setChairMemo] = useState("");
  const missionControlViewMode = useSelector(missionControlViewModeSelector);
  const [musicianMemo, setMusicianMemo] = useState("");
  const internal = useSelector(layoutInternalSelector);
  const debug = useSelector(layoutDebugSelector);

  const {
    emptyPositions,
    positionsWithEmptyChairs,
    visibleChairs,
    visibleAssignmentIDs,
    visibleMusicians,
    removableMusicians,
    positions,
    releasableMusicians,
    chairMemos,
    assignmentMemos,
    resetMercuryJobIDs,
    emptyChairs,
  } = selecto;

  const { callSent } = internal;
  const { project } = layoutUtils;

  const updater = useUpdater(
    selecto.positionIDs,
    visibleAssignmentIDs,
    internal,
    layoutUtils
  );

  useEffect(() => {
    setMusicianMemo(assignmentMemos);
  }, [assignmentMemos]);

  useEffect(() => {
    setChairMemo(chairMemos);
  }, [chairMemos]);

  const askQuestion = useAskQuestion();

  const dispatch = useDispatch();
  const exceptionsForDialog: FormID[] = ["positionLookup", "assignmentLookup"];
  const formOpenMap = useSelector(formOpenMapSelector);
  let otherDialogOpened = false;
  for (const key in formOpenMap) {
    if (Object.prototype.hasOwnProperty.call(formOpenMap, key)) {
      const open = formOpenMap[key];
      const k = key as FormID;
      if (k !== "projectSidebar" && !exceptionsForDialog.includes(k) && open)
        otherDialogOpened = true;
    }
  }

  const exceptionChairs = selection.userIsAboutToCreateWorkSessionChairs(
    selecto.positions,
    selecto.positionIDs,
    layoutUtils
  );

  const declinedMusicians = removableMusicians.filter((m) => {
    const assignment = layoutUtils.assignments.find(
      (a) => a.musicianID === m.id
    );
    if (assignment.mercuryStageID === 3) {
      // declined
      return true;
    }
    return false;
  });

  const removeButton = (handler: boolean) => (
    <IconButton
      variant="plain"
      color="neutral"
      disabled={removableMusicians.length === 0 && !debug}
      onClick={handler ? () => updater.handleRemoveMusicans() : undefined}
      sx={{
        flexDirection: "column",
        flexShrink: 0,
        color: "inherit",
      }}
    >
      <i
        style={{ fontSize: 18 }}
        className={actionIcons["remove-musician"]}
      ></i>
      <Typography sx={{ color: "inherit" }} level="body4">
        {actionLabel["remove-musician"]}
      </Typography>
      {removableMusicians.length ? (
        <Typography
          color="primary"
          sx={{
            position: "absolute",
            bottom: -6,
            width: 80,
          }}
          level="body5"
        >
          {removableMusicians.length} musician
          {removableMusicians.length > 1 ? "s" : ""}
        </Typography>
      ) : (
        []
      )}
    </IconButton>
  );

  const content = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        overflowY: "auto",
        p: 2,
        flexGrow: 1,
      }}
    >
      <IconButton
        disabled={positionsWithEmptyChairs.length === 0}
        variant="plain"
        color="neutral"
        sx={{
          flexDirection: "column",
          flexShrink: 0,
          position: "relative",
          color: "inherit",
        }}
        onClick={() =>
          dispatch(setFormOpen({ isOpen: true, formID: "autofill" }))
        }
      >
        <i
          style={{ fontSize: 18 }}
          className="fa-solid fa-wand-magic-sparkles"
        ></i>
        <Typography level="body4" sx={{ lineHeight: 1, color: "inherit" }}>
          Autofill
        </Typography>
        {positionsWithEmptyChairs.length ? (
          <Typography
            color="primary"
            sx={{ position: "absolute", bottom: -6 }}
            level="body5"
          >
            {positionsWithEmptyChairs.length} chair
            {positionsWithEmptyChairs.length > 1 ? "s" : ""}
          </Typography>
        ) : (
          []
        )}
      </IconButton>
      <ContentTooltip
        tooltipContent={(onClose) => {
          return (
            <List size="sm">
              {sectionRoles?.map((role) => (
                <ListItemButton
                  onClick={() => {
                    onClose();
                    updater.handleSectionRoleUpdate(role.id);
                  }}
                  key={role.id}
                >
                  {role.name}
                </ListItemButton>
              ))}
            </List>
          );
        }}
      >
        <IconButton
          variant="plain"
          color="neutral"
          sx={{ flexDirection: "column", flexShrink: 0, color: "inherit" }}
        >
          <i style={{ fontSize: 18 }} className={actionIcons["role"]}></i>
          <Typography sx={{ color: "inherit" }} level="body4">
            {actionLabel["role"]}
          </Typography>
        </IconButton>
      </ContentTooltip>
      <InstrumentsTooltip
        height={600}
        hideTitle
        clearInstruments
        onSelect={(instrumentIDs) =>
          updater.handleInstrumentsUpdate(instrumentIDs)
        }
      >
        <IconButton
          variant="plain"
          color="neutral"
          sx={{ flexDirection: "column", flexShrink: 0, color: "inherit" }}
        >
          <i
            style={{ fontSize: 18 }}
            className={actionIcons["instruments"]}
          ></i>
          <Typography sx={{ color: "inherit" }} level="body4">
            {actionLabel["instruments"]}
          </Typography>
        </IconButton>
      </InstrumentsTooltip>
      <ContentTooltip
        tooltipContent={(onClose) => (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
            <Textarea
              onFocus={(event) => {
                event.target.select();
                setTimeout(() => {
                  event.target.select();
                }, 200);
              }}
              maxRows={4}
              autoFocus
              placeholder="Piece Memo..."
              value={chairMemo}
              onChange={(e) => setChairMemo(e.target.value)}
              size="md"
              sx={{
                fontSize: 11,
                p: 0.5,
                background: "#F2F2F2",
                width: CHAIR_WIDTH,
              }}
              variant="outlined"
              endDecorator={
                <Box
                  sx={{
                    pl: 0.5,
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    level="body4"
                    endDecorator={
                      <Tooltip
                        size="sm"
                        title={
                          <Box style={{ textAlign: "center" }}>
                            The memo is attached to the chair playing the piece.
                            <br />
                            Anyone who sits on it will receive it.
                          </Box>
                        }
                      >
                        <i className="fa-sharp fa-regular fa-circle-info"></i>
                      </Tooltip>
                    }
                  >
                    Piece Memo
                  </Typography>
                  <i
                    onClick={() => {
                      onClose();
                      updater.handleChairMemo("");
                      setChairMemo("");
                      onClose();
                    }}
                    style={{ cursor: "pointer" }}
                    className="fa-solid fa-trash"
                  ></i>
                </Box>
              }
            />
            <Button
              size="sm"
              onClick={() => {
                onClose();
                updater.handleChairMemo(chairMemo);
                setChairMemo("");
                dispatch(
                  setChairAccessorial({
                    chairAccessorial: "chairMemo",
                    visible: true,
                  })
                );
              }}
            >
              Apply
            </Button>
          </Box>
        )}
      >
        <IconButton
          variant="plain"
          color="neutral"
          className="tour-chair-memo"
          sx={{ flexDirection: "column", flexShrink: 0, color: "inherit" }}
        >
          <i style={{ fontSize: 18 }} className={actionIcons["piece-memo"]}></i>
          <Typography sx={{ color: "inherit" }} level="body4">
            {actionLabel["piece-memo"]}
          </Typography>
        </IconButton>
      </ContentTooltip>
      {!project?.template ? (
        <ContentTooltip
          tooltipContent={(onClose) => (
            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Textarea
                maxRows={4}
                onFocus={(event) => {
                  event.target.select();
                  setTimeout(() => {
                    event.target.select();
                  }, 200);
                }}
                autoFocus
                placeholder="Assignment Memo..."
                value={musicianMemo}
                onChange={(e) => setMusicianMemo(e.target.value)}
                size="md"
                sx={{
                  fontSize: 11,
                  p: 0.5,
                  background: "#F2F2F2",
                  width: CHAIR_WIDTH,
                }}
                variant="outlined"
                endDecorator={
                  <Box
                    sx={{
                      pl: 0.5,
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      endDecorator={
                        <Tooltip
                          size="sm"
                          title={
                            <Box style={{ textAlign: "center" }}>
                              The memo is attached to the assignment. <br />
                              It will be included in the call.
                            </Box>
                          }
                        >
                          <i className="fa-sharp fa-regular fa-circle-info"></i>
                        </Tooltip>
                      }
                      level="body4"
                    >
                      Assignment Memo
                    </Typography>
                    <i
                      onClick={() => {
                        updater.handleMusicianMemo("");
                        setMusicianMemo("");
                        onClose();
                      }}
                      style={{ cursor: "pointer" }}
                      className="fa-solid fa-trash"
                    ></i>
                  </Box>
                }
              />
              <Button
                size="sm"
                onClick={() => {
                  updater.handleMusicianMemo(musicianMemo);
                  setMusicianMemo("");
                  onClose();
                  dispatch(
                    setChairAccessorial({
                      chairAccessorial: "musicianMemo",
                      visible: true,
                    })
                  );
                }}
              >
                Apply
              </Button>
            </Box>
          )}
        >
          <IconButton
            disabled={visibleMusicians.length === 0}
            variant="plain"
            color="neutral"
            sx={{
              flexDirection: "column",
              flexShrink: 0,
              position: "relative",
              color: "inherit",
            }}
          >
            <i
              style={{ fontSize: 18 }}
              className={actionIcons["musician-memo"]}
            ></i>
            <Typography level="body4" sx={{ lineHeight: 1, color: "inherit" }}>
              Assignment Memo
            </Typography>
            {visibleMusicians.length ? (
              <Typography
                color="primary"
                sx={{ position: "absolute", bottom: -6 }}
                level="body5"
              >
                {visibleMusicians.length} musician
                {visibleMusicians.length > 1 ? "s" : ""}
              </Typography>
            ) : (
              []
            )}
          </IconButton>
        </ContentTooltip>
      ) : (
        []
      )}
      <MoveTooltip
        onMove={(sectionID) => updater.handleMoveTo(sectionID)}
        disabled={
          !selection.allWorkSessionsSelected ||
          missionControlViewMode === "workSessions"
        }
      >
        <IconButton
          variant="plain"
          color="neutral"
          disabled={
            !selection.allWorkSessionsSelected ||
            missionControlViewMode === "workSessions"
          }
          sx={{ flexDirection: "column", flexShrink: 0, color: "inherit" }}
        >
          <i style={{ fontSize: 18 }} className={actionIcons["section"]}></i>
          <Typography sx={{ color: "inherit" }} level="body4">
            Change Section
          </Typography>
        </IconButton>
      </MoveTooltip>
      <IconButton
        variant="plain"
        color="neutral"
        onClick={() => {
          dispatch(setAttendanceAssignmentIDs(visibleAssignmentIDs));
          dispatch(setAttendanceWorkSessionID(positions[0]?.forWorkSessionID));
          dispatch(setFormOpen({ isOpen: true, formID: "attendance" }));
        }}
        sx={{ flexDirection: "column", flexShrink: 0, color: "inherit" }}
      >
        <i style={{ fontSize: 18 }} className="fa-regular fa-clock-desk"></i>
        <Typography sx={{ color: "inherit" }} level="body4">
          Attendance
        </Typography>
      </IconButton>
      {declinedMusicians?.length &&
      declinedMusicians.length !== removableMusicians.length ? (
        <ContentTooltip
          tooltipContent={(onClose) => {
            return (
              <List size="sm">
                <ListItemButton
                  onClick={() => {
                    onClose();
                    updater.handleRemoveMusicans();
                  }}
                >
                  <Box>
                    All
                    <Typography color="primary" level="body4">
                      {removableMusicians.length} musician
                      {removableMusicians.length > 1 ? "s" : ""}
                    </Typography>
                  </Box>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    onClose();
                    updater.handleRemoveMusicans(true);
                  }}
                >
                  <Box>
                    Declined only
                    <Typography color="primary" level="body4">
                      {declinedMusicians.length} musician
                      {declinedMusicians.length > 1 ? "s" : ""}
                    </Typography>
                  </Box>
                </ListItemButton>
              </List>
            );
          }}
        >
          {removeButton(false)}
        </ContentTooltip>
      ) : (
        removeButton(true)
      )}
      <IconButton
        variant="plain"
        disabled={resetMercuryJobIDs.length === 0}
        color="neutral"
        onClick={() => {
          askQuestion("Warning", ["Cancel", "Got it"], {
            subtitle: (
              <Typography>
                Unconfirmed musicians will be marked as Ready.
              </Typography>
            ),
          }).then((i) => {
            if (i == 1) updater.handleResetStatus(resetMercuryJobIDs);
          });
        }}
        sx={{
          flexDirection: "column",
          flexShrink: 0,
          color: "inherit",
        }}
      >
        <i style={{ fontSize: 18 }} className="fa-solid fa-rotate-left"></i>
        <Typography sx={{ color: "inherit" }} level="body4">
          Reset Status
        </Typography>
        {!(resetMercuryJobIDs.length === 0) ? (
          <Typography
            color="primary"
            sx={{ position: "absolute", bottom: -6 }}
            level="body5"
          >
            {resetMercuryJobIDs.length} musician
            {resetMercuryJobIDs.length > 1 ? "s" : ""}
          </Typography>
        ) : (
          []
        )}
      </IconButton>
      <IconButton
        variant="plain"
        disabled={(emptyPositions.length === 0 || exceptionChairs) && !debug}
        color="neutral"
        onClick={() => {
          askQuestion("Warning", ["Cancel", "Got it"], {
            subtitle: (
              <Typography>Only empty chairs will be deleted.</Typography>
            ),
          }).then((i) => {
            if (i == 1) updater.handleDeleteChair(emptyPositions);
          });
        }}
        sx={{
          flexDirection: "column",
          flexShrink: 0,
          color: "inherit",
        }}
      >
        <i style={{ fontSize: 18 }} className={actionIcons["delete-chair"]}></i>
        <Typography sx={{ color: "inherit" }} level="body4">
          {actionLabel["delete-chair"]}
        </Typography>
        {!(emptyPositions.length === 0 || exceptionChairs) ? (
          <Typography
            color="primary"
            sx={{ position: "absolute", bottom: -6 }}
            level="body5"
          >
            {emptyPositions.length} chair{emptyPositions.length > 1 ? "s" : ""}
          </Typography>
        ) : (
          []
        )}
      </IconButton>
      {debug ? (
        <IconButton
          variant="plain"
          disabled={(emptyPositions.length === 0 || exceptionChairs) && !debug}
          color="neutral"
          onClick={() => {
            askQuestion("Warning", ["Cancel", "Got it"], {
              subtitle: (
                <Typography>
                  Work Session Chairs from selection will be deleted.
                </Typography>
              ),
            }).then((i) => {
              if (i == 1) updater.handleDeleteWorkSessionChairs(positions);
            });
          }}
          sx={{
            flexDirection: "column",
            flexShrink: 0,
            color: "inherit",
          }}
        >
          <i
            style={{ fontSize: 18, color: "inherit" }}
            className={"fa-solid fa-chair"}
          ></i>
          <Typography color="danger" sx={{ color: "inherit" }} level="body4">
            Delete WSC
          </Typography>
          {!(emptyPositions.length === 0 || exceptionChairs) ? (
            <Typography
              sx={{ position: "absolute", bottom: -6, color: "inherit" }}
              level="body5"
              color="neutral"
            >
              {emptyPositions.length} chair
              {emptyPositions.length > 1 ? "s" : ""}
            </Typography>
          ) : (
            []
          )}
        </IconButton>
      ) : (
        []
      )}
      {debug ? (
        <IconButton
          variant="plain"
          disabled={(emptyPositions.length === 0 || exceptionChairs) && !debug}
          color="neutral"
          onClick={() => {
            askQuestion("Warning", ["Cancel", "Got it"], {
              subtitle: (
                <Typography>
                  Revision flags (important & critical) will be removed from
                  selected assignments.
                </Typography>
              ),
            }).then((i) => {
              if (i == 1) updater.handleClearRevision();
            });
          }}
          sx={{
            flexDirection: "column",
            flexShrink: 0,
            color: "inherit",
          }}
        >
          <i
            style={{ fontSize: 18, color: "inherit" }}
            className={"fa-solid fa-bolt"}
          ></i>
          <Typography color="danger" sx={{ color: "inherit" }} level="body4">
            Clear Revision
          </Typography>
        </IconButton>
      ) : (
        []
      )}
    </Box>
  );

  const workSessionCountSelectionIsAbout =
    selection.workSessionCountSelectionIsAbout(
      selecto.positions,
      selecto.positionIDs,
      layoutUtils
    ).length;

  const exceptionWarning = (
    <Box
      sx={{
        position: "absolute",
        top: -26,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Alert
        variant="solid"
        color="danger"
        size="sm"
        sx={{
          p: 0.5,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Typography level="body3" sx={{ color: "white" }}>
          You are about to make a change that will only affect{" "}
          {workSessionCountSelectionIsAbout} Work Session
          {workSessionCountSelectionIsAbout > 1 ? "s" : ""}
        </Typography>
      </Alert>
    </Box>
  );

  return (
    <>
      <Snackbar
        open={visibleChairs?.length > 0 && !otherDialogOpened}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id="groupAction"
      >
        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: "100vw",
            maxWidth: 1092,
            borderRadius: 50,
            justifyContent: "space-between",
            boxShadow: "0px 15px 50px rgba(0, 0, 0, 0.3)",
            transition:
              "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background .3s, color .3s !important",
          }}
        >
          {exceptionChairs ? exceptionWarning : []}
          <Avatar
            sx={{ ml: 2 }}
            color={exceptionChairs ? "danger" : "primary"}
            variant="solid"
          >
            {positions?.length}
          </Avatar>
          <Box>
            <Typography level="h6" noWrap sx={{ color: "inherit" }}>
              Chair{positions?.length > 1 ? "s" : ""} Selected
            </Typography>
            <Typography
              level="body4"
              noWrap
              sx={{ color: "inherit" }}
              endDecorator={<i className="fa-solid fa-arrow-right"></i>}
            >
              Select an action to update
            </Typography>
          </Box>
          <Divider orientation="vertical" />
          {content}
          {callSent ? (
            <>
              <Divider orientation="vertical" />
              <Tooltip
                title="Excuse the musician for Work Session(s)"
                variant="outlined"
                size="sm"
                arrow
                color="neutral"
              >
                <IconButton
                  variant="plain"
                  disabled={releasableMusicians.length > 1 && !debug}
                  color="neutral"
                  onClick={() => {
                    dispatch(
                      setFormOpen({ isOpen: true, formID: "releaseAdmin" })
                    );
                  }}
                  sx={{
                    flexDirection: "column",
                    flexShrink: 0,
                    color: "inherit",
                  }}
                >
                  <i
                    style={{ fontSize: 18, color: "inherit" }}
                    className={"fa-regular fa-calendar-xmark"}
                  ></i>
                  <Typography sx={{ color: "inherit" }} level="body4">
                    Excuse
                  </Typography>
                  {releasableMusicians.length === 1 ? (
                    <Typography
                      color="primary"
                      sx={{ position: "absolute", bottom: -6, width: 75 }}
                      level="body5"
                    >
                      {releasableMusicians.length} musician
                      {releasableMusicians.length > 1 ? "s" : ""}
                    </Typography>
                  ) : (
                    []
                  )}
                </IconButton>
              </Tooltip>
            </>
          ) : (
            []
          )}
          <Tooltip size="sm" title="Unselect All [esc]">
            <Avatar
              sx={{
                cursor: "pointer",
                mr: 2,
                background: "rgba(155,155,155,0.2)",
                "&:hover": {
                  background: "rgba(155,155,155,0.4)",
                },
              }}
              onClick={() => dispatch(layoutUnselectAll())}
              color="neutral"
              variant="soft"
            >
              <i className="fa-solid fa-xmark"></i>
            </Avatar>
          </Tooltip>
        </Paper>
      </Snackbar>
    </>
  );
}

function ContentTooltip({
  children,
  disabled,
  tooltipContent,
}: {
  children: ReactElement;
  disabled?: boolean;
  tooltipContent: (onClose) => ReactElement;
}) {
  const [open, setOpen] = useState(false);

  if (disabled) return children;
  return (
    <>
      <Tooltip
        arrow
        open={open}
        color="neutral"
        variant="outlined"
        title={
          <ClickAwayListener
            onClickAway={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpen(false);
            }}
          >
            <Box sx={{ pointerEvents: "auto" }}>
              {tooltipContent(() => setOpen(false))}
            </Box>
          </ClickAwayListener>
        }
      >
        <Box onClick={() => setOpen(true)}>{children}</Box>
      </Tooltip>
    </>
  );
}

function MoveTooltip({
  onMove,
  children,
  disabled,
}: {
  children: ReactElement;
  disabled: boolean;
  onMove: (sectionID: number) => void;
}) {
  const { sections } = useSections();
  const internal = useSelector(layoutInternalSelector);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const sectionsInProject = internal?.sections;
  const { company } = useCurrentCompany();
  const settings = useCompanySettings();
  const [updateCompany] = useUpdateCurrentCompanyMutation();
  const sectionInProjectIDs = sectionsInProject.reduce((a, v) => {
    a.push(v.sectionID);
    return a;
  }, []);
  const askQuestion = useAskQuestion();

  const getList = (items: Section[]) => {
    return (
      <List size="sm">
        {items.map((s) => (
          <ListItemButton onClick={() => onMove(s.id)} key={s.id}>
            {s.name}
          </ListItemButton>
        ))}
      </List>
    );
  };

  return (
    <>
      <Tooltip
        arrow
        open={open}
        color="neutral"
        variant="outlined"
        title={
          <ClickAwayListener
            onClickAway={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpen(false);
            }}
          >
            <Box
              sx={{
                width: 400,
                height: 400,
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography>
                <b>Select the new Section:</b>
              </Typography>
              <Box sx={{ p: 1 }}>
                <Input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  startDecorator={
                    <i className="fa-solid fa-magnifying-glass"></i>
                  }
                  variant="soft"
                  size="sm"
                  autoFocus
                  placeholder="Search"
                  endDecorator={
                    <IconButton
                      tabIndex={-1}
                      onClick={() => setSearchText("")}
                      variant="plain"
                      color="neutral"
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </IconButton>
                  }
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  minHeight: "auto",
                  height: 0,
                  overflow: "auto",
                }}
              >
                {sectionsInProject?.length ? (
                  <>
                    <Typography level="body3">Existing Sections:</Typography>
                    {getList(
                      sections?.filter(
                        (s) =>
                          sectionInProjectIDs.includes(s.id) &&
                          s.name
                            .toLowerCase()
                            .indexOf(searchText.toLowerCase()) !== -1
                      )
                    )}
                    <Divider sx={{ height: 5 }} />
                    <Typography level="body3">Other Sections:</Typography>
                  </>
                ) : (
                  []
                )}
                {getList(
                  sections?.filter(
                    (s) =>
                      s.name.toLowerCase().indexOf(searchText.toLowerCase()) !==
                        -1 && !sectionInProjectIDs.includes(s.id)
                  )
                )}
              </Box>
            </Box>
          </ClickAwayListener>
        }
      >
        <Box
          onClick={
            !disabled
              ? () => {
                  if (!settings.dontShowAgain?.changeSection) {
                    askQuestion("Change Section", ["Cancel", "Got it"], {
                      subtitle: (
                        <Typography>
                          This action will transfer the selected chair(s) to a
                          new Section.
                          <br />
                          <br />
                          <Alert
                            color="warning"
                            startDecorator={
                              <i className="fa-solid fa-triangle-exclamation"></i>
                            }
                          >
                            Warning: The chair contains metadata such as the
                            Piece, instruments, role, memo and the musician. By
                            transfering a chair, you are also changing the
                            instrumentation of the Piece.
                          </Alert>
                          <br />
                          <Typography startDecorator={<span>👍</span>}>
                            <b>
                              A good example of use would be to dispatch
                              musicians from a broad "Violins" Section to
                              "Violin I" or "Violin II"
                            </b>
                          </Typography>
                          <Divider sx={{ mt: 1, mb: 1 }} />
                          <Checkbox
                            onChange={(e) => {
                              updateCompany({
                                ...company,
                                settings: JSON.stringify({
                                  ...settings,
                                  dontShowAgain: {
                                    ...(settings?.dontShowAgain ?? {}),
                                    changeSection: e.target.checked,
                                  },
                                }),
                              });
                            }}
                            defaultChecked={
                              settings?.dontShowAgain?.changeSection ?? false
                            }
                            label="Don't show this message again"
                          />
                        </Typography>
                      ),
                    }).then((i) => {
                      if (i == 1) setOpen(true);
                    });
                  } else {
                    setOpen(true);
                  }
                }
              : undefined
          }
        >
          {children}
        </Box>
      </Tooltip>
      {/* <DialogClose
        open={sectionOrderOpen}
        onClose={() => setSectionOrderOpen(false)}
        sx={{ zIndex: 999999 }}
      >
        <DialogTitle>
          <Box sx={{ maxWidth: 400 }}>
            <Typography level="h6">Which position?</Typography>
            <Typography level="body2">
              Chose the placement in section{" "}
              <b>{sectionsMap[selectedSectionID]?.name}</b> where you'd like to
              insert the <b>{positions?.length} selected chairs</b>.
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: CHAIR_WIDTH,
              margin: "auto",
              gap: 1,
            }}
          >
            <Divider
              onMouseEnter={() => setHoverPosition(0)}
              onMouseLeave={() => setHoverPosition(null)}
              onClick={() => setSelectedPosition(0)}
              sx={{
                cursor: "pointer",
                pt: 1,
                background: positionColor(0),
              }}
            />
            {internal
              .getSection(selectedSectionID, familyID)
              ?.positions?.filter((p) => p.visibleChairIDs.length)
              ?.map((p) => (
                <Box
                  onMouseEnter={() => setHoverPosition(p.order)}
                  onMouseLeave={() => setHoverPosition(null)}
                  onClick={() => setSelectedPosition(p.order)}
                  key={p.order}
                  sx={{ cursor: "pointer" }}
                >
                  <Paper
                    variant="outlined"
                    sx={{
                      p: "4px",
                      display: "flex",
                      gap: 0.5,
                      width: CHAIR_WIDTH,
                    }}
                  >
                    <Typography level="body3" sx={{ fontWeight: 600 }}>
                      {p.order}
                    </Typography>
                    <ChairSectionRole
                      sectionRoles={sectionRoles}
                      sectionRolesMap={sectionRolesMap}
                      sectionRoleIDs={p.visibleChairIDs.reduce((a, v) => {
                        if (!a.includes(chairsMap[v].sectionRoleID))
                          a.push(chairsMap[v].sectionRoleID);
                        return a;
                      }, [])}
                      disabled
                      onChange={(e) => console.log(e)}
                    />
                  </Paper>
                  <Divider
                    sx={{
                      mt: 1,
                      pt: 1,
                      background: positionColor(p.order),
                    }}
                  />
                </Box>
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelectedSectionID(null);
              setOpen(false);
              setSectionOrderOpen(false);
            }}
            variant="soft"
            color="neutral"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onMove(selectedSectionID, selectedPosition);
              setOpen(false);
              setSectionOrderOpen(false);
              setSelectedPosition(null);
              setSelectedSectionID(null);
              setHoverPosition(null);
            }}
          >
            Move
          </Button>
        </DialogActions>
      </DialogClose> */}
    </>
  );
}
