/*global google*/

import { Box, Button, Checkbox, Typography } from "@mui/joy";
import {
  DataGridPremium,
  GridColDef,
  GridFooterContainer,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { Musician } from "entities/musician";
import { Venue } from "entities/venue";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { heightWithToolbar } from "global";
import { getterDivider, setterDivider } from "helpers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { rhapsodyApi } from "redux/api";
import { useUpdateMusician2Mutation } from "redux/musician/musicianEndpoints";
import { useMusicians } from "redux/musician/musicianHooks";
import {
  useCreateMusicianVenue2Mutation,
  useUpdateMusicianVenue2Mutation,
} from "redux/musicianVenue/musicianVenueEndpoints";
import { useMusicianVenues } from "redux/musicianVenue/musicianVenueHooks";
import { useUpdateVenue2Mutation } from "redux/venue/venueEndpoints";
import { useVenues } from "redux/venue/venueHooks";

/**
 *
 * @returns {ReactElement} MileageRoute page
 */
export function MileageRoute() {
  const { musicians: musicians, musiciansMap } = useMusicians();
  const { venues: venues } = useVenues();
  const [loading, setLoading] = useState(false);

  const [geolocate, setGeolocate] = useState(true);
  const [mileage, setMileage] = useState(true);
  const { musicianVenues } = useMusicianVenues();
  const [selection, setSelection] = useState<GridRowSelectionModel>();
  const musicianVenuesHash = musicianVenues.reduce((a, v) => {
    a[`${v.musicianID}-${v.venueID}`] = v;
    return a;
  }, {});
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const [updateMusician] = useUpdateMusician2Mutation();
  const [updateVenue] = useUpdateVenue2Mutation();
  const [createMusicianVenue] = useCreateMusicianVenue2Mutation();
  const [updateMusicianVenue] = useUpdateMusicianVenue2Mutation();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const _rows = [...musicians];
    _rows.forEach((m) => {
      venues.forEach((v) => {
        m[`v_${v.id}`] = musicianVenuesHash[`${m.id}-${v.id}`]?.mileage
          ? musicianVenuesHash[`${m.id}-${v.id}`]?.mileage
          : "";
      });
    });
    console.log("Set Row useState");
    setRows(_rows);
  }, [musicianVenues, musicians]);

  const onRowUpdate = async (m) => {
    const index = rows?.findIndex((r) => r.id === m.id);
    return new Promise((resolve) => {
      const proms = [];
      for (const key in venues) {
        if (Object.prototype.hasOwnProperty.call(venues, key)) {
          const v = venues[key];
          const mv = musicianVenuesHash[`${m.id}-${v.id}`];
          if (mv && mv?.mileage !== m[`v_${v.id}`]) {
            proms.push(
              updateMusicianVenue({
                id: mv.id,
                body: {
                  ...mv,
                  mileage: m[`v_${v.id}`],
                  verified: false,
                  polyline: "",
                  musicianPlaceID: m.placeID,
                  venuePlaceID: v.placeID,
                },
              }).unwrap()
            );
          }
        }
      }
      Promise.all(proms).then(() => {
        dispatch(rhapsodyApi.util.invalidateTags(["musicianVenues"]));
      });
      const _rows = [...rows];
      _rows[index] = m;
      setRows(_rows);
      resolve(m);
    });
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      width: 200,
      headerName: "Musician",
      renderCell: (p) => {
        const musician = musiciansMap[p.value];
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <MusicianAvatar size={30} musician={musician} />
            <Box>
              <Typography level="body2">{musician?.fullName()}</Typography>
              {musician.address ? (
                <Typography
                  startDecorator={
                    musician.placeID ? (
                      <i
                        style={{ color: "#2196f3" }}
                        className="fa-solid fa-badge-check"
                      ></i>
                    ) : undefined
                  }
                  level="body3"
                >
                  {musician.city}, {musician.state}
                </Typography>
              ) : (
                []
              )}
            </Box>
          </Box>
        );
      },
    },
  ];

  venues.forEach((v) => {
    columns.push({
      field: `v_${v.id}`,
      headerName: v.name,
      editable: true,
      type: "number",
      valueGetter: getterDivider(`v_${v.id}`, 1000),
      valueSetter: setterDivider(`v_${v.id}`, 1000),
      renderHeader: (p) => {
        return (
          <Box>
            <Typography level="body2">{v.name}</Typography>
            <Typography
              level="body3"
              startDecorator={
                v.placeID ? (
                  <i
                    style={{ color: "#2196f3" }}
                    className="fa-solid fa-badge-check"
                  ></i>
                ) : undefined
              }
            >
              {v.city}, {v.state}
            </Typography>
          </Box>
        );
      },
      width: 200,
    });
  });

  const slotProps: any = { footer: { musicians, venues } };

  const process = async () => {
    setLoading(true);
    if (geolocate) await geocode();
    if (mileage) await distance();
    setLoading(false);
  };

  const geocode = async () => {
    for (const key in selection) {
      if (Object.prototype.hasOwnProperty.call(selection, key)) {
        const musicianID = selection[key];
        const musician = musiciansMap[musicianID];
        if (!musician.placeID) {
          try {
            const m = await musician.geocode();
            updateMusician({
              id: musician.id,
              body: { ...musician, ...m },
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    }

    for (const key in venues) {
      if (Object.prototype.hasOwnProperty.call(venues, key)) {
        const venue = venues[key];
        if (!venue.placeID) {
          try {
            const v = await venue.geocode();
            updateVenue({ id: venue.id, body: { ...venue, ...v } });
          } catch (error) {
            console.log(error);
          }
        }
      }
    }

    setLoading(false);

    dispatch(rhapsodyApi.util.invalidateTags(["musicians", "venues"]));
  };

  const distance = async () => {
    for (const k in selection) {
      if (Object.prototype.hasOwnProperty.call(selection, k)) {
        const musicianID = selection[k];
        const musician = musiciansMap[musicianID];

        for (const j in venues) {
          if (Object.prototype.hasOwnProperty.call(venues, j)) {
            const venue = venues[j];

            if (venue.placeID && musician.placeID) {
              const musicianVenue =
                musicianVenuesHash[`${musician.id}-${venue.id}`];

              if (!musicianVenue?.verified) {
                const r = await musician.distanceToVenue(venue);
                if (musicianVenue) {
                  await updateMusicianVenue({
                    id: musicianVenue.id,
                    body: {
                      ...musicianVenue,
                      venuePlaceID: venue.placeID,
                      musicianPlaceID: musician.placeID,
                      verified: true,
                      ...r,
                    },
                  }).unwrap();
                } else {
                  await createMusicianVenue({
                    musicianID: musician.id,
                    venueID: venue.id,
                    venuePlaceID: venue.placeID,
                    musicianPlaceID: musician.placeID,
                    verified: true,
                    ...r,
                  }).unwrap();
                }
              }
            } else {
              console.log(
                `--> Skipping: ${musician.firstName} ${musician.lastName} ---> ${venue.name}`
              );
            }
          }
        }
      }
    }

    dispatch(rhapsodyApi.util.invalidateTags(["musicianVenues"]));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        p: 1,
        gap: 1,
        width: "100vw",
        flexDirection: "column",
        height: heightWithToolbar,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Checkbox
          checked={geolocate}
          onChange={(e) => setGeolocate(e.target.checked)}
          label="Geolocate"
        />
        <Checkbox
          checked={mileage}
          onChange={(e) => setMileage(e.target.checked)}
          label="Mileage"
        />
        <Button
          disabled={selection?.length === 0}
          loading={loading}
          size="sm"
          onClick={process}
        >
          Process {selection?.length} Musicians
        </Button>
      </Box>
      <DataGridPremium
        rowSelection
        onRowSelectionModelChange={(e) => {
          setSelection(e);
        }}
        checkboxSelection
        apiRef={apiRef}
        processRowUpdate={onRowUpdate}
        onProcessRowUpdateError={(e) => console.log(e)}
        getCellClassName={(p) => {
          const mv = musicianVenuesHash[`${p.row.id}-${p.field.split("_")[1]}`];
          if (mv?.verified) return "success";
          if (mv?.mileage) return "warning";
        }}
        columns={columns}
        rows={rows}
        slots={{
          footer: CustomFooter, // Override default footer
        }}
        slotProps={slotProps}
      />
    </Box>
  );
}

const CustomFooter = ({
  musicians,
  venues,
}: {
  musicians: Musician[];
  venues: Venue[];
}) => {
  return (
    <GridFooterContainer>
      <Box sx={{ flexGrow: 1, p: 1 }}>
        <Typography level="body2">
          Musicians: {musicians.length}, Venues: {venues.length}
        </Typography>
        <Typography level="body3">
          TOTAL: {musicians.length * venues.length} items
        </Typography>
      </Box>
    </GridFooterContainer>
  );
};
