import { Button, Tooltip, Typography } from "@mui/joy";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import Id from "atoms/Id/Id";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setFormOpen, setSelectedBookletID } from "reducers/rhapsody";
import { useBooklets } from "redux/booklet/bookletHooks";

export default function BookletDataGrid({
  projectID,
  venueID,
  musicianID,
  tagID,
  pieceID,
  snippet,
}: {
  projectID?: number;
  venueID?: number;
  musicianID?: number;
  tagID?: number;
  pieceID?: number;
  snippet?: boolean;
}) {
  const filters = [];
  if (projectID) {
    filters.push({
      name: "booklets.projectID",
      comparison: "eq",
      value: projectID,
    });
  }
  if (venueID) {
    filters.push({
      name: "booklets.venueID",
      comparison: "eq",
      value: venueID,
    });
  }
  if (musicianID) {
    filters.push({
      name: "booklets.musicianID",
      comparison: "eq",
      value: musicianID,
    });
  }
  if (tagID) {
    filters.push({
      name: "booklets.tagID",
      comparison: "eq",
      value: tagID,
    });
  }
  if (pieceID) {
    filters.push({
      name: "booklets.pieceID",
      comparison: "eq",
      value: pieceID,
    });
  }

  if (snippet) {
    filters.push({
      name: "booklets.snippet",
      comparison: "eq",
      value: true,
    });
  }

  const { booklets } = useBooklets({
    filters: JSON.stringify(filters),
  });
  const dispatch = useDispatch();

  const columns: any = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      width: 75,
      renderCell: (p) => (
        <Id startDecorator={<i className="fa-solid fa-paperclip"></i>}>
          {p.row.id}
        </Id>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      flex: 1,
    },
    {
      field: "author",
      headerName: "Author",
      type: "string",
      width: 200,
    },
    {
      field: "updatedAt",
      headerName: "Last Update",
      width: 175,
      type: "date",
      valueFormatter: (p) => {
        return moment(p.value).fromNow();
      },
      renderCell: (p) => {
        return (
          <Tooltip title={moment(p.value).format("lll")}>
            <Box sx={{ lineHeight: 1.2, fontSize: 12 }}>
              {moment(p.value).format("lll")}
              <br />
              <span style={{ fontSize: 11 }}>{moment(p.value).fromNow()}</span>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "active",
      width: 200,
      headerName: "Public Sharing",
      renderCell: (p) => {
        return (
          <Typography color={p.row.active ? "success" : "danger"}>
            <i
              className={
                p.row.active
                  ? "fa-duotone fa-solid fa-square-check"
                  : "fa-solid fa-xmark"
              }
            ></i>
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <DataGridPro
        autoHeight={tagID != undefined}
        onRowClick={(p) => {
          dispatch(setSelectedBookletID(p.row.id));
          dispatch(setFormOpen({ isOpen: true, formID: "booklet" }));
        }}
        rows={booklets}
        columns={columns}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          noRowsOverlay: {
            icon: <i className="fa-solid fa-paperclip"></i>,
            title: "No Booklets",
            subtitle: "Write rich text documents",
            buttonText: "New Booklet",
            callback: () =>
              dispatch(setFormOpen({ isOpen: true, formID: "booklet" })),
          },
        }}
      />
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();

  return (
    <Box
      className="header"
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Box>
        <GridToolbarFilterButton />
      </Box>
      <Box sx={{ pt: 1, pr: 1 }}>
        <Button
          size="sm"
          startDecorator={<i className="fa-solid fa-book"></i>}
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "booklet" }))
          }
        >
          New Booklet
        </Button>
      </Box>
    </Box>
  );
}

const getTieString = (fMap, sMap) => (p) => {
  if (p.row.global) return "Everybody";
  const ret = [];
  const _f = p.value.filter((e) => e.modelName === "family");
  const _s = p.value.filter((e) => e.modelName === "section");

  _f.forEach((e) => ret.push(fMap[e.modelID]?.name));
  _s.forEach((e) => ret.push(sMap[e.modelID]?.name));

  return ret.join(", ");
};
