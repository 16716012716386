import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { MusicianVenue_Entity } from "entities/musicianVenue";
import { rhapsodyApi } from "redux/api";

const musicianVenuesAdapter = createEntityAdapter<MusicianVenue_Entity>();
const initialState = musicianVenuesAdapter.getInitialState();

export const musicianVenueEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getMusicianVenues: build.query<
      EntityState<MusicianVenue_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `musicianVenues`,
          params: args,
        };
      },
      transformResponse: (responseData: MusicianVenue_Entity[]) => {
        return musicianVenuesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["musicianVenues"],
    }),
    getMusicianVenue: build.query<MusicianVenue_Entity, number>({
      query: (id) => `musicianVenues/${id}`,
      providesTags: (result, error, id) => [{ type: "musicianVenues", id }],
    }),
    createMusicianVenue: build.mutation<
      MusicianVenue_Entity,
      Partial<MusicianVenue_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `musicianVenues`,
      }),
      invalidatesTags: ["musicianVenues"],
    }),
    createMusicianVenue2: build.mutation<
      MusicianVenue_Entity,
      Partial<MusicianVenue_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `musicianVenues`,
      }),
      invalidatesTags: [],
    }),
    updateMusicianVenue: build.mutation<
      void,
      { id: number; body: Partial<MusicianVenue_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `musicianVenues/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "musicianVenues", id },
        "musicianVenues",
      ],
    }),
    updateMusicianVenue2: build.mutation<
      void,
      { id: number; body: Partial<MusicianVenue_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `musicianVenues/${args.id}`,
      }),
      invalidatesTags: [],
    }),
    deleteMusicianVenue: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `musicianVenues/${id}`,
      }),
      invalidatesTags: ["musicianVenues"],
    }),
  }),
});

export const {
  useGetMusicianVenueQuery,
  useCreateMusicianVenue2Mutation,
  useUpdateMusicianVenue2Mutation,
  useGetMusicianVenuesQuery,
  useCreateMusicianVenueMutation,
  useDeleteMusicianVenueMutation,
  useUpdateMusicianVenueMutation,
} = musicianVenueEndpoints;

export default musicianVenueEndpoints;
