import MusicianVenue_Entity from "./musicianVenue";
export default class MusicianVenue extends MusicianVenue_Entity {
  static fromList(musicianVenuesJSON: unknown): Array<MusicianVenue> {
    const musicianVenues: MusicianVenue[] = [];
    if (musicianVenuesJSON)
      Array.isArray(musicianVenuesJSON) &&
        musicianVenuesJSON.forEach((musicianVenueJSON) => {
          musicianVenues.push(new MusicianVenue(musicianVenueJSON));
        });
    return musicianVenues;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
