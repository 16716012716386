import { Box, Button, Tab, TabList, Tabs, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import DialogClose from "atoms/DialogClose/DialogClose";
import { paydayMap, scaleTypeMap } from "constants/payday";
import { typeOptions } from "constants/workSessionTypeOptions";
import { Scale, Scale_Entity } from "entities/scale";
import { currencyFormatter, getterDivider, setterDivider } from "helpers";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  selectedTagIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { useInstruments } from "redux/instrument/instrumentHooks";
import { useUpdateScaleMutation } from "redux/scale/scaleEndpoints";
import { useProjectScales, useTagScales } from "redux/scale/scaleHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";

/**
 *
 * @returns {ReactElement} ScalesForm page
 */
export function ScalesForm() {
  const open = useSelector(formOpenSelector("scales"));
  const dispatch = useDispatch();
  const { instrumentsMap } = useInstruments();
  const tagID = useSelector(selectedTagIDSelector);
  const projectID = useSelector(selectedProjectIDSelector);
  const { scales: projectScales } = useProjectScales(projectID);
  const { scales: tagScales } = useTagScales(tagID);
  const [tab, setTab] = useState("payday");
  let scales: Scale[];
  if (projectID) scales = projectScales;
  if (tagID) scales = tagScales;
  const onClose = () => {
    dispatch(setFormOpen({ formID: "scales", isOpen: false }));
  };
  const { sectionRolesMap } = useSectionRoles();

  const [updateScale] = useUpdateScaleMutation();

  const onScaleUpdate = async (newScale: Scale_Entity) => {
    return new Promise<Scale_Entity>((resolve) => {
      updateScale({ id: newScale.id, body: newScale });
      resolve(newScale);
    });
  };

  try {
    scales?.sort((a, b) => (a?.itemKey ?? "").localeCompare(b?.itemKey ?? ""));
  } catch (error) {}

  let columns: GridColDef[] = [
    {
      field: "itemID",
      headerName: "Item ID",
      editable: false,
      width: 200,
      valueGetter: (p) => {
        switch (p.api.getRow(p.id)?.itemKey) {
          case "service":
            return (
              typeOptions.find((e) => e.sessionTypeID === p.value)?.title ??
              p.value
            );
          case "sectionRole":
            if (p.value === 50) return "Contractor";
            return sectionRolesMap[p.value]?.name ?? p.value;
          case "payday":
            return paydayMap[p.value] ?? p.value;
          case "muse":
            return instrumentsMap[p.value]?.name;
          case "doubling":
            return `${p.value} Instruments`;
          default:
            return p.value;
        }
      },
    },
    {
      field: "hourly",
      headerName: "Hourly",
      editable: true,
      type: "boolean",
      renderCell: (p) => {
        return p.isEditable ? (
          <Typography color={p.value ? "success" : "neutral"}>
            {p.value ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              <i className="fa-solid fa-xmark"></i>
            )}
          </Typography>
        ) : (
          <Box />
        );
      },
    },
    {
      field: "percentage",
      headerName: "Percentage",
      editable: true,
      type: "boolean",
      renderCell: (p) => {
        return p.isEditable ? (
          <Typography color={p.value ? "success" : "neutral"}>
            {p.value ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              <i className="fa-solid fa-xmark"></i>
            )}
          </Typography>
        ) : (
          <Box />
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      renderHeader: () => (
        <span
          style={{
            flex: 1,
            textAlign: "right",
            fontWeight: 500,
          }}
        >
          Amount
        </span>
      ),
      headerAlign: "right",
      align: "right",
      editable: true,
      flex: 1,
      valueGetter: getterDivider("amount", 1000),
      valueSetter: setterDivider("amount", 1000),
      valueFormatter: (p) =>
        p.api.getRow(p.id).percentage
          ? `${p.value.toFixed(2)}%`
          : `${currencyFormatter.format(p.value)}`,
    },
  ];

  if (tab !== "service") columns = columns.filter((c) => c.field !== "hourly");
  if (tab !== "payday")
    columns = columns.filter((c) => c.field !== "percentage");
  // Budget, Work Session Type, Role, Doubling Cartage
  // Percentage => Budget
  // Hourly => Work Session Types

  return (
    <DialogClose open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {tagID ? "Season" : ""}
        {projectID ? "Project" : ""} Scales
      </DialogTitle>
      <DialogContent
        sx={{
          gap: 1,
          display: "flex",
          flexDirection: "column",
          height: 800,
        }}
      >
        <Tabs onChange={(e, v) => setTab(v as string)} value={tab} size="sm">
          <TabList>
            <Tab value="payday">Budget</Tab>
            <Tab value="service">Work Session Type</Tab>
            <Tab value="sectionRole">Role</Tab>
            <Tab value="doubling">Doubling</Tab>
            <Tab value="muse">Cartage</Tab>
          </TabList>
        </Tabs>
        <DataGridPremium
          sx={{ height: "75vh" }}
          experimentalFeatures={{ clipboardPaste: true }}
          unstable_cellSelection
          density="compact"
          hideFooter
          isCellEditable={(p) => {
            if (
              p.field === "percentage" &&
              p.row.itemKey === "payday" &&
              p.row.itemID !== 5
            )
              return true;

            if (p.field === "hourly" && p.row.itemKey === "service")
              return true;
            if (p.field === "amount") return true;
            return false;
          }}
          getCellClassName={(p) => {
            let classes = "";
            if (!p.isEditable && p.colDef.type !== "actions")
              classes += "cellReadOnly ";

            return classes;
          }}
          processRowUpdate={onScaleUpdate}
          columns={columns as any}
          rows={scales
            .filter((e) => e.itemKey === tab)
            .sort((a, b) => a.position - b.position)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
