import { Box } from "@mui/material";
import { DataGridPro, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import Id from "atoms/Id/Id";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFormOpen, setSelectedVenueID } from "reducers/rhapsody";
import { useVenues } from "redux/venue/venueHooks";

export default function VenuesDataGrid({ variant, autoheight = true }) {
  const { venues, isLoading } = useVenues();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const readOnly = useReadOnly();

  const simple = variant === "simple";

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      width: 75,
      renderCell: (p) => (
        <Id
          startDecorator={<i className="fa-sharp fa-solid fa-location-dot"></i>}
        >
          {p.row.id}
        </Id>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      type: "string",
      flex: 1,
    },
    {
      field: "tzName",
      headerName: "Timezone",
      type: "string",
      flex: 1,
    },
  ];

  if (!simple) {
    columns.push({
      field: "contact",
      headerName: "Contact",
      type: "string",
      flex: 1,
    });
  }

  return (
    <DataGridPro
      autoHeight={autoheight}
      pagination
      pageSize={25}
      density="compact"
      onRowClick={(p) => {
        dispatch(setSelectedVenueID(p.row.id));
      }}
      rows={venues}
      columns={columns}
      components={{
        Toolbar: !simple && Toolbar,
      }}
      loading={isLoading}
    />
  );
}

function Toolbar() {
  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        gap: 2,
        alignItems: "center",
      }}
    >
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}
