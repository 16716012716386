import {
  Box,
  Button,
  Input,
  List,
  ListItemButton,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import { alpha, ClickAwayListener } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import { Chair_Entity } from "entities/chair";
import { Family } from "entities/family";
import { RhapsodyChair, RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { Section } from "entities/section";
import { useState } from "react";
import { useTemplates } from "redux/project/projectHooks";

/**
 *
 * @returns {ReactElement} UseTemplate page
 */
export function UseTemplate({
  templateID,
  onChange,
}: {
  templateID?: number;
  onChange: (e?: number) => void;
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { templates } = useTemplates();

  const template = templates.find((t) => t.id === templateID);

  if (templates.length === 0) return <></>;
  return (
    <Tooltip
      arrow
      open={open}
      color="neutral"
      variant="outlined"
      title={
        <ClickAwayListener
          onClickAway={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(false);
          }}
        >
          <Box
            sx={{
              width: 320,
              height: 400,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              gap: 1,
            }}
          >
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="soft"
              size="sm"
              startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
              placeholder="Search..."
            />
            <List
              sx={{
                overflowY: "auto",
                flexGrow: 1,
                alignItems: "start",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {templates
                  .filter((e) =>
                    e.name?.toLowerCase().includes(search?.toLowerCase())
                  )
                  .map((t) => (
                    <ListItemButton
                      onClick={() => {
                        onChange(t.id);
                        setOpen(false);
                      }}
                      key={t.id}
                    >
                      {t.name}
                    </ListItemButton>
                  ))}
              </Box>
            </List>
            {templateID ? (
              <Button
                onClick={() => {
                  onChange();
                  setOpen(false);
                }}
                variant="soft"
                color="neutral"
              >
                Clear
              </Button>
            ) : (
              []
            )}
          </Box>
        </ClickAwayListener>
      }
    >
      <Button
        size="sm"
        color={templateID ? "info" : "neutral"}
        variant="soft"
        onClick={() => {
          setOpen(true);
        }}
        startDecorator={<i className="fa-regular fa-users"></i>}
        endDecorator={
          <i style={{ fontSize: 10 }} className="fa-solid fa-chevron-down"></i>
        }
      >
        {template ? template.name : "Use Template"}
      </Button>
    </Tooltip>
  );
}

export function UseTemplateButton({
  onClick,
  match = true,
}: {
  onClick?: () => void;
  match?: boolean;
}) {
  return (
    <Tooltip
      arrow
      variant="outlined"
      size="sm"
      title="Use Template"
      enterDelay={1000}
      enterNextDelay={1000}
    >
      <Sheet
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClick();
        }}
        variant={match ? "soft" : "outlined"}
        color={match ? "info" : "neutral"}
        sx={{
          width: 20,
          height: 20,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 4,
          cursor: "pointer",
          "&:hover": {
            background: alpha("#9c27b0", 0.1),
          },
          "&:active": {
            background: alpha("#9c27b0", 0.2),
          },
        }}
      >
        <Typography level="body3" sx={{ color: "inherit", opacity: 0.8 }}>
          <i className="fa-regular fa-users"></i>
        </Typography>
      </Sheet>
    </Tooltip>
  );
}

export const templateInputsAll = (templateChairs: RhapsodyChair[]) => {
  const ret: Dictionary<number> = {};

  templateChairs.forEach((c) => {
    if (ret[c.sectionID]) {
      ret[c.sectionID]++;
    } else {
      ret[c.sectionID] = 1;
    }
  });

  return ret;
};

export const templateChairsAll = (templateChairs: RhapsodyChair[]) => {
  const ret: Dictionary<Partial<RhapsodyChair_Entity>[]> = {};

  templateChairs.forEach((c) => {
    const _chair = { ...c };
    delete _chair.musicianID;
    delete _chair.assignmentID;
    if (ret[c.sectionID]) {
      ret[c.sectionID].push(_chair);
    } else {
      ret[c.sectionID] = [_chair];
    }
  });

  for (const key in ret) {
    if (Object.prototype.hasOwnProperty.call(ret, key)) {
      const element = ret[key];
      element.sort((a, b) => a.sectionOrder - b.sectionOrder);
    }
  }

  return ret;
};

export const templateInputsForFamily = (
  family: Family,
  sections: Section[],
  templateChairs: RhapsodyChair[]
) => {
  const ret: Dictionary<number> = {};

  templateChairs.forEach((c) => {
    const section = sections.find((s) => s.id === c.sectionID);
    if (section.familyID === family.id) {
      if (ret[c.sectionID]) {
        ret[c.sectionID]++;
      } else {
        ret[c.sectionID] = 1;
      }
    }
  });

  return ret;
};

export const templateChairsForFamily = (
  family: Family,
  sections: Section[],
  templateChairs: RhapsodyChair[]
) => {
  const ret: Dictionary<Partial<RhapsodyChair_Entity>[]> = {};

  templateChairs.forEach((c) => {
    const section = sections.find((s) => s.id === c.sectionID);
    if (section.familyID === family.id) {
      if (ret[c.sectionID]) {
        ret[c.sectionID].push(c);
      } else {
        ret[c.sectionID] = [c];
      }
    }
  });

  for (const key in ret) {
    if (Object.prototype.hasOwnProperty.call(ret, key)) {
      const element = ret[key];
      element.sort((a, b) => a.sectionOrder - b.sectionOrder);
    }
  }

  return ret;
};

export const templateInputsForSection = (
  section: Section,
  templateChairs: RhapsodyChair[]
) => {
  const ret: Dictionary<number> = {};

  templateChairs.forEach((c) => {
    if (c.sectionID === section.id) {
      if (ret[c.sectionID]) {
        ret[c.sectionID]++;
      } else {
        ret[c.sectionID] = 1;
      }
    }
  });

  return ret;
};

export const templateChairsForSection = (
  section: Section,
  templateChairs: RhapsodyChair[]
) => {
  const ret: Dictionary<Partial<RhapsodyChair_Entity>[]> = {};

  templateChairs.forEach((c) => {
    if (c.sectionID === section.id) {
      if (ret[c.sectionID]) {
        ret[c.sectionID].push(c);
      } else {
        ret[c.sectionID] = [c];
      }
    }
  });

  ret[section.id].sort((a, b) => a.sectionOrder - b.sectionOrder);

  return ret;
};

export const chairsForFamily = (
  family: Family,
  sections: Section[],
  templateChairs: RhapsodyChair[]
) => {
  return templateChairs.filter((c) => {
    const section = sections.find((s) => s.id === c.sectionID);
    if (section.familyID === family.id) return true;
    return false;
  });
};

export const chairsForSection = (
  section: Section,
  templateChairs: RhapsodyChair[]
) => {
  return templateChairs.filter((c) => {
    if (section.id === c.sectionID) return true;
    return false;
  });
};
