import { Box, Button, Chip, Typography } from "@mui/joy";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { PdLabor, PdLabor_Entity } from "entities/pdLabor";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import {
  AddMusicianFieldToPayroll,
  aggregations,
  musicianFields,
} from "features/payroll/AddMusicianFieldToPayroll";
import { currencyFormatter, getterDivider, setterDivider } from "helpers";
import { ProjectCalculateMileage } from "hooks/projectCalculateMileage/projectCalculateMileage";
import { ProjectContinuationForm } from "hooks/ProjectContinuationForm/ProjectContinuationForm";
import { useSelector } from "react-redux";
import { pdModeSelector } from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useCompanySettings } from "redux/company/companyHooks";
import { useMusicianNotes } from "redux/musicianNote/musicianNoteHooks";
import { useNoteTypes } from "redux/noteType/noteTypeHooks";
import { useUpdatePdLaborMutation } from "redux/pdLabor/pdLaborEndpoints";

export function PdLaborCard({ labors }: { labors: PdLabor[] }) {
  const [updatePdLabor] = useUpdatePdLaborMutation();
  const pdMode = useSelector(pdModeSelector);
  const columns = useLaborColumns(true) ?? [];
  const settings = useCompanySettings();

  const onLaborUpdate = async (newPdLabor: PdLabor_Entity) => {
    return new Promise<PdLabor_Entity>((resolve) => {
      updatePdLabor({ id: newPdLabor.id, body: newPdLabor });
      resolve(newPdLabor);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        flex: 1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
        {settings.bform ? <ProjectContinuationForm labors={labors} /> : []}
        {settings.mileage ? <ProjectCalculateMileage /> : []}
        <AddMusicianFieldToPayroll />
      </Box>
      <DataGridPremium
        sx={{ flexGrow: 1, maxHeight: "calc(100vh - 116px)" }}
        experimentalFeatures={{ clipboardPaste: true }}
        unstable_cellSelection
        // autoHeight={false}
        density="compact"
        hideFooter={pdMode === "budget"}
        getCellClassName={(p) => {
          let classes = "";
          if (!p.isEditable && p.colDef.type !== "actions")
            classes += "cellReadOnly ";
          return classes;
        }}
        processRowUpdate={onLaborUpdate}
        rows={labors ?? []}
        columns={columns}
        initialState={{
          pinnedColumns: { left: ["description"], right: ["total", "actions"] },
        }}
      />
    </Box>
  );
}

export function useLaborColumns(trigger?: boolean): any[] {
  const utils = useSelector(layoutUtilsSelector);
  const chairsMap = utils?.chairsMap;
  const musiciansMap = utils?.musiciansMap;
  const settings = useCompanySettings();
  const {
    payrollMusicianCustomFieldIDs: _payrollMusicianCustomFieldIDs,
    payrollMusicianFields: _payrollMusicianFields,
    payrollMusicianAggregationFieldIDs: _payrollMusicianAggregationFieldIDs,
  } = settings;
  const payrollMusicianCustomFieldIDs = _payrollMusicianCustomFieldIDs ?? [];
  const payrollMusicianFields = _payrollMusicianFields ?? [];
  const payrollMusicianAggregationFieldIDs =
    _payrollMusicianAggregationFieldIDs ?? [];
  const { noteTypesMap } = useNoteTypes();

  const musicianIDs = Object.keys(utils?.musiciansMap ?? {});
  const { musicianNotes, isSuccess: s1 } = useMusicianNotes(
    {
      filters: JSON.stringify([
        {
          name: "musician_notes.musicianID",
          comparison: "in",
          value: musicianIDs,
        },
      ]),
    },
    { skip: musicianIDs.length === 0 || !trigger }
  );

  const ret = [
    {
      field: "description",
      headerName: "Musician",
      width: 220,
      valueGetter: (p) => {
        const chair = chairsMap[p.row.parentID];
        const musician = musiciansMap[chair?.musicianID];
        return musician
          ? `${musician?.firstName} ${musician?.lastName}`
          : "Empty Chair";
      },
      renderCell: (p) => {
        const chair = chairsMap[p.row.parentID];
        const musician = musiciansMap[chair?.musicianID];
        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <MusicianAvatar size={24} musician={musician} />
            <Typography level="body2">
              {musician
                ? `${musician?.firstName} ${musician?.lastName}`
                : "Empty Chair"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "baseScale",
      headerName: "Base Scale",
      valueGetter: getterDivider("baseScale", 1000),
      valueSetter: setterDivider("baseScale", 1000),
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      excelFormat: (v) => v,
      currency: (row) => true,
      editable: true,
    },
    {
      field: "overscale",
      headerName: "Overscale",
      valueGetter: getterDivider("overscale", 1000),
      valueSetter: setterDivider("overscale", 1000),
      // valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      valueFormatter: (p) => `${p.value.toFixed(2)}%`,
      excelFormat: (v) => v / 100,
      percentage: (row) => true,
      editable: true,
    },
    {
      field: "rate",
      headerName: "Rate",
      valueGetter: getterDivider("rate", 1000),
      valueSetter: setterDivider("rate", 1000),
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      excelFormat: (v) => v,
      currency: (row) => true,
      editable: true,
    },
    {
      field: "adjustment",
      headerName: "Adjustment",
      valueGetter: getterDivider("adjustment", 1000),
      valueSetter: setterDivider("adjustment", 1000),
      valueFormatter: (p) =>
        p.api.getRow(p.id).adjustmentPercent
          ? `${p.value.toFixed(2)}%`
          : `${currencyFormatter.format(p.value)}`,
      excelFormat: (v) => v,
      currency: (row) => !row.adjustmentPercent,
      percentage: (row) => row.adjustmentPercent,
      editable: true,
    },
    {
      field: "adjustmentPercent",
      headerName: "Adj %",
      type: "boolean",
      editable: true,
    },
    {
      field: "doubling",
      headerName: "Doubling",
      type: "number",
      editable: true,
      valueGetter: getterDivider("doubling", 1000),
      valueSetter: setterDivider("doubling", 1000),
      valueFormatter: (p) => `${p.value.toFixed(2)}%`,
      excelFormat: (v) => v,
      percentage: (row) => true,
    },
    {
      field: "scaleWages",
      headerName: "Scale Wages",
      valueGetter: getterDivider("scaleWages", 1000),
      valueSetter: setterDivider("scaleWages", 1000),
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      editable: true,
      excelFormat: (v) => v,
      currency: (row) => true,
    },
    {
      field: "cartage",
      headerName: "Cartage",
      valueGetter: getterDivider("cartage", 1000),
      valueSetter: setterDivider("cartage", 1000),
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      excelFormat: (v) => v,
      currency: (row) => true,
      editable: true,
    },
    {
      field: "benefitsOnly",
      headerName: "BenefitsOnly",
      type: "boolean",
      editable: true,
    },
    {
      field: "pension",
      headerName: "Pension",
      valueGetter: getterDivider("pension", 1000),
      valueSetter: setterDivider("pension", 1000),
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      editable: true,
      excelFormat: (v) => v,
      currency: (row) => true,
    },
    {
      field: "welfare",
      headerName: "Welfare",
      valueGetter: getterDivider("welfare", 1000),
      valueSetter: setterDivider("welfare", 1000),
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      editable: true,
      excelFormat: (v) => v,
      currency: (row) => true,
    },
    {
      field: "pensionAdjustment",
      headerName: "Pension Adjustment",
      valueGetter: getterDivider("pensionAdjustment", 1000),
      valueSetter: setterDivider("pensionAdjustment", 1000),
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      editable: true,
      excelFormat: (v) => v,
      currency: (row) => true,
    },
    {
      field: "welfareAdjustment",
      headerName: "Welfare Adjustment",
      valueGetter: getterDivider("welfareAdjustment", 1000),
      valueSetter: setterDivider("welfareAdjustment", 1000),
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      editable: true,
      excelFormat: (v) => v,
      currency: (row) => true,
    },
    {
      field: "mileage",
      headerName: "Mileage",
      valueGetter: getterDivider("mileage", 1000),
      valueSetter: setterDivider("mileage", 1000),
      // valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      excelFormat: (v) => v,
      currency: (row) => false,
      editable: true,
    },
    {
      field: "tripsCount",
      headerName: "Trips",
      valueGetter: getterDivider("tripsCount", 1),
      valueSetter: setterDivider("tripsCount", 1),
      // valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      excelFormat: (v) => v,
      currency: (row) => false,
      editable: true,
    },
    {
      field: "mileageReimbursement",
      headerName: "Mileage Reimbursement",
      valueGetter: getterDivider("mileageReimbursement", 1000),
      valueSetter: setterDivider("mileageReimbursement", 1000),
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      excelFormat: (v) => v,
      currency: (row) => true,
      editable: false,
    },
    {
      field: "ein",
      headerName: "EIN",
      editable: false,
      valueGetter: (p) => {
        const chair = chairsMap[p.row.parentID];
        const musician = musiciansMap[chair?.musicianID];

        return musician?.ein ?? "";
      },
    },
    {
      field: "ssn",
      headerName: "SSN",
      editable: false,
      valueGetter: (p) => {
        const chair = chairsMap[p.row.parentID];
        const musician = musiciansMap[chair?.musicianID];

        return musician?.ssn ?? "";
      },
    },
    {
      field: "unionNumber",
      headerName: "Union Number",
      editable: false,
      valueGetter: (p) => {
        const chair = chairsMap[p.row.parentID];
        const musician = musiciansMap[chair?.musicianID];

        return musician?.localNumber ?? "";
      },
    },
    {
      field: "employeeNumber",
      headerName: "Employee Number",
      editable: false,
      valueGetter: (p) => {
        const chair = chairsMap[p.row.parentID];
        const musician = musiciansMap[chair?.musicianID];

        return musician?.cardNumber ?? "";
      },
    },
    {
      field: "total",
      headerName: "Total",
      align: "right",
      valueGetter: getterDivider("total", 1000),
      valueSetter: setterDivider("total", 1000),
      valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
      width: 140,
      excelFormat: (v) => v / 1000,
      currency: (row) => true,
    },
  ];

  payrollMusicianFields.forEach((f) => {
    const field = musicianFields.find((e) => e.key === f);
    if (field) {
      ret.splice(ret.length - 1, 0, {
        field: field.key,
        headerName: field.label,
        editable: false,
        valueGetter: (p) => {
          const chair = chairsMap[p.row.parentID];
          const musician = musiciansMap[chair?.musicianID];

          return musician ? musician[field.key] ?? "" : musician;
        },
      });
    }
  });

  payrollMusicianCustomFieldIDs.forEach((f) => {
    const noteType = noteTypesMap[f];
    if (noteType) {
      ret.splice(ret.length - 1, 0, {
        field: `${noteType.id}`,
        headerName: noteType.name,
        editable: false,
        valueGetter: (p) => {
          const chair = chairsMap[p.row.parentID];
          const musician = musiciansMap[chair?.musicianID];

          const musicianNote = musicianNotes.find(
            (mn) =>
              mn.musicianID === musician?.id && mn.noteTypeID === noteType?.id
          );

          return musicianNote?.value;
        },
      });
    }
  });

  payrollMusicianAggregationFieldIDs.forEach((f) => {
    const aggregation = aggregations.find((a) => a.id === f);
    if (aggregation) {
      ret.splice(ret.length - 1, 0, {
        field: `aggregation_${aggregation.id}`,
        headerName: aggregation.name,
        editable: false,
        valueGetter: (p) => {
          const chair = chairsMap[p.row.parentID];
          const musician = musiciansMap[chair?.musicianID];

          return aggregation.pattern.replace(/{(\w+)}/g, (match, p1) =>
            musician && musician[p1] ? musician[p1] : ""
          );
        },
      });
    }
  });
  if (s1) return ret;
}
