import { IconButton, Tooltip } from "@mui/joy";
import { InternalSection } from "entities/internal/InternalSection";
import { Selection } from "entities/selection";
import {
  LayoutUtils,
  usePreviewChanges,
} from "features/projects/ProjectMissionControl/LayoutUtils";
import { useCompanySettings } from "redux/company/companyHooks";
import { useChairsGravityMutation } from "redux/rhapsodyChair/rhapsodyChairEndpoints";

/**
 *
 * @returns {ReactElement} Gravity page
 */
export function Gravity({
  internalSection,
  utils,
  selection,
}: {
  internalSection: InternalSection;
  utils: LayoutUtils;
  selection: Selection;
}) {
  const previewChanges = usePreviewChanges();
  const [chairsGravity] = useChairsGravityMutation();
  const { apiPreview } = useCompanySettings();
  const { chairs } = utils;

  const gravity = async () => {
    let workSessionIDs;
    let workSessionID;
    let projectPieceID;
    let projectPieceIDs;
    if (internalSection.forWorkSessionID) {
      workSessionIDs = [internalSection.forWorkSessionID];
      workSessionID = internalSection.forWorkSessionID;
    } else if (!selection.allWorkSessionsSelected) {
      workSessionIDs = selection.workSessionIDs;
    }

    if (internalSection.forProjectPieceID) {
      projectPieceIDs = [internalSection.forProjectPieceID];
      projectPieceID = internalSection.forProjectPieceID;
    } else if (!selection.allProjectPiecesSelected) {
      projectPieceIDs = selection.projectPieceIDs;
    }

    console.log({
      projectID: utils.project.id,
      sectionID: internalSection.sectionID,
      workSessionIDs,
      workSessionID,
      projectPieceID,
      projectPieceIDs,
      preview: apiPreview,
    });

    const query: any = await chairsGravity({
      id: 0,
      body: {
        projectID: utils.project.id,
        sectionID: internalSection.sectionID,
        workSessionIDs,
        workSessionID,
        projectPieceID,
        projectPieceIDs,
        preview: apiPreview,
      },
    });

    previewChanges(
      chairs.reduce((a, v) => {
        const find = query.data.find((c) => c.id == item.id);
        const item = find ? find : v;
        a.push(item);
        return a;
      }, [])
    );
  };

  return (
    <Tooltip
      size="sm"
      variant="outlined"
      arrow
      enterDelay={1000}
      enterNextDelay={1000}
      sx={{ maxWidth: 200 }}
      title="Gravity: Move empty chairs/declined musicians to the bottom of the section"
    >
      <IconButton
        disabled={!selection.allWorkSessionsSelected}
        onClick={gravity}
        size="sm"
        variant="plain"
        color="neutral"
      >
        <i className="fa-solid fa-arrow-down-to-square"></i>
      </IconButton>
    </Tooltip>
  );
}
