import SaveIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Chip, Input, useTheme } from "@mui/joy";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import DialogClose from "atoms/DialogClose/DialogClose";
import FileUpload from "atoms/FileUpload";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { mapToArray } from "helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedLinkDSelector,
  selectedMusicianIDSelector,
  selectedProjectIDSelector,
  selectedTagIDSelector,
  selectedWorkSessionIDSelector,
  setFormOpen,
  setSelectedLinkID,
} from "reducers/rhapsody";
import { useFamilies } from "redux/family/familyHooks";
import {
  useCreateLinkMutation,
  useDeleteLinkMutation,
  useUpdateLinkMutation,
} from "redux/link/linkEndpoints";
import { useLink } from "redux/link/linkHooks";
import { useSections } from "redux/section/sectionHooks";
import {
  useCreateTieMutation,
  useDeleteTieMutation,
} from "redux/tie/tieEndpoints";

export default function FormLink() {
  const workSessionID = useSelector(selectedWorkSessionIDSelector);
  const projectID = useSelector(selectedProjectIDSelector);
  const musicianID = useSelector(selectedMusicianIDSelector);
  const tagID = useSelector(selectedTagIDSelector);

  const [updateLink] = useUpdateLinkMutation();
  const [createLink] = useCreateLinkMutation();
  const [deleteLink] = useDeleteLinkMutation();
  const [createTie] = useCreateTieMutation();
  const [deleteTie] = useDeleteTieMutation();

  const [tiesHaveChanged, setTiesHaveChanged] = useState(false);
  const [selected, setSelected] = useState({ family: {}, section: {} });
  const formOpen = useSelector(formOpenSelector("link"));
  const selectedLinkID = useSelector(selectedLinkDSelector);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const { link: _link } = useLink(selectedLinkID);
  const [link, setLink] = useState({
    url: "",
    name: "",
    description: "",
    global: true,
    musicianID: musicianID > 0 ? musicianID : undefined,
    tagID: tagID > 0 ? tagID : -1,
    sessionID: !musicianID && workSessionID > 0 ? workSessionID : undefined,
    projectID:
      !musicianID && !workSessionID && projectID > 0 ? projectID : undefined,
  });
  const { familiesMap } = useFamilies();
  const { sectionsMap } = useSections();

  const askQuestion = useAskQuestion();

  useEffect(() => {
    return () => dispatch(setSelectedLinkID());
  }, []);

  useEffect(() => {
    if (_link) {
      setLink(_link);
      const _selected = { family: {}, section: {} };
      _link.ties.forEach((t) => {
        let map;
        if (t.modelName === "section") map = sectionsMap;
        if (t.modelName === "family") map = familiesMap;
        _selected[t.modelName][t.modelID] = map[t.modelID];
        setSelected(_selected);
      });
    } else {
      setLink({
        url: "",
        name: "",
        description: "",
        global: true,
        tagID: tagID > 0 ? tagID : -1,
        musicianID: musicianID > 0 ? musicianID : undefined,
        sessionID: !musicianID && workSessionID > 0 ? workSessionID : undefined,
        projectID:
          !musicianID && !workSessionID && projectID > 0
            ? projectID
            : undefined,
      });
    }
  }, [_link]);

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    setLink((s) => ({ ...s, [name]: value }));
  };

  const askDelete = () => {
    askQuestion("Are you sure?", ["Cancel", "Yes"], {
      subtitle: (
        <Typography>You are about to delete a link, please confirm</Typography>
      ),
    }).then((i) => {
      if (i == 1) confirmDelete();
    });
  };

  const confirmDelete = async () => {
    deleteLink(link.id);

    closeForm();
  };

  const createTies = (id) => {
    // create ties for families
    Object.keys(selected.family).forEach((familyID) => {
      createTie({
        modelName: "family",
        modelID: parseInt(familyID),
        linkID: id,
      });
    });

    // create ties for sections
    Object.keys(selected.section).forEach((sectionID) => {
      createTie({
        modelName: "section",
        modelID: parseInt(sectionID),
        linkID: id,
      });
    });
  };

  const save = async () => {
    if (link?.id) {
      updateLink({ id: link.id, body: { ...link } });
      if (tiesHaveChanged) {
        link.ties.forEach((t) => deleteTie(t.id));
        if (!musicianID) createTies(link?.id);
      }
    } else {
      createLink(link)
        .unwrap()
        .then((r) => {
          createTies(r.id);
        });
    }

    setSelected({ family: {}, section: {} });

    closeForm();
  };

  const closeForm = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "link" }));
    dispatch(setSelectedLinkID());
  };

  return (
    <DialogClose
      open={formOpen}
      scroll="paper"
      onClose={closeForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {link?.name
          ? `${link.name}`
          : `New ${link.projectID ? "Project" : ""} ${
              link.sessionID ? "Work Session" : ""
            }  Link`}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" display="block">
          Your files can be stored in the cloud of your choice (Dropbox, Google
          Drive, Private Cloud...). Just provide the public url to access it.
        </Typography>
        <Typography variant="subtitle2">OR</Typography>
        <Typography variant="body2" display="block">
          {"Rhapsody can host your files for free:"}
        </Typography>
        <Typography>
          <FileUpload onUpload={(f) => setLink((s) => ({ ...s, url: f }))} />
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Input
              id="url"
              startDecorator="Url:"
              placeholder="ex: https://www.music.org/parts"
              value={link.url}
              onBlur={() => {
                if (link.url && !link.url.includes("http")) {
                  setLink((l) => ({ ...l, url: `https://${l.url}` }));
                }
              }}
              onChange={handleChange("url")}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="name:"
              startDecorator="Name"
              value={link.name}
              onChange={handleChange("name")}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="description:"
              startDecorator="Description"
              value={link.description}
              onChange={handleChange("description")}
            />
          </Grid>
        </Grid>
        {!musicianID && !tagID ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Who gets this link?
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={1}>
                <Grid item>
                  <Chip
                    variant={link.global ? "solid" : "soft"}
                    onClick={() => {
                      setTiesHaveChanged(true);
                      if (!link.global) {
                        setSelected({ family: {}, section: {} });
                      }
                      setLink((l) => ({
                        ...l,
                        global: !l.global,
                      }));
                    }}
                  >
                    Everybody
                  </Chip>
                </Grid>
                <Grid item>
                  <RestrictTo
                    selected={selected}
                    onSelect={(...args) => {
                      setSelected(...args);
                      setTiesHaveChanged(true);
                    }}
                    setGlob={(v) => setLink((l) => ({ ...l, global: v }))}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        {selectedLinkID && (
          <Button variant="soft" color="danger" onClick={askDelete}>
            Delete
          </Button>
        )}
        <Box sx={{ flex: 1 }} />
        <Button variant="soft" color="neutral" onClick={closeForm}>
          <CloseIcon />
          &nbsp;Cancel
        </Button>
        <Button autoFocus variant="solid" disabled={!link.url} onClick={save}>
          <SaveIcon />
          {selectedLinkID ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </DialogClose>
  );
}

const RestrictTo = ({ selected, onSelect, setGlob }) => {
  const [anchorEl, setAnchorEl] = useState();

  const { families } = useFamilies();
  const { sections } = useSections();

  const handleOnChange = (model, item) => (e) => {
    const checked = e.target.checked;
    const _selected = { ...selected };

    if (checked) {
      _selected[model][item.id] = item;
    } else {
      delete _selected[model][item.id];
    }

    // for families, we uncheck all sections as well
    if (model === "family") {
      mapToArray(_selected.section)
        .filter((s) => s.familyID === item.id)
        .forEach((s) => delete _selected.section[s.id]);
    }

    setGlob(false);
    onSelect(_selected);
  };

  const restricted =
    Object.keys(selected.family).length || Object.keys(selected.section).length;

  return (
    <>
      <div onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Chip
          onClick={(e) => setAnchorEl(e.currentTarget)}
          variant={restricted ? "solid" : "soft"}
          endDecorator={<i className="fa-solid fa-angle-down"></i>}
        >
          {restricted ? "Restricted" : "Restrict to"}
        </Chip>
      </div>
      <DialogClose
        scroll="paper"
        fullWidth={true}
        maxWidth={"xl"}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl()}
      >
        <DialogTitle>Select all that apply</DialogTitle>
        <DialogContent>
          <Grid container>
            {families.map((f) => (
              <Grid
                item
                key={f.id}
                sx={{
                  width: 300,
                  pb: 4,
                  borderLeft: `solid 1px rgba(155,155,155,0.3)`,
                }}
              >
                <Box sx={{ pl: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selected.family[f.id] ?? false}
                          onChange={handleOnChange("family", f)}
                          sx={{ padding: 0.5 }}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          sx={{ fontWeight: 600 }}
                        >
                          {f.name}
                        </Typography>
                      }
                    />
                  </Box>

                  {sections
                    .filter((s) => s.familyID === f.id)
                    .map((s) => (
                      <Box key={s.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ padding: 0.5 }}
                              disabled={selected.family[s.familyID]}
                              onChange={handleOnChange("section", s)}
                              checked={
                                (selected.section[s.id] ||
                                  selected.family[s.familyID]) ??
                                false
                              }
                            />
                          }
                          label={s.name}
                        />
                      </Box>
                    ))}
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAnchorEl()}>Done</Button>
        </DialogActions>
      </DialogClose>
    </>
  );
};
