import { MusicianGroup } from "entities/musicianGroup";
import { useMemo } from "react";
import { useGetMusicianGroupQuery, useGetMusicianGroupsQuery } from "./musicianGroupEndpoints";

export function useMusicianGroup(musicianGroupID: number | undefined) {
  const musicianGroupQuery = useGetMusicianGroupQuery(musicianGroupID ?? 0, {
    skip: (musicianGroupID ?? 0) <= 0,
  });
  const musicianGroupEntity = musicianGroupQuery.data;

  return useMemo(() => {
    const ret: typeof musicianGroupQuery & { musicianGroup?: MusicianGroup | undefined } = {
      ...musicianGroupQuery,
    };
    if (musicianGroupEntity) ret.musicianGroup = new MusicianGroup(musicianGroupEntity);
    return ret;
  }, [musicianGroupEntity]);
}

export function useMusicianGroups() {
  const musicianGroupQuery = useGetMusicianGroupsQuery();
  const musicianGroupEntitiesMap = musicianGroupQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianGroupQuery & {
      musicianGroups?: MusicianGroup[];
      musicianGroupsMap?: { [id: number]: MusicianGroup };
    } = {
      ...musicianGroupQuery,
      musicianGroups: [],
      musicianGroupsMap: {},
    };
    if (musicianGroupEntitiesMap) {
      const musicianGroups = [];
      const musicianGroupsMap = {};

      for (const key in musicianGroupEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(musicianGroupEntitiesMap, key)) {
          const c = musicianGroupEntitiesMap[key];
          const musicianGroup = new MusicianGroup(c);
          musicianGroups.push(musicianGroup);
          musicianGroupsMap[musicianGroup.id] = musicianGroup;
        }
      }
      ret.musicianGroups = musicianGroups;
      ret.musicianGroupsMap = musicianGroupsMap;
    }

    return ret;
  }, [musicianGroupEntitiesMap]);
}
