import { Button, Divider, Typography } from "@mui/joy";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 48px)",
        background: "#021B33",
      }}
    >
      <div class="no-signal"></div>
      <div class="frame">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          gap: 4,
          flexDirection: "column",
        }}
      >
        <img
          style={{ height: 80 }}
          src="https://storage.googleapis.com/wid-rhapsody/images/logo_main-white.png"
        />
        <Box>
          <Typography sx={{ color: "white", fontWeight: 800 }} level="h4">
            404 Not Found
          </Typography>
          <Typography sx={{ color: "white" }}>
            The resource could not be found
          </Typography>
        </Box>
        <Button onClick={() => navigate("/")}>Go to Journal</Button>
      </Box>
    </Box>
  );
}
