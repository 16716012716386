import { show } from "@intercom/messenger-js-sdk";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Input,
  ListItemDecorator,
  Menu,
  MenuItem,
  Sheet,
  Tab,
  TabList,
  Tabs,
  Textarea,
  Typography,
} from "@mui/joy";
import { DialogContent, Hidden, Skeleton } from "@mui/material";
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import DialogClose from "atoms/DialogClose/DialogClose";
import { REACT_APP_API } from "config";
import { Musician } from "entities/musician";
import { Tag_Entity } from "entities/tag";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import SlidingDialog from "hooks/SlidingDialog/SlidingDialog";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  notifier3Selector,
  notifierBodySelector,
  resetNotifierBody,
  selectedTagIDSelector,
  setFormOpen,
  setNotifierBody,
} from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import {
  seasonUtilsSelector,
  selectedMissionsSelector,
} from "reducers/v2/seasonControl";
import { useUpdateAssignmentMutation } from "redux/assignment/assignmentEndpoints";
import { useCurrentCompany } from "redux/company/companyHooks";
import { useEventStatuses } from "redux/eventStatus/eventStatusHooks";
import { useUpdateMissionMutation } from "redux/mission/missionEndpoints";
import { useLazyGetTagNotifyQuery } from "redux/projectNotify/hiringNotifyEndpoints";
import { useTag } from "redux/tag/tagHooks";
import { TagCover } from "routes/season/seasonSettings";
import { meSelector } from "selectors/me";
/**
 *
 * @returns {ReactElement} Notifier page
 */
export function Notifier3() {
  const open = useSelector(formOpenSelector("notifier3"));
  const notifier3 = useSelector(notifier3Selector);
  const tagID: number = useSelector(selectedTagIDSelector);
  const { tag: _tag } = useTag(tagID);
  const [sendCall] = useLazyGetTagNotifyQuery();
  const { company } = useCurrentCompany();
  const selectedMissions = useSelector(selectedMissionsSelector);
  const utils = useSelector(seasonUtilsSelector);
  const { eventStatuses } = useEventStatuses();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >();

  const [preview, setPreview] = useState<string>();
  const [acknowledge1, setAcknowledge1] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [musicianIDForPreview, setMusicianIDForPreview] = useState<number>();
  const [tag, setTag] = useState<Tag_Entity | null>();
  const [mobileTab, setMobileTab] = useState<"info" | "preview">("info");
  const [previewTimeout, setPreviewTimeout] = useState<{
    timeoutID: TimeoutId;
    time: number;
  } | null>();
  const ref = useRef();
  const dispatch = useDispatch();
  const body = useSelector(notifierBodySelector);
  const me = useSelector(meSelector);
  const stages = (
    (notifier3.nudge
      ? notifier3.hProject?.currentStages.filter((s) => s.nudgeable)
      : notifier3.hProject?.upcomingStages) ?? []
  ).filter((j) => j.jobIDs?.length);

  const selectedStage = stages.find((s) => s.id === notifier3.selectedStageID);

  const hJobs = notifier3.hProject.jobs.filter((j) =>
    selectedStage?.jobIDs.includes(j.id)
  );

  const musicianForPreview = utils.musiciansMap[musicianIDForPreview];

  const musicianIDs = hJobs.reduce((a, v) => {
    if (!a.includes(v.refID)) a.push(v.refID);
    return a;
  }, []);

  const musicians: Musician[] = musicianIDs.reduce((a, v) => {
    a.push(utils.musiciansMap[v]);
    return a;
  }, []);

  useEffect(() => {
    if (selectedStage && selectedStage?.config) {
      try {
        const config = JSON.parse(selectedStage?.config);
        dispatch(
          setNotifierBody({
            ...body,
            greeting: `<p className="ql-align-center">${config.greeting}</p>`,
            messenger: `${me?.firstName} ${me?.lastName}`,
          })
        );
      } catch (error) {}
    }
  }, [notifier3]);

  useEffect(() => {
    if (_tag?.id) {
      setTag(_tag);
      getHTMLPreview();
    }
  }, [_tag]);

  useEffect(() => {
    if (previewTimeout) {
      clearTimeout(previewTimeout.timeoutID);
    }
    const time = new Date().getTime();
    const timeoutID = setTimeout(() => {
      getHTMLPreview();
      setPreviewTimeout(null);
    }, 500);
    setPreviewTimeout({
      time,
      timeoutID,
    });
  }, [body, musicianIDForPreview]);

  useEffect(() => {
    if (musicians.length) {
      setMusicianIDForPreview(musicians[0].id);
    }
  }, [notifier3]);

  useEffect(() => {
    if (!open) dispatch(resetNotifierBody());
  }, [open]);

  const notify = () => {
    const _b = {
      ...body,
      ...projectNotifyBody,
      nudge: notifier3.nudge,
      mercuryStageID: notifier3.selectedStageID,
    };
    const _notes = [];
    // _b.projectNoteIDs?.forEach((id) => {
    //   const projectNote = projectNotes.find((pn) => pn.id === id);
    //   _notes.push(projectNote.value);
    // });
    _b.notes = _notes.join("<hr />");
    sendCall({
      tagID,
      body: _b,
    });
    onClose();
  };

  const onClose = () => {
    dispatch(setNotifierBody({ ...body, greeting: "" }));
    dispatch(setFormOpen({ isOpen: false, formID: "notifier3" }));
  };

  const projectNotifyBody = {
    musicianIDs: selectedMissions.reduce((a, v) => {
      const mission = utils.missionsMap[v];
      if (mission) a.push(mission.musicianID);
      return a;
    }, []),
  };

  const getHTMLPreview = async () => {
    if (!musicianIDForPreview) return;
    setLoading(true);
    const _b: any = {
      ...body,
      ...projectNotifyBody,
      nudge: notifier3.nudge,
      musician: { id: musicianIDForPreview },
    };
    _b.mercuryStageID = notifier3.selectedStageID;
    const _notes = [];
    // _b.projectNoteIDs?.forEach((id) => {
    //   const projectNote = projectNotes.find((pn) => pn.id === id);
    //   _notes.push(projectNote.value);
    // });
    _b.notes = _notes.join("<hr />");

    fetch(`${REACT_APP_API}/rhapsody/tags/${tagID}/mercury/html`, {
      body: JSON.stringify(_b),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      method: "PUT",
    })
      .then((t) => t.text())
      .then((t) => {
        setPreview(t);
        setLoading(false);
      });
  };

  const eventStatus = eventStatuses?.find(
    (s) => s.mercuryStageID === notifier3.selectedStageID
  );

  const musicianForPreviewDropdown = (
    <Menu
      size="sm"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      sx={{ zIndex: 9999, maxHeight: 600 }}
    >
      {musicians
        .filter((m) => m.id !== musicianIDForPreview)
        .map((m) => (
          <MenuItem
            onClick={() => {
              setMusicianIDForPreview(m.id);
              setAnchorEl(null);
            }}
            sx={{ gap: 1 }}
            key={m.id}
          >
            <ListItemDecorator>
              <MusicianAvatar size={26} noBadge musician={m} />
            </ListItemDecorator>
            {m.fullName()}
          </MenuItem>
        ))}
    </Menu>
  );

  const callPreview = selectedStage && tag && company && (
    <Sheet
      variant="soft"
      color="neutral"
      sx={{
        flex: 1,
        flexShrink: 0,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        background: "#F2F5F9",
      }}
    >
      <Box sx={{ p: 1 }}>
        <Typography level="body3" startDecorator="To:">
          <Chip
            startDecorator={
              <MusicianAvatar
                size={20}
                noBadge
                musicianID={musicianIDForPreview}
              />
            }
            size="sm"
            color="neutral"
          >
            {musicianForPreview?.fullName()} ({musicianForPreview?.email})
          </Chip>
          {musicians.length > 1 ? (
            <>
              <Chip
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: "rgba(155,155,155,0.2)",
                  },
                }}
                size="sm"
                color="neutral"
                variant="outlined"
              >
                {musicians.length - 1 >= 1
                  ? ` +${musicians.length - 1} more ▾`
                  : ""}
              </Chip>
              {musicianForPreviewDropdown}
            </>
          ) : (
            []
          )}
        </Typography>
        <Typography level="body3" startDecorator="From:">
          {body.messenger || company?.name || "Rhapsody Bot"}
        </Typography>
        <Typography level="body3" startDecorator="Reply To:">
          {company?.email}
        </Typography>
        <Typography level="body3" startDecorator="Subject:">
          {/* {jobForPreview?.channels?.find((c) => c.name === "Email")?.subject ??
            ""} */}
          {selectedStage?.channels?.find((c) => c.name === "Email")?.subject ??
            ""}
        </Typography>
      </Box>
      <Divider>Email Preview</Divider>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          position: "relative",
          overflow: "auto",
        }}
      >
        {preview ? (
          <iframe
            title="preview"
            ref={ref}
            srcDoc={preview}
            style={{
              border: "none",
              width: "100%",
              flex: 1,
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              flex: 1,
              p: 2,
            }}
          >
            <Skeleton />
            <Skeleton />
          </Box>
        )}
        <Box
          sx={{
            backdropFilter: loading ? "blur(5px)" : "blur(0px)",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "backdrop-filter .2s",
            pointerEvents: "none",
          }}
        >
          {loading ? <CircularProgress /> : []}
        </Box>
      </Box>
    </Sheet>
  );

  const callInfo = selectedStage && tag && (
    <Sheet
      variant="plain"
      color="neutral"
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: 2,
        overflow: "auto",
        minHeight: "auto",
        height: 0,
      }}
    >
      {/* <Box sx={{ height: 1400 }}>Hello World</Box> */}
      <Typography
        sx={{ color: selectedStage.color }}
        startDecorator={<img src={selectedStage.icon} height={25} />}
        level="h6"
      >
        {selectedStage.name}
      </Typography>
      <TagCover />
      <Input
        onChange={(e) =>
          dispatch(setNotifierBody({ ...body, messenger: e.target.value }))
        }
        value={body.messenger}
        startDecorator="Sender:"
        placeholder=""
      />
      <Box sx={{ position: "relative" }}>
        <FormLabel>Greetings</FormLabel>
        <ReactQuill
          theme="snow"
          value={body.greeting}
          onChange={(e) => {
            dispatch(setNotifierBody({ ...body, greeting: e }));
          }}
          modules={{
            toolbar: [
              ["bold", "italic", "underline", "strike"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ align: [] }],
              [{ color: [] }],
              ["clean"], // Remove formatting button
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
        />
      </Box>
      <MissionMemo musicianID={musicianIDForPreview} />
      {/* <Box sx={{ display: "flex", gap: 2 }}>
        <Checkbox
          size="sm"
          label="Add Roster"
          variant="solid"
          checked={body.showRoster}
          onChange={(e) =>
            dispatch(setNotifierBody({ ...body, showRoster: e.target.checked }))
          }
        />
      </Box> */}
      {/* <Box sx={{ display: "flex", gap: 2 }}>
        <Checkbox
          size="sm"
          label="Include Session Details"
          variant="solid"
          checked={body.showSessionDetails}
          onChange={(e) => {
            dispatch(
              setNotifierBody({ ...body, showSessionDetails: e.target.checked })
            );
          }}
        />
      </Box> */}
      {/* {body.showSessionDetails ? (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Checkbox
            size="sm"
            label="Show full schedule"
            variant="solid"
            checked={!project.hideWhenNotPlaying}
            onChange={(e) => {
              updateProject({
                id: projectID,
                body: { ...project, hideWhenNotPlaying: !e.target.checked },
              })
                .unwrap()
                .then(getHTMLPreview);
            }}
          />
          <Tooltip
            size="sm"
            arrow
            title="If enabled, musicians will see all the Work Sessions the Project
            contains (even the ones they are not in)."
          >
            <Typography level="body2">
              <i className="fa-regular fa-circle-question"></i>
            </Typography>
          </Tooltip>
        </Box>
      ) : (
        []
      )} */}
      {/* <Divider>Other Options</Divider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          // alignItems: "start",
        }}
      >
        <ProjectNotesPicker
          value={body?.projectNoteIDs}
          onChange={(projectNoteIDs: number[]) => {
            dispatch(setNotifierBody({ ...body, projectNoteIDs }));
          }}
        />
        <ProjectWage />
        <ProjectDressCode />
        <ProjectContacts />
      </Box> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          p: 1,
          mb: "30px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "sticky",
          bottom: 30,
          backdropFilter: "blur(10px)",
          background: "rgba(255,255,255,0.2)",
          borderTop: "solid 1px rgba(155,155,155,0.3)",
        }}
      >
        <Checkbox
          size="sm"
          onChange={(e) => setAcknowledge1(e.target.checked)}
          checked={acknowledge1}
          sx={{ alignItems: "center", gap: 2 }}
          label={
            <Typography level="body2">
              I understand that this action will send emails/SMS. I checked all
              the information concerning the Season and the related Project(s).{" "}
              <br />
            </Typography>
          }
          variant="solid"
          defaultChecked
        />
        <Button fullWidth disabled={!acknowledge1} onClick={notify}>
          Send Now!
        </Button>
      </Box>
    </Sheet>
  );

  const callBuilder = selectedStage && (
    <>
      <Hidden smUp>
        <Box sx={{ p: 1 }}>
          <Tabs
            size="sm"
            value={mobileTab}
            onChange={(e, v) => {
              setMobileTab(v as "info" | "preview");
            }}
          >
            <TabList variant="soft" color="neutral">
              <Tab value={"info"}>Info</Tab>
              <Tab value="preview">Preview</Tab>
            </TabList>
          </Tabs>
        </Box>
      </Hidden>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            position: "relative",
            alignItems: "stretch",
          }}
        >
          <Hidden smDown>
            <Box sx={{ width: "50%", display: "flex" }}>{callPreview}</Box>
            <Box sx={{ width: "50%", display: "flex", flexFlow: "column" }}>
              {callInfo}
            </Box>
          </Hidden>
          <Hidden smUp>
            {mobileTab === "preview" ? callPreview : callInfo}
          </Hidden>
        </Box>
      </Box>
    </>
  );

  const DialogComponent = selectedStage ? SlidingDialog : DialogClose;

  const stageNotFound = (
    <DialogContent>
      <Typography level="h6">Stage not found</Typography>
      <Typography>
        Looks like the Mercury Pipeline doesn't include the desired stage.
        Contact us if you need more assistance.
      </Typography>
      <Button
        sx={{ mt: 2 }}
        startDecorator={<i className="fa-solid fa-comment"></i>}
        onClick={() => {
          show();
        }}
      >
        Chat with us
      </Button>
    </DialogContent>
  );

  const getContent = () => {
    if (!selectedStage) return stageNotFound;
    if (selectedStage) return callBuilder;
  };

  return (
    <DialogComponent open={open} onClose={onClose}>
      {getContent()}
    </DialogComponent>
  );
}

function MissionMemo({ musicianID }: { musicianID: number }) {
  const utils = useSelector(seasonUtilsSelector);
  const [missionMemo, setMissionMemo] = useState("");
  const [updateMission] = useUpdateMissionMutation();
  const musician = utils.musiciansMap[musicianID];
  const mission = utils.missions.find((a) => a.musicianID === musicianID);

  useEffect(() => {
    if (mission) {
      setMissionMemo(mission.memo ?? "");
    }
  }, [musicianID]);

  const save = () => {
    updateMission({
      id: mission.id,
      body: { ...mission, memo: missionMemo },
    });
  };

  return (
    <FormControl>
      <FormLabel>Mission Memo for {musician.fullName()}</FormLabel>
      <Textarea
        value={missionMemo}
        onChange={(e) => setMissionMemo(e.target.value)}
        placeholder={"Any info to add for this mission?"}
        minRows={2}
        onBlur={save}
      />
      <Typography color={"neutral"} level="body4">
        Only {musician.fullName()} will see this
      </Typography>
    </FormControl>
  );
}
