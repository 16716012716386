import { mapToArray } from "entities/internal/helper";
import { RhapsodyChair } from "entities/rhapsodyChair";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  layoutInternalSelector,
  layoutUtilsSelector,
  selectionSelector,
} from "reducers/v2/missionControl";
import { useMusicianNotes } from "redux/musicianNote/musicianNoteHooks";
import { useNoteTypes } from "redux/noteType/noteTypeHooks";
import {
  Indexes,
  createWorkbook,
  downloadExcel,
  rhapsodyHeader,
} from "../utils";
import { Tardy } from "hooks/AttendanceAssignments/AttendanceAssignments";

export function useProjectV2Export() {
  const [trigger, setTrigger] = useState(false);
  const utils = useSelector(layoutUtilsSelector);
  const internal = useSelector(layoutInternalSelector);
  const selection = useSelector(selectionSelector);
  const { noteTypesMap, isSuccess: s1 } = useNoteTypes(undefined, {
    skip: !trigger,
  });
  const musicianIDs = Object.keys(utils?.musiciansMap ?? {});
  const project = utils?.project;
  const { musicianNotes, isSuccess: s2 } = useMusicianNotes(
    {
      filters: JSON.stringify([
        {
          name: "musician_notes.musicianID",
          comparison: "in",
          value: musicianIDs,
        },
      ]),
    },
    { skip: musicianIDs.length === 0 || !trigger }
  );

  useEffect(() => {
    if (trigger && s2) {
      setTrigger(false);
      download();
    }
  }, [trigger, s2, s1]);

  function newPage(workbook, name) {
    const worksheet = workbook.addWorksheet(name, {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 1,
      },
    });
    worksheet.pageSetup.margins = {
      left: 0.5,
      right: 0.5,
      top: 0,
      bottom: 0.5,
      header: 0,
      footer: 0.3,
    };

    return worksheet;
  }

  function groupedPage(workbook, worksheet) {
    const indexes = new Indexes(worksheet);
    rhapsodyHeader(workbook, worksheet, indexes);
    indexes.goToRow(4);
    indexes.goToColumn(0);
    indexes.text(project?.name);
    indexes.bold();
    indexes.fontSize(14);
    indexes.nextRow();
    indexes.text("Full Ensemble");
    indexes.nextColumn();
    indexes.text(
      `${internal.positionCount} Chair${internal.positionCount > 1 ? "s" : ""}`
    );
    indexes.goToColumn(0);
    indexes.nextRow();
    indexes.nextRow();

    internal.families.forEach((f) => {
      const family = utils.familiesMap[f.familyID];
      indexes.goToColumn(0);
      indexes.text(family?.name);
      indexes.fill(`eeeeee`);
      indexes.rectangle();
      indexes.bold();
      indexes.nextColumn();
      indexes.text(`${f.positionCount} Chair${f.positionCount > 1 ? "s" : ""}`);
      indexes.fill(`eeeeee`);
      indexes.rectangle();
      indexes.goToColumn(0);
      indexes.nextRow();
      let savedFamilyRow = indexes.getRow();

      f.sections.forEach((s) => {
        const section = utils.sectionsMap[s.sectionID];
        indexes.text(section?.name);
        indexes.fill(`eeeeee`);
        indexes.rectangle();
        indexes.bold();
        indexes.nextColumn();
        indexes.text(
          `${s.positionCount} Chair${s.positionCount > 1 ? "s" : ""}`
        );
        indexes.fill(`eeeeee`);
        indexes.rectangle();
        const saveSectionRow = indexes.getY();
        indexes.nextRow();
        indexes.previousColumn();
        s.positions.forEach((p) => {
          const chairs = getSimilarChairs(p.chairIDs, utils);
          indexes.text(p.order);
          if (chairs.length > 1) {
            indexes.select(0, chairs.length - 1);
            indexes.merge();
            indexes.unselect();
          }
          indexes.rectangle();
          indexes.wrapText();
          const savedPositionColumn = indexes.getX();
          indexes.nextColumn();
          chairs.forEach((c) => {
            const role = utils.sectionRolesMap[c.sectionRoleID];
            const musician = utils.musiciansMap[c.musicianID];
            const assignment = utils.assignmentsMap[c.assignmentID];
            const stage = utils.stagesMap[assignment?.mercuryStageID];
            const instruments = JSON.parse(
              c.instrumentIDs ? c.instrumentIDs : "[]"
            ).reduce((a, v) => {
              a.push(utils.instrumentsMap[v].name);
              return a;
            }, []);

            indexes.width(15);
            indexes.text(role?.name);
            indexes.rectangle();
            indexes.wrapText();
            indexes.nextColumn();
            indexes.width(25);
            indexes.text(c?.memo ? c.memo : "Memo not set");
            if (!c?.memo) indexes.color({ argb: `FFBDBDBD` });
            indexes.rectangle();
            indexes.wrapText();
            indexes.nextColumn();
            indexes.width(15);
            indexes.text(musician ? musician.firstName : "Empty Chair");
            indexes.rectangle();
            indexes.wrapText();
            indexes.nextColumn();
            indexes.width(15);
            indexes.text(musician ? musician.lastName : "-");
            indexes.rectangle();
            indexes.wrapText();
            indexes.nextColumn();
            indexes.width(15);
            indexes.text(instruments.join(", "));
            indexes.rectangle();
            indexes.wrapText();
            indexes.nextColumn();
            indexes.width(15);
            indexes.text(stage?.id ? stage.wording() : "Ready");
            if (stage?.color) {
              indexes.fill(hexColorWithOpacity(stage.color, 0.8));
              indexes.color({ argb: `FF${stage.color.substring(1)}` });
            }
            indexes.rectangle();
            indexes.wrapText();
            indexes.nextColumn();
            indexes.width(25);
            indexes.text(assignment?.memo ? assignment?.memo : "Memo not set");
            if (!assignment?.memo) indexes.color({ argb: `FFBDBDBD` });
            indexes.rectangle();
            indexes.wrapText();
            indexes.nextColumn();
            indexes.nextRow();
            indexes.goToColumn(savedPositionColumn + 1);
          });
          indexes.goToColumn(savedPositionColumn);
        });
        indexes.columnRelativeMove(9);
        if (indexes.getRow() > savedFamilyRow)
          savedFamilyRow = indexes.getRow();
        indexes.goToRow(saveSectionRow);
      });

      indexes.goToRow(savedFamilyRow + 1);
    });

    return worksheet;
  }

  function piecesPage(workbook, worksheet) {
    const indexes = new Indexes(worksheet);
    rhapsodyHeader(workbook, worksheet, indexes);
    indexes.goToRow(4);
    indexes.goToColumn(0);
    indexes.text(project?.name);
    indexes.bold();
    indexes.fontSize(14);
    indexes.nextRow();

    utils.projectPieces.forEach((pp) => {
      indexes.goToRow(5);
      let savedPieceColumn = indexes.getX();
      const _internal = internal.iforProjectPiece(pp.id, utils, selection);
      const piece = utils.piecesMap[pp.pieceID];
      indexes.width(15);
      indexes.text(piece?.name);
      indexes.nextColumn();
      indexes.text(
        `${_internal.enabledPositionCount} Chair${
          _internal.enabledPositionCount > 1 ? "s" : ""
        }`
      );
      indexes.previousColumn();
      indexes.nextRow();
      indexes.text(piece?.composer);
      indexes.nextRow();

      _internal.families.forEach((f) => {
        if (f.enabledPositionCount === 0) return;
        const savedFamilyColumn = indexes.getX();
        const family = utils.familiesMap[f.familyID];
        indexes.text(family?.name);
        indexes.fill(`eeeeee`);
        indexes.rectangle();
        indexes.bold();
        indexes.nextColumn();
        indexes.text(
          `${f.enabledPositionCount} Chair${
            f.enabledPositionCount > 1 ? "s" : ""
          }`
        );
        indexes.fill(`eeeeee`);
        indexes.rectangle();
        indexes.previousColumn();
        indexes.nextRow();
        let savedFamilyRow = indexes.getRow();

        f.sections.forEach((s) => {
          if (s.enabledPositionCount === 0) return;
          const section = utils.sectionsMap[s.sectionID];
          indexes.text(section?.name);
          indexes.fill(`eeeeee`);
          indexes.rectangle();
          indexes.bold();
          indexes.nextColumn();
          indexes.text(
            `${s.enabledPositionCount} Chair${
              s.enabledPositionCount > 1 ? "s" : ""
            }`
          );
          indexes.fill(`eeeeee`);
          indexes.rectangle();
          indexes.nextRow();
          indexes.previousColumn();
          s.enabledPositionIDs.forEach((positionID) => {
            const p = s.getPosition(positionID);
            const chairs = getSimilarChairs(p.chairIDs, utils);
            indexes.text(p.order);
            // if (chairs.length > 1) {
            //   indexes.select(0, chairs.length - 1);
            //   indexes.merge();
            //   indexes.unselect();
            // }
            indexes.rectangle();
            indexes.wrapText();
            const savedPositionColumn = indexes.getX();
            indexes.nextColumn();
            chairs.forEach((c) => {
              const role = utils.sectionRolesMap[c.sectionRoleID];
              const musician = utils.musiciansMap[c.musicianID];
              const assignment = utils.assignmentsMap[c.assignmentID];
              const stage = utils.stagesMap[assignment?.mercuryStageID];
              const instruments = JSON.parse(
                c.instrumentIDs ? c.instrumentIDs : "[]"
              ).reduce((a, v) => {
                a.push(utils.instrumentsMap[v].name);
                return a;
              }, []);

              indexes.width(15);
              indexes.text(role?.name);
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(25);
              indexes.text(c?.memo ? c.memo : "Memo not set");
              if (!c?.memo) indexes.color({ argb: `FFBDBDBD` });
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(15);
              indexes.text(musician ? musician.firstName : "Empty Chair");
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(15);
              indexes.text(musician ? musician.lastName : "-");
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(15);
              indexes.text(instruments.join(", "));
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(15);
              indexes.text(stage?.id ? stage.wording() : "Ready");
              if (stage?.color) {
                indexes.fill(hexColorWithOpacity(stage.color, 0.8));
                indexes.color({ argb: `FF${stage.color.substring(1)}` });
              }
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(25);
              indexes.text(assignment?.memo ? assignment.memo : "Memo not set");
              if (!assignment?.memo) indexes.color({ argb: `FFBDBDBD` });
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.nextRow();
              indexes.goToColumn(savedPositionColumn + 1);
            });
            indexes.goToColumn(savedPositionColumn);
          });
          indexes.columnRelativeMove(9);
          if (indexes.getRow() > savedFamilyRow)
            savedFamilyRow = indexes.getRow();
          if (indexes.getX() > savedPieceColumn)
            savedPieceColumn = indexes.getX();
          indexes.nextRow();
          indexes.goToColumn(savedFamilyColumn);
        });
      });
      indexes.goToColumn(savedPieceColumn);
    });
  }

  function workSessionsPage(workbook, worksheet) {
    const indexes = new Indexes(worksheet);
    rhapsodyHeader(workbook, worksheet, indexes);
    indexes.goToRow(4);
    indexes.goToColumn(0);
    indexes.text(project?.name);
    indexes.bold();
    indexes.fontSize(14);
    indexes.nextRow();

    utils.workSessions.forEach((w) => {
      indexes.goToRow(5);
      let savedPieceColumn = indexes.getX();
      const _internal = internal.iforWorkSession(w.id, utils, selection);
      const workSession = utils.workSessionsMap[w.id];
      indexes.width(15);
      indexes.text(workSession?.title);
      indexes.nextColumn();
      indexes.text(
        `${_internal.enabledPositionCount} Chair${
          _internal.enabledPositionCount > 1 ? "s" : ""
        }`
      );
      indexes.previousColumn();
      indexes.nextRow();
      indexes.text(workSession?.formatDateRange());
      indexes.nextRow();

      _internal.families.forEach((f) => {
        if (f.enabledPositionCount === 0) return;
        const savedFamilyColumn = indexes.getX();
        const family = utils.familiesMap[f.familyID];
        indexes.text(family?.name);
        indexes.fill(`eeeeee`);
        indexes.rectangle();
        indexes.bold();
        indexes.nextColumn();
        indexes.text(
          `${f.enabledPositionCount} Chair${
            f.enabledPositionCount > 1 ? "s" : ""
          }`
        );
        indexes.fill(`eeeeee`);
        indexes.rectangle();
        indexes.previousColumn();
        indexes.nextRow();
        let savedFamilyRow = indexes.getRow();

        f.sections.forEach((s) => {
          if (s.enabledPositionCount === 0) return;
          const section = utils.sectionsMap[s.sectionID];
          indexes.text(section?.name);
          indexes.fill(`eeeeee`);
          indexes.rectangle();
          indexes.bold();
          indexes.nextColumn();
          indexes.text(
            `${s.enabledPositionCount} Chair${
              s.enabledPositionCount > 1 ? "s" : ""
            }`
          );
          indexes.fill(`eeeeee`);
          indexes.rectangle();
          indexes.nextRow();
          indexes.previousColumn();
          s.enabledPositionIDs.forEach((positionID) => {
            const p = s.getPosition(positionID);
            const chairs = getSimilarChairs(p.visibleChairIDs, utils);
            indexes.text(p.order);
            // if (chairs.length > 1) {
            //   indexes.select(0, chairs.length - 1);
            //   indexes.merge();
            //   indexes.unselect();
            // }
            indexes.rectangle();
            indexes.wrapText();
            const savedPositionColumn = indexes.getX();
            indexes.nextColumn();
            chairs.forEach((c) => {
              const role = utils.sectionRolesMap[c.sectionRoleID];
              const musician = utils.musiciansMap[c.musicianID];
              const assignment = utils.assignmentsMap[c.assignmentID];
              const stage = utils.stagesMap[assignment?.mercuryStageID];
              const instruments = JSON.parse(
                c.instrumentIDs ? c.instrumentIDs : "[]"
              ).reduce((a, v) => {
                a.push(utils.instrumentsMap[v].name);
                return a;
              }, []);

              indexes.width(15);
              indexes.text(role?.name);
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(25);
              indexes.text(c?.memo ? c.memo : "Memo not set");
              if (!c?.memo) indexes.color({ argb: `FFBDBDBD` });
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(15);
              indexes.text(musician ? musician.firstName : "Empty Chair");
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(15);
              indexes.text(musician ? musician.lastName : "-");
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(15);
              indexes.text(instruments.join(", "));
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(15);
              indexes.text(stage?.id ? stage.wording() : "Ready");
              if (stage?.color) {
                indexes.fill(hexColorWithOpacity(stage.color, 0.8));
                indexes.color({ argb: `FF${stage.color.substring(1)}` });
              }
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.width(25);
              indexes.text(assignment?.memo ? assignment.memo : "Memo not set");
              if (!assignment?.memo) indexes.color({ argb: `FFBDBDBD` });
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.nextRow();
              indexes.goToColumn(savedPositionColumn + 1);
            });
            indexes.goToColumn(savedPositionColumn);
          });
          indexes.columnRelativeMove(9);
          if (indexes.getRow() > savedFamilyRow)
            savedFamilyRow = indexes.getRow();
          if (indexes.getX() > savedPieceColumn)
            savedPieceColumn = indexes.getX();
          indexes.nextRow();
          indexes.goToColumn(savedFamilyColumn);
        });
      });
      indexes.goToColumn(savedPieceColumn);
    });
  }

  function assignmentsPage(workbook, worksheet) {
    const indexes = new Indexes(worksheet);
    rhapsodyHeader(workbook, worksheet, indexes);
    indexes.goToRow(4);
    indexes.goToColumn(0);
    indexes.text(project?.name);
    indexes.bold();
    indexes.fontSize(14);
    indexes.nextRow();

    indexes.goToColumn(5);

    utils.workSessions.forEach((w) => {
      indexes.width(25);
      indexes.select(2, 0);
      indexes.merge();
      indexes.unselect();
      indexes.text(w?.title);
      indexes.fill(`eeeeee`);
      indexes.rectangle();
      indexes.wrapText();
      indexes.nextRow();

      indexes.text(w?.formatDateRange());
      indexes.select(2, 0);
      indexes.merge();
      indexes.unselect();
      indexes.fill(`eeeeee`);
      indexes.rectangle();
      indexes.previousRow();
      indexes.wrapText();
      indexes.nextColumn();
      indexes.nextColumn();
      indexes.nextColumn();
    });

    indexes.goToColumn(0);
    indexes.nextRow();
    indexes.nextRow();

    internal.families.forEach((f) => {
      f.sections.forEach((s) => {
        const section = utils.sectionsMap[s.sectionID];
        indexes.text(section?.name);
        indexes.fill(`eeeeee`);
        indexes.rectangle();
        indexes.wrapText();
        s.visibleAssignmentIDs.forEach((assignmentID) => {
          const a = utils.assignmentsMap[assignmentID];
          const musician = utils.musiciansMap[a.musicianID];
          const stage = utils.stagesMap[a?.mercuryStageID];
          indexes.nextColumn();

          indexes.width(15);
          indexes.text(musician?.firstName);
          indexes.rectangle();
          indexes.wrapText();
          indexes.nextColumn();

          indexes.width(15);
          indexes.text(musician?.lastName);
          indexes.rectangle();
          indexes.wrapText();
          indexes.nextColumn();

          indexes.width(15);
          indexes.text(stage?.id ? stage.wording() : "Ready");
          if (stage?.color) {
            indexes.fill(hexColorWithOpacity(stage.color, 0.8));
            indexes.color({
              argb: `FF${stage.color.substring(1)}`,
            });
          }
          indexes.rectangle();
          indexes.wrapText();
          indexes.nextColumn();

          indexes.width(25);
          indexes.text(a?.memo ? a.memo : "Memo not set");
          if (!a?.memo) indexes.color({ argb: `FFBDBDBD` });
          indexes.rectangle();
          indexes.wrapText();
          indexes.nextColumn();

          utils.workSessions.forEach((w) => {
            const chairs = utils.chairs.filter(
              (c) =>
                c.assignmentID === a.id &&
                c.workSessionIDs.includes(w.id) &&
                !c.hidden
            );
            const projectPieceCount = chairs.reduce((a, v) => {
              if (!a.includes(v.projectPieceID)) a.push(v.projectPieceID);
              return a;
            }, []);
            if (chairs.length === 0) {
              indexes.text("Not Playing");
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.nextColumn();
            } else {
              const _chairs = getChairsForDescription(chairs);
              const instruments = _chairs.reduce((e, v) => {
                const i = JSON.parse(v.instrumentIDs ? v.instrumentIDs : "[]");
                i.forEach((_i) => {
                  if (!e.includes(utils.instrumentsMap[_i]?.name)) {
                    e.push(utils.instrumentsMap[_i]?.name);
                  }
                });
                return e;
              }, []);
              const text = [];
              _chairs.forEach((c) =>
                text.push(`${utils.sectionRolesMap[c.sectionRoleID]?.name}`)
              );
              indexes.text(
                `Plays on ${projectPieceCount.length} piece${
                  projectPieceCount.length > 1 ? "s" : ""
                } `
              );
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.text(`${text.join(",")}`);
              indexes.rectangle();
              indexes.wrapText();
              indexes.nextColumn();
              indexes.text(`${instruments.join(",")}`);
              indexes.rectangle();
              indexes.wrapText();
            }
            indexes.rectangle();
            indexes.wrapText();

            indexes.nextColumn();
          });

          indexes.goToColumn(0);
          indexes.nextRow();
        });
        indexes.nextRow();
      });
    });
  }

  function musiciansPage(workbook, worksheet) {
    const indexes = new Indexes(worksheet);
    rhapsodyHeader(workbook, worksheet, indexes);
    indexes.goToRow(4);
    indexes.nextRow();
    indexes.goToColumn(0);

    const usedProjectNotesIDs = musicianNotes.reduce((a, v) => {
      if (!a.includes(v.noteTypeID) && noteTypesMap[v.noteTypeID]) {
        a.push(v.noteTypeID);
      }
      return a;
    }, []);

    const columns = [
      "First Name",
      "Last Name",
      "Section",
      "Chair Number",
      "Role",
      "Instrument(s) on Project",
      "Email",
      "Phone",
      "Hiring Status",
      "Middle Name",
      "Nickname",
      "Birthdate",
      "Company Name",
      "Address",
      "Zipcode",
      "City",
      "State",
    ];

    columns.forEach((c) => {
      indexes.text(c);
      indexes.fill(`eeeeee`);
      indexes.rectangle();
      indexes.bold();
      indexes.width(15);
      indexes.nextColumn();
    });
    usedProjectNotesIDs.forEach((c) => {
      if (noteTypesMap[c]) {
        indexes.text(noteTypesMap[c].name);
        indexes.fill(`b3e5fc`);
        indexes.rectangle();
        indexes.bold();
        indexes.width(15);
        indexes.nextColumn();
      }
    });
    indexes.nextRow();
    indexes.goToColumn(0);

    const musicians = mapToArray(utils.musiciansMap)
      .filter((m) => {
        const assignment = utils.assignments.find((a) => a.musicianID === m.id);
        return assignment && assignment.active === true;
      })
      .sort((a, b) => {
        const aChairs = utils.chairs.filter((c) => c.musicianID === a.id);
        const bChairs = utils.chairs.filter((c) => c.musicianID === b.id);

        const aSection = utils.sectionsMap[aChairs[0]?.sectionID];
        const bSection = utils.sectionsMap[bChairs[0]?.sectionID];

        const aFamily = utils.familiesMap[aSection?.familyID];
        const bFamily = utils.familiesMap[bSection?.familyID];

        if (aFamily?.pos !== bFamily?.pos) return aFamily?.pos - bFamily?.pos;

        if (aSection?.pos !== bSection?.pos)
          return aSection?.pos - bSection?.pos;

        return aChairs[0]?.sectionOrder - bChairs[0]?.sectionOrder;
      });

    const assignments = mapToArray(utils.assignmentsMap);

    musicians.forEach((m) => {
      const notes = musicianNotes.filter((n) => n.musicianID === m.id);
      const assignment = assignments.find((a) => a.musicianID === m.id);
      const stage = utils.stagesMap[assignment?.mercuryStageID];
      const _chairs = utils.chairs.filter((c) => c.musicianID === m.id);
      const instruments = _chairs.reduce((e, v) => {
        const i = JSON.parse(v.instrumentIDs ? v.instrumentIDs : "[]");
        i.forEach((_i) => {
          if (!e.includes(utils.instrumentsMap[_i]?.name)) {
            e.push(utils.instrumentsMap[_i]?.name);
          }
        });
        return e;
      }, []);

      musicians.filter((m) => m.active);

      const sections = _chairs.reduce((a, v) => {
        if (!a.includes(utils.sectionsMap[v.sectionID].name))
          a.push(utils.sectionsMap[v.sectionID].name);
        return a;
      }, []);

      const sectionOrders = _chairs.reduce((a, v) => {
        if (!a.includes(v.sectionOrder)) a.push(v.sectionOrder);
        return a;
      }, []);

      const roles = _chairs.reduce((a, v) => {
        if (!a.includes(utils.sectionRolesMap[v.sectionRoleID].name))
          a.push(utils.sectionRolesMap[v.sectionRoleID].name);
        return a;
      }, []);

      indexes.text(m.firstName);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.lastName);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(sections.join(", "));
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(sectionOrders.join(", "));
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(roles.join(", "));
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(instruments.join(","));
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.email);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.phone);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(stage?.id ? stage.wording() : "Ready");
      if (stage?.color) {
        indexes.fill(hexColorWithOpacity(stage.color, 0.8));
        indexes.color({ argb: `FF${stage.color.substring(1)}` });
      }
      indexes.rectangle();
      indexes.wrapText();
      indexes.nextColumn();

      indexes.text(m.middleName);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.nickName);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.birthdate ? moment(m.birthdate).format("MM/DD/YYYY") : "");
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.companyName);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.address);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.zipcode);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.city);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.state);
      indexes.rectangle();
      indexes.nextColumn();

      usedProjectNotesIDs.forEach((noteTypeID) => {
        const note = notes.find((n) => n.noteTypeID === noteTypeID);
        if (note && noteTypesMap[note?.noteTypeID] === undefined) {
          return;
        }
        indexes.text(note?.value ?? "");
        indexes.rectangle();
        indexes.nextColumn();
      });

      indexes.goToColumn(0);
      indexes.nextRow();
    });
  }

  function removedMusiciansPage(workbook, worksheet) {
    const indexes = new Indexes(worksheet);
    rhapsodyHeader(workbook, worksheet, indexes);
    indexes.goToRow(4);
    indexes.nextRow();
    indexes.goToColumn(0);

    const usedProjectNotesIDs = musicianNotes.reduce((a, v) => {
      if (!a.includes(v.noteTypeID) && noteTypesMap[v.noteTypeID]) {
        a.push(v.noteTypeID);
      }
      return a;
    }, []);

    const columns = [
      "First Name",
      "Last Name",
      "Email",
      "Phone",
      "Hiring Status",
      "Middle Name",
      "Nickname",
      "Birthdate",
      "Company Name",
      "Address",
      "Zipcode",
      "City",
      "State",
    ];

    columns.forEach((c) => {
      indexes.text(c);
      indexes.fill(`eeeeee`);
      indexes.rectangle();
      indexes.bold();
      indexes.width(15);
      indexes.nextColumn();
    });
    usedProjectNotesIDs.forEach((c) => {
      if (noteTypesMap[c]) {
        indexes.text(noteTypesMap[c].name);
        indexes.fill(`b3e5fc`);
        indexes.rectangle();
        indexes.bold();
        indexes.width(15);
        indexes.nextColumn();
      }
    });
    indexes.nextRow();
    indexes.goToColumn(0);

    const musicians = mapToArray(utils.musiciansMap).filter((m) => {
      const assignment = utils.assignments.find((a) => a.musicianID === m.id);
      return assignment && assignment.active === false;
    });

    const assignments = mapToArray(utils.assignmentsMap);

    musicians.forEach((m) => {
      const notes = musicianNotes.filter((n) => n.musicianID === m.id);
      const assignment = assignments.find((a) => a.musicianID === m.id);
      const stage = utils.stagesMap[assignment?.mercuryStageID];

      indexes.text(m.firstName);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.lastName);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.email);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.phone);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(stage?.id ? stage.wording() : "Ready");
      if (stage?.color) {
        indexes.fill(hexColorWithOpacity(stage.color, 0.8));
        indexes.color({ argb: `FF${stage.color.substring(1)}` });
      }
      indexes.rectangle();
      indexes.wrapText();
      indexes.nextColumn();

      indexes.text(m.middleName);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.nickName);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.birthdate ? moment(m.birthdate).format("MM/DD/YYYY") : "");
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.companyName);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.address);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.zipcode);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.city);
      indexes.rectangle();
      indexes.nextColumn();

      indexes.text(m.state);
      indexes.rectangle();
      indexes.nextColumn();

      usedProjectNotesIDs.forEach((noteTypeID) => {
        const note = notes.find((n) => n.noteTypeID === noteTypeID);
        if (note && noteTypesMap[note?.noteTypeID] === undefined) {
          return;
        }
        indexes.text(note?.value ?? "");
        indexes.rectangle();
        indexes.nextColumn();
      });

      indexes.goToColumn(0);
      indexes.nextRow();
    });
  }

  function attendancePage(workbook, worksheet) {
    const indexes = new Indexes(worksheet);
    rhapsodyHeader(workbook, worksheet, indexes);
    indexes.goToRow(4);
    indexes.goToColumn(0);
    indexes.text(project?.name);
    indexes.bold();
    indexes.fontSize(14);
    indexes.nextRow();

    indexes.goToColumn(0);

    indexes.text("Musician");
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.bold();
    indexes.nextColumn();
    indexes.text("Work Session");
    indexes.width(25);
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.bold();
    indexes.nextColumn();
    indexes.width(30);
    indexes.text("Date");
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.bold();
    indexes.nextColumn();
    indexes.text("Type");
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.bold();
    indexes.nextColumn();
    indexes.width(30);
    indexes.text("Comments");
    indexes.fill(`eeeeee`);
    indexes.rectangle();
    indexes.bold();
    indexes.nextColumn();

    indexes.goToColumn(0);
    indexes.nextRow();

    utils.assignments.forEach((a) => {
      const tardiness: Tardy[] = JSON.parse(a.tardiness ? a.tardiness : "[]");
      if (tardiness.length) {
        const musician = utils.musiciansMap[a.musicianID];
        indexes.text(musician.fullName());
        indexes.rectangle();
        indexes.nextColumn();
        tardiness.forEach((t) => {
          indexes.text(`${utils.workSessionsMap[t.workSessionID].title}`);
          indexes.rectangle();
          indexes.nextColumn();

          indexes.text(
            `${utils.workSessionsMap[t.workSessionID].formatDateRange()}`
          );
          indexes.rectangle();
          indexes.nextColumn();

          indexes.text(t.type);
          indexes.rectangle();
          indexes.nextColumn();

          indexes.text(t.comments);
          indexes.rectangle();
          indexes.nextColumn();
          indexes.goToColumn(1);
          indexes.nextRow();
        });
        indexes.goToColumn(0);
        indexes.nextRow();
      }
    });
  }

  function download() {
    console.log("download start");
    const workbook = createWorkbook();
    const fileName = `${utils.project.name} Rhapsody Export ${moment().format(
      "lll"
    )}`;

    const grouped = newPage(workbook, "Grouped");
    groupedPage(workbook, grouped);

    if (!project.template) {
      const pieces = newPage(workbook, "Pieces");
      piecesPage(workbook, pieces);

      const workSessions = newPage(workbook, "Work Sessions");
      workSessionsPage(workbook, workSessions);

      const assignments = newPage(workbook, "Assignments");
      assignmentsPage(workbook, assignments);

      const attendance = newPage(workbook, "Attendance");
      attendancePage(workbook, attendance);
    }

    const musicians = newPage(workbook, "Active Musicians");
    musiciansPage(workbook, musicians);

    const allMusicians = newPage(workbook, "Removed Musicians");
    removedMusiciansPage(workbook, allMusicians);

    console.log("download stop");
    downloadExcel(workbook, fileName);
    console.log("download Finished");
  }

  return () => {
    setTrigger(true);
  };
}

function getSimilarChairs(chairs: number[], utils: LayoutUtils) {
  const ret: RhapsodyChair[] = [];
  chairs.forEach((c) => {
    const _chair = utils.chairsMap[c];
    if (_chair?.hidden) return;
    const index = ret?.findIndex(
      (e) => e.musicianID === _chair?.musicianID && e.memo === _chair?.memo
    );
    if (index >= 0) {
      const cInstruments = JSON.parse(
        _chair?.instrumentIDs ? _chair?.instrumentIDs : "[]"
      );
      const eInstrument = JSON.parse(
        ret[index].instrumentIDs ? ret[index].instrumentIDs : "[]"
      );
      const combinedInstruments = cInstruments.concat(eInstrument);
      ret[index].instrumentIDs = JSON.stringify([
        ...new Set(combinedInstruments),
      ]);
    } else {
      ret.push(_chair);
    }
  });
  return ret;
}

function getChairsForDescription(chairs: RhapsodyChair[]) {
  const ret: RhapsodyChair[] = [];
  chairs.forEach((c) => {
    const index = ret?.findIndex(
      (e) =>
        e.musicianID === c.musicianID && e.sectionRoleID === c.sectionRoleID
    );
    if (index >= 0) {
      const cInstruments = JSON.parse(c.instrumentIDs ? c.instrumentIDs : "[]");
      const eInstrument = JSON.parse(
        ret[index].instrumentIDs ? ret[index].instrumentIDs : "[]"
      );
      const combinedInstruments = cInstruments.concat(eInstrument);
      ret[index].instrumentIDs = JSON.stringify([
        ...new Set(combinedInstruments),
      ]);
    } else {
      ret.push(c);
    }
  });
  return ret;
}

function hexColorWithOpacity(hexColor, opacity) {
  // Remove the '#' if present
  hexColor = hexColor.replace("#", "");

  // Convert hex to RGB
  const originalColor = [
    parseInt(hexColor.slice(0, 2), 16),
    parseInt(hexColor.slice(2, 4), 16),
    parseInt(hexColor.slice(4, 6), 16),
  ];

  // Ensure opacity is in the range [0, 1]
  opacity = Math.max(0, Math.min(1, opacity));

  // Calculate the new color by mixing with white
  const newColor = originalColor.map((channel) =>
    Math.round(channel * (1 - opacity) + 255 * opacity)
  );

  // Convert the RGB components back to hex
  const newColorHex = `${newColor
    .map((channel) => channel.toString(16).padStart(2, "0"))
    .join("")}`;

  return newColorHex;
}
