import { useMemo } from "react";
import { useGetBookletQuery, useGetBookletsQuery } from "./bookletEndpoints";
import { Booklet } from "entities/booklet";

export function useBooklet(bookletID: number | undefined) {
  const bookletQuery = useGetBookletQuery(bookletID ?? 0, {
    skip: (bookletID ?? 0) <= 0,
  });
  const bookletEntity = bookletQuery.data;

  return useMemo(() => {
    const ret: typeof bookletQuery & { booklet?: Booklet | undefined } = {
      ...bookletQuery,
    };
    if (bookletEntity) ret.booklet = new Booklet(bookletEntity);
    return ret;
  }, [bookletEntity]);
}

export function useBooklets(ars?, opts?) {
  const bookletQuery = useGetBookletsQuery(ars, opts);
  const bookletEntitiesMap = bookletQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof bookletQuery & {
      booklets?: Booklet[];
      bookletsMap?: { [id: number]: Booklet };
    } = {
      ...bookletQuery,
      booklets: [],
      bookletsMap: {},
    };
    if (bookletEntitiesMap) {
      const booklets: Booklet[] = [];
      const bookletsMap: { [id: number]: Booklet } = {};

      for (const key in bookletEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(bookletEntitiesMap, key)) {
          const c = bookletEntitiesMap[key];
          const booklet = new Booklet(c);
          booklets.push(booklet);
          bookletsMap[booklet.id] = booklet;
        }
      }
      ret.booklets = booklets;
      ret.bookletsMap = bookletsMap;
    }

    return ret;
  }, [bookletEntitiesMap]);
}
