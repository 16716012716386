import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import ImageResize from "image-resize";
import React, { ReactNode, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import { useUploadFileMutation } from "redux/unity/unity";
import getCroppedImg from "./cropImage";
import { Button, Chip } from "@mui/joy";

export default function Upload({
  raw = false,
  onUpload,
  disabled = false,
  cropDimension = undefined,
  label = "Browse files...",
  children,
  className,
}: {
  raw?: boolean;
  onUpload: (e: string, file?: File) => void;
  disabled?: boolean;
  cropDimension?: { width: number; height: number };
  label?: string;
  children?: ReactNode;
  className?: string;
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [rotation, setRotation] = React.useState(0);
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [croppedImage, setCroppedImage] = React.useState(null);
  const [uploadFile] = useUploadFileMutation();

  const onDrop = useCallback(async (acceptedFiles) => {
    if (cropDimension) {
      const file = acceptedFiles[0];
      const imageDataUrl: any = await readFile(file);
      setValue(imageDataUrl);
      setOpen(true);
    } else {
      upload(acceptedFiles[0]);
    }
  }, []); //eslint-disable-line

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const upload = async (file) => {
    if (raw) {
      onUpload(file);
    } else {
      await uploadFile(file)
        .unwrap()
        .then((e) => {
          const url = `${e}`;
          onUpload(url, file);
        });
    }
  };

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = React.useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        value,
        croppedAreaPixels,
        rotation
      );
      const imageResize = new ImageResize({
        format: "png",
        width: cropDimension.width,
        height: cropDimension.height,
      });
      imageResize.play(croppedImage).then(async (resized: string) => {
        fetch(resized)
          .then((res) => res.blob())
          .then(async (blob) => {
            upload(blob);
            setOpen(false);
          });
      });
      // upload(croppedImage);
      // setOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  if (disabled) {
    return <div />;
  }

  return (
    <div style={{ display: "flex" }} className={className}>
      <Box
        {...getRootProps()}
        sx={{
          cursor: "pointer",
          "&:hover": { background: "rgba(155,155,155,0.1)" },
        }}
      >
        <input {...getInputProps()} />
        {children ? (
          <Box sx={{ background: isDragActive ? "#2196f355" : undefined }}>
            {children}
          </Box>
        ) : (
          <>
            {isDragActive ? (
              <Chip
                size="sm"
                color="primary"
                sx={{ pt: 0.5, pb: 0.5, background: "white" }}
                startDecorator={<i className="fad fa-chevron-square-down"></i>}
                variant="soft"
              >
                Drop the files here!
              </Chip>
            ) : (
              <Chip
                size="sm"
                color="neutral"
                sx={{ pt: 0.5, pb: 0.5, background: "white" }}
                startDecorator={
                  <i className="fad fa-cloud-upload-alt p-right"></i>
                }
                variant="outlined"
              >
                {label}
              </Chip>
            )}
          </>
        )}
      </Box>
      {cropDimension ? (
        <DialogClose
          onClose={() => setOpen(false)}
          aria-labelledby="simple-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="simple-dialog-title">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flex: 1,
                width: "100%",
              }}
            >
              <Typography variant="h6">Crop the image</Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                width: 600,
                height: 600,
                position: "relative",
                margin: "auto",
              }}
            >
              <Cropper
                image={value}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={cropDimension.width / cropDimension.height}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
              {croppedImage}
            </div>
            <div>
              <Typography variant="overline">Zoom</Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom as number)}
              />
            </div>
            <div>
              <Typography variant="overline">Rotation</Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                onChange={(e, rotation) => setRotation(rotation as number)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={showCroppedImage}>Crop</Button>
          </DialogActions>
        </DialogClose>
      ) : (
        []
      )}
    </div>
  );
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
