import {
  Box,
  Button,
  Chip,
  IconButton,
  Option,
  Select,
  Textarea,
  Typography,
} from "@mui/joy";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { nanoid } from "@reduxjs/toolkit";
import DialogClose from "atoms/DialogClose/DialogClose";
import { Assignment } from "entities/assignment";
import { Musician } from "entities/musician";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "reducers/rhapsody";
import {
  attendanceAssignmentIDsSelector,
  attendanceWorkSessionIDSelector,
  layoutUtilsSelector,
  setAttendanceAssignmentIDs,
  setAttendanceWorkSessionID,
} from "reducers/v2/missionControl";
import {
  useUpdateAssignmentMutation,
  useUpdateBatchAssignmentsMutation,
} from "redux/assignment/assignmentEndpoints";

export type Tardy = {
  id?: string;
  projectID?: number;
  assignmentIDs?: number[];
  workSessionID?: number;
  type?: "Late" | "No Show";
  comments?: string;
};

/**
 *
 * @returns {ReactElement} AttendanceAssignments page
 */
export function AttendanceAssignments() {
  const [tab, setTab] = useState(0);
  const [newOpen, setNewOpen] = useState(false);
  const [tardy, setTardy] = useState<Tardy>({});
  const utils = useSelector(layoutUtilsSelector);
  const open = useSelector(formOpenSelector("attendance"));
  const attendanceWorkSessionID = useSelector(attendanceWorkSessionIDSelector);
  const [updateAssignments] = useUpdateBatchAssignmentsMutation();
  const [updateAssignment] = useUpdateAssignmentMutation();
  const attendanceAssignmentIDs = useSelector(attendanceAssignmentIDsSelector);
  const attendanceAssignments = attendanceAssignmentIDs?.reduce<Assignment[]>(
    (a, v) => {
      a.push(utils.assignmentsMap[v]);
      return a;
    },
    []
  );
  const musicians = attendanceAssignments?.reduce<Musician[]>((a, v) => {
    a.push(utils.musiciansMap[v.musicianID]);
    return a;
  }, []);

  useEffect(() => {
    if (attendanceAssignmentIDs?.length === 1) {
      setTardy((t) => ({ ...t, assignmentID: attendanceAssignmentIDs[0] }));
    }
  }, [attendanceAssignmentIDs]);

  useEffect(() => {
    if (attendanceWorkSessionID) {
      setTardy((t) => ({ ...t, workSessionID: attendanceWorkSessionID[0] }));
    }
  }, [attendanceWorkSessionID]);

  const dispatch = useDispatch();

  const onClose = () => {
    setTardy({});
    dispatch(setFormOpen({ isOpen: false, formID: "attendance" }));
    dispatch(setAttendanceAssignmentIDs([]));
    dispatch(setAttendanceWorkSessionID());
  };

  const createTardy = () => {
    const body = [];
    tardy.assignmentIDs.forEach((assignmentID) => {
      const assignment = attendanceAssignments.find(
        (a) => a.id === assignmentID
      );
      if (assignment) {
        const tardiness: Tardy[] = JSON.parse(
          assignment.tardiness ? assignment.tardiness : "[]"
        );
        const _tardy = { ...tardy };
        delete _tardy.assignmentIDs;
        body.push({
          id: assignmentID,
          tardiness: JSON.stringify([...tardiness, _tardy]),
        });
      }
    });

    console.log(body);

    updateAssignments(body);
    setTardy({});
    setNewOpen(false);
  };

  const musician = musicians[tab];
  const assignment = musician
    ? attendanceAssignments.find((a) => a.musicianID === musician.id)
    : undefined;
  const tardiness: Tardy[] = assignment
    ? JSON.parse(assignment.tardiness ? assignment.tardiness : "[]")
    : [];

  const newTardy = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        p: 2,
        width: "100%",
        maxWidth: 600,
        margin: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography level="h6">Who?</Typography>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() =>
              setTardy((t) => ({
                ...t,
                assignmentIDs: attendanceAssignmentIDs,
              }))
            }
            level="body3"
          >
            <u>Select All</u>
          </Typography>
          <Typography level="body3">|</Typography>
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() => setTardy((t) => ({ ...t, assignmentIDs: [] }))}
            level="body3"
          >
            <u>Unselect All</u>
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={1}>
        {musicians.map((m) => {
          const assignment = attendanceAssignments.find(
            (a) => a.musicianID === m.id
          );
          const selected = tardy.assignmentIDs?.includes(assignment?.id);
          return (
            <Grid item key={m.id}>
              <Chip
                startDecorator={
                  <MusicianAvatar border={1} musician={m} size={28} />
                }
                variant={"soft"}
                color={selected ? "primary" : "neutral"}
                onClick={() => {
                  if (selected) {
                    setTardy((e) => ({
                      ...e,
                      assignmentIDs: e.assignmentIDs.filter(
                        (i) => i !== assignment.id
                      ),
                    }));
                  } else {
                    setTardy((e) => ({
                      ...e,
                      assignmentIDs: [...e.assignmentIDs, assignment.id],
                    }));
                  }
                }}
              >
                {m.fullName()}
              </Chip>
            </Grid>
          );
        })}
      </Grid>
      <Typography level="h6">When?</Typography>
      <Select
        slotProps={{
          listbox: { sx: { zIndex: 9999 } },
        }}
        placeholder="Select Work Session..."
        value={tardy.workSessionID ?? null}
        color={tardy.workSessionID ? "primary" : "neutral"}
        onChange={(e, v) => setTardy((t) => ({ ...t, workSessionID: v }))}
        variant="soft"
      >
        {utils.workSessions?.map((w) => (
          <Option value={w.id} key={w.id}>
            <Box sx={{ flexDirection: "column", alignItems: "start" }}>
              <span>{w.title}</span>
              <Typography level="body2"> - {w.formatDateRange()}</Typography>
            </Box>
          </Option>
        ))}
      </Select>
      <Typography level="h6">What?</Typography>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Button
          onClick={() => setTardy((t) => ({ ...t, type: "Late" }))}
          startDecorator={<i className="fa-regular fa-alarm-clock"></i>}
          color={tardy.type === "Late" ? "primary" : "neutral"}
          variant="soft"
        >
          Late
        </Button>
        <Button
          onClick={() => setTardy((t) => ({ ...t, type: "No Show" }))}
          startDecorator={<i className="fa-regular fa-user-xmark"></i>}
          color={tardy.type === "No Show" ? "primary" : "neutral"}
          variant="soft"
        >
          No Show
        </Button>
      </Box>
      <Textarea
        placeholder={`Comments`}
        sx={{ width: "100%", background: "#FFFDEF", maxWidth: 600 }}
        value={tardy.comments ?? ""}
        onChange={(e) => setTardy((t) => ({ ...t, comments: e.target.value }))}
        size="sm"
        minRows={5}
        maxRows={10}
        endDecorator={
          <Typography level="body4">Not visible by the Musician</Typography>
        }
      />
    </Box>
  );

  const history = (
    <DataGridPremium
      hideFooter
      autoHeight
      rows={tardiness ?? []}
      columns={[
        {
          field: "workSessionID",
          headerName: "Work Session",
          width: 300,
          renderCell: (r) => {
            return (
              <Box>
                <Typography>{utils.workSessionsMap[r.value]?.title}</Typography>
                <Typography level="body2">
                  {utils.workSessionsMap[r.value]?.formatDateRange()}
                </Typography>
              </Box>
            );
          },
        },
        {
          field: "type",
          headerName: "Type",
          renderCell: (c) => {
            return (
              <Typography
                startDecorator={
                  c.row.type === "Late" ? (
                    <i className="fa-regular fa-alarm-clock"></i>
                  ) : (
                    <i className="fa-regular fa-user-xmark"></i>
                  )
                }
                sx={{ textTransform: "capitalize" }}
                level="body2"
              >
                {`${c.value}`.replace("-", " ")}
              </Typography>
            );
          },
        },
        { field: "comments", flex: 1, headerName: "Comments" },
        {
          field: "id",
          align: "right",
          headerName: "Delete",
          renderCell: (r) => {
            return (
              <IconButton
                size="sm"
                variant="plain"
                color="neutral"
                onClick={() => {
                  updateAssignment({
                    id: assignment.id,
                    body: {
                      tardiness: JSON.stringify(
                        tardiness.filter((t) => t.id !== r.row.id)
                      ),
                    },
                  });
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </IconButton>
            );
          },
        },
      ]}
    />
  );
  return (
    <>
      <DialogClose fullWidth maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle sx={{ justifyContent: "space-between", display: "flex" }}>
          <Typography
            level="h5"
            startDecorator={<i className="fa-regular fa-clock-desk"></i>}
          >
            Attendance
          </Typography>
          <Button
            onClick={() => {
              setNewOpen(true);
              setTardy((t) => ({
                ...t,
                assignmentIDs: [assignment?.id],
                workSessionID: attendanceWorkSessionID,
                id: nanoid(),
              }));
            }}
            size="sm"
          >
            + Add new
          </Button>
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          <Tabs
            onChange={(e, v) => setTab(v)}
            value={tab}
            variant="scrollable"
            sx={{ width: "100%" }}
          >
            {musicians.map((m) => (
              <Tab
                label={
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <MusicianAvatar size={24} musician={m} />
                    <span>{m.fullName()}</span>
                  </Box>
                }
                key={m.id}
              />
            ))}
          </Tabs>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {history}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="soft" color="neutral">
            Close
          </Button>
        </DialogActions>
      </DialogClose>
      <DialogClose
        open={newOpen}
        onClose={() => {
          setNewOpen(false);
          setTardy({});
        }}
      >
        <DialogContent sx={{ width: 500 }}>{newTardy}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNewOpen(false);
              setTardy({});
            }}
            variant="soft"
            color="neutral"
          >
            Cancel
          </Button>
          <Button
            disabled={
              tardy.assignmentIDs?.length === 0 ||
              !tardy.workSessionID ||
              !tardy.type
            }
            color={
              tardy.assignmentIDs?.length === 0 ||
              !tardy.workSessionID ||
              !tardy.type
                ? "neutral"
                : "primary"
            }
            onClick={createTardy}
          >
            Create
          </Button>
        </DialogActions>
      </DialogClose>
    </>
  );
}
