import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Booklet_Entity } from "entities/booklet";
import { rhapsodyApi } from "redux/api";

const bookletsAdapter = createEntityAdapter<Booklet_Entity>();
const initialState = bookletsAdapter.getInitialState();

export const bookletEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getBooklets: build.query<
      EntityState<Booklet_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `booklets`,
          params: args,
        };
      },
      transformResponse: (responseData: Booklet_Entity[]) => {
        return bookletsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["booklets"],
    }),
    getBooklet: build.query<Booklet_Entity, number>({
      query: (id) => `booklets/${id}`,
      providesTags: (result, error, id) => [{ type: "booklets", id }],
    }),
    createBooklet: build.mutation<Booklet_Entity, Partial<Booklet_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `booklets`,
      }),
      invalidatesTags: ["booklets"],
    }),
    updateBooklet: build.mutation<
      void,
      { id: number; body: Partial<Booklet_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `booklets/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "booklets", id },
        "booklets",
      ],
    }),
    deleteBooklet: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `booklets/${id}`,
      }),
      invalidatesTags: ["booklets"],
    }),
  }),
});

export const {
  useGetBookletQuery,
  useGetBookletsQuery,
  useCreateBookletMutation,
  useDeleteBookletMutation,
  useUpdateBookletMutation,
} = bookletEndpoints;

export default bookletEndpoints;
