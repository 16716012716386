/**
 * Booklet_Entity type guard.
 *
 * @param {any} bookletJson Booklet object from API
 * @returns {boolean} Return true if type is Booklet_Entity
 */
function validator(bookletJson: unknown): bookletJson is Booklet_Entity {
  if (typeof bookletJson === "object" && bookletJson != null) {
    Booklet_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(bookletJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Booklet_Entity {
  static requiredFields = [
    "id",
    "organizationID",
    "name",
    "description",
    "mergeTag",
    "snippet",
    "author",
    "content",
    "projectID",
    "venueID",
    "musicianID",
    "tagID",
    "pieceID",
    "createdAt",
    "updatedAt",
  ];

  constructor(bookletJson: unknown) {
    if (validator(bookletJson)) {
      this.id = bookletJson.id;
      this.organizationID = bookletJson.organizationID;
      this.name = bookletJson.name;
      this.description = bookletJson.description;
      this.mergeTag = bookletJson.mergeTag;
      this.snippet = bookletJson.snippet;
      this.author = bookletJson.author;
      this.content = bookletJson.content;
      this.projectID = bookletJson.projectID;
      this.venueID = bookletJson.venueID;
      this.musicianID = bookletJson.musicianID;
      this.tagID = bookletJson.tagID;
      this.publicToken = bookletJson.publicToken;
      this.active = bookletJson.active;
      this.pieceID = bookletJson.pieceID;
      this.headerTitle = bookletJson.headerTitle;
      this.headerSubtitle = bookletJson.headerSubtitle;
      this.createdAt = bookletJson.createdAt;
      this.updatedAt = bookletJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Booklet_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  organizationID: number;
  name: string;
  description: string;
  mergeTag: string;
  snippet: boolean;
  author: string;
  content: string;
  projectID: number;
  venueID: number;
  musicianID: number;
  tagID: number;
  pieceID: number;
  publicToken: string;
  active: boolean;
  headerSubtitle: string;
  headerTitle: string;
  createdAt: string;
  updatedAt: string;
}
