import {
  Box,
  Checkbox,
  Link,
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Menu,
  MenuItem,
  Sheet,
  Switch,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useSelector } from "react-redux";
import {
  missionControlModeSelector,
  missionControlViewModeSelector,
  setActionInitiator,
  setFormOpen,
  setRetrieveInstrumentationForProjectPieceID,
  setSelectedPieceID,
  setSelectedProjectPieceID,
} from "reducers/rhapsody";
import {
  chairIDsHoveredSelector,
  layoutDebugSelector,
  layoutInternalSelector,
  layoutUtilsSelector,
  selectionSelector,
  setSelectedProjectPieceIDs,
} from "reducers/v2/missionControl";
import { Dictionary } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { usePiece } from "redux/piece/pieceHooks";
import { useNavigate } from "react-router-dom";
import { useMissionControlModeTint } from "hooks/Layout/v2";
import {
  useDeleteProjectPieceMutation,
  useUpdateProjectPieceMutation,
} from "redux/projectPiece/projectPieceEndpoints";
import { useDeleteBatchChairsMutation } from "redux/rhapsodyChair/rhapsodyChairEndpoints";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { ListItemIcon, ListItemText, alpha } from "@mui/material";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { SoloButton, useSoloProjectPiece } from "./utils";

/**
 *
 * @returns {ReactElement} ProjectPiecesSelect page
 */
export function ProjectPiecesSelect({ hideMenu }: { hideMenu?: boolean }) {
  const utils = useSelector(layoutUtilsSelector);
  const missionControlMode = useSelector(missionControlModeSelector);
  const missionControlViewMode = useSelector(missionControlViewModeSelector);
  const handleSoloProjectPiece = useSoloProjectPiece();
  const { projectPieces } = utils;
  const { selectedProjectPieceIDs } = useSelector(selectionSelector);
  const hasOneProjectPieceSelected = oneTrue(selectedProjectPieceIDs);
  const dispatch = useDispatch();

  const handleSelectProjectPiece = (id: number) => {
    dispatch(setActionInitiator("projectPiece"));
    const currentValue = selectedProjectPieceIDs[id] ?? false;
    const nextValue = !currentValue;
    if (!(hasOneProjectPieceSelected && currentValue)) {
      // dispatch(layoutUnselectAll());
      dispatch(
        setSelectedProjectPieceIDs({
          ...selectedProjectPieceIDs,
          [id]: nextValue,
        })
      );
    }
  };

  return (
    <List>
      {projectPieces.map((p, i) => {
        return (
          <ProjectPieceListItemButton
            key={p.id}
            toggle
            disableMenu={missionControlMode !== "edit" || hideMenu}
            index={i}
            projectPieceID={p.id}
            id={p.pieceID}
            disableCheck={
              hasOneProjectPieceSelected && selectedProjectPieceIDs[p.id]
            }
            disabled={missionControlViewMode === "pieces"}
            selected={selectedProjectPieceIDs[p.id]}
            onEdit={() => {
              dispatch(setSelectedProjectPieceID(p.id));
              dispatch(setFormOpen({ formID: "projectPiece", isOpen: true }));
            }}
            onSolo={() => handleSoloProjectPiece(p.id)}
            onClick={() => handleSelectProjectPiece(p.id)}
          />
        );
      })}
    </List>
  );
}

// has exactly one item to true
function oneTrue(s: Dictionary<boolean>) {
  let count = 0;

  for (const key in s) {
    if (Object.prototype.hasOwnProperty.call(s, key)) {
      if (s[key]) count++;
    }
  }

  return count === 1;
}

export function ProjectPieceListItemButton({
  id,
  index,
  projectPieceID,
  selected,
  disableCheck,
  onClick,
  onSolo,
  toggle,
  onAdd,
  disableMenu,
  disabled,
  onEdit,
  color: _color,
  tooltip,
}: {
  projectPieceID?: number;
  id: number;
  index?: number;
  selected?: boolean;
  disableCheck?: boolean;
  onClick?: () => void;
  onEdit?: () => void;
  onSolo?: () => void;
  onAdd?: () => void;
  toggle?: boolean;
  disabled?: boolean;
  disableMenu?: boolean;
  tooltip?: string;
  color?: "warning" | "primary" | "neutral";
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const { piece } = usePiece(id);
  const { validProjectPieces, callSent } = useSelector(layoutInternalSelector);
  const debug = useSelector(layoutDebugSelector);
  const navigate = useNavigate();
  const missionControlModeTint = useMissionControlModeTint();
  let _missionControlModeTint = missionControlModeTint;
  const missionControlNode = useSelector(missionControlModeSelector);
  if (_color) {
    _missionControlModeTint = "#4186DE";
  }
  const { projectPieces, chairs } = useSelector(layoutUtilsSelector);
  const { selectedProjectPieceIDs } = useSelector(selectionSelector);
  const dispatch = useDispatch();
  const [deleteProjectPiece] = useDeleteProjectPieceMutation();
  const [deleteChairs] = useDeleteBatchChairsMutation();
  const askQuestion = useAskQuestion();
  const [updateProjectPiece] = useUpdateProjectPieceMutation();
  const chairIDsHovered = useSelector(chairIDsHoveredSelector);
  const utils = useSelector(layoutUtilsSelector);
  const chairsForProjectPiece = chairs.filter(
    (c) => c.projectPieceID === projectPieceID
  );

  let variant: "soft" | undefined;
  chairIDsHovered?.forEach((c) => {
    const _chair = utils.chairsMap[c];
    if (_chair?.projectPieceID === projectPieceID) {
      variant = "soft";
      return;
    }
  });

  const askDelete = () => {
    askQuestion("Are you sure?", ["Cancel", "Yes"], {
      subtitle:
        validProjectPieces.length === 1 ? (
          <Typography>
            You are about to delete the only Project Piece. Chairs won't be
            deleted and your project will have a unique seating arrangement.
          </Typography>
        ) : (
          <Typography>
            You are about to delete a Project Piece. Chairs linked to this
            Project Piece will be deleted as well.
          </Typography>
        ),
    }).then((i) => {
      if (i == 1) confirmDelete();
    });
  };

  const confirmDelete = async () => {
    if (validProjectPieces.length === 1) {
      updateProjectPiece({
        id: validProjectPieces[0]?.id,
        body: { pieceID: -1 },
      });
    } else {
      deleteProjectPiece(projectPieceID);
      const projectPieceChairs = chairs.filter(
        (c) => c.projectPieceID === projectPieceID
      );
      deleteChairs(projectPieceChairs);
      // we deselect the deleted project piece
      const otherProjectPieces = projectPieces.filter(
        (e) => e.id !== projectPieceID
      );
      const _selectedProjectPieceIDs = {
        ...selectedProjectPieceIDs,
        [projectPieceID]: false,
      };
      // If there is only 1 project piece remaining, we select it.
      if (otherProjectPieces.length === 1) {
        _selectedProjectPieceIDs[otherProjectPieces[0].id] = true;
      }
      dispatch(setSelectedProjectPieceIDs(_selectedProjectPieceIDs));
      setAnchorEl(null);
    }
  };

  let CheckComponent = Checkbox;
  if (toggle) CheckComponent = Switch;

  return (
    <Tooltip size="sm" arrow title={tooltip}>
      <Box>
        <ListItemButton
          variant={variant}
          selected={selected}
          onClick={() => {
            if (!disableCheck && !disabled) {
              onClick();
            }
          }}
          sx={{
            gap: 1,
            justifyContent: "space-between",
            cursor: disableCheck || disabled ? "not-allowed" : undefined,
            transition: "background .2s",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <CheckComponent
              disabled={disabled}
              variant={disableCheck ? "soft" : undefined}
              color={toggle ? undefined : "primary"}
              size="sm"
              checked={selected ?? false}
              sx={{
                cursor: disableCheck || disabled ? "not-allowed" : undefined,
                pointerEvents: disableCheck || disabled ? "none" : undefined,
                opacity: disabled ? 0.4 : 1,
              }}
            />
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography level="body2">{`${index + 1}.`}</Typography>
              <Link
                level="body2"
                color="neutral"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (piece?.id) {
                    dispatch(setSelectedPieceID(piece?.id));
                  }
                }}
                sx={{
                  fontWeight: "normal",
                  color: selected ? _missionControlModeTint : undefined,
                }}
                endDecorator={
                  debug ? (
                    <Typography level="body5">
                      {projectPieceID} ({id})
                    </Typography>
                  ) : undefined
                }
              >
                {piece?.name ?? "Seating assignment"}
                {piece?.composer ? `, ${piece?.composer}` : ""}
              </Link>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            {!disableMenu ? (
              <Sheet
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setAnchorEl(e.currentTarget);
                }}
                // variant="outlined"
                sx={{
                  width: 20,
                  height: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 4,
                  cursor: "pointer",
                  "&:hover": {
                    background: alpha("#3774CB", 0.1),
                  },
                  "&:active": {
                    background: alpha("#3774CB", 0.2),
                  },
                }}
              >
                <Typography level="body3" sx={{ color: "inherit" }}>
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </Typography>
              </Sheet>
            ) : (
              []
            )}
            <Menu
              disablePortal
              size="sm"
              onClose={() => setAnchorEl(null)}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                // setAnchorEl(e.currentTarget);
              }}
            >
              {missionControlNode === "edit" && onEdit ? (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    onEdit();
                  }}
                >
                  <ListItemDecorator>
                    <i className="fa-solid fa-swap"></i>
                  </ListItemDecorator>
                  <ListItemContent>Change Piece</ListItemContent>
                </MenuItem>
              ) : (
                []
              )}
              {id ? (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    dispatch(setSelectedPieceID(id));
                    dispatch(setFormOpen({ isOpen: true, formID: "piece" }));
                  }}
                >
                  <ListItemDecorator>
                    <i className="fa-solid fa-pen"></i>
                  </ListItemDecorator>
                  <ListItemContent>Edit Piece</ListItemContent>
                </MenuItem>
              ) : (
                []
              )}
              {missionControlNode === "edit" && id ? (
                <MenuItem
                  disabled={callSent}
                  sx={{
                    opacity: callSent ? 0.3 : 1,
                  }}
                  onClick={() => {
                    setAnchorEl(null);
                    dispatch(
                      setRetrieveInstrumentationForProjectPieceID(
                        projectPieceID
                      )
                    );
                    dispatch(
                      setFormOpen({
                        formID: "retrieveInstrumentationForPiece",
                        isOpen: true,
                      })
                    );
                  }}
                >
                  <ListItemDecorator>
                    <i className="fa-solid fa-arrow-down-to-line"></i>
                  </ListItemDecorator>
                  <ListItemContent>
                    Retrieve Instrumentation
                    <Typography level="body4">
                      from a previous Project
                    </Typography>
                  </ListItemContent>
                </MenuItem>
              ) : (
                []
              )}
              {missionControlNode === "edit" ? (
                <MenuItem
                  color="danger"
                  disabled={callSent && chairsForProjectPiece.length > 0}
                  sx={{
                    opacity:
                      callSent && chairsForProjectPiece.length > 0 ? 0.3 : 1,
                  }}
                  onClick={() => {
                    setAnchorEl(null);
                    askDelete();
                  }}
                >
                  <ListItemDecorator sx={{ color: "#f44336" }}>
                    <i className="fa-solid fa-trash"></i>
                  </ListItemDecorator>
                  <ListItemContent>
                    <span style={{ color: "#f44336" }}>
                      Remove Project Piece
                    </span>
                  </ListItemContent>
                </MenuItem>
              ) : (
                []
              )}
            </Menu>
            {onSolo ? (
              <Box
                sx={{
                  opacity: disabled ? 0.4 : 1,
                  cursor: disableCheck || disabled ? "not-allowed" : undefined,
                  pointerEvents: disableCheck || disabled ? "none" : undefined,
                }}
              >
                <SoloButton onClick={!disabled && onSolo} />
              </Box>
            ) : (
              []
            )}
          </Box>
        </ListItemButton>
      </Box>
    </Tooltip>
  );
}
