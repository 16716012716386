/**
 * MusicianVenue_Entity type guard.
 *
 * @param {any} musicianVenueJson MusicianVenue object from API
 * @returns {boolean} Return true if type is MusicianVenue_Entity
 */
function validator(
  musicianVenueJson: unknown
): musicianVenueJson is MusicianVenue_Entity {
  if (typeof musicianVenueJson === "object" && musicianVenueJson != null) {
    MusicianVenue_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(musicianVenueJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class MusicianVenue_Entity {
  static requiredFields = ["id", "musicianID", "venueID", "mileage"];

  constructor(musicianVenueJson: unknown) {
    if (validator(musicianVenueJson)) {
      this.id = musicianVenueJson.id;
      this.musicianID = musicianVenueJson.musicianID;
      this.venueID = musicianVenueJson.venueID;
      this.musicianPlaceID = musicianVenueJson.musicianPlaceID;
      this.venuePlaceID = musicianVenueJson.venuePlaceID;
      this.mileage = musicianVenueJson.mileage;
      this.verified = musicianVenueJson.verified;
      this.polyline = musicianVenueJson.polyline;
    } else {
      throw new Error(
        "Failed to create new instance of ${ MusicianVenue_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  musicianID: number;
  venueID: number;
  musicianPlaceID: string;
  venuePlaceID: string;
  mileage: number;
  verified: boolean;
  polyline: string;
}
