import { Box, Button, List, ListItemButton, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { RosterOptions } from "hooks/projectRoster/projectRoster";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  setFormOpen,
  setSelectedRosterPresetID,
} from "reducers/rhapsody";
import { useUpdateCurrentCompanyMutation } from "redux/company/companyEndpoints";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";

/**
 *
 * @returns {ReactElement} DefaultRosters page
 */
export function DefaultRosters() {
  const open = useSelector(formOpenSelector("defaultRosters"));
  const dispatch = useDispatch();
  const settings = useCompanySettings();
  const [defaultRosters, setDefaultRosters] = useState<
    {
      id: number;
      autoCreate: boolean;
      name: string;
      options: Partial<RosterOptions>;
    }[]
  >([]);
  const [index, setIndex] = useState(0);

  const onClose = () => {
    dispatch(setFormOpen({ formID: "defaultRosters", isOpen: false }));
  };

  useEffect(() => {
    if (defaultRosters.length && !index) setIndex(0);
  }, [defaultRosters]);

  useEffect(() => {
    if (settings.rosterPresets) setDefaultRosters(settings.rosterPresets);
  }, [settings]);

  return (
    <DialogClose open={open} onClose={onClose}>
      <DialogTitle>
        <Typography level="h6">Roster Presets</Typography>
        <Typography level="body2">
          Create rosters configuration ready to use.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ flexShrink: 0, height: 400 }}>
          <Button
            fullWidth
            size="sm"
            onClick={() => {
              dispatch(setFormOpen({ isOpen: true, formID: "roster" }));
            }}
          >
            New Preset
          </Button>
          <List sx={{ overflow: "scroll" }}>
            {defaultRosters.map((e, i) => (
              <ListItemButton
                onClick={() => {
                  dispatch(setSelectedRosterPresetID(e.id));
                  dispatch(
                    setFormOpen({
                      isOpen: true,
                      formID: "roster",
                    })
                  );
                }}
                key={e.id}
              >
                <Box>
                  {e.name ? e.name : "Untitled"}
                  <Typography
                    startDecorator={
                      e.autoCreate ? (
                        <i className="fa-solid fa-check"></i>
                      ) : undefined
                    }
                    level="body2"
                  >
                    {e.autoCreate ? "Auto create" : ""}
                  </Typography>
                </Box>
              </ListItemButton>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
