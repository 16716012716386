import moment from "moment";
import WorkSession_Entity from "./workSession";
import { WorkSessionProjectPiece } from "entities/workSessionProjectPiece";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { ProjectPiece } from "entities/projectPiece";
import { Piece } from "entities/piece";
import { renderToString } from "react-dom/server";
import { Internal } from "entities/internal";
import { Section } from "entities/section";
export default class WorkSession extends WorkSession_Entity {
  static fromList(workSessionsJSON: unknown): Array<WorkSession> {
    const workSessions: WorkSession[] = [];
    if (workSessionsJSON)
      Array.isArray(workSessionsJSON) &&
        workSessionsJSON.forEach((workSessionJSON) => {
          workSessions.push(new WorkSession(workSessionJSON));
        });
    return workSessions;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  getTzName() {
    return this.tzName ?? this.studio.tzName ?? "America/Los_Angeles";
  }

  from() {
    return moment.tz(this.dateFromUTC, this.getTzName());
  }

  to() {
    return moment.tz(this.dateToUTC, this.getTzName());
  }

  sectionDescription = (utils: LayoutUtils, internal: Internal) => {
    const projectSectionIDs = internal.sectionIDs;
    const families = projectSectionIDs.reduce<
      { id: number; name: string; sections: Section[] }[]
    >((a, v) => {
      const section = utils.sectionsMap[v];
      const family = utils.familiesMap[section.familyID];
      const index = a?.findIndex((e) => e.id === section.familyID);

      if (index >= 0) {
        a[index].sections.push(section);
      } else {
        a.push({ name: family.name, id: family.id, sections: [section] });
      }
      return a;
    }, []);

    if (this.sectionIDs === "" || this.sectionIDs === "[]") return "Tutti";

    let sectionIDsArray = JSON.parse(this.sectionIDs);
    sectionIDsArray = sectionIDsArray.filter(
      (item, index) => sectionIDsArray.indexOf(item) === index
    );
    const ret = [];
    const familyStatus = (f) => {
      return projectSectionIDs.reduce(
        (a, v) => {
          const section = utils.sectionsMap[v];
          if (section.familyID === f.id) {
            if (sectionIDsArray.includes(section.id)) {
              a.checked = false;
            } else {
              a.indeterminate = true;
            }
          }
          return a;
        },
        { checked: true, indeterminate: false }
      );
    };

    families.forEach((f) => {
      const { checked } = familyStatus(f);
      if (checked) {
        ret.push(f.name);
      } else {
        f.sections.forEach((s) => {
          if (!sectionIDsArray.includes(s.id)) ret.push(s.name);
        });
      }
    });

    return ret.join(", ");
  };

  schedule(utils: LayoutUtils, internal: Internal) {
    const projectPieceOrder: number[] = JSON.parse(
      this.projectPiecesOrder ? this.projectPiecesOrder : "[]"
    );
    return (
      <ul>
        {projectPieceOrder.map((e) => {
          const projectPiece = utils.projectPiecesMap[e];
          const piece = utils.piecesMap[projectPiece?.pieceID];
          if (piece)
            return (
              <li key={piece.id}>
                {piece.name}, {piece.composer} (
                {this.sectionDescription(utils, internal)})
              </li>
            );

          return <li key="sa">Seating Arrangement</li>;
        })}
      </ul>
    );
  }

  formatDate() {
    return moment
      .tz(this.dateFromUTC, this.getTzName())
      .format("dddd, MMMM D, YYYY");
  }

  formatFrom() {
    return moment.tz(this.dateFromUTC, this.getTzName()).format("h:mm A (z)");
  }

  formatTo() {
    return moment.tz(this.dateToUTC, this.getTzName()).format("h:mm A (z)");
  }

  formatDateRange() {
    if (this.dateFromUTC === this.dateToUTC) {
      return moment.tz(this.dateFromUTC, this.getTzName()).format("ll");
    }
    return `${moment
      .tz(this.dateFromUTC, this.getTzName())
      .format("dd, MMM DD, h:mm A")} - ${moment
      .tz(this.dateToUTC, this.getTzName())
      .format("h:mm A (z)")}`;
  }

  workSessionProjectPieces(
    workSessionProjectPieces: WorkSessionProjectPiece[]
  ) {
    return workSessionProjectPieces.filter(
      (wpp) => wpp.workSessionID === this.id
    );
  }
}
