import { useMediaQuery, useTheme } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DialogClose from "atoms/DialogClose/DialogClose";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formOpenSelector } from "reducers/rhapsody";
import * as yup from "yup";
// styles
import {
  Alert,
  Button,
  Chip,
  ChipDelete,
  CircularProgress,
  Input,
} from "@mui/joy";
import { Box } from "@mui/system";
import { InstrumentsPopover } from "features/musicians/InstrumentsPopover/InstrumentsPopover";
import { setFormOpen, setSelectedMusicianID } from "reducers/rhapsody";
import { useInstruments } from "redux/instrument/instrumentHooks";
import {
  useCreateMusicianMutation,
  useUpdateMusicianInstrumentsMutation,
} from "redux/musician/musicianEndpoints";

export default function FormMusician() {
  const [createMusician, { isLoading, data }] = useCreateMusicianMutation();
  const [updateMusicianInstrument] = useUpdateMusicianInstrumentsMutation();
  const theme = useTheme();
  const formOpen = useSelector(formOpenSelector("musician"));
  const sm = useMediaQuery(theme.breakpoints.down("lg"));
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLAnchorElement) | null
  >(null);

  const { instrumentsMap } = useInstruments();

  const dispatch = useDispatch();
  const [musician, setMusician] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [instrumentIDs, setInstrumentIDs] = useState<{ [i: number]: boolean }>(
    {}
  );

  const handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    setMusician((s) => ({ ...s, [name]: value }));
  };

  useEffect(() => {
    if (data) {
      Object.keys(instrumentIDs).forEach((instrumentID) => {
        updateMusicianInstrument({
          id: data.id,
          body: { instrumentID: parseInt(instrumentID) },
        });
      });
      dispatch(setSelectedMusicianID(data.id));
      dispatch(setFormOpen({ isOpen: false, formID: "musician" }));
    }
  }, [data]);

  function handleNewInstruments(ids: number[]) {
    const _instrumentIDs = { ...instrumentIDs };
    ids.forEach((i) => {
      _instrumentIDs[i] = true;
    });

    setInstrumentIDs(_instrumentIDs);
  }

  const save = async () => {
    const schema = yup.object().shape({
      lastName: yup.string().min(1, "Last Name is required").required(),
      email: yup.string().email().required(),
      firstName: yup.string().min(1, "First Name is required").required(),
    });

    schema
      .validate(musician)
      .catch(function (err) {
        return;
      })
      .then(async (b) => {
        if (!b) return;
        createMusician({
          ...musician,
          sub: false,
          latitude: "",
          longitude: "",
          placeID: "",
          tzName: "",
        });
      });
  };

  return (
    <DialogClose
      open={formOpen}
      onClose={() =>
        dispatch(setFormOpen({ isOpen: false, formID: "musician" }))
      }
      scroll="paper"
      aria-labelledby="responsive-dialog-title"
    >
      {isLoading ? (
        <Box sx={{ p: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogContent sx={{ minWidth: 600 }}>
            <Grid container>
              <Grid item xs={12}>
                <div
                  style={{
                    background: "#e3f2fd",
                    padding: 16,
                    borderRadius: "16px",
                    textAlign: "center",
                  }}
                >
                  <i
                    style={{ fontSize: 80, color: "#001E3C" }}
                    className="fad fa-user-music"
                  ></i>
                  <Typography variant="h6" style={{ color: "#001E3C" }}>
                    New Musician
                  </Typography>
                  <Typography
                    style={{ color: "#001E3C", opacity: 0.8 }}
                    variant="body2"
                  >
                    This is someone you will hire for a work session.
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", flexDirection: "column", mt: 2, gap: 1 }}
            >
              <Input
                endDecorator={"Required"}
                startDecorator="First Name"
                fullWidth
                autoFocus
                value={musician.firstName}
                onChange={handleChange("firstName")}
              />
              <Input
                endDecorator={"Required"}
                startDecorator="Last Name"
                fullWidth
                value={musician.lastName}
                onChange={handleChange("lastName")}
              />
              <Input
                endDecorator={"Required"}
                startDecorator="Email"
                fullWidth
                type="email"
                value={musician.email}
                onChange={handleChange("email")}
              />
              <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                color="neutral"
                variant="soft"
                endDecorator={<i className="fa-duotone fa-piano"></i>}
              >
                + Add Instruments
              </Button>
              <InstrumentsPopover
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                onSelect={handleNewInstruments}
              />
              <Grid container sx={{ mt: 1 }} spacing={1}>
                {Object.keys(instrumentIDs).map((i) => (
                  <Grid item key={i}>
                    <Chip
                      color="neutral"
                      variant="solid"
                      endDecorator={
                        <ChipDelete
                          onDelete={() => {
                            setInstrumentIDs((_i) => {
                              const ret = { ..._i };
                              delete ret[i];
                              return ret;
                            });
                          }}
                        />
                      }
                    >
                      {instrumentsMap[i]?.name}
                    </Chip>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="neutral"
              variant="soft"
              onClick={() =>
                dispatch(
                  setFormOpen({
                    isOpen: false,
                    formID: "musician",
                  })
                )
              }
            >
              Cancel
            </Button>
            <Button
              disabled={
                !musician.firstName || !musician.lastName || !musician.email
              }
              color="primary"
              onClick={save}
            >
              Create Musician
            </Button>
          </DialogActions>
        </>
      )}
    </DialogClose>
  );
}
