import { SearchMetadata } from "@nerdjs/nerd-core";
import { Email_Entity } from "entities/email";
import { postalApi } from "../api/postalApi";

export const emailEndpoints = postalApi.injectEndpoints({
  endpoints: (build) => ({
    forwardEmail: build.mutation<
      void,
      { id: number; body: Partial<Email_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `emails/${args.id}/forward`,
      }),
    }),
    deleteEmail: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `emails/${id}`,
      }),
      invalidatesTags: ["emails"],
    }),
    getEmailSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `emails/searchMetadata`,
    }),
  }),
});

export const {
  useForwardEmailMutation,
  useGetEmailSearchMetadataQuery,
  useDeleteEmailMutation,
} = emailEndpoints;

export default emailEndpoints;
