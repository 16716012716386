import { lighten, rgbToHex } from "@mui/material";
import { paydayMap, scaleTypeMap } from "constants/payday";
import { typeOptions } from "constants/workSessionTypeOptions";
import { PdGroup } from "entities/pdGroup";
import { Workbook, Worksheet } from "exceljs";
import { getPdColumnColumns } from "hooks/PayDayProject/PdGroup";
import { useLaborColumns } from "hooks/PayDayProject/PdLabor";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedPdProjectIDSelector,
  selectedProjectIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { usePdProject } from "redux/pdProject/pdProjectHooks";
import { useProjectScales } from "redux/scale/scaleHooks";
import { Indexes, createWorkbook, downloadExcel } from "../utils";

export function useDownloadPaydayExcel() {
  const rhapsodyProjectID = useSelector(selectedProjectIDSelector);
  const paydayProjectID = useSelector(selectedPdProjectIDSelector);
  const utils = useSelector(layoutUtilsSelector);
  const sectionRolesMap = utils?.sectionRolesMap;
  const instrumentsMap = utils?.instrumentsMap;
  const project = utils?.project;
  const projectWorkSessions = utils?.workSessions;
  const [trigger, setTrigger] = useState(false);
  const { scales: projectScales, isSuccess: s1 } = useProjectScales(
    rhapsodyProjectID,
    { skip: !trigger }
  );
  const { pdProject, isSuccess: s2 } = usePdProject(paydayProjectID);
  const laborColumns = useLaborColumns(trigger);

  const dispatch = useDispatch();

  useEffect(() => {
    if (trigger && s1 && s2 && laborColumns) {
      setTrigger(false);
      download();
    }
  }, [trigger, s1, s2, laborColumns]);

  function header(worksheet: Worksheet, indexes: Indexes) {
    const studioNames = projectWorkSessions.reduce((a, v) => {
      if (!a.includes(v.studioName)) {
        a.push(v.studioName);
      }
      return a;
    }, []);

    let dates = projectWorkSessions.reduce((a, v) => {
      a.push(moment(v.dateFromUTC));
      return a;
    }, []);

    dates = dates.sort((a, b) => a.valueOf() - b.valueOf());
    const from = dates.length ? dates[0].format("ll") : undefined;
    const to = dates.length ? dates[dates.length - 1].format("ll") : undefined;

    const workSessionTypes = projectWorkSessions.reduce((a, v) => {
      const type = typeOptions.find((t) => t.sessionTypeID === v.sessionTypeID);
      if (a[type.title]) {
        a[type.title]++;
      } else {
        a[type.title] = 1;
      }
      return a;
    }, {});

    const description = Object.keys(workSessionTypes)
      .reduce((a, v) => {
        a.push(`${workSessionTypes[v]} ${v}`);
        return a;
      }, [])
      .join(", ");

    // Project Name & date
    [...Array(9).keys()].forEach((k) => {
      if (k === 0 || k === 1 || k === 8) {
        indexes.width(18);
      } else {
        indexes.width(12);
      }
      indexes.nextColumn();
    });
    indexes.goToColumn(0);

    indexes.select(0, 1);
    indexes.merge();
    indexes.rectangle();
    indexes.unselect();

    indexes.nextColumn();

    indexes.select(6, 1);
    indexes.merge();
    indexes.rectangle();
    indexes.unselect();
    indexes.text(`${project.name}\r\nBudget & Payroll`);
    indexes.centerText();
    indexes.bold();

    indexes.goToColumn(8);
    indexes.select(0, 1);
    indexes.merge();
    indexes.rectangle();
    indexes.unselect();
    indexes.text(moment().format("ll"));
    indexes.centerText();
    indexes.goToColumn(0);
    indexes.goToRow(4);

    // Project Info
    indexes.text("Name:");
    indexes.rectangle();
    indexes.nextColumn();
    indexes.select(7, 0);
    indexes.merge();
    indexes.rectangle();
    indexes.unselect();
    indexes.text(pdProject.name);
    indexes.nextRow();
    indexes.goToColumn(0);

    indexes.text("Description:");
    indexes.rectangle();
    indexes.nextColumn();
    indexes.select(7, 0);
    indexes.merge();
    indexes.rectangle();
    indexes.unselect();
    indexes.text(pdProject.description);
    indexes.nextRow();
    indexes.goToColumn(0);

    if (project.customerName) {
      indexes.text("Client:");
      indexes.rectangle();
      indexes.nextColumn();
      indexes.select(7, 0);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text(project.customerName);
      indexes.nextRow();
      indexes.goToColumn(0);
    }

    if (description) {
      indexes.text("Work Sessions:");
      indexes.rectangle();
      indexes.nextColumn();
      indexes.select(7, 0);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text(description);
      indexes.nextRow();
      indexes.goToColumn(0);
    }

    if (studioNames.length) {
      indexes.text(`Location${studioNames.length > 1 ? "s" : ""}:`);
      indexes.rectangle();
      indexes.nextColumn();
      indexes.select(7, 0);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text(studioNames.join(", "));
      indexes.nextRow();
      indexes.goToColumn(0);
    }

    if (from && to) {
      indexes.text("Dates:");
      indexes.rectangle();
      indexes.nextColumn();
      indexes.select(7, 0);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.text(`${from} - ${to}`);
      indexes.nextRow();
      indexes.goToColumn(0);
      indexes.nextRow();
    }
  }

  function budget(worksheet: Worksheet) {
    const indexes = new Indexes(worksheet);
    header(worksheet, indexes);

    pdProject.groups.forEach((g) => {
      genPdGroup(g, worksheet, indexes, false);
    });

    if (pdProject.footnote) {
      indexes.goToColumn(0);
      indexes.select(8, 2);
      indexes.merge();
      indexes.rectangle();
      indexes.unselect();
      indexes.fill("FDF3D0");
      indexes.text(pdProject.footnote);
    }

    indexes.rowRelativeMove(4);
    indexes.goToColumn(6);
    indexes.select(1, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text("Grand Total:");
    indexes.fontSize(14);
    indexes.rectangle();
    indexes.nextColumn();
    indexes.nextColumn();
    indexes.text(pdProject.total / 1000);
    indexes.fontSize(14);
    indexes.bold();
    indexes.dollar();
    indexes.rectangle();

    return worksheet;
  }

  function genPdGroup(
    pdGroup: PdGroup,
    worksheet: Worksheet,
    indexes: Indexes,
    subgroup: boolean
  ) {
    const columns = getPdColumnColumns(pdGroup);

    const color = rgbToHex(
      lighten(pdGroup.color ? pdGroup.color : "#ffffff", 0.8)
    ).slice(1);

    // colored main group name
    if (!subgroup) {
      indexes.goToColumn(0);
      indexes.select(8, 0);
      indexes.merge();
      indexes.unselect();
      indexes.centerText();
      indexes.fill(color);
      indexes.bold();
      indexes.text(pdGroup.name);
      indexes.nextRow();
    } else {
      indexes.goToColumn(0);
      indexes.fill(color);
      indexes.text(pdGroup.name);
      indexes.width(20);
    }

    // left subgroup details
    indexes.fill(color);
    indexes.nextColumn();

    if (pdGroup.subgroups?.length === 0) {
      columns.forEach((c) => {
        if (c.type === "action") return;
        if (!c.headerName) return;
        indexes.text(c.headerName);
        if (c.field === "description" && columns.length !== 8) {
          indexes.select(3, 0);
          indexes.merge();
          indexes.unselect();
          indexes.columnRelativeMove(3);
        }
        indexes.rectangle();
        indexes.nextColumn();
      });
      indexes.nextRow();

      pdGroup.items.forEach((labor) => {
        indexes.goToColumn(0);
        indexes.fill(color);
        indexes.goToColumn(1);
        columns.forEach((c) => {
          if (c.type === "action") return;
          if (!c.headerName) return;
          if (c.field === "description" && columns.length !== 8) {
            indexes.select(3, 0);
            indexes.merge();
            indexes.unselect();
            indexes.columnRelativeMove(3);
          }
          indexes.rectangle();
          indexes.text(
            c.excelFormat ? c.excelFormat(labor[c.field]) : labor[c.field]
          );
          if (c.currency) {
            indexes.dollar();
          }
          if (c.percentage) {
            indexes.getCell().numFmt = "0.00%";
          }
          indexes.nextColumn();
        });
        indexes.nextRow();
      });

      indexes.goToColumn(0);
      indexes.fill(color);
      indexes.goToColumn(7);
      indexes.text("Subtotal:");
      indexes.bold();
      indexes.rectangle();
      indexes.nextColumn();
      indexes.text(pdGroup.subtotal / 1000);
      indexes.bold();
      indexes.dollar();
      indexes.rectangle();
      indexes.nextRow();
      indexes.goToColumn(1);
    } else {
      indexes.goToColumn(1);
      pdGroup.subgroups.forEach((g, i) => {
        genPdGroup(g, worksheet, indexes, true);
        if (i < pdGroup.subgroups.length - 1) indexes.nextRow();
      });
    }

    if (!subgroup) {
      indexes.goToColumn(8);
      indexes.fill(color);
      indexes.text(pdGroup.subtotal / 1000);
      indexes.dollar();
      indexes.bold();
      indexes.previousColumn();
      indexes.select(-7, 0);
      indexes.merge();
      indexes.unselect();
      indexes.text(`Total ${pdGroup.name}:`);
      indexes.getCell().alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
      indexes.bold();
      indexes.fill(color);
      indexes.nextRow();
      indexes.nextRow();
    }

    if (subgroup) {
      indexes.goToColumn(0);
      indexes.fill(color);
    }
  }

  function payroll(worksheet: Worksheet) {
    const indexes = new Indexes(worksheet);

    laborColumns.forEach((c) => {
      indexes.width(15);
      indexes.text(c.headerName);
      indexes.bold();
      indexes.rectangle();
      indexes.nextColumn();
    });

    const labors = pdProject?.labors;

    indexes.goToColumn(0);

    labors.forEach((l) => {
      indexes.nextRow();
      laborColumns.forEach((c) => {
        const value = c.valueGetter ? c.valueGetter({ row: l }) : l[c.field];
        indexes.text(c.excelFormat ? c.excelFormat(value) : value);
        if (c.currency && c.currency(l)) {
          indexes.dollar();
        }
        if (c.percentage && c.percentage(l)) {
          indexes.getCell().numFmt = "0.00%";
        }
        indexes.rectangle();
        indexes.nextColumn();
      });
      indexes.goToColumn(0);
    });

    return worksheet;
  }

  function scales(worksheet: Worksheet) {
    const indexes = new Indexes(worksheet);

    projectScales.forEach((s) => {
      indexes.width(20);
      indexes.goToColumn(0);
      indexes.rectangle();
      indexes.text(scaleTypeMap[s.itemKey]);
      indexes.nextColumn();
      indexes.width(20);
      switch (s.itemKey) {
        case "service":
          indexes.text(
            typeOptions.find((e) => e.sessionTypeID === s.itemID)?.title ??
              s.itemID
          );
          break;
        case "sectionRole":
          if (s.itemID === 50) {
            indexes.text("Contractor");
            break;
          } else {
            indexes.text(sectionRolesMap[s.itemID]?.name ?? s.itemID);
            break;
          }
        case "payday":
          indexes.text(paydayMap[s.itemID] ?? s.itemID);
          break;
        case "muse":
          indexes.text(instrumentsMap[s.itemID]?.name);
          break;
        case "doubling":
          indexes.text(`${s.itemID} Instruments`);
          break;
        default:
          indexes.text(s.itemID);
      }
      indexes.rectangle();
      indexes.nextColumn();
      indexes.width(20);
      indexes.rectangle();
      indexes.text(s.amount / 1000);
      if (s.percentage) {
        indexes.getCell().numFmt = "0.00%";
      } else {
        indexes.dollar();
      }
      indexes.nextRow();
    });
    return worksheet;
  }

  function download() {
    const workbook: Workbook = createWorkbook();
    const fileName = `${project.name} - ${pdProject.name}`;

    const budgetWorksheet: Worksheet = workbook.addWorksheet(`Budget`, {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 1,
      },
    });
    budgetWorksheet.pageSetup.margins = {
      left: 0.5,
      right: 0.5,
      top: 0,
      bottom: 0.5,
      header: 0,
      footer: 0.3,
    };

    const payrollWorksheet: Worksheet = workbook.addWorksheet(`Payroll`, {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 1,
      },
    });
    payrollWorksheet.pageSetup.margins = {
      left: 0.5,
      right: 0.5,
      top: 0,
      bottom: 0.5,
      header: 0,
      footer: 0.3,
    };

    const ratesWorksheet: Worksheet = workbook.addWorksheet(`Scales`, {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 1,
      },
    });
    ratesWorksheet.pageSetup.margins = {
      left: 0.5,
      right: 0.5,
      top: 0,
      bottom: 0.5,
      header: 0,
      footer: 0.3,
    };

    budget(budgetWorksheet);
    payroll(payrollWorksheet);
    scales(ratesWorksheet);

    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
    downloadExcel(workbook, fileName);
  }

  return () => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setTrigger(true);
  };
}
