/*global google*/
import { parsePlace } from "hooks/googlePlaceSuggestions/maps";
import Venue_Entity from "./venue";
export default class Venue extends Venue_Entity {
  static fromList(venuesJSON: unknown): Array<Venue> {
    const venues: Venue[] = [];
    if (venuesJSON)
      Array.isArray(venuesJSON) &&
        venuesJSON.forEach((venueJSON) => {
          venues.push(new Venue(venueJSON));
        });
    return venues;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  formattedAddress() {
    return `${this.address ?? ""}\n ${this.city ?? ""} ${this.state ?? ""} ${
      this.zipcode ?? ""
    }`;
  }

  async geocode(): Promise<Geocode> {
    return new Promise((resolve, reject) => {
      if (this.address) {
        const googleMapsGeocoder = new google.maps.Geocoder();
        googleMapsGeocoder.geocode(
          { address: this.formattedAddress() },
          async (results) => {
            if (results?.length) {
              const place = parsePlace(results[0]);
              resolve({
                placeID: place.placeId,
                latitude: `${place.latitude}`,
                longitude: `${place.longitude}`,
              });
            } else {
              reject("Venue Geocode: No Result");
            }
          }
        );
      } else {
        reject("Venue Geocode: No Address");
      }
    });
  }
}

type Geocode = {
  placeID?: string;
  latitude?: string;
  longitude?: string;
};
