/**
 * Scale_Entity type guard.
 *
 * @param {any} scaleJson Scale object from API
 * @returns {boolean} Return true if type is Scale_Entity
 */

export default class Scale_Entity {
  constructor(scaleJson: Scale_Entity) {
    try {
      this.id = scaleJson.id;
      this.organizationID = scaleJson.organizationID;
      this.tagID = scaleJson.tagID;
      this.projectID = scaleJson.projectID;
      this.percentage = scaleJson.percentage;
      this.hourly = scaleJson.hourly;
      this.amount = scaleJson.amount;
      this.itemKey = scaleJson.itemKey;
      this.itemID = scaleJson.itemID;
      this.position = scaleJson.position;
      this.createdAt = scaleJson.createdAt;
      this.updatedAt = scaleJson.updatedAt;
    } catch (e) {
      console.log(`Failed to create new instance of ${Scale_Entity.name}`);
    }
  }

  id: number;
  organizationID: number;
  tagID: number;
  projectID: number;
  percentage: boolean;
  hourly: boolean;
  position: number;
  amount: number;
  itemKey: string;
  itemID: number;
  createdAt: string;
  updatedAt: string;
}
