import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemDecorator,
} from "@mui/joy";
import { Musician } from "entities/musician";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectedPdProjectIDSelector } from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { rhapsodyApi } from "redux/api";
import { paydayApi } from "redux/api/paydayApi";
import { useUpdateMusician2Mutation } from "redux/musician/musicianEndpoints";
import {
  useCreateMusicianVenue2Mutation,
  useUpdateMusicianVenue2Mutation,
} from "redux/musicianVenue/musicianVenueEndpoints";
import { useMusicianVenuesFor } from "redux/musicianVenue/musicianVenueHooks";
import { usePaydaySyncMutation } from "redux/project/projectEndpoints";
import { useVenues } from "redux/venue/venueHooks";

/**
 *
 * @returns {ReactElement} ProjectCalculateMileage page
 */
export function ProjectCalculateMileage() {
  const utils = useSelector(layoutUtilsSelector);
  const pdProjectID = useSelector(selectedPdProjectIDSelector);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [syncPdProject] = usePaydaySyncMutation();
  const musicianIDs: number[] = [];
  const { venuesMap } = useVenues();
  const [updateMusician] = useUpdateMusician2Mutation();
  const [createMusicianVenue] = useCreateMusicianVenue2Mutation();
  const [updateMusicianVenue] = useUpdateMusicianVenue2Mutation();
  for (const key in utils.musiciansMap) {
    if (Object.prototype.hasOwnProperty.call(utils.musiciansMap, key)) {
      const musician = utils.musiciansMap[key];
      musicianIDs.push(musician.id);
    }
  }
  const askQuestion = useAskQuestion();
  const venueIDs = utils.workSessions?.reduce<number[]>((a, v) => {
    if (!a.includes(v.studio?.id)) a.push(v.venueID);
    return a;
  }, []);

  const { musicianVenues } = useMusicianVenuesFor(musicianIDs);

  const proceed = async () => {
    const noAddress = [];
    let mileageCount = 0;
    setLoading(true);
    for (const k in venueIDs) {
      if (Object.prototype.hasOwnProperty.call(venueIDs, k)) {
        const venueID = venueIDs[k];
        const venue = venuesMap[venueID];
        for (const j in musicianIDs) {
          if (Object.prototype.hasOwnProperty.call(musicianIDs, j)) {
            const musicianID = musicianIDs[j];
            const musician = utils.musiciansMap[musicianID];

            if (!musician.placeID && musician?.validAddress()) {
              const geo = await musician.geocode();
              if (geo)
                updateMusician({
                  id: musician.id,
                  body: { ...musician, ...geo },
                });
            }
            if (!musician.placeID && !musician?.validAddress()) {
              if (!noAddress.includes(musician)) noAddress.push(musician);
              continue;
            }
            const musicianVenue = musicianVenues?.find(
              (mv) => mv.venueID === venue.id && mv.musicianID === musician?.id
            );

            if (
              musicianVenue &&
              musicianVenue.verified &&
              musicianVenue.musicianPlaceID === musician.placeID &&
              musicianVenue.venuePlaceID === venue.placeID
            ) {
              console.log(
                `MusicianVenue already exist for: ${musician?.fullName()} - ${
                  venue.name
                }`
              );
            } else {
              const r = await musician.distanceToVenue(venue);
              mileageCount++;

              if (musicianVenue) {
                await updateMusicianVenue({
                  id: musicianVenue.id,
                  body: {
                    ...musicianVenue,
                    venuePlaceID: venue.placeID,
                    musicianPlaceID: musician.placeID,
                    verified: true,
                    ...r,
                  },
                }).unwrap();
              } else {
                await createMusicianVenue({
                  musicianID: musician.id,
                  venueID: venue.id,
                  venuePlaceID: venue.placeID,
                  musicianPlaceID: musician.placeID,
                  verified: true,
                  ...r,
                }).unwrap();
              }
            }
          }
        }
      }
    }
    dispatch(rhapsodyApi.util.invalidateTags(["musicianVenues"]));
    syncPdProject({
      id: utils.project.id,
      body: { id: pdProjectID },
    })
      .unwrap()
      .then(() => {
        dispatch(paydayApi.util.invalidateTags(["pdProjects"]));
      });

    setLoading(false);

    askQuestion("Missing Address", ["OK"], {
      subtitle: (
        <Box>
          {mileageCount} distances have been calculated
          <br />
          {noAddress.length ? (
            <Box>
              The following Musicians don't have an address:
              <List>
                {noAddress.map((m) => (
                  <ListItem key={m.id}>
                    <ListItemDecorator>
                      <MusicianAvatar size={18} musician={m} />
                    </ListItemDecorator>
                    {m.fullName()}
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            []
          )}
        </Box>
      ),
    });
  };

  return (
    <Button
      onClick={proceed}
      startDecorator={loading ? <CircularProgress /> : undefined}
      size="sm"
      variant="outlined"
    >
      Calculate Mileage
    </Button>
  );
}
