import { Box, Button, IconButton, Typography } from "@mui/joy";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import Id from "atoms/Id/Id";
import { PdProject_Entity } from "entities/pdProject";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { currencyFormatter, getterDivider, setterDivider } from "helpers";
import { FinaleChip } from "hooks/Branding/branding";
import { finale, useMySubscription } from "hooks/subscription/restrictionHooks";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  selectedPdProjectIDSelector,
  selectedProjectIDSelector,
  setDescription420,
  setFormOpen,
  setSelectedPdProjectID,
} from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { paydayApi } from "redux/api/paydayApi";
import { useDeletePdProjectMutation } from "redux/pdProject/pdProjectEndpoints";
import { usePdProjects } from "redux/pdProject/pdProjectHooks";
import { usePaydaySyncMutation } from "redux/project/projectEndpoints";

/**
 *
 * @returns {ReactElement} PayDayPayroll page
 */
export function PayDayPayroll() {
  const rhapsodyProjectID = useSelector(selectedProjectIDSelector);
  const payDayProjectID = useSelector(selectedPdProjectIDSelector);
  const { pdProjects } = usePdProjects(rhapsodyProjectID);
  const readOnly = useReadOnly();
  const [syncPdProject] = usePaydaySyncMutation();
  const utils = useSelector(layoutUtilsSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mySubscription = useMySubscription();
  const askQuestion = useAskQuestion();
  const [deletePdProject] = useDeletePdProjectMutation();
  const params = useParams();

  useEffect(() => {
    if (params.pdProjectID && !payDayProjectID) {
      dispatch(setSelectedPdProjectID(parseInt(params.pdProjectID)));
      dispatch(setFormOpen({ formID: "pdProject", isOpen: true }));
    }
  }, [params]);

  const handleDeletePdProject = async (projectID: number) => {
    if (!readOnly) {
      askQuestion("Are you sure?", ["Cancel", "Yes"], {
        subtitle: (
          <Typography>You are about to delete a Budget Document.</Typography>
        ),
      }).then((i) => {
        if (i == 1) {
          deletePdProject(projectID);
        }
      });
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        p: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: 1,
        position: "relative",
        flexShrink: 0,
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Button
          size="sm"
          onClick={() => {
            dispatch(setFormOpen({ isOpen: true, formID: "scales" }));
          }}
          color="neutral"
          variant="soft"
        >
          View Scales
        </Button>
        <Button
          size="sm"
          endDecorator={
            mySubscription !== finale ? <FinaleChip transparent /> : []
          }
          disabled={readOnly}
          onClick={() => {
            if (mySubscription !== finale) {
              dispatch(setFormOpen({ isOpen: true, formID: "subscribe" }));
              dispatch(
                setDescription420(
                  "Budget & Payroll is an exclusive to the Finale plan."
                )
              );
            } else {
              syncPdProject({ id: rhapsodyProjectID, body: {} })
                .unwrap()
                .then((e) => {
                  const project = e as unknown as PdProject_Entity;
                  dispatch(paydayApi.util.invalidateTags(["pdProjects"]));
                  dispatch(setSelectedPdProjectID(project.id));
                  dispatch(setFormOpen({ formID: "pdProject", isOpen: true }));
                  navigate(`${project.id}`);
                });
            }
          }}
        >
          + Create Document
        </Button>
      </Box>
      <DataGridPremium
        sx={{ width: "100%" }}
        onRowClick={(e) => {
          dispatch(setSelectedPdProjectID(e.row.id));
          dispatch(setFormOpen({ formID: "pdProject", isOpen: true }));
          navigate(`${e.row.id}`);
        }}
        rows={pdProjects}
        columns={[
          {
            field: "id",
            headerName: "ID",
            renderCell: (p) => (
              <Id startDecorator={<i className="fa-solid fa-dollar-sign"></i>}>
                {p.row.id}
              </Id>
            ),
          },
          { field: "name", headerName: "Name", width: 220 },
          { field: "description", headerName: "Description", flex: 1 },
          {
            field: "childID",
            headerName: "Work Sessions",
            flex: 1,
            valueGetter: (p) => {
              return p.row.childID
                ? `${
                    utils.workSessionsMap[p.row.childID]?.title
                  } ${utils.workSessionsMap[p.row.childID]?.formatDateRange()}`
                : "Whole Project";
            },
          },
          { field: "revision", headerName: "Revision", width: 150 },
          {
            field: "total",
            headerName: "Total",
            valueGetter: getterDivider("total", 1000),
            valueSetter: setterDivider("total", 1000),
            valueFormatter: (p) => `${currencyFormatter.format(p.value)}`,
            width: 100,
          },
          {
            field: "delete",
            headerName: "",
            width: 30,
            renderCell: (p) => {
              return (
                <IconButton
                  size="sm"
                  color="neutral"
                  variant="plain"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    handleDeletePdProject(p.row.id);
                  }}
                >
                  <i className="fa-solid fa-trash"></i>
                </IconButton>
              );
            },
          },
        ]}
        initialState={{ pinnedColumns: { right: ["total", "delete"] } }}
      />
    </Box>
  );
}
