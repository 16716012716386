import { Button, Divider } from "@mui/joy";
import { Box, Paper, Typography } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import FileUpload from "atoms/FileUpload";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { useExporter } from "features/exporter/exporter";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMusicians } from "redux/musician/musicianHooks";

export default function AddressBook() {
  const navigate = useNavigate();
  const [excelFile, setExcelFile] = useState();
  const { musicians, musiciansMap: existingMusicians } = useMusicians();
  const gotIt = true;
  const excelDownload = true;
  const askQuestion = useAskQuestion();
  const apiRef = useGridApiRef();
  const exporter = useExporter();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (excelFile) {
  //     processMusiciansTemplate(excelFile).then((m) =>
  //       dispatch(setTmpMusicians(m))
  //     );
  //   }
  // }, [excelFile]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h4">Address Book</Typography>
        {!gotIt ? (
          <Typography variant="body2">
            Let's add your musicians to your Rhapsody Address Book.
            <br />
            Rhapsody simplifies the data entry process.
          </Typography>
        ) : (
          []
        )}
        {!gotIt ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Button
              onClick={() => {
                askQuestion("Instructions", ["Cancel", "Download"], {
                  subtitle: (
                    <Typography sx={{ mt: 1 }}>
                      <ul>
                        <li>Don't change/add the columns</li>
                        <li>First Name, Last Name, and Email are required</li>
                        <li>Make sure to not have duplicates</li>
                      </ul>
                    </Typography>
                  ),
                }).then((r) => {
                  if (r) {
                    exporter.musicianImportTemplate();
                    // dispatch(setGotIt(true));
                    // dispatch(setExcelDownload(true));
                  }
                });
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}
              >
                <i
                  style={{ fontSize: 28 }}
                  className="fa-solid fa-file-excel"
                ></i>
                <div>
                  Download & Fill the Excel Template
                  <br />
                  <div style={{ opacity: 0.8, marginTop: 8 }}>Recommended!</div>
                </div>
              </Box>
            </Button>
            <Divider>OR</Divider>
            <Button
              // onClick={() => dispatch(setGotIt(true))}
              startDecorator={<i className="fa-duotone fa-user-music"></i>}
              variant="outlined"
            >
              Add musicians manually
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 2,
            }}
          >
            {excelDownload ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography>
                  Fill out the Excel template.
                  <br />
                  Once it is done, upload it so we can process the data.
                </Typography>
                <br />
                <FileUpload
                  raw
                  label="Upload Excel template"
                  onUpload={(f) => setExcelFile(f)}
                />
              </Box>
            ) : (
              []
            )}
            <Button
              variant="soft"
              // onClick={() => dispatch(addTmpMusician())}
            >
              + New Musician
            </Button>
            <Box
              sx={{
                height: "calc(100vh - 400px)",
                position: "relative",
                display: "flex",
              }}
            >
              {musicians.length > 0 || !excelDownload ? (
                <DataGridPro
                  apiRef={apiRef}
                  onCellClick={(e) => {
                    if (e.isEditable)
                      apiRef.current?.startCellEditMode({
                        id: e.id,
                        field: e.field,
                      });
                  }}
                  getCellClassName={(r) => {
                    if (existingMusicians[r.row.email]) return "success";
                  }}
                  rows={musicians}
                  disableSelectionOnClick
                  density={"compact"}
                  experimentalFeatures={{ newEditingApi: true }}
                  columns={[
                    {
                      field: "firstName",
                      headerName: "First Name",
                      renderCell: (p) => (
                        <Typography
                          variant="body2"
                          sx={{ opacity: !p.value ? 0.4 : 1 }}
                        >
                          {p.value || "Not Set"}
                        </Typography>
                      ),
                      editable: true,
                    },
                    {
                      field: "lastName",
                      headerName: "Last Name",
                      renderCell: (p) => (
                        <Typography
                          variant="body2"
                          sx={{ opacity: !p.value ? 0.4 : 1 }}
                        >
                          {p.value || "Not Set"}
                        </Typography>
                      ),
                      editable: true,
                    },
                    {
                      field: "email",
                      headerName: "Email",
                      renderCell: (p) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ opacity: !p.value ? 0.4 : 1 }}
                          >
                            {p.value || "Not Set"}
                          </Typography>
                          {existingMusicians[p.value] ? (
                            <Typography
                              sx={{ color: "#1b5e20" }}
                              variant="body2"
                            >
                              Imported
                            </Typography>
                          ) : (
                            []
                          )}
                        </Box>
                      ),
                      flex: 1,
                      editable: true,
                    },
                  ]}
                />
              ) : (
                []
              )}
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
