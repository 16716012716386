import {
  createSelector,
  createSlice,
  Dictionary,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Assignment } from "entities/assignment";
import { Internal, Internal_Entity } from "entities/internal";
import { InternalPosition } from "entities/internal/InternalPosition";
import {
  InternalSection,
  InternalSection_Entity,
} from "entities/internal/InternalSection";
import { Selection_Entity, Selection } from "entities/selection";
import { Selecto, Selecto_Entity } from "entities/selecto";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import { RootState } from "store";

interface MissionControlState {
  selection: Selection_Entity;
  savedSelection?: Selection_Entity;
  chairIDsHovered: number[];
  internal: Internal_Entity;
  internalInit?: boolean;
  debug: boolean;
  collapseMap: Dictionary<boolean>;
  moveToSectionID?: number;
  hoverSectionID?: number;
  layoutUtils?: LayoutUtils;
  layoutUtilsInit?: boolean;
  attendanceAssignmentIDs?: number[];
  internalPositionForLookup?: InternalPosition;
  assignmentIDForLookup: number;
  assignmentIDForRelease: number;
  reorderInternalSection: InternalSection_Entity;
  instrumentationProjectPieceIDs: number[] | null;
  instrumentationWorkSessionIDs: number[] | null;
  selecto: Selecto_Entity;
  attendanceWorkSessionID?: number;
  notifyMode: "communication" | "nudge";
}

const initialState = {
  collapseMap: {},
  debug: window.localStorage.getItem("debug")
    ? window.localStorage.getItem("debug") === "true"
    : false,
  selecto: { positionIDs: [] },
  notifyMode: "communication",
  selection: {
    selectedWorkSessionIDs: {},
    selectedProjectPieceIDs: {},
  },
} as MissionControlState;

export const missionControlSlice = createSlice({
  name: "missionControl",
  initialState,
  reducers: {
    setNotifyMode(state, action: PayloadAction<"communication" | "nudge">) {
      state.notifyMode = action.payload;
    },
    setChairIDsHovered(state, action: PayloadAction<number[]>) {
      state.chairIDsHovered = action.payload;
    },
    setInternalInit(state, action: PayloadAction<boolean>) {
      state.internalInit = action.payload;
    },
    setInstrumentationProjectPieceIDs(
      state,
      action: PayloadAction<number[] | null>
    ) {
      state.instrumentationProjectPieceIDs = action.payload;
    },
    setInstrumentationWorkSessionIDs(
      state,
      action: PayloadAction<number[] | null>
    ) {
      state.instrumentationWorkSessionIDs = action.payload;
    },
    setLayoutUtilsInit(state, action: PayloadAction<boolean>) {
      state.layoutUtilsInit = action.payload;
    },
    layoutHoverSectionID(state, action: PayloadAction<number>) {
      state.hoverSectionID = action.payload;
    },
    setReorderInternalSection(state, action: PayloadAction<InternalSection>) {
      state.reorderInternalSection = action.payload;
    },
    layoutSetInternal(state, action: PayloadAction<Internal_Entity>) {
      if (!state.internalInit) state.internalInit = true;
      state.internal = action.payload;
    },
    layoutSetUtils(state, action: PayloadAction<LayoutUtils>) {
      if (!state.layoutUtilsInit) state.layoutUtilsInit = true;
      state.layoutUtils = action.payload;
    },
    layoutSetDebug(state, action: PayloadAction<boolean>) {
      window.localStorage.setItem("debug", `${action.payload}`);
      state.debug = action.payload;
    },
    setInternalPositionForLookup(
      state,
      action: PayloadAction<InternalPosition>
    ) {
      state.internalPositionForLookup = action.payload;
    },
    setAssignmentIDForLookup(state, action: PayloadAction<number>) {
      state.assignmentIDForLookup = action.payload;
    },
    setAttendanceWorkSessionID(state, action: PayloadAction<number>) {
      state.attendanceWorkSessionID = action.payload;
    },
    setAttendanceAssignmentIDs(state, action: PayloadAction<number[]>) {
      state.attendanceAssignmentIDs = action.payload;
    },
    setAssignmentIDForRelease(state, action: PayloadAction<number>) {
      state.assignmentIDForRelease = action.payload;
    },
    layoutSetMoveToSectionID(state, action: PayloadAction<number>) {
      state.moveToSectionID = action.payload;
    },
    layoutSetCollapseMap(state, action: PayloadAction<Dictionary<boolean>>) {
      state.collapseMap = action.payload;
    },
    selectPositions(state, action: PayloadAction<string[]>) {
      const positionIDs = action.payload;
      const selecto = state.selecto;
      positionIDs.forEach((p) => {
        if (!selecto.positionIDs.includes(p)) selecto.positionIDs.push(p);
      });

      state.selecto = selecto;
    },
    unselectPositions(state, action: PayloadAction<string[]>) {
      const positions = action.payload;
      const selecto = state.selecto;
      positions.forEach((p) => {
        const index = selecto.positionIDs?.findIndex((s) => s === p);
        if (index >= 0) selecto.positionIDs.splice(index, 1);
      });
    },
    layoutUnselectAll(state) {
      state.selecto.positionIDs = [];
    },
    setSelectedWorkSessionIDs(
      state,
      action: PayloadAction<Dictionary<boolean> | null>
    ) {
      state.selection.selectedWorkSessionIDs = action.payload;
    },
    selectWorkSessionID(state, action: PayloadAction<number>) {
      state.selection.selectedWorkSessionIDs[action.payload] = true;
    },
    setSelectedProjectPieceIDs(
      state,
      action: PayloadAction<Dictionary<boolean> | null>
    ) {
      state.selection.selectedProjectPieceIDs = action.payload;
    },
    selectProjectPieceID(state, action: PayloadAction<number>) {
      state.selection.selectedProjectPieceIDs[action.payload] = true;
    },
    saveSelection(state) {
      state.savedSelection = state.selection;
    },
    restoreSelection(state) {
      if (state.savedSelection) {
        state.selection = state.savedSelection;
        state.savedSelection = null;
      }
    },
  },
});

export const {
  setAssignmentIDForRelease,
  setNotifyMode,
  setInstrumentationProjectPieceIDs,
  setInstrumentationWorkSessionIDs,
  setInternalInit,
  setLayoutUtilsInit,
  setAssignmentIDForLookup,
  setInternalPositionForLookup,
  layoutSetUtils,
  layoutSetCollapseMap,
  layoutSetDebug,
  layoutSetInternal,
  layoutSetMoveToSectionID,
  layoutUnselectAll,
  layoutHoverSectionID,
  setReorderInternalSection,
  selectPositions,
  unselectPositions,
  setSelectedProjectPieceIDs,
  setSelectedWorkSessionIDs,
  selectWorkSessionID,
  selectProjectPieceID,
  setChairIDsHovered,
  saveSelection,
  restoreSelection,
  setAttendanceAssignmentIDs,
  setAttendanceWorkSessionID,
} = missionControlSlice.actions;

export const layoutReorderEnabledForSectionSelector = (sectionID?: number) => {
  return createSelector(
    [(s: RootState) => s.missionControl.hoverSectionID],
    (hoverSectionID) => {
      if (sectionID) return sectionID === hoverSectionID;
      return false;
    }
  );
};

export const layoutUtilsInitSelector = createSelector(
  (s: RootState) => s.missionControl.layoutUtilsInit,
  (s) => s
);

export const chairIDsHoveredSelector = createSelector(
  (s: RootState) => s.missionControl.chairIDsHovered,
  (s) => s
);

export const notifyModeSelector = createSelector(
  (s: RootState) => s.missionControl.notifyMode,
  (s) => s
);

export const instrumentationProjectPieceIDsSelector = createSelector(
  (s: RootState) => s.missionControl.instrumentationProjectPieceIDs,
  (s) => s
);

export const attendanceWorkSessionIDSelector = createSelector(
  (s: RootState) => s.missionControl.attendanceWorkSessionID,
  (s) => s
);

export const instrumentationWorkSessionIDsSelector = createSelector(
  (s: RootState) => s.missionControl.instrumentationWorkSessionIDs,
  (s) => s
);

export const internalInitSelector = createSelector(
  (s: RootState) => s.missionControl.internalInit,
  (s) => s
);

export const internalPositionForLookupSelector = createSelector(
  (s: RootState) => s.missionControl.internalPositionForLookup,
  (s) => s
);

export const assignmentIDForLookupSelector = createSelector(
  (s: RootState) => s.missionControl.assignmentIDForLookup,
  (s) => s
);

export const assignmentIDForReleaseSelector = createSelector(
  (s: RootState) => s.missionControl.assignmentIDForRelease,
  (s) => s
);

export const attendanceAssignmentIDsSelector = createSelector(
  (s: RootState) => s.missionControl.attendanceAssignmentIDs,
  (s) => s
);

export const layoutHoverSectionIDSelector = createSelector(
  (s: RootState) => s.missionControl.hoverSectionID,
  (s) => s
);

export const layoutUtilsSelector = createSelector(
  (s: RootState) => s.missionControl.layoutUtils,
  (s) => s
);

export const layoutCollapseMapSelector = createSelector(
  (s: RootState) => s.missionControl.collapseMap,
  (s) => s
);

export const layoutDebugSelector = createSelector(
  (s: RootState) => s.missionControl.debug,
  (s) => s
);

export const layoutInternalSelector = createSelector(
  [
    (s: RootState) => s.missionControl.internal,
    (s: RootState) => s.missionControl.layoutUtils,
    (s: RootState) => s.missionControl.selection,
  ],
  (i, u, s) => {
    if (i && u) return new Internal(i, u, s);
    return undefined;
  }
);

export const reorderInternalSectionSelector = createSelector(
  [
    (s: RootState) => s.missionControl.reorderInternalSection,
    (s: RootState) => s.missionControl.layoutUtils,
    (s: RootState) => s.missionControl.selection,
  ],
  (i, u, s) => new InternalSection(i, u, s)
);

export const layoutInternal2Selector = createSelector(
  (s: RootState) => s.missionControl.internal,
  (s) => s
);

export const positionsSelectedSelector = createSelector(
  [
    (s: RootState) => s.missionControl.selecto,
    (s: RootState) => s.missionControl.internal,
    (s: RootState) => s.missionControl.layoutUtils,
    (s: RootState) => s.missionControl.selection,
  ],
  (s, i, u, o) => {
    return new Selecto(s, i, u, o);
  }
);

export const layoutMoveToSectionIDSelector = createSelector(
  (s: RootState) => s.missionControl.moveToSectionID,
  (s) => s
);

export const selectionSelector = createSelector(
  [
    (s: RootState) => s.missionControl.selection,
    (s: RootState) => s.missionControl.layoutUtils,
  ],
  (s, u) => new Selection(s, u)
);
