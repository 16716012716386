import { Grid, Typography } from "@mui/joy";
import { Box, CircularProgress, Divider, Tab, Tabs } from "@mui/material";
import { Link, Route, Routes } from "react-router-dom";
import Id from "atoms/Id/Id";
import ProjectsDataGrid from "features/dataGrids/ProjectsDataGrid";
import WorkSessionsDataGrid from "features/dataGrids/WorkSessionsDataGrid/WorkSessionsDataGrid";
import Calendar from "hooks/Calendar";
import VenueDangerZone from "hooks/VenueDangerZone";
import VenueGeneral from "hooks/VenueGeneral";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setSelectedVenueID,
  setTabIndex,
  tabIndexSelector,
} from "reducers/rhapsody";
import { useVenue } from "redux/venue/venueHooks";
import { useCompanySettings } from "redux/company/companyHooks";
import BookletDataGrid from "features/dataGrids/BookletDataGrid/BookletDataGrid";

export default function VenuePage({ id, routerMode = false }) {
  const params = useParams();
  const dispatch = useDispatch();
  const venueID = id || parseInt(params.venueID);
  const { venue } = useVenue(venueID);
  const tabIndex = useSelector(tabIndexSelector("venue"));
  const settings = useCompanySettings();

  useEffect(() => {
    return () => {
      dispatch(setSelectedVenueID());
    };
  }, []);

  if (!venue) return <div />;

  const paths = [
    "general",
    "calendar",
    "projects",
    "work-sessions",
    "danger-zone",
  ];

  if (settings.booklet) {
    paths.splice(paths.length - 1, 0, "Booklets");
  }

  const contents = [
    <Box key={paths[0]} sx={{ p: 2, pb: 8 }}>
      <VenueGeneral id={venueID} />
    </Box>,
    <Box
      key={paths[1]}
      sx={{ p: 2, flex: 1, height: "calc(100vh - 141px)", display: "flex" }}
    >
      <Calendar venueID={venueID} />
    </Box>,
    <Box
      key={paths[2]}
      sx={{ p: 2, flex: 1, height: "calc(100vh - 141px)", display: "flex" }}
    >
      <ProjectsDataGrid venueID={venueID} />
    </Box>,
    <Box
      key={paths[3]}
      sx={{ p: 2, flex: 1, height: "calc(100vh - 141px)", display: "flex" }}
    >
      <WorkSessionsDataGrid venueID={venueID} />
    </Box>,
    <Box
      key={paths[4]}
      sx={{ p: 2, flex: 1, height: "calc(100vh - 141px)", display: "flex" }}
    >
      <BookletDataGrid venueID={venueID} />
    </Box>,
    <Box
      key={paths[5]}
      sx={{ p: 2, flex: 1, height: "calc(100vh - 141px)", display: "flex" }}
    >
      <VenueDangerZone venueID={venueID} />
    </Box>,
  ];

  if (!venue)
    return (
      <Grid
        item
        xs={12}
        style={{
          textAlign: "center",
          height: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
          <Typography variant="h6" color="textPrimary">
            Please Wait
          </Typography>
        </div>
      </Grid>
    );

  return (
    <>
      <Box
        sx={{
          top: -65,
          position: "sticky",
          zIndex: 100,
          background: "white",
        }}
      >
        <Helmet>
          <title>Rhapsody | {venue.name ?? ""}</title>
        </Helmet>
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
          <Box>
            <Typography level="h6">{venue.name}</Typography>
            <Typography level="body2" endDecorator={<Id>{venue.id}</Id>}>
              Venue
            </Typography>
          </Box>
        </Box>
        <MyTabs routerMode={routerMode} />
        <Divider />
      </Box>
      {routerMode ? (
        <Routes>
          {contents.map((e, i) => (
            <Route key={paths[i]} path={paths[i]} element={e}></Route>
          ))}
        </Routes>
      ) : (
        contents[tabIndex]
      )}
    </>
  );
}

function MyTabs({ routeMatch, routerMode }) {
  const currentTab = routeMatch?.pattern?.path;
  const dispatch = useDispatch();
  const tabIndex = useSelector(tabIndexSelector("venue"));
  const settings = useCompanySettings();

  return (
    <Tabs
      variant="scrollable"
      value={routerMode ? currentTab : tabIndex}
      onChange={
        !routerMode &&
        ((e, v) => dispatch(setTabIndex({ tabID: "venue", value: v })))
      }
    >
      <Tab
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <i className="fa-regular fa-location-dot"></i>
            General
          </Box>
        }
        value={routerMode ? "/venues/:id/general" : 0}
        to={routerMode && "general"}
        component={Link}
      />
      <Tab
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <i className="fa-regular fa-calendar"></i>
            Calendar
          </Box>
        }
        value={routerMode ? "/venues/:id/calendar" : 1}
        to={routerMode && "calendar"}
        component={Link}
      />
      <Tab
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <i className="fa-regular fa-folder-open"></i>
            Projects
          </Box>
        }
        value={routerMode ? "/venues/:id/projects" : 2}
        to={routerMode && "projects"}
        component={Link}
      />
      <Tab
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <i className="fa-regular fa-music"></i>
            Work Sessions
          </Box>
        }
        value={routerMode ? "/venues/:id/work-sessions" : 3}
        to={routerMode && "work-sessions"}
        component={Link}
      />
      <Tab
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <i className="fa-regular fa-books"></i>
            Booklets
          </Box>
        }
        style={{
          display: !settings.booklet ? "none" : "flex",
        }}
        value={routerMode ? "/venues/:id/booklets" : 4}
        to={routerMode && "booklets"}
        component={Link}
      />
      <Tab
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <i className="fa-solid fa-hand"></i>
            Danger Zone
          </Box>
        }
        value={routerMode ? "/venues/:id/danger-zone" : 5}
        to={routerMode && "sent"}
        component={Link}
      />
    </Tabs>
  );
}
