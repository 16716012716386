import {
  Button,
  IconButton,
  Input,
  Sheet,
  Slider,
  Switch,
  Tab,
  TabList,
  Tabs,
  Typography,
  useTheme,
} from "@mui/joy";
import { Hidden, Tooltip, useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import moment from "moment";
// material-ui
import { REACT_APP_API } from "config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedWorkSessionIDSelector } from "reducers/rhapsody";
import { useWorkSession } from "redux/workSession/workSessionHooks";
import { useExporter } from "features/exporter/exporter";

const fonts = ["helvetica", "courier", "times"];

const checks = [
  "showProjectTitle",
  "showPosition",
  "showMessages",
  "showAlternates",
  "showRemoved",
  "showFamilyTitle",
  "showProjectNotes",
];

const labels = {
  showProjectTitle: "Show Project Title",
  showPosition: "Show Chair Numbers",
  showMessages: "Show Messages",
  showAlternates: "Show Alternates",
  showRemoved: "Show Removed",
  showFamilyTitle: "Show Family Title",
  showProjectNotes: "Show Project Notes",
};

const marks = [
  {
    value: -5,
    label: "-5",
  },
  {
    value: -4,
  },
  {
    value: -3,
  },
  {
    value: -2,
  },
  {
    value: -1,
  },
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
    label: "+5",
  },
];

export default function Roster({ id }) {
  const [pdf, setPDF] = useState();
  const [config, setConfig] = useState({
    font: "helvetica",
    orientation: "portrait",
    options: {
      fontSizeZoom: "0",
    },
  });
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const workSessionID = id ?? useSelector(selectedWorkSessionIDSelector);
  const { workSession } = useWorkSession(workSessionID);
  const exporter = useExporter();

  useEffect(() => {
    refreshPDF(config);
  }, []);

  const refreshPDF = async (c) => {
    await fetch(`${REACT_APP_API}/rhapsody/workSessions/${id}/roster`, {
      body: JSON.stringify(c),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      method: "PUT",
    })
      .then((response) => response.blob())
      .then((d) => {
        setPDF(window.URL.createObjectURL(d));
      });
  };

  const downloadPDF = async () => {
    await fetch(`${REACT_APP_API}/rhapsody/workSessions/${id}/roster`, {
      body: JSON.stringify({ ...config, disposition: "attachment" }),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      method: "PUT",
    })
      .then((response) => response.blob())
      .then((d) => {
        downloadBlob(
          d,
          `${workSession.title}_${moment(workSession.dateFromUTC).format(
            "MM/DD/YYYY"
          )}.pdf`
        );
      });
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flex: 1,
        alignItems: "stretch",
      }}
    >
      <Hidden smDown>
        {workSession?.id ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
            key={1}
          >
            {pdf ? (
              <iframe
                title="preview"
                type="application/pdf"
                src={pdf}
                style={{ flex: 1, height: "100%", width: "100%" }}
              />
            ) : (
              []
            )}
          </div>
        ) : (
          []
        )}
      </Hidden>
      <Hidden smDown>
        <Divider orientation="vertical" />
      </Hidden>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          width: !sm ? 280 : "100%",
        }}
      >
        <Box
          sx={{
            p: 1,
            flex: 1,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Button
              onClick={downloadPDF}
              startDecorator={<i className="fa-regular fa-file-arrow-down"></i>}
            >
              Download Roster in PDF
            </Button>
            <Button
              onClick={() => exporter.workSessionRoster()}
              variant="soft"
              color="neutral"
              startDecorator={<i className="fa-regular fa-file-arrow-down"></i>}
            >
              Export in Excel
            </Button>
            <Box>
              <Typography level="body2">Title</Typography>
              <Input
                onBlur={() => refreshPDF(config)}
                value={config.options.title ?? ""}
                placeholder="Roster Title"
                variant="outlined"
                onChange={(e) =>
                  setConfig((c) => ({
                    ...c,
                    options: { ...c.options, title: e.target.value },
                  }))
                }
              />
            </Box>
            <Box>
              <Typography level="body2">Font</Typography>
              <Tabs
                aria-label="Plain tabs"
                value={fonts.indexOf(config.font)}
                size="sm"
                onChange={(event, value) =>
                  setConfig((c) => {
                    const _c = { ...c, font: fonts[value] };
                    refreshPDF(_c);
                    return _c;
                  })
                }
                sx={{ borderRadius: "lg" }}
              >
                <TabList variant="outlined">
                  {fonts.map((f) => (
                    <Tab
                      variant={config.font === f ? "soft" : "plain"}
                      color={config.font === f ? "primary" : "neutral"}
                      key={f}
                    >
                      {f}
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            </Box>
            <Box>
              <Typography level="body2">Orientation</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Tooltip title="Portrait">
                  <IconButton
                    variant={
                      config.orientation === "portrait" ? "soft" : "outlined"
                    }
                    color={
                      config.orientation === "portrait" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setConfig((c) => {
                        const _c = { ...c, orientation: "portrait" };
                        refreshPDF(_c);
                        return _c;
                      })
                    }
                  >
                    <i className="fa-duotone fa-image-portrait"></i>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Landscape">
                  <IconButton
                    variant={
                      config.orientation === "landscape" ? "soft" : "outlined"
                    }
                    color={
                      config.orientation === "landscape" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setConfig((c) => {
                        const _c = { ...c, orientation: "landscape" };
                        refreshPDF(_c);
                        return _c;
                      })
                    }
                  >
                    <i className="fa-duotone fa-image-landscape"></i>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ pr: 1 }}>
              <Typography level="body2">
                Zoom: {config.options.fontSizeZoom}
              </Typography>
              <Slider
                track={false}
                aria-labelledby="track-false-slider"
                defaultValue={0}
                marks={marks}
                onChangeCommitted={() => refreshPDF(config)}
                min={-5}
                max={5}
                step={1}
                onChange={(e, v) =>
                  setConfig((c) => ({
                    ...c,
                    options: { ...c.options, fontSizeZoom: `${v}` },
                  }))
                }
              />
            </Box>
            <Sheet
              variant="soft"
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                borderRadius: "8px",
              }}
            >
              <Typography level="body1">
                <b>Options</b>
              </Typography>
              {checks.map((option) => (
                <Box
                  key={option}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  onClick={() => {
                    setConfig((c) => {
                      const _c = {
                        ...c,
                        options: {
                          ...c.options,
                          [option]:
                            c.options[option] === "true" ? "false" : "true",
                        },
                      };
                      refreshPDF(_c);
                      return _c;
                    });
                  }}
                >
                  <Typography level="body2">{labels[option]}</Typography>
                  <Switch
                    checked={config.options[option] === "true"}
                    size="sm"
                  />
                </Box>
              ))}
            </Sheet>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export function downloadBlob(blob, fileName) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob)
    return window.navigator.msSaveOrOpenBlob(blob);

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = data;
  link.download = fileName;

  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
}
