import { Box, Button } from "@mui/joy";
import {
  Avatar,
  Icon,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setFormOpen } from "reducers/rhapsody";
import { useVenue, useVenues } from "redux/venue/venueHooks";

export default function VenueFinder({
  venueID,
  setVenue,
  simple = false,
  autofocus = false,
}) {
  const [venueInput, setVenueInput] = React.useState("");
  const [open, setOpen] = useState(false);
  const { venue } = useVenue(venueID);

  const dispatch = useDispatch();
  const { venues } = useVenues();

  const searchVenue = (e) => {
    setVenueInput(e.target.value);
  };

  let filteredUserVenues =
    venues?.filter(
      (s) =>
        `${s.name} ${s.address} ${s.zipcode} ${s.city} ${s.state}`
          .toLowerCase()
          .indexOf(venueInput.toLowerCase()) !== -1
    ) ?? [];

  const ref = useRef();

  const InputComponent = simple ? InputBase : TextField;

  return (
    <Box ref={ref} sx={{ width: "100%", flex: 1, position: "relative" }}>
      {!venue ? (
        <InputComponent
          autoFocus={autofocus}
          onFocus={() => setOpen(true)}
          label="Venue"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="fad fa-location-dot"></i>
              </InputAdornment>
            ),
            placeholder: "Examples: Capitol Records, Fox...",
          }}
          placeholder={simple && "Venue"}
          size="small"
          value={venueInput}
          onChange={searchVenue}
          fullWidth
          className={"textfield"}
        />
      ) : (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <div
            style={{
              border: !simple && `solid 1px #C4C4C4`,
              height: 41,
              padding: "2px 4px",
              borderRadius: "16px",
              flexGrow: 1,
              background: !simple && "white",
              textAlign: "left",
              lineHeight: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              position: "relative",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <div
              style={{
                paddingRight: 4,
                flexGrow: 1,
              }}
            >
              <Typography
                style={{
                  fontSize: simple && 14,
                }}
                noWrap
                display="block"
              >
                {venue?.name}
              </Typography>
            </div>
          </div>
          <Box sx={{ mr: 1, position: "sticky", right: 0 }}>
            <IconButton
              sx={{ height: 16, width: 16, padding: 0 }}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setVenue();
              }}
            >
              <Icon
                fontSize="inherit"
                style={{
                  fontSize: 14,
                  width: 14,
                  marginLeft: 4,
                }}
                className="far fa-times"
              />
            </IconButton>
          </Box>
        </Box>
      )}
      {open && !venue ? (
        <Popper
          open={open && ref.current}
          anchorEl={open && ref.current}
          placement="top"
          style={{
            width: "calc(100% - 40px)",
            maxWidth: 400,
            zIndex: 9999,
          }}
        >
          <Paper elevation={10} sx={{ position: "relative" }}>
            <List dense style={{ maxHeight: 400, overflow: "auto" }}>
              {filteredUserVenues?.map((p) => (
                <ListItemButton
                  button
                  key={p.id}
                  dense
                  divider
                  onClick={() => {
                    setOpen(false);
                    setVenue(p);
                    setVenueInput("");
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <i className="fas fa-star"></i>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    key={p.id}
                    primary={p.name}
                    secondary={`${p.address} ${p.zipcode} ${p.city} ${p.state}`}
                  />
                </ListItemButton>
              ))}
            </List>
            <Box
              sx={{
                p: 1,
                position: "sticky",
                bottom: 0,
                background: "white",
              }}
            >
              <Button
                size="sm"
                fullWidth
                onClick={() => {
                  setOpen(false);
                  dispatch(setFormOpen({ isOpen: true, formID: "venue" }));
                }}
              >
                + Venue
              </Button>
            </Box>
          </Paper>
        </Popper>
      ) : (
        []
      )}
    </Box>
  );
}
