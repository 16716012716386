import { Button, Tooltip } from "@mui/joy";
import { mapToArray } from "helpers";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectedProjectIDSelector } from "reducers/rhapsody";
import { layoutUtilsSelector } from "reducers/v2/missionControl";
import { useProjectLinks } from "redux/link/linkHooks";
import { useProject } from "redux/project/projectHooks";
import { useProjectContacts } from "redux/projectContact/projectContactHooks";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";
import { useProjectRosters } from "redux/projectRoster/projectRosterHooks";
import { useSubRules } from "redux/subRule/subRuleHooks";
import { useVenues } from "redux/venue/venueHooks";

/**
 *
 * @returns {ReactElement} Deletes useless work session chairs
 */
export function JSONExport() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { project } = useProject(projectID);
  const { projectContacts } = useProjectContacts(projectID);
  const { links } = useProjectLinks(projectID);
  const { projectRosters } = useProjectRosters(projectID);
  const { projectNotes } = useProjectNotes(projectID);
  const { subRules } = useSubRules({
    filters: JSON.stringify([
      {
        name: "sub_rules.projectID",
        comparison: "eq",
        value: projectID,
      },
    ]),
  });
  const utils = useSelector(layoutUtilsSelector);

  const venueIDs = utils.workSessions.reduce((a, v) => {
    if (!a.includes(v.venueID)) a.push(v.venueID);
    return a;
  }, []);

  const { venues } = useVenues();

  const jsonExport = () => {
    const musicians = mapToArray(utils.musiciansMap);
    const assignments = mapToArray(utils.assignmentsMap);
    const fileName = `${project.id}_${moment().utc().format()}.json`;
    const data = {
      project,
      workSessions: utils.workSessions,
      chairs: utils.chairs,
      musicians,
      projectRosters,
      projectPieces: utils.projectPieces,
      workSessionProjectPieces: utils.workSessionProjectPieces,
      pieces: mapToArray(utils.piecesMap),
      assignments,
      projectContacts,
      links,
      projectNotes,
      subRules,
      venues: venues.filter((v) => venueIDs.includes(v.id)),
    };
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // Create a link element, use it to download the file and then remove it
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Free up the memory from the blob
    URL.revokeObjectURL(href);
  };

  return (
    <Button
      onClick={jsonExport}
      color={"neutral"}
      sx={{ flexShrink: 0 }}
      size="sm"
      startDecorator={<i className="fa-regular fa-file-arrow-down"></i>}
    >
      Export
    </Button>
  );
}
