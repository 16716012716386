import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Assignment_Entity } from "entities/assignment";
import { rhapsodyApi } from "../api/rhapsodyApi";

const assignmentsAdapter = createEntityAdapter<Assignment_Entity>();
const initialState = assignmentsAdapter.getInitialState();

export const assignmentEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getAssignments: build.query<
      EntityState<Assignment_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `assignments`,
          params: args,
        };
      },
      transformResponse: (responseData: Assignment_Entity[]) => {
        return assignmentsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["assignments"],
    }),
    getTagAssignments: build.query<EntityState<Assignment_Entity>, number>({
      query: (id) => `tags/${id}/assignments`,
      transformResponse: (responseData: Assignment_Entity[]) => {
        return assignmentsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["assignments"],
    }),
    getAssignment: build.query<Assignment_Entity, number>({
      query: (id) => `assignments/${id}`,
      providesTags: (result, error, id) => [{ type: "assignments", id }],
    }),
    createAssignment: build.mutation<
      Assignment_Entity,
      Partial<Assignment_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `assignments`,
      }),
      invalidatesTags: ["assignments"],
    }),
    updateAssignment: build.mutation<
      void,
      { id: number; body: Partial<Assignment_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `assignments/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "assignments", id },
        "assignments",
      ],
    }),
    updateAssignmentMercury: build.mutation<
      void,
      { id: number; body: { mercuryInteractorID: number } }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `assignments/${args.id}/mercury`,
      }),
      invalidatesTags: ["assignments", "mercuryJobs"],
    }),
    updateMissionMercury: build.mutation<
      void,
      { id: number; body: { mercuryInteractorID: number } }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `missions/${args.id}/mercury`,
      }),
      invalidatesTags: ["assignments", "mercuryJobs"],
    }),
    updateBatchAssignments: build.mutation<
      Assignment_Entity,
      Partial<Assignment_Entity>[]
    >({
      query: (body) => ({
        method: "PUT",
        body: body,
        url: `assignments/batch`,
      }),
      invalidatesTags: ["assignments"],
    }),
    deleteAssignment: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `assignments/${id}`,
      }),
      invalidatesTags: ["assignments"],
    }),
  }),
});

export const {
  useGetTagAssignmentsQuery,
  useLazyGetAssignmentsQuery,
  useUpdateAssignmentMercuryMutation,
  useUpdateMissionMercuryMutation,
  useGetAssignmentQuery,
  useUpdateBatchAssignmentsMutation,
  useGetAssignmentsQuery,
  useCreateAssignmentMutation,
  useDeleteAssignmentMutation,
  useUpdateAssignmentMutation,
} = assignmentEndpoints;

export default assignmentEndpoints;
