import { useMemo } from "react";
import {
  useGetMusicianVenueQuery,
  useGetMusicianVenuesQuery,
} from "./musicianVenueEndpoints";
import { MusicianVenue } from "entities/musicianVenue";

export function useMusicianVenue(musicianVenueID: number | undefined) {
  const musicianVenueQuery = useGetMusicianVenueQuery(musicianVenueID ?? 0, {
    skip: (musicianVenueID ?? 0) <= 0,
  });
  const musicianVenueEntity = musicianVenueQuery.data;

  return useMemo(() => {
    const ret: typeof musicianVenueQuery & {
      musicianVenue?: MusicianVenue | undefined;
    } = {
      ...musicianVenueQuery,
    };
    if (musicianVenueEntity)
      ret.musicianVenue = new MusicianVenue(musicianVenueEntity);
    return ret;
  }, [musicianVenueEntity]);
}

export function useMusicianVenues(musicianID?: number) {
  const musicianVenueQuery = useGetMusicianVenuesQuery(
    musicianID
      ? {
          filters: JSON.stringify([
            {
              name: "musician_venues.musicianID",
              comparison: "eq",
              value: musicianID,
            },
          ]),
        }
      : null
  );
  const musicianVenueEntitiesMap = musicianVenueQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianVenueQuery & {
      musicianVenues?: MusicianVenue[];
      musicianVenuesMap?: { [id: number]: MusicianVenue };
    } = {
      ...musicianVenueQuery,
      musicianVenues: [],
      musicianVenuesMap: {},
    };
    if (musicianVenueEntitiesMap) {
      const musicianVenues: MusicianVenue[] = [];
      const musicianVenuesMap: { [id: number]: MusicianVenue } = {};

      for (const key in musicianVenueEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(musicianVenueEntitiesMap, key)
        ) {
          const c = musicianVenueEntitiesMap[key];
          const musicianVenue = new MusicianVenue(c);
          musicianVenues.push(musicianVenue);
          musicianVenuesMap[musicianVenue.id] = musicianVenue;
        }
      }
      ret.musicianVenues = musicianVenues;
      ret.musicianVenuesMap = musicianVenuesMap;
    }

    return ret;
  }, [musicianVenueEntitiesMap]);
}

export function useMusicianVenuesFor(musicianIDs?: number[]) {
  const musicianVenueQuery = useGetMusicianVenuesQuery(
    musicianIDs
      ? {
          filters: JSON.stringify([
            {
              name: "musician_venues.musicianID",
              comparison: "in",
              value: musicianIDs,
            },
          ]),
        }
      : null
  );
  const musicianVenueEntitiesMap = musicianVenueQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianVenueQuery & {
      musicianVenues?: MusicianVenue[];
      musicianVenuesMap?: { [id: number]: MusicianVenue };
    } = {
      ...musicianVenueQuery,
      musicianVenues: [],
      musicianVenuesMap: {},
    };
    if (musicianVenueEntitiesMap) {
      const musicianVenues: MusicianVenue[] = [];
      const musicianVenuesMap: { [id: number]: MusicianVenue } = {};

      for (const key in musicianVenueEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(musicianVenueEntitiesMap, key)
        ) {
          const c = musicianVenueEntitiesMap[key];
          const musicianVenue = new MusicianVenue(c);
          musicianVenues.push(musicianVenue);
          musicianVenuesMap[musicianVenue.id] = musicianVenue;
        }
      }
      ret.musicianVenues = musicianVenues;
      ret.musicianVenuesMap = musicianVenuesMap;
    }

    return ret;
  }, [musicianVenueEntitiesMap]);
}
