import {
  Alert,
  Button,
  Chip,
  ChipDelete,
  CircularProgress,
  Divider,
  Input,
  Link,
  Sheet,
  Textarea,
  Tooltip,
} from "@mui/joy";
import JoyTypography from "@mui/joy/Typography";
import { Avatar, Grid, Menu, MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { NerdPhoneField } from "@nerdjs/nerd-ui";
import { Dictionary } from "@reduxjs/toolkit";
import FileUpload from "atoms/FileUpload";
import { Musician_Entity } from "entities/musician";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import * as moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  selectedMusicianIDSelector,
  setSelectedMusicianID,
} from "reducers/rhapsody";
import { useGroups } from "redux/group/groupHooks";
import { useUpdateMusicianMutation } from "redux/musician/musicianEndpoints";
import { useMusician } from "redux/musician/musicianHooks";
import {
  useCreateMusicianGroupMutation,
  useDeleteMusicianGroupMutation,
} from "redux/musicianGroup/musicianGroupEndpoints";
import {
  useCreateMusicianVenueMutation,
  useDeleteMusicianVenueMutation,
  useUpdateMusicianVenueMutation,
} from "redux/musicianVenue/musicianVenueEndpoints";
import { useMusicianVenues } from "redux/musicianVenue/musicianVenueHooks";
import { useVenues } from "redux/venue/venueHooks";
import { MusicianInstruments } from "../MusicianInstruments/MusicianInstruments";
import { MusicianPrivateNotes } from "./MusiciansPrivateNotes";
import { GooglePlaceSuggestions } from "hooks/googlePlaceSuggestions/googlePlaceSuggestions";
import Musician, { Geocode } from "entities/musician/helper";

export default function MusicianGeneral() {
  const musicianID = useSelector(selectedMusicianIDSelector);
  const { musician } = useMusician(musicianID);
  const readOnly = useReadOnly();
  const [groupAnchorEl, setGroupAnchorEl] = useState<
    (EventTarget & HTMLAnchorElement) | null
  >(null);
  const { groups } = useGroups();
  const dispatch = useDispatch();
  const [updateMusician, { isLoading: isUpdating }] =
    useUpdateMusicianMutation();
  const [deleteMusicianGroup] = useDeleteMusicianGroupMutation();
  const [createMusicianGroup] = useCreateMusicianGroupMutation();
  const [birthday, setBirthday] = useState<moment.Moment | null>(null);
  const navigate = useNavigate();
  const [_musician, _setMusician] = useState<Musician_Entity | undefined>();
  const { venues, venuesMap } = useVenues();

  const [createMusicianVenue] = useCreateMusicianVenueMutation();
  const [updateMusicianVenue] = useUpdateMusicianVenueMutation();
  const [deleteMusicianVenue] = useDeleteMusicianVenueMutation();
  const { musicianVenues } = useMusicianVenues(musicianID);

  const [mileage, setMileage] = useState<Dictionary<number>>({});

  useEffect(() => {
    if (!musician.placeID && musician.validAddress()) {
      musician.geocode().then((e) => {
        updateMusician({ id: musician.id, body: { ...musician, ...e } });
      });
    }
  }, [musician]);

  useEffect(() => {
    setMileage(
      venues.reduce<Dictionary<number>>((a, v) => {
        a[v.id] = musicianVenues.find((mv) => mv.venueID === v.id)?.mileage;
        return a;
      }, {})
    );
  }, [musicianVenues, venues]);

  useEffect(() => {
    _setMusician(musician);
    if (musician?.birthdate && musician?.birthdate !== "0001-01-01T00:00:00Z") {
      setBirthday(moment(musician?.birthdate));
    }
  }, [musician]);

  useEffect(() => {
    if (birthday?.isValid) {
      _setMusician((m) => {
        m.birthdate = birthday.utc().format();
        return { ...m };
      });
    }
  }, [birthday]);

  const save = async (name: string, numeric?: boolean) => {
    if (musician[name] === _musician[name]) return;
    let geocode: Geocode = {};

    if (
      name === "address" ||
      name === "zipcode" ||
      name === "city" ||
      name === "state"
    ) {
      const _newMusician = new Musician({
        ...musician,
        [name]: numeric ? Number(_musician[name]) : _musician[name],
      });
      geocode = await _newMusician.geocode();
    }

    updateMusician({
      id: musician.id,
      body: {
        [name]: numeric ? Number(_musician[name]) : _musician[name],
        ...geocode,
      },
    });
  };

  const clearAllMileage = () => {
    musicianVenues.forEach((mv) => {
      deleteMusicianVenue(mv.id);
    });
  };

  const saveMileage = () => {
    for (const venueID in mileage) {
      if (Object.prototype.hasOwnProperty.call(mileage, venueID)) {
        const amount = mileage[venueID];

        if (!amount) continue;

        const musicianVenue = musicianVenues.find(
          (mv) => mv.venueID === parseInt(venueID)
        );

        if (musicianVenue && musicianVenue.mileage === amount) continue;

        if (musicianVenue && amount !== musicianVenue.mileage) {
          updateMusicianVenue({
            id: musicianVenue.id,
            body: {
              ...musicianVenue,
              mileage: amount,
              verified: false,
              polyline: "",
              musicianPlaceID: musician.placeID,
              venuePlaceID: venuesMap[parseInt(venueID)]?.placeID,
            },
          });
        } else {
          createMusicianVenue({
            mileage: amount,
            venueID: parseInt(venueID),
            musicianID: musicianID,
            verified: false,
            musicianPlaceID: musician.placeID,
            venuePlaceID: venuesMap[parseInt(venueID)]?.placeID,
          });
        }
      }
    }
  };

  if (!_musician) return <CircularProgress />;

  return (
    <Grid container spacing={2} className="musician">
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Sheet
              variant="soft"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 2,
                borderRadius: "8px",
              }}
            >
              <Grid container sx={{ flex: 1 }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <FileUpload
                    cropDimension={{ width: 512, height: 512 }}
                    label="Upload Avatar"
                    onUpload={(f) => {
                      if (!readOnly)
                        updateMusician({
                          id: musician.id,
                          body: { avatar: f },
                        });
                    }}
                  >
                    <Avatar
                      src={musician.avatar}
                      style={{
                        height: 150,
                        width: 150,
                        fontSize: 54,
                        marginBottom: 8,
                      }}
                    >
                      <i className="fa-duotone fa-user-music"></i>
                    </Avatar>
                  </FileUpload>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {_musician.avatar && !readOnly ? (
                      <Chip
                        variant="outlined"
                        color="neutral"
                        onClick={(f) => {
                          updateMusician({
                            id: musician.id,
                            body: { avatar: "" },
                          });
                        }}
                        size="sm"
                      >
                        Remove Avatar
                      </Chip>
                    ) : (
                      []
                    )}
                    {!readOnly ? (
                      <FileUpload
                        cropDimension={{ width: 512, height: 512 }}
                        label="Upload Avatar"
                        onUpload={(f) => {
                          updateMusician({
                            id: musician.id,
                            body: { avatar: f },
                          });
                        }}
                      >
                        <Chip
                          variant="outlined"
                          color="neutral"
                          onClick={(f) => {
                            updateMusician({
                              id: musician.id,
                              body: { avatar: "" },
                            });
                          }}
                          size="sm"
                        >
                          Upload Avatar
                        </Chip>
                      </FileUpload>
                    ) : (
                      []
                    )}
                  </Box>
                  <Typography variant="h6" color="textPrimary" display="block">
                    {musician.nickName ||
                      `${musician?.firstName} ${musician?.lastName}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} justifyContent="center">
                {musician.email ? (
                  <Grid item xs={12} md={6}>
                    <Button
                      fullWidth
                      onClick={() => {
                        const win: Window = window;
                        win.location = `mailto:${musician.email}`;
                      }}
                      component={Link}
                      href={`mailto:${musician.email}`}
                      startDecorator={<i className="fa-solid fa-envelope"></i>}
                    >
                      Email
                    </Button>
                  </Grid>
                ) : (
                  []
                )}
                {musician.phone ? (
                  <Grid item xs={12} md={6}>
                    <Button
                      fullWidth
                      onClick={() => {
                        const win: Window = window;
                        win.location = `tel:${musician.email}`;
                      }}
                      color="success"
                      component={Link}
                      href={`tel:${musician.phone}`}
                      startDecorator={<i className="fa-solid fa-phone"></i>}
                      sx={{
                        background: "#00c853",
                        "&:hover": {
                          background: "#43a047",
                        },
                        "&:active:": {
                          background: "#388e3c",
                        },
                      }}
                    >
                      Call
                    </Button>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
              {!musician.inSync ? (
                <Alert
                  startDecorator={
                    <i className="fa-solid fa-rotate-exclamation"></i>
                  }
                  color="warning"
                  variant="solid"
                >
                  <Box>
                    <Typography color="inherit" variant="body2">
                      <b>Musician information out of sync</b>
                    </Typography>
                    <Typography color="inherit" variant="caption">
                      {musician.fullName()} is using the Rhapsody mobile app.
                      The personal information he entered are different than
                      what you have.
                    </Typography>
                  </Box>
                </Alert>
              ) : (
                []
              )}
              {!musician.active ? (
                <Alert
                  startDecorator={<i className="fa-duotone fa-box-archive"></i>}
                  sx={{ background: "#C0AE97" }}
                  variant="solid"
                  endDecorator={
                    <Button
                      disabled={readOnly}
                      onClick={() => {
                        _setMusician((m) => {
                          m.active = !m.active;

                          updateMusician({
                            id: musician.id,
                            body: {
                              active: true,
                            },
                          });
                          return { ...m };
                        });
                      }}
                      color="neutral"
                      variant="soft"
                    >
                      Unarchive
                    </Button>
                  }
                >
                  <Box>
                    <Typography color="inherit" variant="body2">
                      <b>This musician is archived</b>
                    </Typography>
                    <Typography color="inherit" variant="caption">
                      {musician.fullName()} won't show up in your hiring
                      suggestions.
                    </Typography>
                  </Box>
                </Alert>
              ) : (
                []
              )}
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  disabled={readOnly}
                  variant={_musician.sub ? "solid" : "outlined"}
                  color={_musician.sub ? "primary" : "neutral"}
                  onClick={() => {
                    _setMusician((m) => {
                      m.sub = !m.sub;

                      updateMusician({
                        id: musician.id,
                        body: {
                          sub: m.sub,
                        },
                      });

                      return { ...m };
                    });
                  }}
                  sx={{
                    flex: 1,
                    background: _musician.sub ? "#607d8b" : "white",
                    "&:hover": {
                      background: _musician.sub ? "#546e7a" : "white",
                    },
                    "&:active:": {
                      background: _musician.sub ? "#455a64" : "white",
                    },
                  }}
                  startDecorator={
                    _musician.sub ? (
                      <i className="fa-regular fa-circle-dashed"></i>
                    ) : (
                      <i className="fa-regular fa-circle-dashed"></i>
                    )
                  }
                  endDecorator={
                    <Tooltip
                      size="sm"
                      arrow
                      variant="outlined"
                      title={
                        <Box sx={{ p: 1, maxWidth: 300 }}>
                          If enabled, a dashed line will appear around the
                          musician's avatar, indicating they are contracted as a
                          subs.
                          <br />
                          You can configure Rhapsody so subs never receive
                          assignments in the{" "}
                          <Link
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              dispatch(setSelectedMusicianID());
                              navigate(`${RouterConfig.myAccount}/preferences`);
                            }}
                            level="body3"
                            component={RouterLink}
                            to={`${RouterConfig.myAccount}/preferences`}
                          >
                            Preferences
                          </Link>
                        </Box>
                      }
                    >
                      <i className="fa-sharp fa-regular fa-circle-info"></i>
                    </Tooltip>
                  }
                >
                  {_musician.sub ? "Is a Sub" : "Set as Sub"}
                </Button>
                <Button
                  disabled={readOnly}
                  variant={_musician.star ? "solid" : "outlined"}
                  color={_musician.star ? "primary" : "neutral"}
                  onClick={() => {
                    _setMusician((m) => {
                      m.star = !m.star;

                      updateMusician({
                        id: musician.id,
                        body: {
                          star: m.star,
                        },
                      });

                      return { ...m };
                    });
                  }}
                  sx={{
                    flex: 1,
                    background: _musician.star ? "#e91e63" : "white",
                    "&:hover": {
                      background: _musician.star ? "#d81b60" : "white",
                    },
                    "&:active:": {
                      background: _musician.star ? "#c2185b" : "white",
                    },
                  }}
                  startDecorator={
                    _musician.star ? (
                      <i className="fa-solid fa-star"></i>
                    ) : (
                      <i className="fa-thin fa-star"></i>
                    )
                  }
                  endDecorator={
                    <Tooltip
                      size="sm"
                      title={
                        <Box style={{ textAlign: "center" }}>
                          If enabled, a star will be displayed on the musician's
                          avatar.
                          <br />
                          Plus, their ranking will increase in musician
                          suggestions.
                        </Box>
                      }
                    >
                      <i className="fa-sharp fa-regular fa-circle-info"></i>
                    </Tooltip>
                  }
                >
                  {_musician.star ? "Is Favorite" : "Add to Favorite"}
                </Button>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  variant={_musician.contactBySMS ? "solid" : "outlined"}
                  color={_musician.contactBySMS ? "primary" : "neutral"}
                  disabled={!_musician.phone || readOnly}
                  onClick={() => {
                    _setMusician((m) => {
                      m.contactBySMS = !m.contactBySMS;

                      updateMusician({
                        id: musician.id,
                        body: {
                          contactBySMS: m.contactBySMS,
                        },
                      });

                      return { ...m };
                    });
                  }}
                  sx={{
                    flex: 1,
                    background: _musician.contactBySMS ? "#00c853" : "white",
                    "&:hover": {
                      background: _musician.contactBySMS ? "#43a047" : "white",
                    },
                    "&:active:": {
                      background: _musician.contactBySMS ? "#388e3c" : "white",
                    },
                  }}
                  startDecorator={<i className="fa-solid fa-comment-sms"></i>}
                >
                  {_musician.contactBySMS ? "SMS Enabled" : "Enable SMS"}
                </Button>
                {musician.active ? (
                  <Button
                    disabled={readOnly}
                    variant={!_musician.active ? "solid" : "outlined"}
                    color={"neutral"}
                    startDecorator={
                      <i className="fa-duotone fa-box-archive"></i>
                    }
                    sx={{ background: "white", flex: 1 }}
                    onClick={() => {
                      _setMusician((m) => {
                        m.active = !m.active;

                        updateMusician({
                          id: musician.id,
                          body: {
                            active: m.active,
                          },
                        });
                        return { ...m };
                      });
                    }}
                  >
                    {_musician.active ? "Archive" : "Unarchive"}
                  </Button>
                ) : (
                  []
                )}
              </Box>
              {musician?.userUUID ? (
                <JoyTypography
                  level="body3"
                  sx={{ margin: "auto" }}
                  startDecorator={
                    <i className="fa-regular fa-mobile-notch"></i>
                  }
                >
                  {musician.fullName()} uses the Rhapsody mobile app
                </JoyTypography>
              ) : (
                []
              )}
              <Divider>Musician Info</Divider>
              <Input
                disabled={readOnly}
                slotProps={{ input: { style: { textAlign: "right" } } }}
                startDecorator="First Name:"
                fullWidth
                autoComplete="off"
                onBlur={() => save("firstName")}
                value={_musician.firstName || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, firstName: e.target.value }))
                }
              />
              <Input
                disabled={readOnly}
                startDecorator="Middle Name:"
                slotProps={{ input: { style: { textAlign: "right" } } }}
                fullWidth
                onBlur={() => save("middleName")}
                value={_musician.middleName || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, middleName: e.target.value }))
                }
              />
              <Input
                disabled={readOnly}
                startDecorator="Last Name:"
                slotProps={{ input: { style: { textAlign: "right" } } }}
                fullWidth
                onBlur={() => save("lastName")}
                value={_musician.lastName || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, lastName: e.target.value }))
                }
              />
              <Input
                disabled={readOnly}
                startDecorator="Nickname:"
                slotProps={{ input: { style: { textAlign: "right" } } }}
                fullWidth
                onBlur={() => save("nickName")}
                value={_musician.nickName || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, nickName: e.target.value }))
                }
              />
              <Box
                sx={{
                  background: "white",
                  border: "solid 1px #EEEEEE",
                  borderRadius: "8px",
                  display: "flex",
                  pl: 1.5,
                  pr: 1,
                  alignItems: "center",
                  height: 40,
                }}
              >
                <Typography sx={{ color: "#707070" }}>Birthdate:</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateField
                    disabled={readOnly}
                    fullWidth
                    slotProps={{
                      textField: {
                        variant: "standard",
                        inputProps: {
                          border: "none",
                          disableUnderline: true,
                          style: { textAlign: "right" },
                        },
                      },
                    }}
                    onBlur={() => save("birthdate")}
                    value={birthday?.utc()}
                    onChange={(e) => setBirthday(e)}
                  />
                </LocalizationProvider>
              </Box>
              <Input
                disabled={readOnly}
                slotProps={{ input: { style: { textAlign: "right" } } }}
                startDecorator="Company Name:"
                fullWidth
                onBlur={() => save("companyName")}
                value={_musician.companyName || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, companyName: e.target.value }))
                }
              />
              <Input
                disabled={readOnly}
                slotProps={{ input: { style: { textAlign: "right" } } }}
                fullWidth
                startDecorator="Email:"
                onBlur={() => {
                  save("email");
                }}
                value={_musician.email || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, email: e.target.value }))
                }
              />
              <Box
                sx={{
                  background: "white",
                  border: "solid 1px #EEEEEE",
                  borderRadius: "8px",
                  display: "flex",
                  pl: 1.5,
                  pr: 1,
                  alignItems: "center",
                  height: 40,
                  justifyContent: "space-between",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Typography sx={{ color: "#707070" }}>Phone:</Typography>
                <Box
                  sx={{
                    width: 200,
                    height: 40,
                    position: "absolute",
                    top: 4,
                    right: 0,
                  }}
                >
                  <NerdPhoneField
                    value={_musician.phone}
                    onAccept={(e) => _setMusician((m) => ({ ...m, phone: e }))}
                    textFieldProps={{
                      fullWidth: true,
                      disabled: isUpdating || readOnly,
                      variant: "standard",
                      inputProps: {
                        border: "none",
                        disableUnderline: true,
                        style: { textAlign: "right" },
                      },
                      size: "small",
                      sx: { background: "white" },
                      onBlur: () => save("phone"),
                    }}
                  />
                </Box>
              </Box>
              <GooglePlaceSuggestions
                onSelect={(e) => {
                  const body = {
                    ...musician,
                    address: e.address,
                    city: e.city,
                    zipcode: e.zipcode,
                    placeID: e.placeID,
                    tzName: e.tzName,
                    latitude: `${e.latitude}`,
                    longitude: `${e.longitude}`,
                  };
                  updateMusician({ id: musician.id, body });
                  _setMusician((m) => ({
                    ...m,
                    ...body,
                  }));
                }}
              />
              <Input
                disabled={readOnly}
                slotProps={{ input: { style: { textAlign: "right" } } }}
                startDecorator="Address:"
                endDecorator={
                  musician.placeID ? (
                    <Tooltip title={"Verified address"} arrow size="sm">
                      <i
                        style={{ color: "#2196f3" }}
                        className="fa-solid fa-badge-check"
                      ></i>
                    </Tooltip>
                  ) : undefined
                }
                fullWidth
                onBlur={() => save("address")}
                value={_musician.address || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, address: e.target.value }))
                }
              />
              <Input
                disabled={readOnly}
                slotProps={{ input: { style: { textAlign: "right" } } }}
                startDecorator="Zipcode:"
                fullWidth
                onBlur={() => save("zipcode")}
                value={_musician.zipcode || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, zipcode: e.target.value }))
                }
              />
              <Input
                disabled={readOnly}
                startDecorator="City:"
                slotProps={{ input: { style: { textAlign: "right" } } }}
                fullWidth
                onBlur={() => save("city")}
                value={_musician.city || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, city: e.target.value }))
                }
              />
              <Input
                disabled={readOnly}
                slotProps={{ input: { style: { textAlign: "right" } } }}
                fullWidth
                startDecorator="State:"
                onBlur={() => save("state")}
                value={_musician.state || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, state: e.target.value }))
                }
              />
              {musician?.address ? (
                <Link
                  color="danger"
                  level="body3"
                  onClick={() => {
                    const body = {
                      ...musician,
                      address: "",
                      city: "",
                      zipcode: "",
                      placeID: "",
                      tzName: "",
                      latitude: "",
                      longitude: "",
                    };
                    updateMusician({ id: musician.id, body });
                  }}
                >
                  Delete Address
                </Link>
              ) : (
                []
              )}
              {musician?.placeID ? (
                <Typography variant="caption" sx={{ textAlign: "left" }}>
                  PlaceID: {musician.placeID}. Latitude: {musician.latitude}{" "}
                  Longitude: {musician.longitude}
                </Typography>
              ) : (
                []
              )}
              <Divider>Custom Fields</Divider>
              <MusicianPrivateNotes />
            </Sheet>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Sheet
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 1,
                borderRadius: "8px",
              }}
              variant="soft"
            >
              <Typography variant="h6" color="textPrimary">
                Instruments
              </Typography>
              <Box>
                <MusicianInstruments musicianID={musicianID} />
              </Box>
            </Sheet>
          </Grid>
          <Grid item xs={12}>
            <Sheet
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 1,
                borderRadius: "8px",
              }}
              variant="soft"
            >
              <Typography variant="h6" color="textPrimary">
                Groups
              </Typography>
              <Box>
                <Button
                  onClick={(e) => setGroupAnchorEl(e.currentTarget)}
                  color="neutral"
                  variant="soft"
                  sx={{ background: "white" }}
                  disabled={
                    groups?.filter(
                      (g) => musician.groupIDs.indexOf(g.id) === -1
                    ).length === 0 || readOnly
                  }
                  endDecorator={<i className="fa-solid fa-user-group"></i>}
                >
                  + Add Group
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={groupAnchorEl}
                  open={Boolean(groupAnchorEl)}
                  onClose={() => setGroupAnchorEl(null)}
                  aria-labelledby="basic-demo-button"
                >
                  {groups
                    ?.filter((g) => musician.groupIDs.indexOf(g.id) === -1)
                    ?.map((g) => (
                      <MenuItem
                        key={g.id}
                        onClick={() => {
                          setGroupAnchorEl(null);
                          createMusicianGroup({
                            musicianID: musician.id,
                            groupID: g.id,
                          });
                        }}
                      >
                        {g.name}
                      </MenuItem>
                    ))}
                </Menu>
                <Grid container sx={{ mt: 1 }} spacing={1}>
                  {musician.groups?.map((g) => (
                    <Grid item key={g.id}>
                      <Chip
                        color="neutral"
                        variant="solid"
                        endDecorator={
                          <ChipDelete
                            disabled={readOnly}
                            onDelete={() =>
                              deleteMusicianGroup(g.musicianRelationID)
                            }
                          />
                        }
                      >
                        {g.name}
                      </Chip>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Sheet>
          </Grid>
          <Grid item xs={12}>
            <Sheet
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 1,
                borderRadius: "8px",
              }}
              variant="soft"
            >
              <Typography variant="h6" color="textPrimary">
                Identification
              </Typography>
              <Input
                slotProps={{ input: { style: { textAlign: "right" } } }}
                startDecorator={"SSN:"}
                fullWidth
                disabled={readOnly}
                onBlur={() => save("ssn")}
                value={_musician.ssn || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, ssn: e.target.value }))
                }
              />
              <Input
                slotProps={{ input: { style: { textAlign: "right" } } }}
                startDecorator="EIN:"
                fullWidth
                disabled={readOnly}
                onBlur={() => save("ein")}
                value={_musician.ein || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, ein: e.target.value }))
                }
              />
              <Input
                slotProps={{ input: { style: { textAlign: "right" } } }}
                startDecorator="Union Number:"
                disabled={readOnly}
                fullWidth
                onBlur={() => save("localNumber")}
                value={_musician.localNumber || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, localNumber: e.target.value }))
                }
              />
              <Input
                slotProps={{ input: { style: { textAlign: "right" } } }}
                startDecorator="Employee Number:"
                disabled={readOnly}
                fullWidth
                onBlur={() => save("cardNumber")}
                value={_musician.cardNumber || ""}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, cardNumber: e.target.value }))
                }
              />
            </Sheet>
          </Grid>
          <Grid item xs={12}>
            <Sheet
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 1,
                borderRadius: "8px",
              }}
              variant="soft"
            >
              <Typography variant="h6">Internal Memo:</Typography>
              <Textarea
                placeholder={`Anything to write down about ${_musician?.firstName}?`}
                sx={{ width: "100%", background: "#FFFDEF" }}
                onChange={(e) =>
                  _setMusician((m) => ({ ...m, comments: e.target.value }))
                }
                disabled={readOnly}
                value={_musician.comments ?? ""}
                onBlur={() => save("comments")}
                size="sm"
                minRows={5}
                maxRows={10}
                endDecorator={
                  <JoyTypography level="body4">
                    Not visible by {musician?.fullName()}
                  </JoyTypography>
                }
              />
            </Sheet>
          </Grid>
          <Grid item xs={12}>
            <Sheet
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 1,
                borderRadius: "8px",
                maxHeight: 800,
                overflowY: "scroll",
              }}
              variant="soft"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="h6" color="textPrimary">
                    Mileage
                  </Typography>
                  <Typography variant="body2">
                    Distance between the musician and venues for Buget & Payroll
                  </Typography>
                </Box>
                <Chip
                  color="neutral"
                  sx={{ background: "white" }}
                  variant="outlined"
                  onClick={() => clearAllMileage()}
                >
                  Clear all
                </Chip>
              </Box>
              {venues.map((v) => {
                return (
                  <Input
                    slotProps={{
                      input: {
                        style: {
                          textAlign: "right",
                        },
                      },
                    }}
                    value={mileage[v.id] ? `${mileage[v.id] / 1000}` : "0"}
                    type="number"
                    onChange={(e) => {
                      try {
                        setMileage((m) => ({
                          ...m,
                          [v.id]: Math.round(parseFloat(e.target.value) * 1000),
                        }));
                      } catch (error) {}
                    }}
                    onBlur={() => saveMileage()}
                    endDecorator="miles"
                    placeholder="0"
                    startDecorator={`${v.name}:`}
                    key={v.id}
                  />
                );
              })}
              {/* <Divider />
              <Box>
                <Typography sx={{ fontSize: 12 }} variant="body2">
                  Rhapsody can automatically calculate the mileage for your full
                  address book at an additional cost of $0.10 per musician per
                  venue.
                </Typography>
                <Link
                  level="body2"
                  // color="neutral"
                  onClick={() =>
                    dispatch(
                      setFormOpen({ formID: "mileageQuote", isOpen: true })
                    )
                  }
                >
                  Get an instant quote.
                </Link>
              </Box> */}
            </Sheet>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
