import { Dictionary } from "@reduxjs/toolkit";
import { Assignment } from "entities/assignment";
import { ProjectPiece } from "entities/projectPiece";
import { Selection_Entity } from "entities/selection";
import { LayoutUtils } from "features/projects/ProjectMissionControl/LayoutUtils";
import moment from "moment";
import { InternalFamily } from "./InternalFamily";
import { InternalPosition, InternalPosition_Entity } from "./InternalPosition";
import { InternalSection, InternalSection_Entity } from "./InternalSection";
import Internal_Entity from "./internal";
import { renderToString } from "react-dom/server";

export default class Internal extends Internal_Entity {
  families: InternalFamily[];
  sections: InternalSection[];
  positions: InternalPosition[];
  enabledPositionIDs: string[];
  visibleChairIDs: number[];
  chairIDs: number[];
  emptyChairIDs: number[];
  assignedChairIDs: number[];
  positionCount: number;
  enabledPositionCount: number;
  allWorkSessionsSelected: boolean;
  selectedWorkSessionIDs: number[];
  positionIDs: string[];
  selectedProjectPieceIDs: number[];
  unselectedWorkSessionIDs: number[];
  forWorkSession?: Dictionary<Internal>;
  forProjectPiece?: Dictionary<Internal>;
  hasValidProjectPieces?: boolean;
  validProjectPieces: ProjectPiece[];
  duplicateMusicianIDs: number[];
  misplacedMusicianIDs: number[];
  preFlight?: PreFlightItem[];
  nudgeableMusicianIDs?: number[];
  revisionNeededForMusicianIDs?: number[];
  callSent: boolean;
  sectionIDs: number[];
  releaseRequests: Assignment[];
  defaultPieceOnly?: boolean;

  constructor(
    json: Internal_Entity,
    utils: LayoutUtils,
    selection: Selection_Entity
  ) {
    super(json);
    const { workSessionsMap, hProject, projectPieces, chairs } = utils;
    const selectedProjectPieceIDs = selection.selectedProjectPieceIDs ?? {};
    const selectedWorkSessionIDs = selection.selectedWorkSessionIDs ?? {};

    this.defaultPieceOnly =
      utils.projectPieces.length === 1 && !utils.projectPieces[0].pieceID;

    this.families = InternalFamily.fromList(json.families, utils, selection);

    this.nudgeableMusicianIDs = hProject?.jobs?.reduce((a, j) => {
      if (j.currentStage?.nudgeable) {
        let date = j.currentStage.createdAt;
        if (j.currentStage?.inspectors?.length) {
          date =
            j.currentStage?.inspectors[j.currentStage?.inspectors?.length - 1]
              .createdAt;
        }

        if (moment(date).add(24, "hours").isBefore(moment())) {
          if (!a.includes(j.refID)) a.push(j.refID);
        }
      }

      return a;
    }, []);

    const assignments = mapToArray(utils.assignmentsMap);
    this.revisionNeededForMusicianIDs = assignments.reduce((a, v) => {
      const stage = utils.stagesMap[v.mercuryStageID];
      if (
        (v.criticalChanges || v.importantChanges) &&
        v.active &&
        !stage?.terminus
      ) {
        a.push(v.musicianID);
      }
      return a;
    }, []);
    this.callSent = assignments.reduce((a, v) => {
      if (v.mercuryStageID && !utils.stagesMap[v.mercuryStageID]?.terminus)
        a = true;
      return a;
    }, false);
    this.duplicateMusicianIDs = assignments.reduce((a, v) => {
      const assignmentChairs = utils.chairs.filter(
        (c) => c.assignmentID === v.id
      );
      assignmentChairs.forEach((c) => {
        const confict = assignmentChairs.find(
          (ac) =>
            ac.projectPieceID === c.projectPieceID &&
            c.id !== ac.id &&
            utils.sectionsMap[c.sectionID].familyID !== 11 &&
            utils.sectionsMap[ac.sectionID].familyID !== 11 &&
            (c.workSessionIDs.filter((value) =>
              ac.workSessionIDs.includes(value)
            ).length > 0 ||
              (ac.workSessionIDs.length === 0 && c.workSessionIDs.length === 0))
        );
        if (confict && !a.includes(v.musicianID)) a.push(v.musicianID);
      });

      return a;
    }, []);
    this.validProjectPieces = projectPieces.filter((p) => p.pieceID > 1);
    this.hasValidProjectPieces = this.validProjectPieces.length > 0;
    const workSessions = mapToArray(workSessionsMap);
    this.selectedWorkSessionIDs = Object.keys(selectedWorkSessionIDs)
      .map((e) => {
        const id = parseInt(e);
        if (selectedWorkSessionIDs[id]) return id;
      })
      .filter((e) => e);
    this.selectedProjectPieceIDs = Object.keys(selectedProjectPieceIDs)
      .map((e) => {
        const id = parseInt(e);
        if (selectedProjectPieceIDs[id]) return id;
      })
      .filter((e) => e);
    this.unselectedWorkSessionIDs = workSessions.reduce((a, v) => {
      if (!selectedWorkSessionIDs[v.id]) a.push(v.id);
      return a;
    }, []);
    this.allWorkSessionsSelected = workSessions.reduce((a, v) => {
      if (!selectedWorkSessionIDs[v.id]) a = false;
      return a;
    }, true);
    this.sections = this.families.reduce((a, v) => {
      a.push(...v.sections);
      return a;
    }, []);
    this.sectionIDs = utils.chairs
      .reduce((a, v) => {
        if (!a.includes(v.sectionID)) {
          a.push(v.sectionID);
        }
        return a;
      }, [])
      ?.sort((a1, b1) => {
        const a = utils.sectionsMap[a1];
        const b = utils.sectionsMap[b1];
        if (a.familyPos === b.familyPos) return a.pos - b.pos;
        return a.familyPos - b.familyPos;
      });
    this.positions = this.sections.reduce((a, v) => {
      a.push(...v.positions);
      return a;
    }, []);
    this.positionIDs = this.positions.reduce((a, v) => {
      a.push(v.id);
      return a;
    }, []);
    this.enabledPositionIDs = this.sections.reduce((a, v) => {
      a.push(...v.enabledPositionIDs);
      return a;
    }, []);
    this.visibleChairIDs = this.families
      .reduce((a, v) => {
        a.push(...v.visibleChairIDs);
        return a;
      }, [])
      .sort();

    this.chairIDs = this.families
      .reduce((a, v) => {
        a.push(...v.chairIDs);
        return a;
      }, [])
      .sort();

    this.emptyChairIDs = chairs.reduce((a, v) => {
      if (!v.assignmentID && !v.hidden) a.push(v.id);
      return a;
    }, []);

    this.assignedChairIDs = chairs.reduce((a, v) => {
      if (v.assignmentID) a.push(v.id);
      return a;
    }, []);

    this.positionCount = this.positions.length;
    this.enabledPositionCount = this.families.reduce((a, v) => {
      a += v.enabledPositionCount;
      return a;
    }, 0);
    this.misplacedMusicianIDs = this.positions.reduce((a, v) => {
      v.misplacedMusicianIDs.forEach((id) => {
        if (!a.includes(id)) a.push(id);
      });
      return a;
    }, []);

    this.preFlight = [];

    this.duplicateMusicianIDs.forEach((musicianID) => {
      this.preFlight.push({
        type: "Duplicate",
        musicianID,
        label: `${utils.musiciansMap[musicianID]?.fullName()} is duplicate.`,
        description:
          "This musician plays on multiple chairs for the same Work Session.",
        resolution: "Replace this musician with someone else.",
        severity: "important",
      });
    });

    const musicians = mapToArray(utils.musiciansMap);

    musicians.forEach((m) => {
      const assignment = assignments.find((a) => a.musicianID === m.id);
      if (assignment?.active && !m?.active) {
        this.preFlight.push({
          type: "Archived Musician",
          musicianID: m.id,
          label: `${m.fullName()} is archived but is active on this Project.`,
          description: "This musician should not be used anymore.",
          resolution: "Remove this musician",
          severity: "critical",
        });
      }
    });

    musicians.forEach((m) => {
      if (m.isOffFor(utils.musicianHolidays, utils.workSessions)) {
        this.preFlight.push({
          type: "Musician is off",
          musicianID: m.id,
          label: `${m.fullName()} is not available for some of the Work Sessions.`,
          description: "Check the musician's time off.",
          resolution: "Remove this musician",
          severity: "critical",
        });
      }
    });

    utils.projectPieces.forEach((pp) => {
      const chairs = utils.chairs.filter((c) => c.projectPieceID === pp.id);
      const piece = utils.piecesMap[pp.pieceID];
      if (chairs.length === 0) {
        this.preFlight.push({
          type: "Unplayed Piece",
          projectPieceID: pp.id,
          label: `${piece?.name ?? "Seating assignment"} ${
            piece?.composer ?? ""
          } has no chairs`,
          description: "There are no chairs for this piece.",
          resolution: "Add chairs to the piece",
          severity: "critical",
        });
      }
      if (
        utils.workSessionProjectPieces?.findIndex(
          (wpp) => wpp.projectPieceID === pp.id
        ) === -1
      ) {
        this.preFlight.push({
          type: "Unplayed Piece",
          projectPieceID: pp.id,
          label: `${piece?.name ?? "Seating assignment"} ${
            piece?.composer ?? ""
          } is not playing.`,
          description: "No Work Sessions have this piece on the program.",
          resolution:
            "Go to the Program and add this piece to at least one Work Session.",
          severity: "critical",
        });
      }
    });

    if (workSessions.length === 0) {
      this.preFlight.push({
        type: "Work Session",
        label: `You don't have any Work Sessions`,
        description:
          "A Work Session is any singular date, event, or occasion where Contractors need to hire/invite personnel. Work Session Types include engagements such as rehearsals, concerts, or recording sessions.",
        resolution: "Add a Work Session",
        severity: "critical",
      });
    }

    assignments.forEach((a) => {
      const chairs = utils.chairs.filter((c) => c.assignmentID === a.id);
      const musician = utils.musiciansMap[a.musicianID];
      if (!musician) return;
      const orders = chairs.reduce((a, v) => {
        if (!a.includes(v.sectionOrder)) a.push(v.sectionOrder);
        return a;
      }, []);
      const sectionRoles = chairs.reduce((a, v) => {
        if (!a.includes(utils.sectionRolesMap[v.sectionRoleID]?.name))
          a.push(utils.sectionRolesMap[v.sectionRoleID]?.name);
        return a;
      }, []);
      const instrumentNames = chairs.reduce((a, v) => {
        const instrumentIDs = JSON.parse(
          v.instrumentIDs ? v.instrumentIDs : "[]"
        );
        instrumentIDs.forEach((instrumentID) => {
          if (!a.includes(utils.instrumentsMap[instrumentID]?.name))
            a.push(utils.instrumentsMap[instrumentID]?.name);
        });
        return a;
      }, []);
      const split = musician.instrumentNames?.split(", ");
      const unplayed = instrumentNames.reduce((a, v) => {
        if (!split?.includes(v)) a.push(v);
        return a;
      }, []);

      if (unplayed.length) {
        this.preFlight.push({
          type: "Misplaced",
          musicianID: musician.id,
          label: `${musician?.fullName()} is misplaced.`,
          description: `This musician plays ${split?.join(
            ","
          )} but is NOT playing ${unplayed.join(", ")}.`,
          resolution: `Add ${unplayed.join(
            ", "
          )} to the musician or chose someone else.`,
          severity: "important",
        });
      }

      if (orders.length > 1) {
        this.preFlight.push({
          type: "Swap",
          musicianID: musician.id,
          assignmentID: a.id,
          label: `${musician.fullName()} has an inconsistent order.`,
          description: `The musician is playing on multiple chair orders (${orders.join(
            ", "
          )}).`,
          resolution:
            "If not intended, make sure to place the musician on the same order.",
          severity: "critical",
        });
      }

      if (sectionRoles.length > 1) {
        this.preFlight.push({
          type: "Multiple Roles",
          musicianID: musician.id,
          assignmentID: a.id,
          label: `${musician.fullName()} has multiple roles.`,
          description: `The musician is ${sectionRoles.join(", ")}.`,
          resolution:
            "If not intended, make sure the musician has a unique role.",
          severity: "critical",
        });
      }
    });

    if (this.emptyChairIDs.length > 0) {
      this.preFlight.push({
        type: `Empty Chairs`,
        label: `${this.emptyChairIDs.length} Empty Chairs`,
        description: `You have chairs without any assigned musicians.`,
        resolution: "Assign musicians to the empty chairs,",
        severity: "critical",
      });
    }

    this.sections.forEach((s) => {
      s.sameChairCountForAllProjectPieces = s.positions.reduce((a, v) => {
        if (
          JSON.stringify(v.visibleProjectPieceIDs) !==
          JSON.stringify(s.positions[0].visibleProjectPieceIDs)
        )
          a = false;
        return a;
      }, true);
    });

    this.releaseRequests = assignments.filter((a) => {
      if (a.releaseWorkSessionIDs) {
        const releaseWorkSessionIDs: number[] = JSON.parse(
          a.releaseWorkSessionIDs
        );
        const chairsForAssignment = chairs.filter(
          (c) => c.assignmentID === a.id
        );
        if (
          a.mercuryStageID !== utils?.releasedStage?.id &&
          a.mercuryStageID !== utils.releaseStage?.id
        ) {
          const workSessionIDsForAssignment = chairsForAssignment.reduce(
            (a, v) => {
              v.workSessionIDs.forEach((w) => {
                if (!a.includes(w)) a.push(w);
              });
              return a;
            },
            []
          );
          return workSessionIDsForAssignment.reduce((a, v) => {
            if (releaseWorkSessionIDs.includes(v)) a = true;
            return a;
          }, false);
        }
      }
      return false;
    });
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  willBeDuplicated(
    utils: LayoutUtils,
    musicianID: number,
    chairID: number
  ): boolean {
    const assignmentChairs = utils.chairs.filter(
      (c) => c.musicianID === musicianID
    );
    const chair = utils.chairsMap[chairID];

    assignmentChairs.push(chair);
    let conficted = false;
    assignmentChairs.forEach((c) => {
      const confict = assignmentChairs?.find(
        (ac) =>
          ac.projectPieceID === c.projectPieceID &&
          c.id !== ac.id &&
          utils.sectionsMap[c.sectionID].familyID !== 11 &&
          utils.sectionsMap[ac.sectionID].familyID !== 11 &&
          (c.workSessionIDs.filter((value) => ac.workSessionIDs.includes(value))
            .length > 0 ||
            (ac.workSessionIDs.length === 0 && c.workSessionIDs.length === 0))
      );
      if (confict) conficted = true;
    });
    return conficted;
  }

  getFamily(familyID: number) {
    return this.families.find((f) => f.familyID === familyID);
  }

  getSection(sectionID: number, familyID: number) {
    const family = this.getFamily(familyID);
    return family?.getSection(sectionID);
  }

  getPosition(positionID: string) {
    const s = positionID.split("|");
    const familyID = parseInt(s[0]);
    const family = this.getFamily(familyID);

    return family.getPosition(positionID);
  }

  getPositions(positionIDs: string[]) {
    const ret: InternalPosition[] = [];

    positionIDs.forEach((positionID) => {
      const s = positionID.split("|");
      const familyID = parseInt(s[0]);
      const family = this.getFamily(familyID);
      if (family) {
        const position = family.getPosition(positionID);
        if (position) ret.push(position);
      }
    });

    return ret;
  }

  workSessionChairIDs(utils: LayoutUtils) {
    const workSessionChairs: number[] = this.positions.reduce<number[]>(
      (a, v) => {
        v.chairIDs.forEach((c) => {
          const _chair = utils.chairsMap[c];
          if (_chair?.workSessionID) a.push(c);
        });

        return a;
      },
      []
    );

    return workSessionChairs;
  }

  iforWorkSession(
    workSessionID: number,
    utils: LayoutUtils,
    selection: Selection_Entity
  ): Internal {
    const internal = getInternal(utils, workSessionID, undefined);
    const i = new Internal(internal, utils, selection);
    return i;
  }

  iforProjectPiece(
    projectPieceID: number,
    utils: LayoutUtils,
    selection: Selection_Entity
  ): Internal {
    const internal = getInternal(utils, undefined, projectPieceID);
    const i = new Internal(internal, utils, selection);
    return i;
  }

  getAssignmentPositionIDs(
    assignmentID: number,
    utils: LayoutUtils,
    selection: Selection_Entity
  ) {
    const chairs = utils.chairs.filter((c) => c.assignmentID === assignmentID);
    const workSessionsIDs = chairs.reduce((a, v) => {
      v.workSessionIDs.forEach((wID) => {
        if (!a.includes(wID)) a.push(wID);
      });
      return a;
    }, []);
    const projectPieceID = chairs.reduce((a, v) => {
      if (!a.includes(v.projectPieceID)) a.push(v.projectPieceID);
      return a;
    }, []);
    const ret: string[] = [];

    workSessionsIDs.forEach((workSessionID) => {
      projectPieceID.forEach((projectPieceID) => {
        ret.push(
          ...this.getPositionIDs(
            utils,
            selection,
            assignmentID,
            workSessionID,
            projectPieceID
          )
        );
      });
    });

    return ret;
  }

  getPositionIDs = (
    utils: LayoutUtils,
    selection: Selection_Entity,
    assignmentID: number,
    workSessionID: number,
    projectpieceID: number
  ): string[] => {
    const pChairs = utils.chairs.filter(
      (c) =>
        c.assignmentID === assignmentID &&
        c.workSessionIDs.includes(workSessionID) &&
        c.projectPieceID === projectpieceID
    );

    if (pChairs.length === 0) return [];

    const iUtils = { ...utils, chairs: pChairs };
    const i = new Internal(
      getInternal(iUtils, workSessionID, projectpieceID),
      iUtils,
      selection
    );

    return i.positionIDs;
  };
}

export function getInternal(
  utils: LayoutUtils,
  forWorkSessionID?: number,
  forProjectPieceID?: number
) {
  const {
    chairs,
    sectionsMap,
    familiesMap,
    workSessionProjectPieces,
    assignmentsMap,
    musiciansMap,
    projectPiecesMap,
  } = utils;
  const ret: Internal_Entity = {
    families: [],
    forWorkSessionID,
    forProjectPieceID,
  };
  if (chairs && chairs.length) {
    const positionsForSections = chairs.reduce<
      Dictionary<InternalPosition_Entity[]>
    >((a, c) => {
      const section = sectionsMap[c.sectionID];
      const workSessionProjectPiece = workSessionProjectPieces.filter(
        (w) => w.projectPieceID === c.projectPieceID
      );
      const visibilityForWorkSessions = {};
      workSessionProjectPiece.forEach(
        (w) => (visibilityForWorkSessions[w.workSessionID] = true)
      );
      const assignment = c.assignmentID
        ? assignmentsMap[c.assignmentID]
        : undefined;
      const musician = musiciansMap[c.musicianID];
      const projectPiece = c.projectPieceID
        ? projectPiecesMap[c.projectPieceID]
        : undefined;
      const item: InternalPosition_Entity = {
        id: `${section.familyID}|${section.id}|${c.sectionOrder}|${
          forWorkSessionID ?? 0
        }|${forProjectPieceID ?? 0}`,
        forWorkSessionID,
        forProjectPieceID,
        order: c.sectionOrder,
        chairIDs: [c.id],
        projectPieceIDs: projectPiece?.id ? [projectPiece.id] : [],
        assignmentIDs: assignment?.id ? [assignment.id] : [],
        musicianIDs: musician?.id ? [musician.id] : [],
        visibilityForProjectPieces: { [c.projectPieceID]: true },
        visibilityForWorkSessions,
        sectionID: c.sectionID,
        familyID: section.familyID,
      };
      if (a[c.sectionID]) {
        const index = a[c.sectionID]?.findIndex(
          (i) => i.order === c.sectionOrder
        );
        if (index >= 0) {
          a[c.sectionID][index].chairIDs.push(c.id);
          if (
            assignment?.id &&
            !a[c.sectionID][index].assignmentIDs.includes(assignment.id)
          ) {
            a[c.sectionID][index].assignmentIDs.push(assignment.id);
          }
          if (
            musician?.id &&
            !a[c.sectionID][index].musicianIDs.includes(musician.id)
          ) {
            a[c.sectionID][index].musicianIDs.push(musician.id);
          }
          if (
            projectPiece?.id &&
            !a[c.sectionID][index].projectPieceIDs.includes(projectPiece.id)
          ) {
            a[c.sectionID][index].projectPieceIDs.push(projectPiece.id);
          }
          a[c.sectionID][index].visibilityForProjectPieces[c.projectPieceID] =
            true;
          if (workSessionProjectPiece)
            a[c.sectionID][index].visibilityForWorkSessions = {
              ...a[c.sectionID][index].visibilityForWorkSessions,
              ...visibilityForWorkSessions,
            };
        } else {
          a[c.sectionID].push(item);
        }
      } else {
        a[c.sectionID] = [item];
      }
      return a;
    }, {});

    for (const key in positionsForSections) {
      if (Object.hasOwnProperty.call(positionsForSections, key)) {
        positionsForSections[key].sort((a, b) => a.order - b.order);
      }
    }

    const sectionsForFamilies = Object.keys(positionsForSections).reduce<
      Dictionary<InternalSection_Entity[]>
    >((a, sectionID) => {
      const section = sectionsMap[parseInt(sectionID)];
      const familyID = section.familyID;

      const s: InternalSection_Entity = {
        sectionID: section.id,
        familyID: section.familyID,
        sectionPos: section.pos,
        positions: positionsForSections[sectionID],
        forWorkSessionID,
        forProjectPieceID,
      };

      if (a[familyID]) {
        const index = a[familyID]?.findIndex(
          (s) => s.sectionID === parseInt(sectionID)
        );
        if (index >= 0) {
          a[familyID][index].positions.push(...positionsForSections[sectionID]);
        } else {
          a[familyID].push(s);
        }
      } else {
        a[familyID] = [s];
      }
      return a;
    }, {});

    Object.keys(sectionsForFamilies).map((familyID) => {
      const family = familiesMap[parseInt(familyID)];
      const f = {
        familyID: family.id,
        familyPos: family.pos,
        sections: sectionsForFamilies[parseInt(familyID)],
        forWorkSessionID,
        forProjectPieceID,
      };
      ret.families.push(f);
    });
  }
  ret.families.sort((a, b) => a.familyPos - b.familyPos);
  ret.families.forEach((f) => {
    f.sections.sort((a, b) => a.sectionPos - b.sectionPos);
  });

  return ret;
}

/**
 * @param  {Map<number} elements
 * @returns Map
 */
export function mapToArray<T extends { id: number }>(elements: {
  [key: number]: T;
}): Array<T> {
  const ret = new Array<T>();
  for (const key in elements) {
    if (Object.prototype.hasOwnProperty.call(elements, key)) {
      const element = elements[key];
      ret.push(element);
    }
  }

  return ret;
}

export type PreFlightItem = {
  type:
    | "Misplaced"
    | "Archived Musician"
    | "Musician is off"
    | "Duplicate"
    | "Unplayed Piece"
    | "Multiple Roles"
    | "Swap"
    | "Work Session"
    | "Empty Chairs";
  label: string;
  description: string;
  resolution: string;
  musicianID?: number;
  workSessionID?: number;
  projectPieceID?: number;
  chairID?: number;
  pieceID?: string;
  assignmentID?: number;
  severity: "critical" | "important" | "minor";
};

export const PreFlightTypeColor = {
  "Archived Musician": "#B29F85",
  "Musician is off": "#f44336",
  Misplaced: "#82B1DD",
  Duplicate: "#B2A5ED",
  "Unplayed Piece": "#B2A6C0",
  "Multiple Roles": "#A0B7D1",
  Swap: "#B2D3D7",
  "Empty Chairs": "#CDB58F",
  "Work Session": "#80d8ff",
};

export const PreFlightItemTypes = [
  "Archived Musician",
  "Musician is off",
  "Misplaced",
  "Duplicate",
  "Unplayed Piece",
  "Multiple Roles",
  "Swap",
  "Empty Chairs",
  "Work Session",
];
