import { show } from "@intercom/messenger-js-sdk";
import { Box, Button, Typography } from "@mui/joy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AppConfig } from "environement";
import { useCurrentSubscription } from "redux/subscription/subscriptionHooks";

/**
 *
 * @returns {ReactElement} TrialExpired page
 */
export function TrialExpired() {
  const { subscription } = useCurrentSubscription();

  return (
    <Dialog open>
      <DialogTitle sx={{ textAlign: "center" }}>
        <Typography level="h6">Your Rhapsody Trial has expired</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          textAlign: "center",
        }}
      >
        Let's start the onboarding to get you all set up.
        <Button
          onClick={() =>
            window.open("https://buy.stripe.com/7sI01VgqXe2B05GdQR")
          }
          variant="soft"
          sx={{ height: 200 }}
        >
          <Box>
            <Typography level="h6">Start the Onboarding</Typography>
            <Typography>$300</Typography>
          </Box>
        </Button>
        <ul style={{ textAlign: "left" }}>
          <li>3 onboarding sessions with your team (1 hour each)</li>
          <li>Musician's address book import </li>
          <li>Your subscription starts in 30 days</li>
        </ul>
      </DialogContent>
    </Dialog>
  );
}
