// import PropTypes from 'prop-types';

// styles
import {
  Button,
  Chip,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuItem,
  Sheet,
  Typography,
} from "@mui/joy";
import { Divider, Hidden, Tab, Tabs, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { nanoid } from "@reduxjs/toolkit";
import { AppConfig } from "environement";
import MusiciansDataGrid from "features/dataGrids/MusiciansDataGrid";
import { useExporter } from "features/exporter/exporter";
import { PotentialMusicianDuplicates } from "features/musicians/PotentialMusicianDuplicates/PotentialMusicianDuplicates";
import { heightWithToolbar } from "global";
import { MusicianBirthdays } from "hooks/MusicianBirthdays/MusicianBirthdays";
import {
  mezzo,
  overture,
  useMySubscription,
} from "hooks/subscription/restrictionHooks";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import {
  setFormOpen,
  setSelectedGroupID,
  setTabIndex,
  tabIndexSelector,
} from "reducers/rhapsody";
import { layoutDebugSelector } from "reducers/v2/missionControl";
import { useGroups } from "redux/group/groupHooks";
import {
  useCreateMusicianMutation,
  useUpdateMusicianMutation,
} from "redux/musician/musicianEndpoints";
import { useMusicians } from "redux/musician/musicianHooks";
import { useMusicianGroups } from "redux/musicianGroup/musicianGroupHooks";
import firstNames from "./firstNames.json";
import lastNames from "./lastNames.json";

export default function MusiciansPage() {
  const param = useParams();
  const { groups } = useGroups();
  const [anchorEl, setAnchorEl] = useState();
  const tabIndex = useSelector(tabIndexSelector("musicians"));
  const { musicians } = useMusicians();
  const debug = useSelector(layoutDebugSelector);
  const [updateMusician] = useUpdateMusicianMutation();
  const { musicianGroups } = useMusicianGroups();
  const [createMusician] = useCreateMusicianMutation();
  const dispatch = useDispatch();
  const subscription = useMySubscription();
  const readOnly = useReadOnly();

  const exporter = useExporter();

  const resetEmails = async () => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setAnchorEl(null);
    const proms = [];
    for (const key in musicians) {
      if (Object.hasOwnProperty.call(musicians, key)) {
        const m = musicians[key];
        proms.push(
          updateMusician({
            id: m.id,
            body: { email: `${nanoid(10)}@ignore.it` },
          }).unwrap()
        );
      }
    }

    await Promise.all(proms);

    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
  };

  const removePhones = async () => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setAnchorEl(null);
    const proms = [];
    for (const key in musicians) {
      if (Object.hasOwnProperty.call(musicians, key)) {
        const m = musicians[key];
        proms.push(
          updateMusician({
            id: m.id,
            body: { phone: "", contactBySMS: false },
          }).unwrap()
        );
      }
    }

    await Promise.all(proms);

    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
  };

  const randomIntBetween = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);

  const fakeMusicians = async () => {
    dispatch(setFormOpen({ formID: "overlay", isOpen: true }));
    setAnchorEl(null);
    const proms = [];

    for (let index = 0; index < 25; index++) {
      const female = Math.random() < 0.5;
      proms.push(
        createMusician({
          sub: false,
          firstName: female
            ? firstNames.female[randomIntBetween(1, 100)]
            : firstNames.male[randomIntBetween(1, 100)],
          lastName: lastNames[randomIntBetween(1, 100)],
          latitude: "",
          longitude: "",
          placeID: "",
          tzName: "",
          email: `${nanoid(10)}@ignore.it`,
          avatar: `https://storage.googleapis.com/rhapsody/Avatars/${
            female ? "female" : "male"
          }/${randomIntBetween(1, 50)}.jpg`,
        }).unwrap()
      );
    }
    await Promise.all(proms);

    dispatch(setFormOpen({ formID: "overlay", isOpen: false }));
  };

  const openSubscribe = () =>
    dispatch(setFormOpen({ formID: "subscribe", isOpen: true }));

  let decorator;
  const musicianCount = musicians?.filter((m) => m.active)?.length;
  if (subscription === overture) {
    decorator = (
      <Tooltip title="Upgrade to get more Musicians in your Address Book" arrow>
        <Chip onClick={openSubscribe} size="sm" color="warning">
          {musicianCount}/30 Musicians
        </Chip>
      </Tooltip>
    );
  }

  if (subscription === mezzo) {
    decorator = (
      <Tooltip title="Upgrade to get more Musicians in your Address Book" arrow>
        <Chip onClick={openSubscribe} size="sm" color="warning">
          {musicianCount}/100 Musicians
        </Chip>
      </Tooltip>
    );
  }

  if (param.musicianID) return <Outlet />;

  return (
    <>
      <Helmet>
        <title>Rhapsody | Address Book</title>
      </Helmet>
      <Box
        sx={{
          p: 2,
          display: "flex",
          gap: 2,
          flexDirection: "column",
          justifyContent: "stretch",
          margin: "auto",
          maxWidth: 1600,
          width: "100%",
          position: "relative",
          flex: 1,
          height: heightWithToolbar,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography endDecorator={decorator} level="h5">
                Address Book
              </Typography>
              <MusicianBirthdays />
            </Box>
            {debug ? (
              <Box>
                <Typography level="body3">
                  {musicians?.length} musicians,{" "}
                  {musicians.filter((m) => !m.active).length} archived
                  musicians, {musicians.filter((m) => m.userUUID).length} mobile
                  app users (
                  {musicians?.length
                    ? Math.round(
                        (musicians.filter((m) => m.userUUID).length /
                          musicians?.length) *
                          100 *
                          100
                      ) / 100
                    : 0}
                  % Adoption). <PotentialMusicianDuplicates />
                </Typography>
              </Box>
            ) : (
              []
            )}
            <Box sx={{ display: "flex", gap: 1 }}>
              <Hidden smDown>
                <IconButton
                  disabled={readOnly}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  size="sm"
                  variant="outlined"
                  color="neutral"
                >
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </IconButton>
              </Hidden>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                size="sm"
                sx={{ maxWidth: 250 }}
              >
                <MenuItem
                  onClick={() => {
                    exporter.musiciansDatabse();
                    setAnchorEl(null);
                  }}
                >
                  <ListItemDecorator>
                    <i className="fa-duotone fa-table"></i>
                  </ListItemDecorator>
                  Export Musician Database in Excel
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    exporter.musicianImportTemplate();
                    setAnchorEl(null);
                  }}
                >
                  <ListItemDecorator>
                    <i className="fa-solid fa-arrow-down-to-line"></i>
                  </ListItemDecorator>
                  <Box>
                    Musician Import Template
                    <Typography level="body3">
                      Import multiple musicians at once by filling out this
                      template.
                    </Typography>
                  </Box>
                </MenuItem>
                {AppConfig.api.endpoint.indexOf("live") === -1 ? (
                  <>
                    <Divider />
                    <MenuItem onClick={resetEmails}>
                      <ListItemDecorator>
                        <i className="fa-solid fa-envelope"></i>
                      </ListItemDecorator>
                      <Box>
                        Reset all email
                        <Typography level="body3">using @ignore.it</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem onClick={removePhones}>
                      <ListItemDecorator>
                        <i className="fa-solid fa-phone-xmark"></i>
                      </ListItemDecorator>
                      <Box>
                        Remove all phones
                        <Typography level="body3">
                          To prevent sending SMS
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem onClick={fakeMusicians}>
                      <ListItemDecorator>
                        <i className="fa-duotone fa-face-disguise"></i>
                      </ListItemDecorator>
                      <Box>
                        Demo Data
                        <Typography level="body3">
                          Add 25 fake musicians
                        </Typography>
                      </Box>
                    </MenuItem>
                  </>
                ) : (
                  []
                )}
              </Menu>
              <Button
                onClick={() =>
                  dispatch(setFormOpen({ isOpen: true, formID: "group" }))
                }
                disabled={readOnly}
                size="sm"
                variant="soft"
                color="neutral"
                startDecorator={<i className="fa-solid fa-plus"></i>}
              >
                <Hidden smDown>Add</Hidden> Group
              </Button>
              <Button
                disabled={readOnly}
                onClick={() =>
                  dispatch(setFormOpen({ formID: "musician", isOpen: true }))
                }
                size="sm"
                startDecorator={<i className="fa-solid fa-plus"></i>}
              >
                <Hidden smDown>Add</Hidden> Musician
              </Button>
            </Box>
          </Box>
          <Hidden smDown>
            <Tabs
              value={tabIndex}
              onChange={(e, v) => {
                console.log(v);
                dispatch(setTabIndex({ tabID: "musicians", value: v }));
              }}
            >
              <Tab label="All" value={-1} />
              <Tab label="Subs" value={0} />
              {groups.map((g) => (
                <Tab
                  onDoubleClick={() => {
                    dispatch(setSelectedGroupID(g.id));
                    dispatch(setFormOpen({ isOpen: true, formID: "group" }));
                  }}
                  label={
                    <Tooltip title="Double click to rename">
                      <Typography
                        endDecorator={
                          <Sheet
                            variant="soft"
                            sx={{
                              borderRadius: "6px",
                              pl: 0.5,
                              pr: 0.5,
                              height: 16,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              fontWeight: 600,
                              fontSize: 10,
                              color: "white",
                              opacity: 0.3,
                              background: "#616161",
                            }}
                          >
                            {
                              musicianGroups.filter((e) => e.groupID === g.id)
                                .length
                            }
                          </Sheet>
                        }
                        sx={{
                          fontSize: "inherit",
                          fontWeight: "inherit",
                          color: "inherit",
                        }}
                      >
                        {g.name}
                      </Typography>
                    </Tooltip>
                  }
                  value={g.id}
                  key={g.id}
                />
              ))}
            </Tabs>
          </Hidden>
          <Divider />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            height: 0,
            minHeight: "auto",
          }}
        >
          <MusiciansDataGrid autoheight={false} groupID={tabIndex} />
        </Box>
      </Box>
    </>
  );
}
