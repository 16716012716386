import {
  Button,
  Chip,
  Divider,
  IconButton,
  Input,
  Link,
  List,
  ListItemButton,
  Typography,
} from "@mui/joy";
import { Box } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useFamilies } from "redux/family/familyHooks";
import { useInstruments } from "redux/instrument/instrumentHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionInstruments } from "redux/sectionInstrument/sectionInstrumentHooks";

export function InstrumentPicker({
  onSelect,
  sectionID,
  familyID,
  hideTitle,
  instrumentIDs = [],
  hideToolbar = false,
  clearInstruments = false,
}: {
  onSelect: (i: number[] | null) => void;
  instrumentIDs?: number[];
  sectionID?: number;
  familyID?: number;
  hideTitle?: boolean;
  hideToolbar?: boolean;
  clearInstruments?: boolean;
}) {
  const { families, familiesMap } = useFamilies();
  const { sectionsMap, sections } = useSections();
  const { sectionInstruments } = useSectionInstruments();
  const { instruments, instrumentsMap } = useInstruments();
  const [searchText, setSearchText] = useState("");
  const [selectedFamilyID, setSelectedFamilyID] = useState<number>();
  const [selectedSectionID, setSelectedSectionID] = useState<number>();
  const [selectedInstruments, setSelectedInstruments] = useState<
    Dictionary<boolean>
  >({});

  useEffect(() => {
    if (instrumentIDs?.length) setSelectedFamilyID(-1);
  }, []);

  useEffect(() => {
    {
      setSelectedSectionID(sectionID);
      const section = sectionsMap[sectionID];
      if (section?.familyID) setSelectedFamilyID(section?.familyID);
    }
  }, [sectionID, sectionsMap]);

  useEffect(() => {
    if (familyID) setSelectedFamilyID(familyID);
  }, [familyID]);

  const instrumentsIds = Object.keys(selectedInstruments)
    .filter((i) => selectedInstruments[i])
    .map((str) => parseInt(str));

  const handleOnSelect = () => {
    onSelect([...instrumentIDs, ...instrumentsIds]);
  };

  return (
    <>
      {!hideTitle ? <Typography level="h6">Add Instruments</Typography> : []}
      <Box sx={{ p: 1 }}>
        <Input
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
          variant="soft"
          size="sm"
          autoFocus
          placeholder="Search"
          endDecorator={
            <IconButton
              tabIndex={-1}
              onClick={() => setSearchText("")}
              variant="plain"
              color="neutral"
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          }
        />
      </Box>
      {!hideToolbar ? (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              pl: 1,
              pb: 1,
              overflow: "auto",
              flexShrink: 0,
            }}
          >
            {instrumentIDs?.length > 0 ? (
              <Button
                tabIndex={-1}
                size="sm"
                onClick={() => setSelectedFamilyID(-1)}
                sx={{ flexShrink: 0 }}
                color={selectedFamilyID === -1 ? "primary" : "neutral"}
                variant={selectedFamilyID === -1 ? "solid" : "soft"}
                key={-1}
              >
                Selected
              </Button>
            ) : (
              []
            )}
            {families.map((f) => (
              <Button
                tabIndex={-1}
                size="sm"
                onClick={() => setSelectedFamilyID(f.id)}
                sx={{ flexShrink: 0 }}
                color={selectedFamilyID === f.id ? "primary" : "neutral"}
                variant={selectedFamilyID === f.id ? "solid" : "soft"}
                key={f.id}
              >
                {f.name}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              pl: 1,
              pb: 1,
              overflow: "auto",
              flexShrink: 0,
            }}
          >
            {sections
              .filter((s) => selectedFamilyID === s.familyID)
              .map((s) => (
                <Chip
                  tabIndex={-1}
                  size="sm"
                  onClick={() => setSelectedSectionID(s.id)}
                  sx={{ flexShrink: 0 }}
                  color={selectedSectionID === s.id ? "primary" : "neutral"}
                  variant={selectedSectionID === s.id ? "solid" : "soft"}
                  key={s.id}
                >
                  {s.name}
                </Chip>
              ))}
          </Box>
          <Divider />
        </>
      ) : (
        []
      )}
      <Box sx={{ flexGrow: 1, overflow: "auto", maxHeight: 600 }}>
        <List size="sm">
          {selectedFamilyID === -1
            ? instrumentIDs.map((id) => {
                const i = instrumentsMap[id];
                return (
                  <ListItemButton
                    key={i.id}
                    variant={selectedInstruments[i.id] ? "soft" : "plain"}
                    color={selectedInstruments[i.id] ? "primary" : "neutral"}
                    onClick={() =>
                      setSelectedInstruments((_s) => ({
                        ..._s,
                        [i.id]: !(_s[i.id] ?? false),
                      }))
                    }
                  >
                    {`${i.name}`}
                  </ListItemButton>
                );
              })
            : []}
          {instruments
            .filter((i) =>
              selectedFamilyID ? i.familyID === selectedFamilyID : true
            )
            .filter((i) => {
              if (!selectedSectionID) return true;
              const _sectionInstruments = sectionInstruments.filter(
                (si) => si.instrumentID === i.id
              );
              for (const key in _sectionInstruments) {
                if (
                  Object.prototype.hasOwnProperty.call(_sectionInstruments, key)
                ) {
                  const sectionInstrument = _sectionInstruments[key];
                  if (selectedSectionID === sectionInstrument.sectionID)
                    return true;
                }
              }
              return false;
            })
            .filter((i) =>
              searchText
                ? `${i.name} ${
                    familiesMap[i.familyID]?.name
                  } ${sectionInstruments
                    .filter((si) => si.instrumentID === i.id)
                    .reduce((a, i) => {
                      a.push(sectionsMap[i.sectionID]?.name);
                      return a;
                    }, [])
                    .join(" ")}`
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) >= 0
                : true
            )
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((i) => {
              return (
                <ListItemButton
                  key={i.id}
                  variant={selectedInstruments[i.id] ? "soft" : "plain"}
                  color={selectedInstruments[i.id] ? "primary" : "neutral"}
                  onClick={() =>
                    setSelectedInstruments((_s) => ({
                      ..._s,
                      [i.id]: !(_s[i.id] ?? false),
                    }))
                  }
                >
                  {`${i.name}`}
                </ListItemButton>
              );
            })}
        </List>
      </Box>
      <Box sx={{ p: 1, textAlign: "center" }}>
        {selectedFamilyID === -1 ? (
          <Button
            onClick={() => onSelect([])}
            disabled={instrumentIDs?.length === 0}
            fullWidth
            color="neutral"
          >
            Clear All
          </Button>
        ) : (
          <Button
            onClick={handleOnSelect}
            disabled={instrumentsIds.length === 0}
            fullWidth
          >
            Add
            {instrumentsIds.length
              ? ` ${instrumentsIds.length} instrument${
                  instrumentsIds.length > 1 ? "s" : ""
                }`
              : ""}
          </Button>
        )}
        {clearInstruments ? (
          <Link
            sx={{ color: "#424242" }}
            onClick={() => onSelect(null)}
            color="neutral"
            level="body3"
          >
            Clear Instruments
          </Link>
        ) : (
          []
        )}
      </Box>
    </>
  );
}
